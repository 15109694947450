import { useState, useRef,useEffect } from 'react';
import Container from './pinPost.js';
import { useSelector } from 'react-redux';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import * as React from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import { formData } from '../../../utils/extactFormData';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PostCard from '../../../components/analysisComponents/PostCard/PostCard.jsx';

var searchPostData = ' ';

const PinPost = () => {
  const TOKEN = useSelector((state) => state.auth.token);
  const [posts, setPosts] = useState([[]]);

  const [FirstHelper,setFirstHelper]=useState(true)
  

  function setter(data) {
    searchPostData = data;
    Call();
  }

  function Call() {
   
    if (searchPostData.length > 1) {
      axios
        .get(
          `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/getCommunityPosts?postName=${searchPostData}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          },
        )
        .then((res) => {
          setFirstHelper(false)
          setPosts(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }


  function getPinnedPost() {
  
      axios
        .get(
          `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/getPinPost`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          },
        )
        .then((res) => {
          console.log("pinned post: ",res.data)
          setPosts(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    
  }

  useEffect(()=>{

    getPinnedPost()

  },[])

  function Refetch()
  {
    getPinnedPost()

  }

  function ReloadPost() {
    Call();
  }

  console.log("Helper status : ",FirstHelper)

 

  return (
    <Container>
      {/* <SideBar /> */}
      <div className="right">
      <div className='position-sticky top-0' style={{zIndex:'5'}}>
        <SearchAppBar cb={setter} />
        </div>
        {/* <div className='position-sticky top-0' style={{zIndex:'5'}}> */}
        <div className="posts">
          {posts.length > 0 && posts[0].content ? (
            posts.map((post, index) => {
              return (
                <div className="post" key={index}>
                  <PostCard Setter={ReloadPost} post={post} from="pinPost"  Refetch={Refetch} FirstHelper={FirstHelper}/>
                </div>
              );
            })
          ) : (
            <h1>No Posts are Available</h1>
          )}
        </div>
      </div>
    </Container>
  );
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchAppBar = React.memo(
  ({ cb }) => {
    const handleInputChange = (e) => {
      cb(e.target.value);
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: '#7451f8' }}>
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleInputChange}
              />
            </Search>
          </Toolbar>
        </AppBar>
      </Box>
    );
  },
  () => true,
);
export default PinPost;
