import styled from 'styled-components';

const PostsContainer = styled.div`
  width: 100%;
  text-align: start;    
  background-color: #f8f9fe;

`;

export default PostsContainer;
    // 