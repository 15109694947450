import ShowListingContainer from './ShowListing';
import { HEADER_URL } from '../../../api/constants.js';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
var listingUrl = `${HEADER_URL}/${localStorage.getItem(
  'role',
)}/dashboard/showlistings`;
import InfiniteScroll from 'react-infinite-scroll-component';
import * as React from 'react';
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ImLocation2 } from 'react-icons/im';
import { SlCalender } from 'react-icons/sl';
import ImageModel from '../ImageModel/ImageModel';
import { useNavigate } from 'react-router-dom';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import RewardModal from '../RewardModal/RewardModal';
const rewardUrl = `${HEADER_URL}/${localStorage.getItem(
  'role',
)}/dashboard/superspaark/give-scratchcard`;
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//Loading
import PropagateLoader from 'react-spinners/PropagateLoader';

function ShowListing() {
  //Navigataion and filter related
  const navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.token);
  const [search, setSearch] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [source, setSource] = React.useState('All');
  const [count, setCount] = useState(0);
  const handleChange = (event) => {
    setSource(event.target.value);
  };

  //Infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [maxLength, setMaxLength] = useState(0);
  const [page, setPage] = useState(1);

  //Modal image
  const [modelImage, setModelImage] = useState('');
  const [modelOpen, setModelOpen] = useState(false);

  //Loading
  let [loading, setLoading] = useState(true);
  const override = {
    display: 'block',
    position: 'absolute',
    borderColor: 'red',
    top: '50%',
    bottom: '50%',
    left: '50%',
    right: '50%',
  };
  // fetching data function
  const getListing = async () => {
    var url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/showlistings`;
    url += '?page=' + page + '&source=' + source;
    if (search.trim() != '') {
      url += '&search=' + search.trim();
    }
    if (from != '') {
      url += '&from=' + from;
    }
    if (to != '') {
      url += '&to=' + to;
    }
    console.log(url);
    setLoading(true);
    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setCount(res.data.count);
        res.data.listings.length < 5 ? setHasMore(false) : setHasMore(true);
        setData([...data, ...res.data.listings]);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
    setLoading(false);
  };

  //reward modal
  const [rewardObj, setRewardObj] = useState({});
  const [rewardIdx, setRewardIdx] = useState(-1);
  const [rewardModal, setRewardModal] = useState(false);
  // Updating Reward Modal
  const sendReward = async (amount) => {
    console.log('amount', amount);
    setLoading(true);
    await axios
      .post(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/superspaark/give-scratchcard`,
        {
          type: 'listingReward',
          name: rewardObj.name,
          amount: amount,
          title: 'Listing reward',
          body: `you are rewarded with amount ${amount}`,
          listingId: rewardObj._id,
          userId: rewardObj.userDetails._id,
        },
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        if (res.data.success == 'Scratch card added successfuly') {
          rewardObj.didRewardGet = true;
          var temp = data;
          temp.splice(rewardIdx, 1, rewardObj);
          setData([...temp]);
        }
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
    setLoading(false);
  };

  useEffect(() => {
    getListing();
  }, [maxLength]);

  //Main Contnet
  return (
    <ShowListingContainer>
      <PropagateLoader
        color="#36d7b7"
        loading={loading}
        cssOverride={override}
        size={20}
      />

      <h3>Listing ( {count} ) </h3>
      {/* filter */}
      <div className="d-flex justify-content-around align-items-end mt-3">
        <div style={{ width: '400px' }}>
          <input
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            className="form-control"
            placeholder="Find Cars, Mobile Phones and more..."
          />
        </div>

        <div className="">
          <label>From :</label>
          <input
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            max={moment().format().slice(0, 16)}
            type="date"
            name="from"
            id="from"
            className="form-control"
          />
        </div>
        <div className="">
          <label>To :</label>
          <input
            value={to}
            onChange={(e) => {
              setTo(e.target.value);
            }}
            max={moment().format().slice(0, 16)}
            type="date"
            name="to"
            id="to"
            className="form-control"
          />
        </div>

        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Source
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={source}
              onChange={handleChange}
              label="Source"
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'User'}>User</MenuItem>
              <MenuItem value={'TeleCaller'}>TeleCaller</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Button
          variant="contained"
          onClick={() => {
            setPage(1);
            setMaxLength(maxLength + 1);
            setData([]);
            setHasMore(true);
          }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setSearch('');
            setFrom('');
            setTo('');
            setSource('All');
            setPage(1);
            setData([]);
            setMaxLength(maxLength + 1);
            setHasMore(true);
          }}
        >
          Reset
        </Button>
      </div>

      {/* display Data */}

      <InfiniteScroll
        dataLength={data.length != 0 && data.length}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'start',
          marginTop: '40px',
          gap: 30,
          paddingLeft: '40px',
        }}
        next={() => {
          getListing();
        }}
        hasMore={hasMore}
        loader={
          <div
            className={page == 1 ? 'd-none' : 'd-flex justify-content-center'}
          >
            <div className="d-flex flex-column gap-3">
              <CircularProgress />
              LOADING . . .
            </div>
          </div>
        }
      >
        {data.map((ele, idx) => {
          return (
            <div className="card " style={{ width: '300px' }} key={idx}>
              <div className="card-img-top">
                {ele.photos.length > 1 && (
                  <div id={idx} className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      {ele.photos.map((item, itemIdx) => {
                        if (itemIdx == 0)
                          return (
                            <div
                              className="carousel-item active"
                              key={itemIdx}
                              onClick={() => {
                                setModelImage(item);
                                setModelOpen(true);
                              }}
                            >
                              <img
                                className="d-block "
                                width="auto"
                                height="220px"
                                src={item}
                                alt="First slide"
                              />
                            </div>
                          );
                        else {
                          return (
                            <div
                              className="carousel-item "
                              key={itemIdx}
                              onClick={() => {
                                setModelImage(item);
                                setModelOpen(true);
                              }}
                            >
                              <img
                                className="d-block "
                                width="auto"
                                height="220px"
                                onClick={() => {
                                  setModelImage(item);
                                  setModelOpen(true);
                                }}
                                src={item}
                                alt="First slide"
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href={`#${idx}`}
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href={`#${idx}`}
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                )}
                {ele.photos.length == 1 && (
                  <div style={{ backgroundColor: 'rgb(173,173,173' }}>
                    <img
                      className="d-block mx-auto  "
                      width="auto"
                      height="220px"
                      onClick={() => {
                        setModelImage(ele.photos[0]);
                        setModelOpen(true);
                      }}
                      src={ele.photos[0]}
                      alt=""
                    />
                  </div>
                )}
              </div>

              <div className="card-body ">
                <div className="d-text text-dark">
                  {' '}
                  {ele.productName.trim().slice(0, 13)}{' '}
                  {ele.productName.trim().length > 13 && <span>...</span>}{' '}
                </div>
                <div
                  className="d-text text-info font-weight-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('../listing', { state: { listId: ele._id } });
                  }}
                >
                  ₹ {ele.price}
                </div>
                <div className="text-danger d-flex justify-content-center">
                  {ele.source == 'user' &&
                    ele.didRewardGet == false &&
                    localStorage.getItem('role') == 'analysisuser' && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          setRewardModal(true);
                          setRewardIdx(idx);
                          setRewardObj(ele);
                        }}
                      >
                        Reward
                      </Button>
                    )}
                  {ele.source == 'user' &&
                    ele.didRewardGet == true &&
                    localStorage.getItem('role') == 'analysisuser' && (
                      <Button variant="contained" disabled color="success">
                        Reward
                      </Button>
                    )}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <ImLocation2 /> <div>{ele.locationName}</div>
                </div>

                <div className="d-flex gap-2 align-items-center">
                  <SlCalender />{' '}
                  <div>{moment(ele.publishedAt).format('lll')}</div>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
      <ImageModel
        image={modelImage}
        width={300}
        height={500}
        open={modelOpen}
        setOpen={setModelOpen}
      />
      <RewardModal
        open={rewardModal}
        setOpen={setRewardModal}
        handleReward={sendReward}
      />
    </ShowListingContainer>
  );
}

export default ShowListing;

// {!ele.didRewardget && <Button variant="text" onClick={()=>{setRewardModal(true);setRewardIdx(idx);setRewardObj(ele)}} >
//                   <AiOutlineHeart style={{display:'block',color:'red',height:'25px',width:'auto'}}/>{ele.didRewardget}
//                 </Button>}
// {ele.didRewardget &&
//   <AiFillHeart style={{display:'block',color:'red',height:'25px',width:'auto'}}/>
// }
