import styled from "styled-components";


const Container = styled.div`

    display: flex;
    width:100%;
    margin:15px;
    justify-content: center;


    .right{
        display: flex;
        flex-direction: column;
       
        margin-top: 50px;
    }

    .date-container{
        margin-left:10%;
        margin-bottom: 3%;
    }

    .chart-container{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        gap: 5%;
        flex-wrap: wrap;
    }

    .chooser{
        display: flex;
        justify-content: flex-start;
        margin-left: 10%;
        gap:10px
    }
        
`

export default Container;