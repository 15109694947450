import { createSlice } from '@reduxjs/toolkit';

export const chartSlice = createSlice({

    name: "Chart",
    initialState: {
        stroke: "#8884d8",
        fill: "#8884d8",
        data: [{}],
        dataKeyTop: 'count',
        dataKeyBottom:'_id'
    },
    reducers: {
        setGraphData: (state, action) => {
            state.data = action.payload.data;
            state.fill = action.payload.fill;
            state.stroke = action.payload.stroke;
            state.dataKeyTop = action.payload.dataKeyTop;
            state.dataKeyBottom = action.payload.dataKeyBottom;
        },
    }
});

export const { setGraphData } = chartSlice.actions;

export default chartSlice.reducer;