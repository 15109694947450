import axios from "axios";
import PostsContainer from "./Posts";


function Posts(){

    var getPosts=async()=>{


    }

    return <PostsContainer>
posts
    </PostsContainer>
}

export default Posts;