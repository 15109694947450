import styled from "styled-components";


const Dashboard=styled.div`

    width: 100%;
  text-align: start;    
  background-color: #f8f9fe;

.active{
    cursor:pointer;
    background-color: #6b6bcf;
    font-size:20px;
    border-radius: 14px;
    padding:0px 10px;
}
.inactive{
    cursor:pointer;

    font-size:18px;
    border-radius: 14px;
    padding:0px 10px;
}



`;



export default Dashboard;