
import styled from "styled-components";

const Container = styled.div`

   display: flex;   
   flex-direction: column;
   border-radius: 30px;
   width:800px;
   justify-content: center;

   .content{
    width: 500px;
   }

   .icon {
      font-size: 15px;
      color: #0C9780 !important;
      background-color: #D8ECD8 !important;
      align-self: center;
      border-radius: 50px;
      padding: 3px;
    }

    .icon-edit{
      font-size: 13px;
      color: #1C64F2 !important;
      background-color: #C3DDFC !important;
      align-self: center;
      border-radius: 50px;
      padding: 3px;
    }

    .icon-edit:hover{
        font-size: 13px;
        color: #C3DDFC !important;
        background-color: #1C64F2 !important;
        align-self: center;
        border-radius: 50px;
        padding: 3px;
    }

    .icon-del{
        font-size: 13px;
        color: #BE421B !important;
        background-color: #FDE8E8 !important;
        align-self: center;
        border-radius: 50px;
        padding: 3px;
    }

    .icon-del:hover{
        font-size: 13px;
        color: #FDE8E8 !important;
        background-color: #BE421B !important;
        align-self: center;
        border-radius: 50px;
        padding: 3px;
    }

    .icon-approve{
        font-size: 13px;
        color: #0C9780 !important;
        background-color: #D8ECD8 !important;
        align-self: center;
        border-radius: 50px;
        padding: 3px;
    }
    .icon-reschedule{
        font-size: 13px;
        color: #0C9780 !important;
        background-color: #D8ECD8 !important;
        align-self: center;
        border-radius: 50px;
        padding: 3px;

        &:hover{
          background-color: #0C9780 !important;
          color: #D8ECD8 !important;
        }
    }

    .icon-approve:hover{
        font-size: 13px;
        color: #D8ECD8 !important;
        background-color: #0C9780 !important;
        align-self: center;
        border-radius: 50px;
        padding: 3px;
    }


    .text{
      font-family: 'Poppins', sans-serif;
      font-weight: 300; 
      margin-top: 1rem;
      font-size: 12px;
      letter-spacing: 0.2px;
    }

    .catre{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap:5px;
        .status {
            padding: 3px;
            border-radius: 5px;
            text-align: center;
            font-size: 10px;

            &.Approved {
            color: green;
            background-color: rgba(0, 128, 0, 0.151);
            margin-top:5px
            }
            &.Pending {
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.103);
            margin-bottom: 5px;
            }
        }
    }

    .dt{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap:5px;

      .time{
        font-family: 'Poppins', sans-serif;
        font-weight: 600; 
        margin-bottom: 0px;
        font-size: 20px;
      }
      .date{
        font-family: 'Poppins', sans-serif;
        font-weight: 300; 
        margin-bottom: 0px;
        font-size: 10px;
      }
    }


`

export default Container;