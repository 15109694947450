import React, { useState } from 'react';

const DateNavWiz = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSearch = () => {
    // Perform search with the selected date range
    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
  };

  return (
    <div className="" style={{ maxWidth: '800px' }}>
      <div className="d-flex  mt-3  rounded p-3 w-50 justify-content-between">
        <div className=" d-flex">
          <input
            type="text"
            id="start-date"
            name="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border  btn  rounded-3"
            placeholder="From"
            onFocus={(e) => (e.target.type = 'date')}
          />
        </div>

        <div className="ms-3 d-flex ">
          <input
            type="text"
            id="end-date"
            name="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border btn  rounded-3"
            placeholder="To"
            onFocus={(e) => (e.target.type = 'date')}
          />
        </div>

        <button className="ms-2 BlueBtn rounded-5 ms-2 " onClick={handleSearch}>
          Search
        </button>
        <button className="  ms-3 BlueBtn rounded-5" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default DateNavWiz;
