import styled from 'styled-components';

const LeadsDeatilsContainer = styled.div`
 
  background-color: #f8f9fe;
  padding:10px;
  font-size: 12px;
  width: 100%;  
  .date{

    font-size:20px
  }
  
 
`;

export default LeadsDeatilsContainer;
