import React, { useEffect, useState } from 'react';
import WizContainer from './WizardFirstPage';
import { useLocation, useNavigate } from 'react-router-dom';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckboxComponent from './SecondPageRows';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import spaarksLogo from '../../../assets/spaarksLogo.svg';
import { typographyClasses } from '@mui/material';

function SecondScreen() {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log('INCOMING', state.nextData);

  const [data, setData] = useState(state.nextData);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [currIdx, setCurrIdx] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);


  function onSubmit(e) {
    e.preventDefault();

  }


  async function navigateToThirdPage() {

    console.log("second Page click button");
    console.log(data);
    let nextData = data.map(post => {
      let newPost = post.serviceProviders.filter(ele => (ele.sendMessage || ele.sendSpaarks || ele.sendWhatsapp))
      return { ...post, serviceProviders: newPost }
    })

    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/sendMessageByWizard`;
    axios(url, {
      method: 'POST',
      data: { arrOfReq: nextData },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err);
        alert(err);
      })

    console.log("ND")
    console.log(nextData)

    navigate('/wizard/wizardThird', { state: { nextData } });
  }

  const handleCheckboxChange = (serviceProviderIndex, field) => {
    const updatedServiceProviders = [...selectedUser.serviceProviders];
    updatedServiceProviders[serviceProviderIndex][field] =
      !updatedServiceProviders[serviceProviderIndex][field];
    setSelectedUser({
      ...selectedUser,
      serviceProviders: updatedServiceProviders,
    });
  };

  //updating serviceProviders post on right
  async function updateServicePost(data, i) {
    // postId
    if (!data?.postId) return;
    let id = data?.postId;
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/service-providers`;
    console.log(url);
    let res = await axios(url, {
      method: 'POST',
      data: { postId: id },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    let response = res.data.data.map((e) => {
      // return { ...e, isSpaarksUser: true, callStatus: false };
      return {
        ...e,
        isSpaarksUser: true,
        sendMessage: false,
        sendWhatsapp: false,
        sendSpaarks: false,
        callStatus: 'notContacted',
      };
    });
    // setServiceProviders(response);
    // data.serviceProviders = [...data.serviceProviders, ...response];
    console.log('BEFORE:', data.serviceProviders);
    data.serviceProviders = [...data.serviceProviders, ...response];
    console.log('AFTER:', data.serviceProviders);

    // return ([...data,...response]);
    return data;
  }

  useEffect(() => {
    console.log('-------------------');
    console.log('Prev Data', data);
    console.log('-------------------');
    async function getDbData() {
      let newData = [];
      let prevData = [...data];
      for (let i = 0; i < data.length; i++) {
        let temp = await updateServicePost(data[i], i);
        // prevData[i].serviceProviders = [ ...prevData[i].serviceProviders, ...temp.serviceProviders ];
        // newData.push(temp)
      }
      console.log('-----entireData--------');
      console.log(prevData);
    }
    setLoading(true);
    getDbData().then((e) => {
      setLoading(false);
    });

    return () => {
      // Cleanup logic (e.g., unsubscribe from event, clear timeouts/intervals)
      setData(null);
    };
  }, []);
  if (loading) {
    return <h2 style={{ color: 'black' }}>Loading...</h2>;
  }

  return (
    <WizContainer>
      {loading ? (
        <h1>Loading</h1>
      ) : (
        <>
          <div class="container-fluid">
            <div class="row ">
              <div className="col-5 ms-2">
                <div className="accepted-spaarks-info mb-3 ">
                  Accepted Spaarks
                </div>
                <table className="row">
                  <thead className=" bg-black text-light pt-1 pb-1  ">
                    <tr className="accSpp ">
                      <th className="col">Details</th>
                      <th className="col">Category</th>
                    </tr>
                  </thead>
                  <tbody className="shadow-sm overflow-auto overflow-controller mb-5">
                    {data.map((ele, idx) => {
                      console.log('fffffffffff', idx, ele);
                      return (
                        <tr
                          className="accSpp animate d-flex justify-content-between mt-2 shadow-sm p-2  "
                          style={{ height: '90px' }}
                          onClick={() => {
                            setServiceProvider(ele.serviceProviders);
                            setCurrIdx(idx);
                            setSelectedUser(ele);
                            // setData((prevData)=>([...prevData,...selectedUser]))
                          }}
                        >
                          <td>
                            <div className="col">
                              <div className="d-flex ">
                                <div>
                                  <img
                                    className="profile"
                                    src={ele.profilePic}
                                  />
                                </div>
                                <p className="profile-name mt-3 ms-3">
                                  {ele.name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="col">
                              <div className="category p-2 rounded">
                                {ele.category}
                              </div>
                              <div className="loc p-2 mb-2 font-xsss ">
                                <svg
                                  width="9"
                                  height="13"
                                  viewBox="0 0 9 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.26697 12.4469C3.43146 10.7315 2.53562 9.67164 1.78892 8.78806C0.759876 7.5707 0 6.67149 0 4.90738C0 3.66491 0.503879 2.53972 1.31811 1.72533C2.13234 0.911105 3.25753 0.407227 4.5 0.407227C5.74247 0.407227 6.86766 0.911105 7.68189 1.72533C8.49612 2.53972 9 3.66491 9 4.90738C9 6.67149 8.24013 7.5707 7.21109 8.78806C6.46438 9.67164 5.56854 10.7315 4.73303 12.4469C4.67041 12.5755 4.51516 12.6291 4.38655 12.5665C4.33235 12.5401 4.29147 12.4973 4.26697 12.4469ZM2.18393 8.45367C2.88195 9.27969 3.70705 10.2558 4.5 11.7602C5.29295 10.2558 6.11805 9.27969 6.81607 8.45367C7.77376 7.32052 8.48127 6.48363 8.48127 4.90738C8.48127 3.80776 8.03557 2.81225 7.31535 2.09188C6.59513 1.37165 5.59962 0.925957 4.5 0.925957C3.40038 0.925957 2.40487 1.37165 1.68465 2.09188C0.964428 2.81225 0.51873 3.80776 0.51873 4.90738C0.51873 6.48363 1.22624 7.32052 2.18393 8.45367ZM4.5 3.09626C4.99975 3.09626 5.45279 3.29913 5.78044 3.62678C6.10809 3.95443 6.31096 4.40763 6.31096 4.90738C6.31096 5.40712 6.10809 5.86002 5.78044 6.18767C5.45279 6.51532 4.99975 6.71819 4.5 6.71819C4.00026 6.71819 3.54721 6.51532 3.21956 6.18767C2.89191 5.86002 2.68904 5.40712 2.68904 4.90738C2.68904 4.40763 2.89191 3.95443 3.21956 3.62678C3.54721 3.29913 4.00026 3.09626 4.5 3.09626ZM5.4139 3.99332C5.18026 3.75968 4.85705 3.61499 4.5 3.61499C4.14295 3.61499 3.81974 3.75968 3.5861 3.99332C3.35245 4.22697 3.20777 4.55033 3.20777 4.90738C3.20777 5.26427 3.35245 5.58749 3.5861 5.82113C3.81974 6.05477 4.14311 6.19946 4.5 6.19946C4.8569 6.19946 5.18026 6.05477 5.4139 5.82113C5.64755 5.58749 5.79223 5.26427 5.79223 4.90738C5.79223 4.55033 5.64755 4.22697 5.4139 3.99332Z"
                                    fill="black"
                                  />
                                </svg>
                                {ele.placeName}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="col-5 ms-2">
                <div className="business-info mb-3">Businesses</div>
                <table className="row">
                  <thead className=" bg-black text-light pt-1 pb-1  ">
                    <tr className="accSpp ">
                      <th className="col">Details</th>
                      <th className="col pe-5 ms-0">Contact</th>
                      <th className="col "></th>
                    </tr>
                  </thead>

                  <tbody
                    className="shadow-sm overflow-auto overflow-controller"
                    style={{ maxHeight: '500px' }}
                  >
                    {data[currIdx]?.serviceProviders ? (
                      data[currIdx].serviceProviders.map((ele, index) => {
                        return (
                          <>
                            <tr className=" mt-2 shadow-sm animate  pb-2 pt-2 accSpp d-flex justify-content-between">
                              <td>
                                <div className="d-flex">
                                  <div>
                                    <img
                                      className="profile border border-primary"
                                      src={ele.profilePic}
                                    />
                                  </div>
                                  <p className="profile-name mt-2 ms-2">
                                    {ele.name}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="">
                                  <SmsIcon />
                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={ele.sendMessage} />
                                    }
                                    // label="sendMessage"
                                    name="sendMessage"
                                    onChange={() =>
                                      handleCheckboxChange(index, 'sendMessage')
                                    }
                                    sx={{
                                      height: '50px',
                                      width: '50px',
                                    }}
                                  />
                                  <WhatsAppIcon />

                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={ele.sendWhatsapp} />
                                    }
                                    // label="sendWhatsapp"
                                    name="sendWhatsapp"
                                    onClick={() =>
                                      handleCheckboxChange(
                                        index,
                                        'sendWhatsapp',
                                      )
                                    }
                                    sx={{
                                      height: '50px',
                                      width: '50px',
                                    }}
                                  />
                                  {ele.isSpaarksUser ? (
                                    <>
                                      <img src={spaarksLogo} />
                                      <FormControlLabel
                                        control={
                                          <Checkbox checked={ele.sendSpaarks} />
                                        }
                                        // label="sendSpaarks"
                                        name="sendSpaarks"
                                        onClick={() =>
                                          handleCheckboxChange(
                                            index,
                                            'sendSpaarks',
                                          )
                                        }
                                        sx={{
                                          height: '50px',
                                          width: '50px',
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <div style={{ height: "50px", width: "50px", border: "1px solid red" }}></div>
                                    // null
                                  )}

                                  {/* <FormControlLabel
                              control={<Checkbox checked={ele.callStatus} />}
                              label="callStatus"
                              name="callStatus"
                              onClick={() => handleCheckboxChange(index,'callStatus')}
                            /> */}
                                </div>
                              </td>
                              {/* <CheckboxComponent currIdx={currIdx} setData={setData} data={data} ele={ele} handleCheckBoxes={handleCheckBoxes} idx={idx} /> */}
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <h3>Nothing to Show</h3>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-1">
                <div className="mt-auto">
                  <button
                    className="send-msg-btn border-0 position-absolute bottom-0 ps-3 pe-3 pt-2 pb-2"
                    onClick={navigateToThirdPage}
                  >
                    Send messages
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </WizContainer>
  );
}

export default SecondScreen;
