import StoryContainer from './Story';
import { HEADER_URL } from '../../../api/constants.js';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment/moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import RewardModal from '../RewardModal/RewardModal';
import ImageModel from '../ImageModel/ImageModel';
import { useDebugValue } from 'react';
const storyUrl = `${HEADER_URL}/${localStorage.getItem('role')}/stories`;
const delStoryUrl = `${HEADER_URL}/${localStorage.getItem(
  'role',
)}/stories/delete`;
const rewardUrl = `${HEADER_URL}/${localStorage.getItem(
  'role',
)}/dashboard/superspaark/give-scratchcard`;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

function Story() {
  //Rewards Related
  const [rewardObj, setRewardObj] = useState({});
  const [rewardIdx, setRewardIdx] = useState(-1);
  const [rewardModal, setRewardModal] = useState(false);

  // Reward Function
  const sendReward = async (amount) => {
    // console.log("amount",amount)

    await axios
      .post(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/superspaark/give-scratchcard`,
        {
          type: 'storyReward',
          name: rewardObj.name,
          amount: amount,
          title: 'story reward',
          body: `you are rewarded with amount ${amount}`,
          storyId: rewardObj._id,
          userId: rewardObj.userId,
        },
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        if (res.data.success == 'Scratch card added successfuly') {
          rewardObj.isRewarded = true;
          var temp = story;
          temp.splice(rewardIdx, 1, rewardObj);
          setStory([...temp]);
        }
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

  // Image modal related
  const [imgModel, setImgModel] = useState(false);
  const [image, setImage] = useState('');

  //Infinite scroll Related
  const [hasMore, setHasMore] = useState(true);
  const [story, setStory] = useState([]);
  const [page, setPage] = useState(1);
  const [maxLength, setMaxLength] = useState(0);

  //Fetch Stories
  const getStory = async () => {
    console.log('page', page);
    console.log('called');
    await axios
      .get(
        `${HEADER_URL}/${localStorage.getItem('role')}/stories` +
          `?page=${page}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        setMaxLength(res.data.count);
        res.data.response.length < 20 ? setHasMore(false) : setHasMore(true);
        setStory([...story, ...res.data.response]);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
    // let res=await axios.get(storyUrl+`?page=${page}`, { method: 'GET',headers: {'Content-Type': 'application/json', Authorization: `Bearer ${TOKEN}`,},    })
    // console.log(res.data)
    // setStory([...story,...res.data.response])
  };
  console.log('story', story);
  //Delete Story Related
  const reason = useRef();
  const [currIndex, setCurrIndex] = useState(-1);
  const [id, setId] = useState(0);

  //Delete function
  const delStory = async (obj) => {
    await axios
      .post(
        `${HEADER_URL}/${localStorage.getItem('role')}/stories/delete`,
        obj,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        // console.log(res.data)
        if (res.data.message == 'story deleted successfuly') {
          var temp = story;
          temp.splice(currIndex, 1);
          setStory([...temp]);
        }
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

  const handleDelete = () => {
    var obj = {};
    obj._id = id;
    obj.reason = reason.current.value.trim();
    delStory(obj);
  };

  //Image Modal related
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Fetching protected routes related values
  const TOKEN = useSelector((state) => state.auth.token);
  const sus_user_passkey = localStorage.getItem('sus_user_passkey');

  useEffect(() => {
    console.log('in use Effect');
    getStory();
  }, []);

  return (
    <StoryContainer>
      <h3 className="d-flex justify-content-center">Story ( {maxLength} )</h3>
      <div className="mt-3">
        <InfiniteScroll
          dataLength={story.length != 0 && story.length}
          style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}
          next={() => {
            console.log('in infinte scrooll');
            console.log('has more', hasMore);
            getStory();
          }}
          hasMore={hasMore}
          loader={
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column gap-3">
                <CircularProgress />
                LOADING . . .
              </div>
            </div>
          }
        >
          {story.map((ele, idx) => {
            if (ele.type == 'text') {
              return (
                <div
                  key={idx}
                  className="d-flex flex-column position-relative"
                  style={{
                    borderRadius: '15px',
                    height: '376.5px',
                    width: '200px',
                  }}
                >
                  <div
                    className="d-flex gap-3 align-items-center w-100    "
                    style={{
                      padding: '5px',
                      backgroundColor: '#302928',
                      borderRadius: '15px 15px 0px 0px',
                      opacity: '0.5',
                    }}
                  >
                    <img
                      src={ele.profilePic}
                      width="30px"
                      height="30px"
                      style={{
                        borderRadius: '50%',
                        boxShadow: '2px 2px 5px black',
                      }}
                      alt=""
                    />
                    <div className="d-flex flex-column align-items-start">
                      <div style={{ color: 'white', fontWeight: 'bold' }}>
                        {ele.name}
                      </div>
                      <div style={{ color: 'white', textAlign: 'left' }}>
                        {moment(ele.createdAt).calendar()}
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                      backgroundColor: ele.backgroundColor,
                      height: '100%',
                      borderRadius: '0px 0px 15px 15px',
                      color: ele.color,
                      fontSize: '14px',
                      width: '200px',
                      overflowWrap: 'break-word',
                      overflow:'scroll'
                    }}
                  >
                    {ele.content}
                  </div>
                  <div className="d-flex justify-content-around mt-1">
                    {!ele.isRewarded &&
                      localStorage.getItem('role') == 'analysisuser' && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setRewardModal(true);
                            setRewardObj(ele);
                            setRewardIdx(idx);
                          }}
                        >
                          Reward
                        </Button>
                      )}
                    {ele.isRewarded &&
                      localStorage.getItem('role') == 'analysisuser' && (
                        <Button variant="contained" disabled>
                          Reward
                        </Button>
                      )}
                    <IconButton
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setId(ele._id);
                        setCurrIndex(idx);
                        handleOpen();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            } else if (ele.type == 'image') {
              return (
                <div
                  key={idx}
                  className="d-flex flex-column position-relative"
                  style={{ borderRadius: '15px' }}
                >
                  <div
                    className="d-flex gap-3 align-items-center w-100  "
                    style={{
                      padding: '5px',
                      backgroundColor: '#302928',
                      borderRadius: '15px 15px 0px 0px',
                      opacity: '0.5',
                    }}
                  >
                    <img
                      src={ele.profilePic}
                      width="30px"
                      height="30px"
                      style={{
                        borderRadius: '50%',
                        boxShadow: '2px 2px 5px black',
                      }}
                      alt=""
                    />
                    <div className="d-flex flex-column align-items-start">
                      <div style={{ color: 'white', fontWeight: 'bold' }}>
                        {ele.name}
                      </div>
                      <div
                        style={{ color: 'white', textAlign: 'left' }}
                        className="ms-auto"
                      >
                        {moment(ele.createdAt).calendar()}
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '200px', height: '300px' }}>
                    <img
                      src={ele.image}
                      alt=""
                      style={{
                        width: '200px',
                        height: '300px',
                        borderRadius: '0px 0px 15px 15px',
                      }}
                      onClick={() => {
                        setImage(ele.image);
                        setImgModel(true);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-around mt-1">
                    {!ele.isRewarded &&
                      localStorage.getItem('role') == 'analysisuser' && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setRewardModal(true);
                            setRewardObj(ele);
                            setRewardIdx(idx);
                          }}
                        >
                          Reward
                        </Button>
                      )}
                    {ele.isRewarded &&
                      localStorage.getItem('role') == 'analysisuser' && (
                        <Button variant="contained" disabled>
                          Reward
                        </Button>
                      )}
                    <IconButton
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setId(ele._id);
                        setCurrIndex(idx);
                        handleOpen();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            } else if (ele.type == 'video') {
              return (
                <div
                  key={idx}
                  className="d-flex flex-column position-relative"
                  style={{ borderRadius: '15px' }}
                >
                  <div
                    className="d-flex gap-3 align-items-center w-100  "
                    style={{
                      padding: '5px',
                      backgroundColor: '#302928',
                      borderRadius: '15px 15px 0px 0px',
                      opacity: '0.5',
                    }}
                  >
                    <img
                      src={ele.profilePic}
                      width="30px"
                      height="30px"
                      style={{
                        borderRadius: '50%',
                        boxShadow: '2px 2px 5px black',
                      }}
                      alt=""
                    />
                    <div className="d-flex flex-column align-items-start">
                      <div style={{ color: 'white', fontWeight: 'bold' }}>
                        {ele.name}
                      </div>
                      <div
                        style={{ color: 'white', textAlign: 'left' }}
                        className="ms-auto"
                      >
                        {moment(ele.createdAt).calendar()}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '200px',
                      height: '300px',
                      borderLeft: '0.5px solid',
                      borderRight: '0.5px solid',
                    }}
                  >
                    <video width="200px" height="300px" controls>
                      <source src={ele.video} type="video/mp4" />
                    </video>
                  </div>
                  <div className="d-flex justify-content-around mt-1">
                    {!ele.isRewarded &&
                      localStorage.getItem('role') == 'analysisuser' && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setRewardModal(true);
                            setRewardObj(ele);
                            setRewardIdx(idx);
                          }}
                        >
                          Reward
                        </Button>
                      )}
                    {ele.isRewarded &&
                      localStorage.getItem('role') == 'analysisuser' && (
                        <Button variant="contained" disabled>
                          Reward
                        </Button>
                      )}
                    <IconButton
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setId(ele._id);
                        setCurrIndex(idx);
                        handleOpen();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            }
          })}
        </InfiniteScroll>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="form-group">
            <label>Enter Reason</label>
            <textarea className="form-control" ref={reason} rows="3"></textarea>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDelete();

                handleClose();
              }}
            >
              <DeleteIcon />
            </Button>
          </div>
        </Box>
      </Modal>
      <ImageModel
        image={image}
        height={550}
        width={350}
        open={imgModel}
        setOpen={setImgModel}
      />
      <RewardModal
        open={rewardModal}
        setOpen={setRewardModal}
        handleReward={sendReward}
      />
    </StoryContainer>
  );
}

export default Story;
