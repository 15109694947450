import styled from 'styled-components';

const Container = styled.div`
  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 25px;
  }

  .map-container {
    height: 45em;
    width: 75em;
    margin-bottom: 10em;
    /* z-index: ; */
  }

  .date {
    margin: 5%;
    display: flex;
    justify-content: space-between;
  }

  .last-seen-pill {
    background-color: #039903;
    color: white;
    border-radius: 5px;
    margin: 0px;
    padding: 5px 0px 5px 0px;
    font-size: 11px;
    text-align: center;
  }

  .flex {
    display: flex;
    justify-content: center;

    p {
      font-size: 18px;
    }

    .img {
      align-self: center;

      img {
        height: 25px;
        width: 25px;
        border-radius: 50%;
      }
    }
  }
`;

export default Container;
