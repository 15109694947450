import styled from 'styled-components';

const WizardContainer = styled.div`
  .profile-img {
    height: 50px;
    width: 50px;
    border: 1px solid grey;
    border-radius: 50%;
    margin-right: 5px;
  }

  .subcategory {
    border-radius: 15px;
    border: 1px solid rgb(255, 255, 255);
    padding: 2px 2px;
    background-color: green;
    color: white;
    font-size: smaller;
    text-align: center;
  }

  .active{
    background-color: rgba(0,0,0,.075);
  }

  .spaarkUser{
    height: min-content;
    border: 1px solid green;
    padding: 2px;
    text-align: center;
    border-radius: 15px;
    color:white;
    background-color:green;
  }

  
  .nonSpaarkUser{
    height: min-content;
    border: 1px solid red;
    padding: 2px;
    text-align: center;
    border-radius: 15px;
    background-color:red;
    color:white;
  }

  .location {
    border-radius: 15px;
    border: 1px solid rgb(255, 255, 255);
    padding: 2px 2px;
    background-color: gray;
    color: white;
    font-size: smaller;
    text-align: center;
  }

  .left {
    overflow: auto;
    height: 100vh;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    width: 40vw;
  }

  .right {
    overflow: auto;
    height: 90vh;
    width: 50vw;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .form-check-input {
    height: 30px;
    width: 30px;
  }

  .nextPage {
    background-color: #002d62;
    padding: 10px;
    margin-right:25px;
  }

  .showAnalytics {
    background-color: #002d62;
    padding: 10px;
    margin-right:25px;
  }

  .ServiceProviderCount {
    background-color: green;
    padding: 10px;
    margin-right:25px;
    text-transform:capitalize;
  }

  th {
    font-size: medium;
  }

  td {
    text-align: center;
    padding: auto auto;

  }
`;

export default WizardContainer;
