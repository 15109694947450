import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import chartReducer from './chartSlice';
import notificationReducer from './notificationSlice';


const store = configureStore({
    reducer: {
        auth: authReducer,
        chart: chartReducer,
        notification:notificationReducer,
    }
})

export default store;