import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  .right {
    width: 85vw;
  }
  .posters{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  
    img {
      max-height: 30vh;
    }
  }
  .rightbutton{
    display: flex;
    justify-content: flex-end;
    margin-top:2vh;
    margin-right:2vh;

  }
  .posterinner{
    Button{
      margin:8px;
    }
  }
`;

export default Container;
