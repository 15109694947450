import TicketStatContainer from "./TicketsStat";
import {useState,useEffect} from 'react';
import { HEADER_URL } from '../../../api/constants.js';
import axios from "axios";




function TicketStat({search,refresh,path,eid}){

  console.log("search",search)
  console.log('path',path)
  console.log('eid',eid)
const [data,setData]=useState({
    total:0,
    open:0,
    closed:0,
    closedrate:0
})


//Get statistics

 var getStats=async()=>{


        var url=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/ticket-stat`
        if(localStorage.getItem('role')=='admin'&&path=='/admin/open-tickets'){
          url+='?type=all'
        }
        else if(localStorage.getItem('role')=='admin' && path=='/admin/open-ticket/emp'){
          url=url+'?type=emp&_id='+eid
        }
        await axios.post(url,{selectTickets:search},{method:"POST",headers:{ 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}`,}})
        .then((res)=>{
            console.log("stats",res.data)
            setData({...res.data})
        })
        .catch((err)=>{
            console.log(err.message)
            alert('err occured')
        })
    


 }



useEffect(()=>{
    getStats()
},[search,refresh])







    return <TicketStatContainer>
        <div className="d-flex justify-content-around">
  <div className="cardd">
    <div className=" lead text-secondary">Total Tickets</div>
  <div className="text-primary font-weight-bold text-count ">  {data.total}</div>
    
    </div>
  <div className="cardd">
    <div className=" lead text-secondary">Open Tickets</div>  
    <div className="text-primary font-weight-bold text-count"> {data.open} </div>
  </div>
  <div className="cardd">
    <div className=" lead text-secondary">Closed Tickets</div>
    <div className="text-primary font-weight-bold text-count">  {data.closed} </div>
    
    </div>
  <div className="cardd">
  <div className=" lead text-secondary" >Closed Rate</div>
  <div className="text-primary font-weight-bold text-count ">   {data.closedrate.toString().slice(0,5)} % </div>
  </div>
</div>
    </TicketStatContainer>

}
export default TicketStat;