
import styled from "styled-components";


const Container = styled.div`
   display: flex;
   justify-content: center;


   .right{

      display: flex;
      flex-direction: column;
     
      margin: 10px 5px;

      .posts{
         display: flex;
         flex-wrap: wrap;
         margin: 20px;
       

         justify-content: space-around;
         .post{
            width:250px;
         }
      }
   }



`

export default Container;  