import styled from 'styled-components';

const AddLeadContainer = styled.div`
  width: 100%;
  text-align: start;    
  background-color: #f8f9fe;
  padding:20px 30px;
  .MuiFormControl-root{
        width:100%
    }
`;

export default AddLeadContainer;
