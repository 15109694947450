import styled from 'styled-components';

const StartupDailogContainer = styled.div`

    background-color: #f8f9fe;
    padding:10px;
    font-size: 12px;
    width: 100%;
    display:flex;
    justify-content:space-between;
    .MuiFormControl-root{
        width:100%
    }
`;

export default StartupDailogContainer;
    