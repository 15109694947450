import MyLeadsContainers from "./MyLeads";
import { HEADER_URL,header } from '../../../api/constants.js';
import { useEffect, useState,useRef } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import MyLeadCard from "./MyLeadCard.jsx";
import ScaleLoader from "react-spinners/ScaleLoader";

function MyLeads(){


//Infinite scroll Related
const [hasMore, setHasMore] = useState(true);
const [page,setPage]=useState(1)




//Variables

    const[data,setData]=useState({})


    var getMyLeads=async()=>{
        if(page==1){
            setLoading(true)
        }
        await axios.get(HEADER_URL+'/emp/dashboard/leads/Pending?page='+page,{method: 'GET',headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }, })
        .then((res)=>{
            // console.log(res.data)
            res.data.leadsGenerated.length<5?setHasMore(false):setHasMore(true)
          
            if(page==1){
                setData(res.data)
            }
            else{
                var temp=data
                temp.leadsGenerated=[...temp.leadsGenerated,...res.data.leadsGenerated]
                setData({...temp})

            }
            setPage(page+1)
        })
        .catch((err)=>{
            console.log("error",err.message)
        })
        setLoading(false)

    }

    useEffect(()=>{
        getMyLeads()
    },[])
    // if(data && data.leadsGenerated)
    // console.log(data.leadsGenerated)
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#36d7b7");
    return <MyLeadsContainers>




<ScaleLoader
        color={color}
        loading={loading}
        style={{position:'absolute',left:'50%',top:'40%'}}
        size={150}
       
      />

        <div className="display-6 text-center text-primary">My Leads (Pending)</div>
      
      
   
        {data&&data.data&&
       
       
       <div className="d-flex justify-content-around mt-3" >
            <div className="d-flex">
        <div className="side-heading fs-3" >Pending : </div>
        <div className="content ms-2 fs-3 text-primary fw-bold">{data.data.Pending}</div>
        </div>
        <div className="d-flex">
        <div className="side-heading fs-3" >Saved : </div>
        <div className="content ms-2 fs-3 text-primary fw-bold">{data.data.Saved}</div>
        </div>
        <div className="d-flex">
        <div className="side-heading fs-3" >Accepted : </div>
        <div className="content ms-2 fs-3 text-primary fw-bold">{data.data.Accepted}</div>
        </div>
      
        <div className="d-flex">
        <div className="side-heading fs-3" >Rejected : </div>
        <div className="content ms-2 fs-3 text-primary fw-bold">{data.data.Rejected}</div>
        </div>
        </div>
    }
       { data&&data.leadsGenerated&& 
       <InfiniteScroll
            dataLength={data.leadsGenerated.length!=0 && data.leadsGenerated.length}
            style={{ display: 'flex', flexWrap:'wrap',gap:'30px' }}
            next={()=>{
                getMyLeads();
               }}
            hasMore={hasMore}
            loader={<div>Loading ... </div>}
            >
    <div className="d-flex p-4 gap-5 flex-wrap">
    { data&&data.leadsGenerated&&
        data.leadsGenerated.map((ele,idx)=>{

            return <div key={idx}>
                
             <MyLeadCard  data={data} setData={setData} idx={idx}  />
            </div>
        })
    }
    </div>
    </InfiniteScroll>  }
    </MyLeadsContainers>
}

export default MyLeads;