import Container from './recentUsage';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import Map from '../../../components/analysisComponents/Map/Map';

function RecentUsage() {
  return (
    <>
      <Container>
        {/* <SideBar /> */}
        <div className="right">
          <Map />
        </div>
      </Container>
    </>
  );
}

export default RecentUsage;
