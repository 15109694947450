
import OpenTicketContainer from "./OpenTicket";
//Common imports
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { HEADER_URL,header } from '../../../api/constants.js';
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import TicketStat from "../TicketStatitics/TicketStat.jsx";
//  Mui Imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 height:'80vh',
  bgcolor: 'background.paper',
  width:'70vw',
  boxShadow: 24,
  p: 4,
  overflowY:'scroll'
};
const stylee = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 height:'50vh',
  bgcolor: 'background.paper',
  width:'400px',
  boxShadow: 24,
  p: 4,
  display:'flex',
  flexDirection:'column',


};
function OpenTicket(){
  const [code,setCode]=useState('');
  const [psw,setPsw]=useState('');
    const TOKEN = useSelector((state) => state.auth.token);

    const navigate=useNavigate();
    var location=useLocation();
console.log("Location",location)
//Function to fetch tickets



  const changePsw=async()=>{
    setPswOpen(false)
    if(psw.trim().length==0){
      alert('enter password')
      return

    }
    await axios.post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/updatePsw`,{id:location.state.eid,code,password:psw},{  method: 'GET',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
    .then((res)=>{alert(res.data.message)})
    .catch((err)=>{alert(err.message)})
  
  }







    const getTickets = async () => {
      setLoading(true)
      if(localStorage.getItem('role')=='admin'){
          var getUrl=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/opentickets?page=${page}`
          if(localStorage.getItem('role')=='admin'&&location.pathname=='/admin/open-tickets'){
            getUrl+='&type=all'
          }
          else if(localStorage.getItem('role')=='admin' && location.pathname=='/admin/open-ticket/emp'){
            getUrl=getUrl+'&type=emp&_id='+location.state.eid
          }
          await axios.get(getUrl , {  method: 'GET',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
          .then((res) => {
            // console.log("IN UFHEU")
            res.data.tickets.length<20?setHasMore(false):setHasMore(true)
            setTickets([...tickets,...res.data.tickets])
            setPage(page+1)
          })
          .catch((err) => {
            console.log('err', err);
            alert('something went wrong!');
          });
      }
      else{
        await axios
        .get(`${HEADER_URL}/emp/dashboard/opentickets?page=${page}`,{ method: 'GET',  headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}`,  }, },) 
        .then((res) => {
                res.data.tickets.length<20?setHasMore(false):setHasMore(true)
                setTickets([...tickets,...res.data.tickets])
                setPage(page+1)
        })
        .catch((err) => {
          console.log('err', err);
          alert('something went wrong!');
        });
      }
      
      setLoading(false)
  };

//Variables
    const [data,setData]=useState({total:0,open:0,closed:0,closerate:0})
    const [hasMore, setHasMore] = useState(true);
    const [page,setPage]=useState(1)
    const [tickets,setTickets]=useState([])
    const [search,setSearch]=useState('')
    // console.log("Tic",tickets)
const [refresh,setRefresh]=useState(true)
useEffect(()=>{
setRefresh(!refresh)
getTickets()
},[location.state])
let [loading, setLoading] = useState(true);
const [open, setOpen] = React.useState(false);
const [pswOpen,setPswOpen]=useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
const handlePswClose=()=>setPswOpen(false);
const [access,setAccess]=useState([])
return <OpenTicketContainer>

 {location.state&&location.pathname=='/admin/open-ticket/emp' && <div className="d-flex justify-content-end mt-2 gap-2 me-2">
  <Button variant="contained" color='error' onClick={()=>{
  setAccess([...location.state.unauthorized])
  handleOpen()}}>Unauthorized Requests</Button>
  <Button variant='contained' color='success' onClick={()=>{setPswOpen(true)}} >Change Password</Button>
  </div>}
 <TicketStat search={search} refresh={refresh} path={location.pathname} eid={location.state!=null?location.state.eid:0} />

<ScaleLoader
        color="#36d7b7" 
        loading={loading}
        style={{position:'absolute',left:'50%',top:'40%'}}
        size={150}
       
      />  
      <div className="d-flex justify-content-center">
               {/* <FormControl className='mt-5 w-50 mx-auto'>
            <InputLabel id="demo-simple-select-label">Select :</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={search}
          label="Select :"
          onChange={(e)=>{
            setPage(1)
            setTickets([])
            setSearch(e.target.value)}}
        >
          <MenuItem value="Missing Tags in your Spaark">Missing Tags in your Spaark</MenuItem>
          <MenuItem value="⚠️Inappropriate Content⚠️">⚠️Inappropriate Content⚠️</MenuItem>
          <MenuItem value="Business Enquiries">Business Enquiries</MenuItem>
          <MenuItem value="Payment related issues?">Payment related issues?</MenuItem>
          <MenuItem value="Are you seeking information?">Are you seeking information?</MenuItem>
          <MenuItem value="Do you require any assistance?">Do you require any assistance?</MenuItem>
          <MenuItem value="Are you facing any problem with application?">Are you facing any problem with application?</MenuItem>
          <MenuItem value="Others">Others</MenuItem>
        </Select>
        </FormControl> */}
        </div>
<InfiniteScroll
            dataLength={tickets.length!=0 && tickets.length}
            style={{ display: 'flex', flexWrap:'wrap',gap:'30px',marginTop:25,marginLeft:20 }}
            next={()=>{getTickets();}}
            hasMore={hasMore}
            loader={ <div>Loading . . .</div>  }
            >


    {
        tickets.map((ele,idx)=>{
          return <div className="card" key={idx}>

                  <div className="card-body">
                      <div className="d-flex align-items-center gap-2 ">
                          <div className="side-heading" >Id : </div>
                          <div className="content ms-1 mt-1">{ele.ticketId}</div>
                      </div>
                      <div className="d-flex align-items-center gap-2 ">
                          <div className="side-heading" >Name: </div>
                          <div className="content ms-1 mt-1">{ele.type}</div>
                      </div>
                      <div className="d-flex align-items-center gap-2 ">
                          <div className="side-heading" >Type: </div>
                          <div className="content ms-1 mt-1">{ele.type}</div>
                      </div>
                      <div className="d-flex align-items-center gap-2 ">
                          <div className="side-heading" >Subject : </div>
                          <div className="content ms-1 mt-1">{ele.subject}</div>
                      </div>
                      <div className="d-flex align-items-center gap-2 ">
                          <div className="side-heading" >Created At : </div>
                          <div className="content ms-1 mt-1">{moment(ele.createdAt).format('lll')}</div>
                      </div>
                  </div>
                  <div className="card-footer text-center">
                  <Button variant="contained" onClick={()=>{
                    // console.log("navogate")
                      navigate('../ticket-details',{state:{'ticketId':ele.ticketId}})
                  }}>Open</Button>
                  </div>
          </div>
        })
    }

















</InfiniteScroll>


<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="display-6 text-center text-primary ">Unauthorized Requests</div>
          {access.length==0&&<div>No Unauthorized Requests </div>

          }
          
        {access.length!=0&&<table className=" mx-auto mt-5 table table-striped">
        <thead className="bg-success text-light">
          <th>#</th>
            <th>URL</th>
            <th>Time</th>
        </thead>
        <tbody>
        {
         access.map((item,itemIdx) => {
        return <tr key={itemIdx}>
          <td >{itemIdx+1}</td>
            <td className='text-primary'>{item.url}</td>
            <td>{moment(item.date).format('lll')}</td>
        </tr>
      })
    }
    </tbody>
    </table>}
        </Box>
      </Modal>
{/* ************************************************** */}
<Modal
        open={pswOpen}
        onClose={handlePswClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylee} className='justify-content-around'>

       
        <TextField id="outlined-basic"  autocomplete="off" value={code} onChange={(e)=>{setCode(e.target.value)}} label="Secret Code" type='password' variant="outlined"   />
        <TextField id="outlined-basic" autocomplete="off"  value={psw} onChange={(e)=>{setPsw(e.target.value)}} label="New Password" type='password'  variant="outlined"   />
     <div className='d-flex justify-content-around'>
        <Button variant='contained' color='error' onClick={()=>{handlePswClose()}}>Close</Button>
        <Button variant='contained' onClick={changePsw}>Change Password</Button>
        </div>

        </Box>
      </Modal>



</OpenTicketContainer>

}


export default OpenTicket












//Deleted code

{/* <div className="d-flex justify-content-around">
  <div className="cardd">
    <div className=" lead text-secondary">Total Tickets</div>
  <div className="text-primary font-weight-bold text-count ">  {data.total}</div>
    
    </div>
  <div className="cardd">
    <div className=" lead text-secondary">Open Tickets</div>  
    <div className="text-primary font-weight-bold text-count">  {data.open}</div>
  </div>
  <div className="cardd">
    <div className=" lead text-secondary">Closed Tickets</div>
    <div className="text-primary font-weight-bold text-count">   {data.closed}</div>
    
    </div>
  <div className="cardd">
  <div className=" lead text-secondary" >Closed Rate</div>
  <div className="text-primary font-weight-bold text-count ">   {data.closedrate}%</div>
  </div>
</div> */}
{/* <div style={{display:'flex'}}><ScaleLoader color="#36d7b7"
    loading={loading}
    style={{position:'absolute',left:'50%',top:'40%'}}
    size={20}
    />
</div> */}