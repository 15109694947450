import Container from './latestPost.js';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import Steeper from '../../../../components/analysisComponents/Steeper/Steeper.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import { HEADER_URL } from '../../../../api/constants.js';
import PostCard from '../../../../components/analysisComponents/PostCard/PostCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import moment from 'moment/moment';

import axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

function LatestPost() {
  const TOKEN = useSelector((store) => store.auth.token);

  const location = useLocation();
  const [refresher, SetRefresher] = useState('');
  const [search, setSearch] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [source, setSource] = React.useState('All');
  const handleChange = (event) => {
    setSource(event.target.value);
  };

  const [hasMore, setHasMore] = React.useState(true);
  const [posts, setPosts] = React.useState([]);
  const [maxLength, setMaxLength] = React.useState(0);
  const [page, setPage] = React.useState(1);
  let [loading, setLoading] = useState(true);

  const fetchPosts = async (TOKEN, cb, key) => {
    let url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/superspaark/fetch-posts?page=${page}&source=${source}`;

    if (search.trim() != '') {
      url += `&search=${search.trim()}`;
    }
    if (from != '') {
      url += `&from=${from}`;
    }
    if (to != '') {
      url += `&to=${to}`;
    }

    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        res.data.posts.length < 5 ? setHasMore(false) : setHasMore(true);
        setPosts([...posts, ...res.data.posts]);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
    setLoading(false);
  };

  const setPostsCallBack = (posts) => {
    setPosts(posts);
  };

  const triggerSearch = (key) => {
    SetRefresher(key);
    fetchPosts(TOKEN, setPostsCallBack, key);
  };

  useEffect(() => {
    fetchPosts(TOKEN, setPostsCallBack, '');
  }, [maxLength]);

  //renders the steeper based on the query ==> query doesn't matter
  if (location.search !== '') {
    return (
      <Container>
        {/* <SideBar /> */}
        <Steeper />
      </Container>
    );
  }

  console.log('post fetched are : ', posts);

  //Get Vals from Call Back
  function Setter(vals) {
    // console.log("callback method called")
    fetchPosts(TOKEN, setPostsCallBack, refresher);
  }

  function deleteHandler(postId) {
    const newPosts = posts.filter((ele) => ele._id !== postId);
    setPosts([...newPosts]);
  }

  // function rewardHandler() {}

  //if there is no search query on the url then render default latest posts page.

  return (
    <Container>
      {/* <SideBar /> */}
      <div className="right">
        {/* <div className="position-sticky top-0 w-100" style={{ zIndex: '5' }}>
          <SearchAppBar cb={triggerSearch} />
        </div> */}
        {/* <div className="posts">
          {posts.length !== 0 &&
            posts.map((post,index) => {
              
              return (
               
                <div className="post" key={index}>
                  <PostCard Setter={Setter} post={post} from="latest" />  
                </div>
              );
            })}
            {posts.length===0?<h1>No Posts are Available</h1>:" "}
        </div> */}
        <div className="d-flex justify-content-around align-items-end mt-3">
          <div style={{ width: '400px' }}>
            <input
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              className="form-control"
              placeholder="Search by userName or content"
            />
          </div>

          <div className="">
            <label>From :</label>
            <input
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
              }}
              max={moment().format().slice(0, 16)}
              type="date"
              name="from"
              id="from"
              className="form-control"
            />
          </div>
          <div className="">
            <label>To :</label>
            <input
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
              }}
              max={moment().format().slice(0, 16)}
              type="date"
              name="to"
              id="to"
              className="form-control"
            />
          </div>

          <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Source
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={source}
                onChange={handleChange}
                label="Source"
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'User'}>User</MenuItem>
                <MenuItem value={'TeleCaller'}>TeleCaller</MenuItem>
              </Select>
            </FormControl>
          </div>

          <Button
            variant="contained"
            onClick={() => {
              setPage(1);
              setMaxLength(maxLength + 1);
              setPosts([]);
              setHasMore(true);
            }}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setSearch('');
              setFrom('');
              setTo('');
              setSource('All');
              setPage(1);
              setData([]);
              setMaxLength(maxLength + 1);
              setHasMore(true);
            }}
          >
            Reset
          </Button>
        </div>

        <InfiniteScroll
          dataLength={posts.length != 0 && posts.length}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'start',
            marginTop: '40px',
            gap: 30,
            paddingLeft: '40px',
          }}
          next={() => {
            fetchPosts();
          }}
          hasMore={hasMore}
          loader={
            <div
              className={page == 1 ? 'd-none' : 'd-flex justify-content-center'}
            >
              <div className="d-flex flex-column gap-3">
                <CircularProgress />
                LOADING . . .
              </div>
            </div>
          }
        >
          {posts.map((post, index) => {
            return (
              <div className="post" key={index}>
                <PostCard
                  Setter={Setter}
                  post={post}
                  onDelete={deleteHandler}
                  from="latest"
                />
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </Container>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchAppBar = React.memo(
  ({ cb }) => {
    const handleInputChange = (e) => {
      cb(e.target.value);
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: '#7451f8' }}>
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleInputChange}
              />
            </Search>
          </Toolbar>
        </AppBar>
      </Box>
    );
  },
  () => true,
);

export default LatestPost;
