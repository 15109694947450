import styled from 'styled-components';

const TelecallerLeadsContainer = styled.div`
  width: 100%;
  text-align: start;
  background-color: #f8f9fe;
  .heading {
    font-size: 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    color: #6d6dff;
    text-align: center;
  }
  .cardd {
    
    margin-left: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 1.5px 1.5px 0.5px 0.1px #787676;
  }
  .side-heading {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
  }
  .content {
    font-size: 12px;
  }
  .form-control {
    rows: 30 !important;
  }
  .wh300 {
    width: 300px;
    height: 300px;
  }
  .carousel-inner > .carousel-item {
    height: 300px;
  }
  .w-500px {
    width: 500px;
  }
  .icon {
    z-index: 5;

    color: white;
    width: 30px;
    height: 30px;
    padding: auto;
  }
  .details-border {
    border: 1px solid #cfbaba;
    padding: 20px;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 0.5px #cfbaba;
  }
  .name,.phone-num,.user-place{
    font-size:15px;

  }
`;

export default TelecallerLeadsContainer;
