import { useEffect, useState } from 'react';
import './WizardDash.css';
import DateNavWiz from './WizardDash_Components/DateNav';
import axios from 'axios';
import WizCard from './WizardDash_Components/WizCard';
import { Modal, Button } from 'react-bootstrap';
import { HEADER_URL } from '../../../api/constants';
// Import DataTable component

function WizardDashBoard() {
  const [selectedOption, setSelectedOption] = useState('wizard1');
  const [dataRange, setDataRange] = useState('30'); // Set default value for data range
  const [jsonData, setJsonData] = useState(null); // Initialize jsonData with null

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const getDataByRange = (e) => {
    setDataRange(e.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false); // Set default value for showModal

  // Function to fetch data from the API
  const getWizDashboard = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${HEADER_URL}/wizard/wizarddashboard?time=${dataRange}&assignedTo=${selectedOption}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setJsonData(response.data);
    } catch (error) {
      setErrorMsg('Error fetching data from the server.');
      console.error(error); // Handle the error
    }
    setLoading(false);
  };

  useEffect(() => {
    getWizDashboard();
  }, [selectedOption, dataRange]);

  const handleShowModal = (error) => {
    setErrorMsg(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (errorMsg && errorMsg.length > 3) {
      handleShowModal();
    }
  }, [errorMsg]);

  if (loading) {
    return <h2 style={{ color: 'black' }}>Loading...</h2>;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="w-90">
            <DateNavWiz />
            {/* header top bar  */}
            <div className="p-3 d-flex text-black fw-600 justify-content-between">
              <div className="">
                <span>Compared To the Previous Period</span>
              </div>
              <div className="d-flex">
                <span className="p-2">Switch User</span>
                <div className="ms-3 ">
                  <select
                    className="border border-primary rounded-3 p-2"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="wizard1">wizard1</option>
                    <option value="analysis">analysis</option>
                    <option value="other">other</option>
                  </select>
                </div>
              </div>
            </div>
            {/* card section */}
            {jsonData && (
              <section>
                <div className="container">
                  <div className="row">
                    <div className="w-90 d-flex">
                      <WizCard
                        name="Accepted"
                        totalData={jsonData.Accepted}
                        percentData={55}
                        // value={50}
                        dataGraph={'positive'}
                      />
                      <WizCard
                        name="Called"
                        totalData={jsonData.Called}
                        percentData={55}
                        value={100}
                        dataGraph={'positive'}
                      />
                      <WizCard
                        name="Pending"
                        totalData={jsonData.Pending}
                        percentData={2}
                        // value={50}
                        dataGraph={'positive'}
                      />
                      <WizCard
                        name="Feedback Survey"
                        totalData={200}
                        percentData={2}
                        // value={100}
                        dataGraph={'negative'}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section>
              <div className="d-flex">
                <div className="d-flex justify-content-end mt-2 ms-auto mt-3">
                  <div className="">
                    <select
                      className="border border-primary rounded-3 p-2"
                      value={selectedOption}
                      onChange={getDataByRange}
                    >
                      <option value={10}>daily</option>
                      <option value={30}>Monthly</option>
                      <option value={730}>Yearly</option>
                    </select>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="col">
                <div className="border p-3 mt-3 rounded">
                  {/* data table siplay */}
                  {jsonData && <DataTable data={jsonData.agg} />}

                  {/* error massage popup display */}
                  {errorMsg && (
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{errorMsg}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default WizardDashBoard;

// table component for data

const DataTable = ({ data }) => {
  return (
    <table className="table tableclasssCOn">
      <thead className="">
        <tr className="fw-600 text-black  ">
          <th className="border-0 border">Date</th>
          <th className="border-0 border">Accepted</th>
          <th className="border-0 border">Accepted Percentage %</th>
          <th className="border-0 border">Connected</th>
          <th className="border-0 border">Connected Percentage %</th>
          <th className="border-0 border">Feedback Percentage %</th>
        </tr>
      </thead>

      <tbody>
        {data &&
          data.map((item, index) => (
            <tr key={index} className="">
              <td>{new Date(item.date).toLocaleDateString()}</td>
              <td>{item.acceptOnDate}</td>
              <td>{item.acceptedPercentage.toFixed(1)} %</td>
              <td>{item.connected}</td>
              <td>{item.connectedPercentage.toFixed(1)} %</td>
              <td>{item.feedback.toFixed(1)}%</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
