import styled from 'styled-components'

const StoryContainer = styled.div`

background-color: #f8f9fe;
  padding:10px;
  font-size: 12px;
  width: 100%;

  


`   

export default StoryContainer;