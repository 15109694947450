import Container from './SteeperGroupPost.js';
import { useSelector } from 'react-redux';
import SideBar from '../SideBar/SideBar.jsx';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';

function SteeperGroupPost() {
  const steps = ['Give Badge URL', 'Give Super Spaark Reward', 'Finish'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [skipBadgeUrl, setSkipBadge] = React.useState(false);
  const TOKEN = useSelector((state) => state.auth.token);
  const { state } = useLocation();
  const move = useNavigate();

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const badgeUrl = document.getElementById('badgeUrl').value;
      const ImageIcon = document.getElementById('ImageIcon').value;

      if (badgeUrl === '' && !skipBadgeUrl) {
        alert('No badge url! Are you sure to continue.');
        setSkipBadge(true);
        return;
      }

    //   dashboard/updateBadgeAndImageGroupPost
      const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/updateBadgeAndImageGroupPost`;

      const data = {
        badgeUrl,
        ImageIcon,
        postId: state.postId,
        userId: state.userId,
      };

      axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        })
        .catch((err) => {
          alert('something went wrong!!');
          console.log('ERR', err);
          return;
        });
    }

    if (activeStep === 1) {
      const amount = parseInt(document.getElementById('amount').value);
      if (!amount) {
        alert('Please enter a amount to issue a scratch card!');
        return;
      }

      const title = document.getElementById('title').value;
      const body = document.getElementById('body').value;
      const name = document.getElementById('name').value;
      const type = document.getElementById('type').value;

      const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/grouPostScratchCard`;

      const data = {
        title,
        body,
        name,
        type,
        amount,
        postId: state.postId,
        userId: state.userId,
      };
    
      axios
        .post(url, data, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        }).then((res)=>{
          alert("Success")

        })
        .catch((err) => {
          alert('something went wrong!!');
          console.log('ERR', err);
        });
    }

    if (activeStep === 2) {
      move('/analysis/super-spaark/live');
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container>
      <Box sx={{ width: '100%', padding: '5%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        )}
        {activeStep === 0 && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <div className="mt-5">
              <form>
                <div className="form-group flex-badge">
                  <div className="flex-badge-item mt-5">
                    <label for="staticEmail" className="form-label">
                      Badge URL
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="badgeUrl"
                      value=""
                    />
                  </div>
                  <div className="flex-badge-item mt-3">
                    <label for="inputPassword" className="form-label">
                      Super Spaark
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ImageIcon"
                      value="https://static-content.spaarksweb.com/superspaark-image.png"
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
        {activeStep === 1 && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <form>
              <div className="step-2-form">
                <div className="step-2-item">
                  <input
                    type="text"
                    id="type"
                    value="SuperSpaark"
                    className="form-control"
                    readOnly
                    hidden
                  />
                </div>
                <div className="step-2-item">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value="Super Spaark"
                    className="form-control"
                    readOnly
                    hidden
                  />
                </div>
                <div className="step-2-item">
                  <label for="amount">Amount:</label>
                  <input
                    type="text"
                    name="amount"
                    id="amount"
                    className="form-control"
                    placeholder="5"
                  />
                </div>
                <div className="step-2-item">
                  <label for="title">FCM Title:</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value="Congratulations"
                    className="form-control"
                  />
                </div>
                <div className="step-2-item">
                  <label for="body">FCM Body:</label>
                  <input
                    type="text"
                    name="body"
                    id="body"
                    value="Your Spaark is selected as Super Spaark for 2 days."
                    className="form-control"
                  />
                </div>
              </div>
            </form>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
        {activeStep === 2 && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <div class="success-animation">
              <svg
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  class="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  class="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <h4 className="super-spaark-text">
              This Spaark is a Super Spaark now!
            </h4>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Container>
  );
}

export default SteeperGroupPost;
