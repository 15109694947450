import Container from './Posters.js';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import { Button, Card } from '@mui/material';
import { useEffect, useState,useRef } from 'react';
import { useSelector } from 'react-redux';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useAxios = () => {
  const TOKEN = useSelector((state) => state.auth.token);

  async function getApi(url) {
    const contructed_url = `${HEADER_URL}/${url}`;
    return axios.get(contructed_url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
    });
  }

  return [getApi];
};



// ActivatePoster
// deActivatePoster

function Posters() {
  const [getApi] = useAxios();
  const [fcmPosters, setFcmPosters] = useState([]);
  const Image=useRef();
  const TOKEN = useSelector((state) => state.auth.token);
  const [invoker,setInvoker]=useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function getPosters() {
      const resp = await getApi('posters');

      console.log('responses are :', resp);
      setFcmPosters(resp.data.posters);
    }
    getPosters();
  }, [invoker]);



  //Poster Creator
 function Submit(e)
 {
  e.preventDefault();
  
  const form=new FormData();
  form.append("photo",Image.current.files[0])

  axios.post(`${HEADER_URL}/createPoster`,form, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'multipart/form-data',
    },
  }).then((res)=>{
    alert("Poster Created")
    setInvoker((invoker)=>{
      return invoker+=1
    })

  }).catch((e)=>{
alert("Not Created")
  })

 }

 function Setter(e){
 
  const body=JSON.stringify({id:e.target.name})
  axios.post(`${HEADER_URL}/deActivatePoster`,body, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'multipart/form-data',
    },
  }).then((res)=>{
    
    setInvoker((invoker)=>{
      return invoker+=1
    })

  }).catch((e)=>{
alert("Not DeActivated")
  })

 }

  return (
    <>
      <Container>
        {/* <SideBar /> */}

     
      
        <div className="right">

        <div className="rightbutton"> 
        <Fab onClick={handleOpen} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
         </div>

      <div className='posters'>
       
          {fcmPosters.map((item, i) => (
            <Card
              style={{ marginBottom: '1vh', marginLeft: '2vh' }}
              key={item._id}
            >
              <img src={item.url} alt={`poster-${i + 1}`} />
              <br />
              <div className='posterinner'>
              <Button 
              variant="outlined"
              
                onClick={(e) => {
                  navigator.clipboard.writeText(item.url);
                  e.target.innerText = 'Copied';
                  setTimeout(() => (e.target.innerText = 'Copy'), 1500);
                }}
              >
                Copy
              </Button>

              {/* <button name={item._id} value={item._id} class="btn btn-success" onClick={Setter}>
                Activate
              </button> */}

              </div>
            </Card>
          ))}
        </div>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>

         <form onSubmit={Submit}>

         <div class="form-group">
    <label for="exampleInputEmail1">Poster Image</label>
    <input ref={Image} type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
    <button  type="submit" class="btn btn-success">Create</button>
    
  </div>

         </form>

            </Box>
          </Modal>

        </div>

      </Container>
    </>
  );
}

export default Posters;
