import styled from 'styled-components';

const Container = styled.div`
display:flex;
width:100%;
margin-top: 30px;
justify-content: space-around;
/* flex-wrap: wrap; */

.cardd{
  /* height:100%; */
height:500px;
position:relative;
  width:350px;
}

`;

export default Container;
