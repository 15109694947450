import styled from 'styled-components';

const MyLeadsContainers = styled.div`
  width: 100%;
  text-align: start;    
  background-color: #f8f9fe;
  .side-heading {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
  }
  .content {
    font-size: 12px;
  }

  .cardd{
    background-color:white;
    padding:12px;
    /* width:350px; */
    border-radius:15px;
    box-shadow: 2px 2px 4px 0.5px black;
  }
  .MuiFormControl-root{
        width:100%
    }

`;

export default MyLeadsContainers;
    // 