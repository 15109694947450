import MyLeadCardContainers from "./MyLeadCard";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useRef } from 'react'
import { HEADER_URL,header } from '../../../api/constants.js';
import axios from "axios";
import { AiFillDelete,AiFillCamera } from "react-icons/ai";
import { useEffect,useState } from "react";
import Modal from '@mui/material/Modal';
import * as React from 'react';
import Box from '@mui/material/Box';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  
function MyLeadCard({data,setData,idx}){
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

const imageRef=useRef();
const profilePicRef=useRef()
var onTextAreaChange=(e)=>{
    var temp=data
    temp.leadsGenerated[idx].postId.content=e.target.value
    setData({...temp})
}

var onLatitudeChange=(e)=>{
    var temp=data
    temp.leadsGenerated[idx].postId.locationStatic.coordinates[0]=e.target.value
    setData({...temp})
}

var onLongitudeChange=(e)=>{
    var temp=data
    temp.leadsGenerated[idx].postId.locationStatic.coordinates[1]=e.target.value
    setData({...temp})
}
const images=useRef()
var handleChangeProfilePic=async()=>{
    profilePicRef.current.click()
}

var handleDeleteProfilePic=async()=>{
    setPP('https://static-content.spaarksweb.com/images/userprofile.png')
    profilePicRef.current.value=null
    await axios.post(HEADER_URL+'/emp/dashboard/lead/removeProfilePic',{id:data.leadsGenerated[idx].userId._id},{method: 'POST',headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }, })
    .then((res)=>{
        console.log(res.data)
        var temp=data
        temp.leadsGenerated[idx].userId.profilePic='https://static-content.spaarksweb.com/images/userprofile.png'
        handleClose()
        setData({...temp})
    })
    .catch((err)=>{
        console.log(err.message)
        alert('error while deleting profile pic')
    })
}


var handleDeleteImg=async(idxx,src)=>{
    // console.log("idx",idxx)
    // console.log('src',src)
    var obj={ Id:data.leadsGenerated[idx].postId._id, src }
    // console.log(obj)
    await axios.post(HEADER_URL+'/emp/dashboard/lead/removeImage',obj,{method: 'POST',headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }, })
    .then((res)=>{
        if(res.data=='success'){
           
        }
    })
    .catch((err)=>{alert("error occured while deleting")})
    var temp=data
    temp.leadsGenerated[idx].postId.photo.splice(idxx,1)
    setData({...temp})
}
var handleDeleteImgTemp=(idxx)=>{
    var temp=tempImg
   temp.splice(idxx,1)

    setTempImg([...temp])
    }

var Submitted=()=>{
    
    // delete imageRef.current.files(0)

    // console.log(imageRef.current.files)
    if(data.leadsGenerated[idx].postId.photo.length+imageRef.current.files.length>4){
        alert("Max photos can be 4 only")
        imageRef.current.value=null
        setTempImg([])
        return
    }
    if(data.leadsGenerated[idx].postId.content<20){
        alert("Content should be greater than 20 characters")
        return
    }
    if( data.leadsGenerated[idx].postId.photo.length+tempImg.length<1){
        alert("Upload atleast one pic")
        return
    }
    var formData=new FormData()
    formData.append('content',data.leadsGenerated[idx].postId.content)
    formData.append('longitude',data.leadsGenerated[idx].postId.locationStatic.coordinates[0])
    formData.append('latitude',data.leadsGenerated[idx].postId.locationStatic.coordinates[1])
    formData.append('userId',data.leadsGenerated[idx].userId._id)
    formData.append('postId',data.leadsGenerated[idx].postId._id)
    if(profilePicRef&&profilePicRef.current&&profilePicRef.current.files.length!=0){
        formData.append('profilePic',profilePicRef.current.files[0])
    }
   if(imageRef&&imageRef.current&&imageRef.current.files){
    for (var i=0;i<imageRef.current.files.length;i++){

        formData.append('photo',imageRef.current.files[i])
    }
   }
//    console.log(formData)
   updateLead(formData)
}   

    var updateLead=async(formData)=>{
        await axios.post(HEADER_URL+'/emp/dashboard/lead/update',formData,{method: 'POST',headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }, })
        .then((res)=>{
            // console.log(res.data)
            if(res.data.message.status=='Saved'){
                var temp=data
                temp.leadsGenerated.splice(idx,1)
                setData({...temp})
                imageRef.current.value=null
                profilePicRef.current.value=null
                setTempImg([])
            }
            else{
            var temp=data
            // console.log(res.data.message)
            // console.log(temp.leadsGenerated[idx])
            temp.leadsGenerated[idx]=res.data.message
            // console.log(temp.leadsGenerated[idx])
            setData({...temp})
            // console.log(temp)
            imageRef.current.value=null
            profilePicRef.current.value=null
            setTempImg([])
            }
        })
        .catch((err)=>{
            console.log(err.message)
        })

    }
    const [tempImg,setTempImg]=useState([])
    var setTempImgs=()=>{
        // console.log()
        var x=[]
        for(var i=0;i<imageRef.current.files.length;i++){
            var tempImgUrl=URL.createObjectURL(imageRef.current.files[i])
            
            x.push(tempImgUrl)
            // console.log("Local array ",x)
            
        }
        setTempImg([...x])
        
    }
    const [changed,setChanged]=useState(false)
    useEffect(()=>{
        // console.log(imageRef.current.files.length)
        setTempImgs()

    },[changed])

    const [pp,setPP]=useState('https://static-content.spaarksweb.com/images/userprofile.png')
    // console.log("pp",pp)
// console.log("changed",changed)
// console.log("profilePicRef",profilePicRef)
// console.log("fgew",profilePicRef.current)
// console.log("value",profilePicRef.current.files)

    return <MyLeadCardContainers>
        <div key={idx} className="mt-2 cardd d-flex flex-column justify-content-around gap-3">
        
        {/* Profile Pic */}
        <div className="d-flex gap-4">

                <div>
                    <img src={data.leadsGenerated[idx].userId.profilePic=='https://static-content.spaarksweb.com/images/userprofile.png'?pp:data.leadsGenerated[idx].userId.profilePic} onClick={handleOpen}  style={{width:'80px', height:'80px',borderRadius:'50%'}} alt="" />
                </div>
                <div className="d-flex flex-column gap-1    align-items-start">
                        <div>{data.leadsGenerated[idx].userId.name}</div>
                        <div>{data.leadsGenerated[idx].userId.phone}</div>
                        <div>{data.leadsGenerated[idx].postId.category}</div>
                </div>

        </div>

        {/* content */}
        <div className="w-100 ">
            <div  className="form-label">Content </div>
            <textarea className="form-control"rows="7" name='content'  value={data.leadsGenerated[idx].postId.content} onChange={(e)=>onTextAreaChange(e)}></textarea>
        </div>
        {/* Latitude And Longitude  */}
        <div className="d-flex gap-2">

        <TextField className='mt-2 ' name='latitude' id="outlined-basic" label="Latitude" type="number" value={data.leadsGenerated[idx].postId.locationStatic.coordinates[0]} onChange={(e)=>onLatitudeChange(e)}   variant="outlined" />
        <TextField className='mt-2' name='longitude' id="outlined-basic" label="Longitude" type="number"  value={data.leadsGenerated[idx].postId.locationStatic.coordinates[1]} onChange={(e)=>onLongitudeChange(e)}  variant="outlined" />
        </div>


        {/* Post Photos */}
        <div className="">
            <label htmlFor="formFileMultiple" className="form-label" >Post Photo</label>
            <input className="form-control" name='photo' type="file"  accept='image/*' onChange={()=>{setChanged(!changed)}} ref={imageRef} id="formFileMultiple" multiple />
            <input className="form-control" name='photo' type="file"  accept='image/*' onChange={()=>{
                setPP(URL.createObjectURL(profilePicRef.current.files[0]))
                handleClose()
            }}   hidden ref={profilePicRef}   />
        </div>


        {/* display Images */}
        <div className="d-flex gap-2">
        {
            data.leadsGenerated[idx].postId.photo.map((img,imgIdx)=>{

                return <div className='d-flex flex-column align-items-center' key={imgIdx}>    
                <img src={img} width='100' height='100' />
                <AiFillDelete style={{cursor:'pointer'}} onClick={()=>{handleDeleteImg(imgIdx,img)}}  />
                </div>
            })

        }
        {
            tempImg.map((img,imgIdx)=>{

                return <div className='d-flex flex-column align-items-center' key={imgIdx}>    
                <img src={img} width='100' height='100' />
               
                </div>
            })

        }
        </div>    

            {/* Button */}
        <Button variant="contained" color="success" onClick={Submitted} >Save</Button>

       
       

        </div>


               {/* image change */}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <div className="d-flex gap-3 text-primary justify-content-center align-items-center " style={{cursor:'pointer',fontSize:'25px'}} onClick={handleDeleteProfilePic}>
            <AiFillDelete />
             Delete Profile Pic
        </div>
        <div className="d-flex gap-3 text-primary align-items-center justify-content-center" style={{cursor:'pointer',fontSize:'25px'}}  onClick={handleChangeProfilePic}>
            <AiFillCamera />
             Change Profile Pic
        </div>
        </Box>
      </Modal>






    </MyLeadCardContainers>
}

export default MyLeadCard;  