import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'Chart',
  initialState: {
    pending: [],
    sheduled: [],
    sent: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      const payload = action.payload;
      state.pending = payload.pending;
      state.sheduled = payload.sheduled;
      state.sent = payload.sent;
    },
    updateNotification: (state, action) => {
      if (action.payload.type === 'pending') {
        const update = action.payload.data;
        let index = state.pending.findIndex(
          (note) => String(note._id) === update.noteId,
        );
        state.pending[index].title = update.title;
        state.pending[index].body = update.body;
        state.pending[index].image = update.image;
        state.pending[index].sendAt = update.sendAt;
      } else if (action.payload.type === 'sheduled') {
        const update = action.payload.data;
        let index = state.sheduled.findIndex(
          (note) => String(note._id) === update.noteId,
        );
        state.sheduled[index].title = update.title;
        state.sheduled[index].body = update.body;
        state.sheduled[index].image = update.image;
        state.sheduled[index].sendAt = update.sendAt;
      }
    },
    deleteNotification: (state, action) => {
      if (action.payload.type === 'pending') {
        state.pending = state.pending.filter(
          (note) => String(note._id) !== action.payload.noteId,
        );
      } else if (action.payload.type === 'sheduled') {
        state.sheduled = state.sheduled.filter(
          (note) => String(note._id) !== action.payload.noteId,
        );
      }
    },
    approveNotification: (state, action) => {
      const approvedNotification = state.pending.find(
        (item) => String(item._id) === action.payload.noteId,
      );
      state.sheduled.splice(0, 0, approvedNotification);
      state.pending = state.pending.filter(
        (item) => String(item._id) !== action.payload.noteId,
      );
    },
    createNotification: (state, action) => {
      state.pending.splice(0, 0, action.payload);
    },
  },
});

export const {
  setNotifications,
  createNotification,
  updateNotification,
  deleteNotification,
  approveNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
