
import styled from "styled-components";

const Container = styled.div`

        display: flex;
        gap:20px;
        margin:15px;
        justify-content: center;

        .date{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            margin-top: 7%;
        }

        .right{
            width: 80%;
        }

        .widget{
            height: 116px;
            width:257px
        }

        .left{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 83%;
        }

        .title-inner{
            text-align: center;
            margin-top:35px;
            text-decoration: underline;
            text-transform: capitalize !important;
        }

        .chooser{
            display: flex;
            justify-content: flex-start;
            margin-left: 10%;
            gap:10px;
            max-height:3.5em
        }

        .Charts{
            height:300px;
            width:300px;
        }
        .box{
            padding: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 39px;
            padding-top:28px;
        }



`

export default Container;