export const formatDate = (date) => {
    return new Date(date).getHours() + " hours ago";
}

export const lastSeenAt = (date) => {
    
    const newDate = new Date(date);
    const now = new Date(Date.now())
    // alert(`${now} ${newDate}`)

    //current time details
    const currentDay = now.getDate(); //day of the month;;
    const currentMonth = now.getMonth(); //gives month 0,1,2...
    const currentYear = now.getFullYear(); // gives the year;
    const currentHour = now.getHours(); //gives the hour

    // users last updated times
    const day = newDate.getDate(); //day of the month;;
    const month = newDate.getMonth(); //gives month 0,1,2...
    const year = newDate.getFullYear(); // gives the year;
    const hour = newDate.getHours(); //gives the hour


    if (currentYear > year) {
        return ' 1 Year ago'
    }

    if (month <= currentMonth && currentMonth - month !== 0) {
        const diffrence = currentMonth - month;
        if (diffrence === 1) {
            return ` 1 month ago`
        }
        return ` ${diffrence} months ago`
    }

    if (day <= currentDay && currentDay - day !== 0) {

        const diffrence = currentDay - day;
        if (diffrence === 1) {
            return ` 1 day ago`
        }
        return `${diffrence} days ago`
    }


    if (hour <= currentHour && currentHour - hour !== 0) {

        const diffrence = currentHour - hour;
        if (diffrence === 1) {
            return ` 1 hour ago`
        }
        return ` ${diffrence} hours ago`
    }


    return `Less then a hour ago`
}
  