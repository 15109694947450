import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import Container from './chart.js';
import { useSelector } from 'react-redux';

export default function Chart({ height, width }) {

  const inputData = useSelector((state) => state.chart);
  const h=Number(height) || 400;
  const w = Number(width) || 830;
  
  console.log(inputData.data)
  return (
    <Container>
      <div className="box">
        <AreaChart
          width={w}
          height={h}
          data={inputData.data.length !== 0 && inputData.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={inputData.dataKeyBottom} />
          <YAxis dataKey={inputData.dataKeyTop} />

          <Tooltip />
          <Area
            type="monotone"
            dataKey={inputData.dataKeyTop}
            stroke={inputData.stroke}
            fill={inputData.fill}
          />
        </AreaChart>
      </div>
    </Container>
  );
}

// const prop= {
//     stroke: "",
//     fill: "color",
//     data: [{}],
//     dataKeyTop: 'bottom bar',
//     dataKeyBottom:'bottom Data'
// }
