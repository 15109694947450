import Dashboard from "./EmpManagaDashboardContainer";
import { useState,useEffect } from "react";
import AddEmp from "../AddEmployee/AddEmp.jsx";
import UserCard from "../UserCard/UserCard.jsx";
import { HEADER_URL } from '../../../../api/constants.js';
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
function EmpManagaDashboard(){

    let [loading, setLoading] = useState(true);
    const [currPage,setCurrPage]=useState('add')
    const [data,setData]=useState([])
    let [color, setColor] = useState("#36d7b7");
    var getUsers=async()=>{
        setLoading(true)
        console.log("hello")
        var url=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/getUserDetails?type=`
        if(currPage=='employee')
        {
            await axios.get(url+'emp' , {  method: 'GET',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
            .then((res)=>setData([...res.data.users]))
            .catch((err)=>{alert(err.message)})
        }
        else if(currPage=='admin')
        {
            await axios.get(url+'admin' , {  method: 'GET',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
            .then((res)=>setData([...res.data.users]))
            .catch((err)=>{alert(err.message)})
        }
        else if(currPage=='super')
        {
            await axios.get(url+'superuser' , {  method: 'GET',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
            .then((res)=>setData([...res.data.users]))
            .catch((err)=>{alert(err.message)})
        }
        else if(currPage=='analysisuser')
        {
            await axios.get(url+'analysisuser' , {  method: 'GET',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
            .then((res)=>setData([...res.data.users]))
            .catch((err)=>{alert(err.message)})
        }
        setLoading(false)
    }

    useEffect(()=>{
        setData([])
        getUsers()
    },[currPage])

    return <Dashboard>
        <ScaleLoader
        color={color}
        loading={loading}
        style={{position:'absolute',left:'50%',top:'40%'}}
        size={150}
       
      />

    <div className="d-flex justify-content-around bg-dark text-light p-3">

            <div className={currPage=='add'?'active':'inactive'}  onClick={()=>setCurrPage('add')} >Create Employee</div>
            <div className={currPage=='employee'?'active':'inactive'} onClick={()=>setCurrPage('employee')} >Manage Employee</div>
            <div className={currPage=='analysisuser'?'active':'inactive'} onClick={()=>setCurrPage('analysisuser')}>Managa Analysis User</div> 
            <div className={currPage=='admin'?'active':'inactive'} onClick={()=>setCurrPage('admin')}>Manage Admin</div>
            <div className={currPage=='super'?'active':'inactive'} onClick={()=>setCurrPage('super')}>Managa SuperUser</div> 

    </div>              
        {currPage=='add'&&<AddEmp/>}
        {currPage!=='add'&&
        
        <div className="d-flex  ms-3 me-3 gap-3 mt-3 mb-3 flex-wrap">
        {
        data.map((ele,idx)=>{
            return <div key={idx}> <UserCard data={ele}/></div>
        })
    }
        
        </div>
}
    </Dashboard>
}


export default EmpManagaDashboard;