import react from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useRef } from 'react';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import { useSelector } from 'react-redux';

function ContestGroupForm(props) {
  const name = useRef();
  const title = useRef();
  const bgimg = useRef();
  const img = useRef();
  const STime = useRef();
  const ETime = useRef();
  const TOKEN = useSelector((state) => state.auth.token);

  async function ContestGroupCreator(e) {
    e.preventDefault();

    const data = {
      contestName: name.current.value,
      contestTitle: title.current.value,
      image: img.current.files[0],
      backgroundImage: bgimg.current.files[0],
      contestGroupStartTime: STime.current.value,
      contestGroupEndTime: ETime.current.value,
    };

    const formData = new FormData();
    formData.append('contestName', data.contestName);
    formData.append('title', data.contestTitle);
    formData.append('image', data.image);
    formData.append('backgroundImage', data.backgroundImage);
    formData.append('contestGroupStartTime', data.contestGroupStartTime);
    formData.append('contestGroupEndTime', data.contestGroupEndTime);

    await axios
      .post(`${HEADER_URL}/CreateContestGroup`, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert('Contest Created Successully');
        console.log('response is : ', res);
        props.addGroup(data);
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  }

  return (
    <div class="mainContainer">
      <form class="ContestGroupForm" onSubmit={ContestGroupCreator}>
        <div class="mb-3">
          <h1>Create Contest Group :</h1>
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            Name :
          </label>
          <input
            required
            placeholder="Christmas and New year live Now"
            ref={name}
            name="contestGroup"
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            Title :
          </label>
          <input
            required
            placeholder="Christmas and New year"
            ref={title}
            name="contestGroupTitle"
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            BackGround Image::
          </label>
          <input
            required
            placeholder="Christmas and New year"
            ref={bgimg}
            name="contestGroup"
            type="file"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            Image:
          </label>
          <input
            required
            placeholder="Christmas and New year"
            ref={img}
            name="contestGroup"
            type="file"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            Start Time:
          </label>
          <input
            required
            placeholder="Christmas and New year"
            ref={STime}
            name="contestGroup"
            type="datetime-local"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            End Time:
          </label>
          <input
            required
            placeholder="Christmas and New year"
            ref={ETime}
            name="contestGroup"
            type="datetime-local"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>
        <div class="GroupButton">
          <Button type="submit" variant="contained">
            Create
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ContestGroupForm;
