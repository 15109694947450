import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CustomizedTables({ array }) {
  const navigate = useNavigate();

  return (
    <div class="ContestGroupTable">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name :</StyledTableCell>
              <StyledTableCell align="left">Active : </StyledTableCell>
              <StyledTableCell align="left">StartTime : </StyledTableCell>
              <StyledTableCell align="left">EndTime : </StyledTableCell>
              <StyledTableCell align="left">Details: </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {array.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="left" component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.isActive === true ? <p>Active</p> : <p>In-Active</p>}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {moment(row.contestGroupStartTime).format('L')} ,
                  {moment(row.contestGroupStartTime).format('LT')}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {moment(row.contestGroupEndTime).format('L')} ,
                  {moment(row.contestGroupEndTime).format('LT')}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    onClick={(e) => {
                      if(localStorage.getItem('role')=='admin'){
                      navigate(                       
                        '/admin/contest', {
                        state: { contestGroup: row._id },
                      });}
                      if(localStorage.getItem('role')==('analysisuser')){
                        navigate(                       
                          '/analysis/contest', {
                          state: { contestGroup: row._id },
                        });}
                        if(localStorage.getItem('role')==('marketing')){
                          navigate(                       
                            '/marketing/contest', {
                            state: { contestGroup: row._id },
                          });}
                    }}
                    variant="contained"
                    color="success"
                  >
                    Details
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
