import styled from "styled-components";

const CreateListingContainer =styled.div`
width:100%;

    .dis{
        width:300px;
        background-color: white;
        /* border:2px solid black; */
    }
`;


export default CreateListingContainer;