import ClosedTicketContainer from "./ClosedTicket";
//Common imports
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { HEADER_URL,header } from '../../../api/constants.js';


function ClosedTicket(){

//Function to fetch tickets
const getTickets = async () => {
    await axios
      .get(`${HEADER_URL}/emp/dashboard/closedtickets`, 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }})
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

useEffect(()=>{
getTickets()
},[])

    return <ClosedTicketContainer>
        Closed TIckets
    </ClosedTicketContainer>
}


export default ClosedTicket;