let HEADER_URL = 'https://crm-backend.ososweb.com';
const __superspaark_url =
  'https://static-content.spaarksweb.com/superspaark-image.png';

const TOKEN = localStorage.getItem('token');

if (process.env.NODE_ENV === 'development') {
  HEADER_URL = 'http://localhost:3002'; //development
} else if (process.env.NODE_ENV === 'staging') {
  //staging
  HEADER_URL = '192.168.0.254:3002';
} else {
  HEADER_URL = 'https://crm-backend.ososweb.com';
}

console.log({
  HEADER_URL,
});

var header = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${TOKEN}`,
};

export { HEADER_URL, TOKEN, header };
// await axios.get(``, { method: 'GET',headers: header })
