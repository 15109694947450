import Container from './activity';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HEADER_URL } from '../../../../api/constants';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';
import Chart from '../../../../components/analysisComponents/Charts/Chart';
import Chooser from '../../../../components/analysisComponents/Charts/Chooser/Chooser';
import { useDispatch } from 'react-redux';
import { setGraphData } from '../../../../store/chartSlice.js';

const mainOptions = ['Logins', 'Downloads'];
const subOptions = ['Hourly', 'Last 7 Days', 'Monthly'];
const colors = [
  '#FA7070',
  '#ACDDDE',
  '#EC7272',
  '#8884d8',
  '#CCFF99',
  '#7FB77E',
  '#F0E161',
  '#9A86A4',
];

// let stateData = {
//     stroke: color ,
//     fill: color,
//     data: [{}],
//     dataKeyTop: 'count',
//     dataKeyBottom:'_id'
// }

function Activity() {
  const [date, setDate] = useState(moment(Date.now()));
  const [mainOption, setMainOption] = useState('Downloads');
  const [subOption, setSubOption] = useState('Last 7 Days');
  const [type, setType] = useState('');
  const TOKEN = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const getOptionFromChild = (option) => {
    if (option.for === 'main') {
      setMainOption(option.value);
    }
    if (option.for === 'sub') {
      setSubOption(option.value);
    }
  };

  const fetchData = async (newDate) => {
    if (newDate) {
      setDate(newDate);
    }

    const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashbaord/getgraphdata?main=${
      mainOption ? mainOption : 'Downloads'
    }&sub=${subOption ? subOption : 'Hourly'}&date=${
      newDate ? moment(newDate).format('L') : moment(date).format('L')
    }`;

    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }, 
      })
      .then((res) => {
        const data = res.data.data;
        if (data.length === 0) {
          alert('No data available');
          return;
        }
        const color = colors[Math.floor(Math.random() * 8)];

        const stateData = {
          stroke: color,
          fill: color,
          data: data,
          dataKeyTop: 'count',
          dataKeyBottom: '_id',
        };

        dispatch(setGraphData(stateData));
        setType(data.type);
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

  useEffect(() => {
    fetchData();
  }, [subOption, mainOption]);

  return (
    <>
      <Container>
        {/* <SideBar /> */}
        <div className="right">
          <div className="date-container">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Date Desktop"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={fetchData}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="chooser">
            <Chooser
              options={mainOptions}
              getOptionFromChild={getOptionFromChild}
              flag="main"
            />
            <Chooser
              options={subOptions}
              getOptionFromChild={getOptionFromChild}
              flag="sub"
            />
          </div>
          <div className="chart-container">
            <Chart type={type} />
          </div>
        </div>
      </Container>
    </>
  );
}

export default Activity;
