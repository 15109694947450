import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import moment from 'moment/moment';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { HEADER_URL } from '../../../api/constants.js';
import WizardContainer from './Wizard.js';

const WizardDashboard = () => {
  const navigate = useNavigate();
  let uploadRef = useRef(null);

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [serviceProviders, setServiceProviders] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedPost, setSelectedPost] = useState({});
  const [reset, setReset] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [isActive, setIsActive] = useState(0);

  //fetch need service post based on dates
  function fetchPosts() {
    let url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/getWizardPostData?`;
    if (from != '') {
      url += `from=${from}`;
    }
    if (to != '') {
      url += `&to=${to}`;
    }

    console.log(url);
    axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log('____________');
        console.log(res.data);
        console.log('____________');
        setPostData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function getCallStatus(status) {
    let obj = {
      connected: 'Connected',
      notConnected: 'Not Connected',
      busy: 'Busy',
      switchOff: 'Switch Off',
      notContacted: 'Not Contacted',
    };
    return obj[status];
  }

  useEffect(() => {
    //fetching need service posts
    function fetchonLoad() {
      let fromCurr = moment().subtract(1, 'year').format().slice(0, 10);
      let toCurr = moment().format().slice(0, 10);

      let url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/sparkswizard/getWizardPostData?`;

      if (fromCurr != '') {
        url += `from=${fromCurr}`;
      }
      if (toCurr != '') {
        url += `&to=${toCurr}`;
      }

      console.log(url);
      axios(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          console.log('____________');
          console.log(res.data);
          console.log('____________');
          setPostData(res.data.data);
          // updateServicePost(postData[0], 0);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }

    fetchonLoad();
  }, [reset]);

  //updating serviceProviders post on right
  function updateServicePost(obj, i) {
    // setIsActive(i);
    // setSelectedPost(obj);
    if (uploadRef.current?.value) uploadRef.current.value = '';
    // const id = obj._id;
    setServiceProviders([]);
    console.log('index', i);
    // let serviceProviders = postData.filter(ele => ele._id == id)
    console.log(postData[i].serviceProviders);

    setServiceProviders([...postData[i].serviceProviders]);
  }

  return (
    <WizardContainer>
      <div className="container-fluid d-flex justify-content-between">
        <div className="left border-danger border-3">
          <div className=" d-flex mb-2 ">
            <div className="d-flex flex-column">
              <label>From </label>
              <input
                value={from}
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                max={moment().format().slice(0, 16)}
                type="date"
                name="from"
                id="from"
                className="form-control"
              />
            </div>
            <div className="d-flex flex-column ms-2">
              <label>To </label>
              <input
                value={to}
                onChange={(e) => {
                  setTo(e.target.value);
                }}
                max={moment().format().slice(0, 16)}
                type="date"
                name="to"
                id="to"
                className="form-control"
              />
            </div>

            <Button
              variant="contained"
              onClick={() => {
                fetchPosts();
              }}
              className="mb-2 ms-2 mt-2"
            >
              Search
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                setFrom('');
                setTo('');
                setReset(!reset);
              }}
              className="mb-2 ms-2 mt-2"
            >
              Reset
            </Button>
          </div>

          <table className="table  table-hover shadow">
            <thead className="table-dark">
              <tr>
                <th scope="col">Details </th>
                <th scope="col">Category</th>
                <th scope="col">Post Link</th>
              </tr>
            </thead>

            <tbody>
              {postData.length > 0 &&
                postData.map((e, i) => {
                  return (
                    <tr key={e._id} className={isActive == i ? 'active' : ''}>
                      <td onClick={() => updateServicePost(e, i)}>
                        <div
                          className="d-flex border-2 border-primary text-start"
                          style={{ width: '15vw' }}
                        >
                          <img
                            src={e.profilePic}
                            className="profile-img"
                            alt=""
                          />
                          <div>
                            <h5>{e.name}</h5>
                            <h6>{e.phone}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="subcategory">
                          {e.subCategory ? e.subCategory : 'Not Available'}
                        </h5>
                        {/* <h5 className="location">
                          {e.placeName ? e.placeName : 'Not Available'}
                        </h5> */}
                      </td>
                      <td>
                        {/* <a
                          href={`https://www.spaarksweb.com/spaark/${
                            e.shareLink.split('/')[5]
                          }`}
                          target="_blank"
                        >
                          Post Link
                        </a> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="right border-danger border-3 d-flex flex-column ">
          <div
            className="d-flex flex-column overflow-scroll"
            style={{ height: '98%', width: '90%' }}
          >
            <table className="table table-hover">
              <thead className="table-dark">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Profile Link</th>
                  <th colSpan={2}>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading && 'Loading...'}
                {serviceProviders.length > 0 &&
                  serviceProviders.map((ele, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <div className="d-flex border-2 border-primary text-start ">
                            {/* <img
                              src={ele.profilePic}
                              className="profile-img"
                              alt={`${ele.name}-profile-pic`}
                            /> */}
                            <div>
                              <h5
                                style={{
                                  maxWidth: '140px',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {ele.name}
                              </h5>
                              <p className="me-1">{ele.phone}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          {/* <a
                            href={`https://www.spaarksweb.com/user/${ele.username}#market`}
                            target="_blank"
                            className="text-center"
                          >
                            {ele.username ? 'Profile' : ''}
                          </a> */}
                          <br />
                          <div
                            className={
                              ele.isSpaarksUser ? 'spaarkUser' : 'nonSpaarkUser'
                            }
                          >
                            {ele.isSpaarksUser ? 'spaarks' : 'non-spaarks'}
                          </div>
                        </td>
                        <td>
                          {ele.callStatus ? getCallStatus(ele.callStatus) : 'Not Recorded'}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end me-5 mt-2 ">
            <Button
              id="send"
              variant="contained"
              className="ServiceProviderCount"
            >
              Total Service Providers :{serviceProviders.length}
            </Button>
            <Button id="send" variant="contained" className="showAnalytics">
              Show Analytics
            </Button>
          </div>
        </div>
      </div>
    </WizardContainer>
  );
};

export default WizardDashboard;
