import CreateListingContainer from "./CreateListing";
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { HEADER_URL,header } from '../../../api/constants.js';
import { useSelector } from "react-redux";
//  Mui Imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Carousel } from 'react-responsive-carousel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
// /dashboard/createlisting

function CreateListing(){

    const menuOptions=["Mobiles", "Bikes", "Cars", "Electronics & Appliance", "Commercial Vehicles & Spares", "Furniture", "Decor", "Clothing", "Fashion", "Books", "Stationary", "Sports", "Pets", "Pet care", "Kids", "Toys", "Industrial products", "Daily needs", "Personal care", "Fitness", "Real estate", "Others"]

//States
    const [username,setUsername]=useState('')
    const [productname,setProductname]=useState('')
    const [price,setPrice]=useState(0)
    const [desc,setDesc]=useState('')
    const [latitude,setLatitude]=useState(0)
    const [longitude,setLongitude]=useState(0)
    const [category,setCategory]=useState('Mobiles')
    const [productTime,setProdcutTime]=useState(30)
    const [imgs,setImgs]=useState([])
    const photos=useRef()

//Functions

    var handleImagesChange=(e)=>{
        var temp=imgs
        for(var i=0;i<e.target.files.length;i++){
            if(temp.length<4){
                temp.push(e.target.files[i])
            }
            else{
                break;
            }
        }
        setImgs([...temp])
        
    }

    var handleSubmit=()=>{
        console.log("inside")
        var formData=new FormData()
        if(username.trim().length==0 || productname.trim().length==0||desc.trim().length==0||price==0){
            alert("Enter all values")
            return
        }
        formData.append("name",username)
        formData.append("productName",productname)
        formData.append("description",desc)
        formData.append("tag",category)
        formData.append("duration",productTime)
        formData.append("price",price)
        formData.append("latitude",latitude)
        formData.append("longitude",longitude)
        for (var i=0;i<imgs.length;i++){
            formData.append("photo",imgs[i])
        }
        console.log("form data is",formData)
        createListing(formData)
    }

    var createListing=async(obj)=>{
            await axios.post(HEADER_URL+'/admin/dashboard/createlisting',obj,{method: 'POST',headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }, })
            .then((res)=>{
                alert("Created Successfully")
                console.log(res.data)
            })
            .catch((err)=>{
                alert("Error")
                console.log(err.message)
            })
    }

console.log("imgs",imgs)


    return <CreateListingContainer>
    <div className="display-6 text-primary text-center">Add Listing</div>
        
        <div className="d-flex gap-2 justify-content-around p-2 align-items-center">
        <div className="d-flex  flex-column justify-content-around  gap-2 mx-auto mt-2 bg-white p-4 mb-5 rounded-4" style={{height:'90%',boxShadow:'2px 2px 4px 0.5px black'}}>
        <div className="d-flex gap-2 justify-content-around">
        <TextField id="outlined-basic" className="w-100" value={username} label="Username :" onChange={(e)=>{setUsername(e.target.value)}} variant="outlined" />
        <TextField id="outlined-basic" className="w-100" value={productname} label="Productname :" variant="outlined" onChange={(e)=>{setProductname(e.target.value)}} />
        <TextField id="outlined-basic"  className="w-100" value={price} label="price" type="number"  variant="outlined" onChange={(e)=>{setPrice(e.target.value)}} />
        </div>
        {/* description */} 
        <div className="d-flex gap-2 justify-content-around">
        <div className="w-75 mx-auto">
            <div  className="form-label" style={{textAlign:'left'}}>Description </div>
            <textarea className="form-control"rows="7" value={desc}  onChange={(e)=>{setDesc(e.target.value)}}></textarea>
        </div>
        <div className="d-flex flex-column justify-content-around mt-3">
        <TextField id="outlined-basic" value={latitude} onChange={(e)=>{setLatitude(e.target.value)}}  className="w-100" label="Latitude" type="number"  variant="outlined" />
        <TextField id="outlined-basic" value={longitude} onChange={(e)=>{setLongitude(e.target.value)}} className="w-100" label="Longitude" type="number"  variant="outlined" />
        </div>
        
        </div>

        <div className="d-flex justify-content-around gap-2 align-items-end">
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" >Category :</InputLabel>
        <Select labelId="demo-simple-select-label" value={category} id="demo-simple-select" onChange={(e)=>{setCategory(e.target.value)}} label="Category" >
          
         
         { menuOptions.map((opt,optIdx)=>{

                        return <MenuItem key={optIdx} value={opt}>{opt}</MenuItem>
              })
        }
        </Select>

        </FormControl>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Product Duration :</InputLabel>
        <Select value={productTime}  onChange={(e)=>{setProductTime(e.target.value)}} labelId="demo-simple-select-label" id="demo-simple-select" label="Product Duration" >
          <MenuItem value={45}>45 Days</MenuItem>
          <MenuItem value={30}>30 Days</MenuItem>
        </Select>
        </FormControl>

        <div class="w-100">
            <div className="d-flex justify-content-around">
        <label for="formFileMultiple" class="form-label">Photos</label>
        <Button  color="primary" onClick={()=>{setImgs([])}}>   Reset </Button>
        </div>
        <input class="form-control" onChange={(e)=>{ handleImagesChange(e) }} type="file" accept='image/jpeg,image/png,image/jpg' id="formFileMultiple" multiple />

        </div>
        </div>
        <p className='ms-auto'>* Max 4 Pics Only</p>
        <Button variant="contained" className='w-25 mx-auto ' color="success" onClick={()=>{ console.log("Hello");handleSubmit()}}>   Submit </Button>
        </div>

        <div className="dis">
        < Carousel showThumbs={false}  autoPlay={true}  infiniteLoop={true}>
        {
            imgs.map((image,imageIdx)=>{
                return <div key={imageIdx}>
                      <img src={URL.createObjectURL(image)} width='300px' height='220px'/>
                      <IconButton aria-label="delete" size="large" className='ms-auto me-5 d-block' onClick={()=>{ var temp=imgs;temp.splice(imageIdx,1);setImgs([...temp])  }}>
                    <DeleteIcon />
                    </IconButton>
                </div>
            })
        }    
            
            
        </Carousel>
        

        </div>

        </div>
       
    </CreateListingContainer>
}


// e.target.files[0]


export default CreateListing;