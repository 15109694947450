import { useState, useRef,useEffect } from 'react';
import Container from './inAppNotification.js';
import { useSelector } from 'react-redux';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import PropagateLoader from "react-spinners/PropagateLoader";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import { formData } from '../../../utils/extactFormData';
import TextareaAutosize from '@mui/base/TextareaAutosize';

const InAppNotification = () => {

  //Loading 
  let [loading, setLoading] = useState(false);
  const override= {
    display: "block",
    position:"absolute",
    borderColor: "red",
    top:"50%",
    bottom:"50%",
    left:"50%",
    right:'50%'
  };



  const TOKEN = useSelector((state) => state.auth.token);

//Form Input variables

const [title,setTitle]=useState('')
const [redirectTo,setRedirectTo]=useState('general')
const [message,setMessage]=useState('')
const [sendTo,setSendTo]=useState('All Users')
const [extraField,setExtraField]=useState('')
const [error,setError]=useState(false)

//Status

// Handle Submit

  var handleSubmit=async()=>{

    setLoading(false)
    if(title.trim().length==0 || message.trim().length==0 ||(sendTo=='Group users' && extraField.trim().length==0)||(sendTo=='Contest users' && extraField.trim().length==0)||(sendTo=='User' && extraField.length!=10 && Number.isInteger(extraField)==false) )
    {
      setError(true)
      return;
    }
    setError(false)
    setLoading(true)
    console.log("true")
  const data = {
     title,
    body: message,
    redirectTo:redirectTo,
    category: sendTo,

  };
  if(sendTo=='Group users')
  {
    data.groupName=extraField
  }
  if(sendTo=='Contest users')
  {
    data.contestName=extraField
  }
  
  if(sendTo=='User')
  {
    data.mobileNumber=extraField
  }
  
  console.log('datais : ', data);
  

  axios
    .post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/createnotification`, data, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
    .then((res) => {
      setLoading(false)
      alert('sent');
      
    })
    .catch((e) => {
      setLoading(false)
      alert('error');
    });

    
  }


//Get Group Nmames
const [groupNames,setGroupNames]=useState([])
var getGroups=async()=>{
  axios
  .get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/groups?type=free`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  })
  .then((res) => {
    setGroupNames(res.data)
    
  })
  .catch((e) => {
    alert('error');
  });
}
useEffect(()=>{
  getGroups()
},[])






  return (
    <Container>
      <div>
      <div className="card ">
        <div className="card-body">
          <div className="display-6 text-primary mb-3">In App Notification</div>
        {/* //Title   */}
        <div className='mt-3'>
              <TextField id="demo-helper-text-misaligned-no-helper" value={title} onChange={(e)=>{setTitle(e.target.value)}} label="Title" />
        </div>
        {/* Message */}
        <div className='mt-3'>
          <TextField id="outlined-multiline-flexible" spellcheck="false"  value={message} onChange={(e)=>{setMessage(e.target.value)}} label="Message" multiline />
        </div>
        {/* //Redirect to */}
        <div className="mt-3">
            <FormControl sx={{ m: 1, minWidth: "70%" }} >
            <InputLabel id="demo-simple-select-label">Redirect to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={redirectTo} 
              onChange={(e)=>{setRedirectTo(e.target.value)}}
              label="RedirectTo"
              
            >
              <MenuItem value={'general'}>NoRedirection</MenuItem>
              <MenuItem value={'spin-wheel'}>SpinWheel</MenuItem>
              <MenuItem value={'contest'}>Contest Page</MenuItem>
          
            </Select>
          </FormControl>

        </div>
         {/* //Send To */}
         <div className="mt-3">
            <FormControl sx={{ m: 1, minWidth: "70%" }} >
            <InputLabel id="demo-simple-select-label">Send to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sendTo} 
              onChange={(e)=>{setSendTo(e.target.value)}}
              label="Send To"
            >
              <MenuItem value={'All Users'}>All Users</MenuItem>
              <MenuItem value={'Group users'}>Group Users</MenuItem>
              <MenuItem value={'Contest users'}>Contest Users</MenuItem>
              <MenuItem value={'Spin Wheel users'}>Spin Wheel Users</MenuItem>
              <MenuItem value={'User'}>User</MenuItem>
            </Select>
          </FormControl>

        </div>
        {/*Group Name */}
       {sendTo=='Group users'&& <div className='mt-3'>
       <FormControl sx={{ m: 1, minWidth: "70%" }} >
            <InputLabel id="demo-simple-select-label">Select Group</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={extraField} 
              onChange={(e)=>{setExtraField(e.target.value)}}
              label="Select Group"
            >
              <MenuItem value={'All'}>All</MenuItem>
              {
                groupNames.groups.map((ele,idx)=>{
                  return <MenuItem key={idx} value={ele._id}>{ele.title}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </div>}
          {/* value={extraField} onChange={(e)=>{setExtraField(e.target.value)}} */}
          {/*Contest Name */}
          {sendTo=='Contest users'&&  <div className='mt-3'>
              <TextField id="demo-helper-text-misaligned-no-helper" value={extraField} onChange={(e)=>{setExtraField(e.target.value)}} label="Contest Name" />
        </div>}
          {/*User Number */}
          {sendTo=='User'&& <div className='mt-3'>
              <TextField id="demo-helper-text-misaligned-no-helper" value={extraField} onChange={(e)=>{setExtraField(e.target.value)}} label="User Number" />
        </div>}
          <div className="mt-3">
          <Button variant="contained" onClick={handleSubmit}>Send</Button>
          </div>
        </div>
      </div>

      </div>
      <div className="status">
        <div className="card cardd">
        <div className="display-6 text-secondary">STATUS</div>
        <div className='h-100 d-flex flex-column justify-content-center'>
        { sendTo=='Group users' && extraField.trim().length==0 &&<h6 className="lead text-danger">* Enter Valid Name</h6>}
        { sendTo=='Contest users' && extraField.trim().length==0  &&<h6 className="lead text-danger">* Enter Valid Name</h6>}
        { sendTo=='User' && extraField.length!=10 && Number.isInteger(extraField)==false &&<h6 className="lead text-danger">* Enter Valid Mobile Number</h6>}
       
        {error&&   <p className="lead text-danger">* Enter All Fields</p>  }

       
       
        <PropagateLoader color="#36d7b7" loading={loading} cssOverride={override} size={10}/>
        </div>
       
       
        </div>

       

      </div>
    </Container>
  );
};

export default InAppNotification;


// function SendData(e) {

// }
