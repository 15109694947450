import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width:100%;
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .date-picker-container {
      display: flex;
      gap: 12px;
    }
    .title-re {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      margin: 5px auto;
      color: #5c5a5a;
      width: fit-content;
      padding: 10px;
      border-radius: 23px;
      font-size: 45px;
      display:inline;
      /* border:1px solid red; */
    }
    .Heading {
      display:flex;
      /* align-content: center; */
      /* justify-content: space-between; */
      align-items: baseline;
      /* border:1px solid red; */
      width:100%;
      padding-left:100px;
    }
  }

  .card-spendings {
    width: 200px;
    height: 122px;
    padding: 10px;
    box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
    .top-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        color: rgb(160, 160, 160);
      }
      .icon {
        font-size: 30px;
        padding: 5px;
        border-radius: 5px;
        align-self: flex-end;
      }
    }

    .card-body {
      display: flex;
      align-items: flex-start;
      .money {
        font-size: 25px;
        padding: 6px;
        .inc-text {
          font-size: 11px;
          padding: 6px;
        }
      }
    }
  }
`;

export default Container;
