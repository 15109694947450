import LeadsDeatilsContainer from "./LeadsDetails";
import { useSelector } from "react-redux";
import { HEADER_URL } from '../../../api/constants.js';
import { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation } from "react-router-dom";
const leadsDetailsUrl=`${HEADER_URL}/superuser/dashboard/emp/leads/details`
import * as React from 'react';
import moment from 'moment/moment';
import Button from '@mui/material/Button';






function LeadsDetails(){
    const TOKEN = useSelector((state) => state.auth.token);
    const sus_user_passkey = localStorage.getItem('sus_user_passkey');
    const employeeId=useLocation().state.employeeId;
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [body,setBody]=useState({employeeId:employeeId})
    const [leadDetails,setLedaDetails]=useState({});
    const getLeadsDetails = async () => {
        var x=body
        x.startDate=fromDate
        x.endDate=toDate
        console.log(x)
        await axios
          .post(leadsDetailsUrl,x ,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },   
          })
          .then((res) => {
            setLedaDetails({...res.data})
          })
          .catch((err) => {
            console.log('err', err);
            alert('something went wrong!');
          });
      };

console.log('leads',leadDetails)
console.log(leadDetails&&leadDetails.final)
  useEffect(() => {
    getLeadsDetails();
  }, []);
return (
   <LeadsDeatilsContainer>
    <div className="d-flex justify-content-between ms-5 me-5">
    <h2 >Filter</h2>

    {leadDetails && leadDetails.userdata&&
    <div className="d-flex gap-3 justify-content-around align-items-center">
            <div>
                <div>{leadDetails.userdata.name}</div>
                <div>{leadDetails.userdata.phone}</div>
            </div>
            <div>
                <img className="rounded-circle" src={leadDetails.userdata.profilePic
} width='80px' height='80px' alt="" />
            </div>
    </div>
}
    </div>
    <div className="filter d-flex justify-content-around align-items-center mt-5">

        <div className="">
            <label  >From :</label> 
            <input
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    max={moment().format().slice(0, 16)}
                    type="datetime-local"
                    name="from"
                    id="from"
                    className="form-control"
                  />
        </div>
        <div className="div">
            <label > To : </label>
            <input
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    max={moment().format().slice(0, 16)}
                    type="datetime-local"
                    name="to"
                    id="to"
                    className="form-control"
                  />
        </div>
        <div>
        <Button variant="contained" size="medium" onClick={()=>{
            // setBody({...body,...{startDate:fromDate},...{endDate:toDate}})
            getLeadsDetails()
        }}>
          Filter
        </Button>
        </div>

    </div>

    {leadDetails && 
        <div className="d-flex justify-content-around mt-5 me-5">

        <div className="d-flex gap-3">
            <div className='date' >From Date :</div>
            <div className='date text-success font-weight-bold'>{moment(leadDetails.startDate).format('lll')}</div>
        </div>
        <div className="d-flex gap-3">
            <div className='date'>To Date :</div>
            <div className='date text-success font-weight-bold'>{moment(leadDetails.endDate).format('lll')}</div>
        </div>
        </div>
    }
    {   leadDetails && leadDetails.final &&
    
    <table className="w-75 mx-auto mt-5 table table-striped">
        <thead className="bg-success text-light">
            <th>DATE</th>
            <th>SAVED</th>
            <th>CREATED</th>
            <th>ACCEPTED</th>
            <th>REJECTED</th>
        </thead>
        <tbody>
        {
         Object.entries(leadDetails.final).map((entry,idx) => {
        const [key, value] = entry;
        return <tr key={idx}>
            <td>{key}</td>
            <td>{value.saved}</td>
            <td>{value.created}</td>
            <td>{value.accepted}</td>
            <td>{value.rejected}</td>
        </tr>
      })
    }
    </tbody>
    </table>
    }

   </LeadsDeatilsContainer>
)
}

export default LeadsDetails;