import styled from 'styled-components';

const SpecificListingContainer = styled.div`
background-color: #f8f9fe;
  padding:10px;
  font-size: 12px;
  width: 100%;
  display:flex;
  gap:80px;
  .cont{
    display:flex;

  }
  .orangeBg{
    box-shadow: 2px 2px 4px 1px #ff495a;
  } 
  .blackBg{
    box-shadow: 2px 2px 4px 1px black;
  }
  .card::-webkit-scrollbar{
    display:none;
  }
 
`;

export default SpecificListingContainer;
