import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  .table {
    .cellWrapper {
      display: flex;
      align-items: center;
      color: black;
      flex-direction: column;
      justify-content: space-between;
    }

    .status {
      padding: 5px;
      border-radius: 5px;

      &.Approved {
        color: green;
        background-color: rgba(0, 128, 0, 0.151);
      }
      &.Pending {
        color: goldenrod;
        background-color: rgba(189, 189, 3, 0.103);
      }
      &.Suspended {
        color: #be421b !important;
        background-color: #fde8e8 !important;
      }
    }

    .last-seen {
      margin: 0px;
      min-width: 77px;
      text-align: center;
      margin-top: 3px;
      font-size: 10px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
      padding: 2px;
      border-radius: 5px;
    }

    .btn-font-loc {
      font-size: 13px;
      color: #1c64f2 !important;
      background-color: #c3ddfc !important;
    }

    .btn-font-loc:hover {
      font-size: 13px;
      color: #c3ddfc !important;
      background-color: #1c64f2 !important;
    }

    .btn-font-del {
      font-size: 13px;
      color: #fde8e8 !important;
      background-color: #be421b !important;
    }
  }
`;

export default Container;
