import UserDetailsContainer from "./UserDetails";
import UserPosts from "../UserPosts/UserPosts.jsx";
import { useLocation } from "react-router-dom";
import { HEADER_URL,header } from '../../../api/constants.js';
import axios from "axios";
import { useEffect,useState,useRef } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import TextField from '@mui/material/TextField';
import SendFcm from "../SendFcm/SendFcm.jsx";
import Button from '@mui/material/Button';
import RewardDetails from "../RewardDetails/RewardDetails.jsx";

// import { useSelector } from "react-redux";
// const TOKEN = useSelector((state) => state.auth.token);
function UserDetails(){

    const userId=useLocation().state.userId
  
    //States
    let [loading, setLoading] = useState(true);
    const [data,setData]=useState()
    const [currNav,setCurrNav]=useState('sendfcm')


    //Refs
    const fcmRef=useRef(null)
    const webRef=useRef(null)
    const postsRef=useRef(null)
    const rewardRef=useRef(null)
    // Fetch user data

    var getUserData=async()=>{
        setLoading(true)
        await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/new/`+userId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }})
        .then((res)=>{console.log(res.data.user)
            setData(res.data.user)
        })
        .catch((err)=>{
            console.log("Error occured")
            alert(err.message)
        })
        setLoading(false)
    }
    useEffect(()=>{
        getUserData()
    },[])


    useEffect(()=>{
        if(currNav=='sendfcm'){
             fcmRef.current?.scrollIntoView({behavior: 'smooth'});
        }
        else if(currNav=='posts')
        {
            postsRef.current?.scrollIntoView({behavior: 'smooth'});
        }
        else if(currNav=='rewards'){
            rewardRef.current?.scrollIntoView({behavior: 'smooth'});
        }

    },[currNav])



    return <UserDetailsContainer>
{loading===false&&data&&<>
  
        
        <div className="d-flex gap-3 p-5    ">

        <div><img src={data.profilePic}  style={{borderRadius:'50%'}}  width="100" height="100"   /></div>
        <div className='d-flex flex-column gap-1 justify-content-around'>

            <div className="text-success">{data.username}</div>
            <div className="text-primary">{data.name}</div>
            <div className="text-primary" >{data.phone}</div>
            <div className="text-primary"> {data.email}</div>


        </div>
        <div className="ms-auto d-flex gap-5"> 
        <div>
            <div >BDE Action</div>
            <Button variant="contained" color='success' >Assign</Button>
        </div>

       {localStorage.getItem('role')=='admin'&& <div>
            <div >Referral Action</div>
            <Button variant="contained" color='error' >Block</Button>
        </div>
}    
            
        </div>
        
        <div></div>

        </div>
        <div className="d-flex justify-content-around">
        <div className="cardd">
            <div className=" lead text-secondary">Posts</div>
            <div className="text-primary font-weight-bold text-count ">  {data.post.length}</div>
        </div>
        <div className="cardd">
            <div className=" lead text-secondary">Reports</div>
            <div className="text-primary font-weight-bold text-count ">  {data.reports.length}</div>
        </div>
        <div className="cardd">
            <div className=" lead text-secondary">Blocked users</div>
            <div className="text-primary font-weight-bold text-count ">  {data.blockedUsers.length}</div>
        </div>
        <div className="cardd">
            <div className=" lead text-secondary">web Logins</div>
            <div className="text-primary font-weight-bold text-count ">  {data.webLogins.length}</div>
        </div>
        <div className="cardd">
            <div className=" lead text-secondary">Followers</div>
            <div className="text-primary font-weight-bold text-count ">  {data.followers.length}</div>
        </div>
        </div>
        <div className="d-flex flex-column gap-3 m-5 p-5">
            <div className="d-flex justify-content-around gap-5">
            <TextField  id="outlined-disabled" label="Username" defaultValue={" "} value={data.username} />
          <TextField  id="outlined-disabled" label="Email" defaultValue={" "} value={data.email} />
            </div>
            <div className="d-flex justify-content-around gap-5">
             <TextField  id="outlined-disabled" label="Name"  defaultValue={" "} value={data.name} />
             <TextField  id="outlined-disabled" label="Phone" defaultValue={" "} value={data.phone} />
            </div>
            <div className="d-flex justify-content-around gap-5">
          <TextField  id="outlined-disabled" label="Address" defaultValue={" "} value={data.username} />
           <TextField  id="outlined-disabled" label="Gender" defaultValue={" "} value={data.gender} />
            </div>
            <div className="d-flex justify-content-around gap-5">
  <TextField  id="outlined-disabled" defaultValue={" "} label="UPI-Id" value={data.bankDetails.upiId} />
        <TextField  id="outlined-disabled" label="Badge Url" defaultValue={" "} value={data.badgeUrl} />
            </div>
            <div className="d-flex justify-content-around gap-5">
   <TextField  id="outlined-disabled" label="ImageIcon" defaultValue={" "} value={data.ImageIcon   } />
           <TextField  id="outlined-disabled" label="Wall Url" defaultValue={" "} value={data.wallUrl} />
            </div>
        </div>
        
        
        
        
        
<div className="d-flex justify-content-around mb-5 pb-5">
        <Button variant={currNav=='sendfcm'?"contained":'outlined'} onClick={()=>{setCurrNav('sendfcm')}}>Send Fcm</Button>
        <Button variant={currNav=='activelogin'?"contained":'outlined'} onClick={()=>{setCurrNav('activelogin')}}>Active Web Logins</Button>
        <Button variant={currNav=='rewards'?"contained":'outlined'} onClick={()=>{setCurrNav('rewards')}}>Reward</Button>
        <Button variant={currNav=='posts'?"contained":'outlined'} onClick={()=>{setCurrNav('posts')}}>Posts</Button>
        </div>

        {currNav=='sendfcm'&& <div ref={fcmRef} ><SendFcm /></div> }
        {currNav=='posts'&&  <div ref={postsRef}> <UserPosts userId={userId} user={{profilePic:data.profilePic,name:data.name}}/> </div>}
        {currNav=='rewards'&& <div ref={rewardRef}><RewardDetails userId={userId} /> </div>}










        </>}
        <ScaleLoader color="#36d7b7"  loading={loading} style={{position:'absolute',left:'50%',top:'40%'}}  size={150}/>
    </UserDetailsContainer>
}

export default UserDetails;












      {/* // <div className="d-flex flex-column gap-3 m-5 p-5">
        //     <div className="d-flex justify-content-around gap-5">
        //     {data&&data.username&& <TextField disabled id="outlined-disabled" label="Username" defaultValue={" "} value={data.username} />}
        //     {data&&data.email&& <TextField disabled id="outlined-disabled" label="Email" defaultValue={" "} value={data.email} />}
        //     </div>
        //     <div className="d-flex justify-content-around gap-5">
        //     {data&&data.name&& <TextField disabled id="outlined-disabled" label="Name"  defaultValue={" "} value={data.name} />}
        //     {data&&data.phone&& <TextField disabled id="outlined-disabled" label="Phone" defaultValue={" "} value={data.phone} />}
        //     </div>
        //     <div className="d-flex justify-content-around gap-5">
        //     {data&&data.username&& <TextField disabled id="outlined-disabled" label="Address" defaultValue={" "} value={data.username} />}
        //     {data&&data.gender&& <TextField disabled id="outlined-disabled" label="Gender" defaultValue={" "} value={data.gender} />}
        //     </div>
        //     <div className="d-flex justify-content-around gap-5">
        //     {data&&data.bankDetails&&data.bankDetails.upiId&& <TextField disabled id="outlined-disabled" defaultValue={" "} label="UPI-Id" value={data.bankDetails.upiId} />}
        //     {data&&data.badgeUrl&& <TextField disabled id="outlined-disabled" label="Badge Url" defaultValue={" "} value={data.badgeUrl} />}
        //     </div>
        //     <div className="d-flex justify-content-around gap-5">
        //     {data&&data.ImageIcon&& <TextField disabled id="outlined-disabled" label="ImageIcon" defaultValue={" "} value={data.ImageIcon   } />}
        //     {data&&data.wallUrl&& <TextField disabled id="outlined-disabled" label="Wall Url" defaultValue={" "} value={data.wallUrl} />}
        //     </div>
        // </div> */}