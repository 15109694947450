import StartupDailogContainer from "./StartupDailog";
import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
// Select mui
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from "react-redux";
// Url
import { HEADER_URL } from '../../../api/constants.js';
const url = `${HEADER_URL}/superuser/dashboard/androidversion/updatedialog`;
import axios from "axios";


function StartupDailog(){

    //Variables
    const [title,setTitle]=useState('Spaarks')
    const [body,setBody]=useState('Body Of Spaarks')
    const [image,setImage]=useState('https://res.cloudinary.com/ososbackend/image/upload/v1658816485/angular/left%20nav%20images/Spaarks_Logo-01_1_ccjrfs.webp')
    const [type,setType]=useState('web')
    const [link,setLink]=useState('')
    const [link_ios,setLinkIos]=useState('')
    const [button,setButton]=useState('Okay')
    const [showDaily,setShowDaily]=useState(false)
    const [showRepeat,setShowRepeat]=useState(false)
    const [showClose,setShowClose]=useState(false)
    const [showDialog,setShowDialog]=useState(false)
    const [closingCount,setClosingCount]=useState(1)
    const [open,setOpen]=useState(false)
    const [errText,setErrText]=useState('')
    //Send Dailog
    const TOKEN = useSelector((state) => state.auth.token);
    var sendDailog=async()=>{
       if(title.trim().length==0||(image.trim().length==0&&body.trim().length==0)||link.trim().length==0||link_ios.trim().length==0||closingCount<=0){
        setOpen(true)
        setErrText("Enter details CORRECTLY")
        return
       }
        var obj={
        title,body,dType:type,image,buttonText:button,link,link_ios,showDaily,showRepeat,showClose,closingCount,showDialogOnStartup:showDialog
        }
        // Axios Call
        await axios.post(url,obj ,{method: 'POST',headers: {'Content-Type': 'application/json',Authorization: `Bearer ${localStorage.getItem('token')}`,},})
        .then((res)=>{
            if(res.data.message=='success'){
                alert("Updated")
            }
        })
        .catch((err)=>{alert(alert  .message)})
       

    }
    if(open==true){
        console.log("Hello")
        setTimeout(()=>{ 
            console.log("Byee")
        setOpen(false)},5000)
       
    }
    return <StartupDailogContainer>

        <div className="card  ps-5 d-flex flex-column justify-content-around pe-5" style={{width:'1000px'}}>

        <div className="display-6 text-primary">Startup Dialog</div>

        {/* //Title   */}
        <div className='d-flex gap-2'>
              <TextField id="demo-helper-text-misaligned-no-helper"  value={title} onChange={(e)=>{setTitle(e.target.value)}} label="Title" />
              <TextField id="demo-helper-text-misaligned-no-helper" value={image} onChange={(e)=>{setImage(e.target.value)}} label="Image Url" />
        </div>
         {/* //body   */}
         <div className="">
            <div  className="form-label">Body </div>
            <textarea className="form-control" value={body} onChange={(e)=>{setBody(e.target.value)}} rows="3"></textarea>
        </div>
        
        {/* Show daily and show repeat */}
        <div className="d-flex gap-2">
            {/* show daily */}
            <Box sx={{width:'100%'}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Show Daily</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showDaily}
                label="Show Daily"
                onChange={(e)=>{setShowDaily(e.target.value)}}
                >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
                
                </Select>
            </FormControl>
            </Box>
            {/*show repeat  */}
            <Box sx={{width:'100%' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Show Repeat</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showRepeat}
                label="Show Repeat"
                onChange={(e)=>{setShowRepeat(e.target.value)}}
                >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
                
                </Select>
            </FormControl>
            </Box>
            {/* type */}
            <Box sx={{width:'100%' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Type"
                onChange={(e)=>{setType(e.target.value)}}
                >
                <MenuItem value={'web'}>web</MenuItem>
                <MenuItem value={'screen'}>screen</MenuItem>
                
                </Select>
            </FormControl>
            </Box>
            </div>


        <div className="d-flex gap-2">
        <TextField id="demo-helper-text-misaligned-no-helper" value={link} onChange={(e)=>{setLink(e.target.value)}} label="Link" />
        <TextField id="demo-helper-text-misaligned-no-helper" value={link_ios} onChange={(e)=>{setLinkIos(e.target.value)}} label="Link_IOS" />
        <TextField id="demo-helper-text-misaligned-no-helper" value={button} onChange={(e)=>{setButton(e.target.value)}} label="Button text" />
        </div>
        
        <div className=" d-flex gap-2  ">
        <TextField id="demo-helper-text-misaligned-no-helper" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={closingCount}
        type='number'
        onChange={(e)=>{
               setClosingCount(e.target.value)
         }} 
           label="Closing Count" />
            <Box sx={{width:'100%'}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Show Close</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showClose}
                label="Show Close"
                onChange={(e)=>{setShowClose(e.target.value)}}
                >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
                
                </Select>
            </FormControl>
            </Box>
            <Box sx={{width:'100%'}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Show Dialog</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showDialog}
                label="Show Dailog"
                onChange={(e)=>{setShowDialog(e.target.value)}}
                >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
                
                </Select>
            </FormControl>
            </Box>
        
        
        </div>
        
        <Button variant="contained" onClick={sendDailog} >Update</Button>
        




        </div>

        {/* Completed */}
        <div className='d-flex flex-column justify-content-center gap-2 w-100 align-items-center'>

        <div className="card bg-dark " style={{width:'230px'}} >
            <div className="card-header font-weight-bold text-light" style={{fontSize:'20px'}}>
                {title}
            </div>
            {
                image.trim()!='' &&
                <div className="card-img-top p-2">
                    <img src={image}  height='150px' width='auto' alt="" />
                </div>
            }

            <div className="card-footer d-flex justify-content-around">

            {showClose && <div className='w-100 text-primary' style={{borderRight:'1px solid black'}}>Close</div>}
                <div className='w-100 text-primary ' >{button} </div>

            </div>

        </div>

        <div className="card bg-dark" style={{width:'230px'}} >
            <div className="card-header  font-weight-bold text-light"  style={{fontSize:'20px'}}>
                {title}
            </div>
            {
                body.trim()!='' &&
                <div className="card-body p-2 text-light">
                    <p className="lead" style={{fontSize:'15px'}}>{body}</p>
                </div>
            }

            <div className="card-footer d-flex justify-content-around">

               {showClose && <div className='w-100 text-primary' style={{borderRight:'1px solid black'}}>Close</div>}
                <div className='w-100 text-primary' >{button} </div>

            </div>

        </div>


        </div>
        {/* MUi Alert */}
        <Snackbar open={open} autoHideDuration={6000} >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errText}
        </Alert>
      </Snackbar>
        
    </StartupDailogContainer>
}


export default StartupDailog;










// <div > show Dialog On Startup: </div>
//         <input type="checkbox" value={show} onChange={()=>{
//             if(show==true){
//                 setShow(false)
//             }
//             else{
//                 setShow(true)
//             }
            
//         }} />

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });