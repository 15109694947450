const places = [
    'Bangalore',
    'Kolkata',
    'Chennai',
    'Ahmadabad',
    'Pune',
    'Delhi',
    'Kanpur',
    'Surat',
    'Hyderabad',
    'Nagpur',
    'Jaipur',
    'Mirzapur',
    'Lucknow',
    'Ghaziabad',
    'Chinchwad',
    'Vadodara',
    'Madurai',
    'Ludhiana',
    'Meerut',
    'Nashik',
    'Varanasi',
    'Thane',
    'Gwalior',
    'Amritsar',
    'Bhopal',
    'Chandigarh',
    'Haora',
    'Kalyan',
    'Jodhpur',
    'Raipur',
    'Rajkot',
    'Trichinopoly',
    'Indore',
    'Allahabad',
    'Dispur',
    'Bhiwandi',
    'Moradabad',
    'Patna',
    'Jalandhar',
    'Bezwada',
    'Jamshedpur',
    'Salem',
    'Bareilly',
    'Jabalpur',
    'Thiruvananthapuram',
    'Solapur',
    'Srinagar',
    'Tiruppur',
    'Bhilai',
    'Bhayandar',
    'Mysore',
    'Warangal',
    'Shimoga',
    'Kochi',
    'Nanded',
    'Cuttack',
    'Coimbatore',
    'Asansol',
    'Raurkela',
    'Tinnevelly',
    'Hubli',
    'Bhavnagar',
    'Ulhasnagar',
    'Kolhapur',
    'Dehra Dun',
    'Jamnagar',
    'Malegaon',
    'Bikaner',
    'Udaipur',
    'Guntur',
    'Nellore',
    'Akola',
    'Chandrapur',
    'Davangere',
    'Muzaffarnagar',
    'Amravati',
    'Ujjain',
    'Agartala',
    'Dhulia',
    'Jammu',
    'Parbhani',
    'Belgaum',
    'Gaya',
    'Ahmadnagar',
    'Bellary',
    'Sangli',
    'Sikar',
    'Jalgaon',
    'Nizamabad',
    'Bhagalpur',
    'Latur',
    'Rajahmundry',
    'Tuticorin',
    'Vishakhapatnam',
    'Vizag',
    'Bhilwara',
    'Kamarhati',
    'Tumkur',
    'Shahjanpur',
    'Panihati',
    'Bilaspur',
    'Purnea',
    'Shiliguri',
    'Bhatpara',
    'Agra',
    'Ichalkaranji',
    'Cuddapah',
    'Barasat',
    'Rohtak',
    'Anantapur',
    'Bijapur',
    'Vijayapura',
    'Mathura',
    'Begusarai',
    'Faridabad',
    'Alwar',
    'Porbandar',
    'Nagercoil',
    'Berhampur',
    'Kulti',
    'Shillong',
    'Patiala',
    'Dhanbad',
    'Itanagar',
    'Sambalpur',
    'Kagaznagar',
    'Bidar',
    'Trichur',
    'Amroha',
    'Ballia',
    'Aligarh',
    'Hisar',
    'Bhalswa Jahangir Pur',
    'Rampur',
    'Karimnagar',
    'Bidhannagar',
    'Bali',
    'Ranchi',
    'Burhanpur',
    'Bhuj',
    'Darbhanga',
    'Aizawl',
    'Tirupati',
    'Dhule',
    'Sannai',
    'Kota',
    'Fatehpur',
    'Ratlam',
    'Guna',
    'Drug',
    'Imphal',
    'Hindupur',
    'Etawah',
    'Guwahati',
    'Jamalpur',
    'Bharatpur',
    'Kadapa',
    'Ongole',
    'Sonipat',
    'Katni',
    'Kirari Suleman Nagar',
    'Bhubaneshwar',
    'Pali',
    'Kumbakonam',
    'Uluberiya',
    'Mahbubnagar',
    'Hapur',
    'Tanjore',
    'Medininagar',
    'Saharanpur',
    'Morbi',
    'Karnal',
    'Nagaon',
    'Pondicherry',
    'New Delhi',
    'Nashik',
    'Ghandinagar',
    'Panvel',
    'Shimla',
    'Gorakhpur',
    'Panaji',
    'Kohima',
    'Gangtok',
    'Adoni',
    'Mangalore',
    'Panchkula',
    'Kavaratti',
    'Anand',
    'Durgapur',
    'Ambattur',
    'Ambala',
    'Bally',
    'Bahraich',
    'Ajmer',
    'Bhimavaram',
    'Avadi',
    'Bettiah',
    'Jhansi',
    'Bihar Sharif',
    'Bathinda',
    'Bhusawal',
    'Gulbarga',
    'Buxar',
    'Bulandshahr',
    'Bhiwani',
    'Dehri',
    'Kurnool',
    'Danapur',
    'Bongaigaon',
    'Dibrugarh',
    'Dharmavaram',
    'Calicut',
    'Gandhidham',
    'Chittoor',
    'Farrukhabad',
    'Quilon',
    'Guntakal',
    'Dewas',
    'Gudivada',
    'Kakinada',
    'Hosur',
    'Erode',
    'Hazaribagh',
    'Rajapalaiyam',
    'Jaunpur',
    'Jalna',
    'Gopalpur',
    'Kalyan-Dombivli',
    'Muzaffarpur',
    'Junagadh',
    'Haridwar',
    'Kavali',
    'Saugor',
    'Katihar',
    'Hubli–Dharwad',
    'Kishanganj',
    'Barddhaman',
    'Khora-Ghaziabad',
    'Jorhat',
    'Loni',
    'Firozabad',
    'Kozhikode',
    'Maheshtala',
    'Karawal Nagar',
    'Madhyamgram',
    'Panipat',
    'Daltonganj',
    'Kharagpur',
    'Mau',
    'Morena',
    'Hospet',
    'Miryalaguda',
    'Kottayam',
    'Naihati',
    'Handwara',
    'Nadiad',
    'Madanapalle',
    'Noida',
    'Raichur',
    'Narasaraopet',
    'Mango',
    'Phagwara',
    'Pallavaram',
    'Mira-Bhayandar',
    'Purnia',
    'Proddatur',
    'Ramgundam',
    'Raipur',
    'Munger',
    'Vizianagaram',
    'Nangloi Jat',
    'Sambhal',
    'Orai',
    'Port Blair',
    'Pimpri-Chinchwad',
    'Daman',
    'Raiganj',
    'Alappuzha',
    'Arrah',
    'Baranagar',
    'Bhind',
    'Bokaro',
    'Chapra',
    'Deoghar',
    'Dindigul',
    'Giridih',
    'Hajipur',
    'Howrah',
    'Jehanabad',
    'Karaikudi',
    'Khandwa',
    'Kollam',
    'Machilipatnam',
    'Malda',
    'Mehsana',
    'Motihari',
    'Nandyal',
    'North Dumdum',
    'Phusro',
    'Raebareli',
    'Ramgarh',
    'Sagar',
    'Satara',
    'Serampore',
    'Silchar',
    'Siwan',
    'Sri Ganganagar',
    'Surendranagar Dudhrej',
    'Tadepalligudem',
    'Tezpur',
    'Thoothukudi',
    'Tiruvottiyur',
    'Unnao',
    'Vasai-Virar',
    'Vellore',
    'Chinsurah',
    'Gurgaon',
    'Sasaram (',
    'Bokaro Steel City',
    'Kannur',
    'Pudukkottai',
    'Palakkad',
    'Tirur',
    'Rajpur Sonarpur',
    'Rewa',
    'Vijayawada',
    'Saharsa',
    'Secunderabad',
    'Aurangabad',
    'Sirsa',
    'Shivpuri',
    'SultanPur Majra',
    'South Dumdum',
    'Tenali',
    'Suryapet',
    'Tirunelveli',
    'Marie Oulgaret',
    'Thanjavur',
    'Gulbarga',
    'Udupi',
    'Mumbai',
    'Vasco Da Gama',
    'Hamirpur',
    'Eluru',
    'Purulia',
    'Trichy',
    'Malappuram',
    'Amaravati',
    'Tiruvannamalai',
    'Aurangabad - bihar',
    'Raurkela Industrial Township',
    'Satna',
    'Singrauli',
    'Srikakulam',
    'Tadipatri',
    'Tinsukia',
    'Uzhavarkarai',
    'Berhampur',
    'Khammam',
    'Goa',
    'Perinthalmanna',
    'Yamunanagar',
  ];

  export default places;