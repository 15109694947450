
import styled from "styled-components";


const Container = styled.div`
   display: flex;
   width: 86%;

   .flex-badge{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 12%;
      .flex-badge-item{
         width: 50%;
      }
   }

   .step-2-form{
      display: flex;
      flex-direction: column;
      margin-bottom: 10%;
      margin-top:10%;

      .step-2-item{
         display: flex;
         align-items: center;
         margin-right:auto;
         margin-left: auto;

         .label{
            width: 100px !important;
         }
         
         input{
            width:25em;
            margin: 10px;
         }
      }
   }

   .success-animation { margin:100px auto;}

   .checkmark {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #4bb71b;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px #4bb71b;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      position:relative;
      top: 5px;
      right: 5px;
      margin: 0 auto;
   }
   .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
   
   }

   .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
   }

   @keyframes stroke {
      100% {
         stroke-dashoffset: 0;
      }
   }

   @keyframes scale {
      0%, 100% {
         transform: none;
      }

      50% {
         transform: scale3d(1.1, 1.1, 1);
      }
   }

   @keyframes fill {
      100% {
         box-shadow: inset 0px 0px 0px 30px #4bb71b;
      }
   }

   .super-spaark-text{
      font-family: 'Poppins',sans-serif;
      font-weight: 400;
      letter-spacing: 2px;
      font-size:10px
   }






`

export default Container;  