import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SideNavBar from '../../components/analysisComponents/SideNavBar/SideNavBar.jsx';
import ContestGroup from '../../pages/analysisPages/Contest/contestGroup/ContestGroup.jsx';
import PushNotifications from '../../pages/analysisPages/PushNotifications/PushNotifications.jsx';
import CreatePosts from '../../pages/analysisPages/CreatePosts/CreatePosts.jsx';
import AnalysisDashBoard from '../../pages/analysisPages/DashBoard/AnalysisDashBoard.jsx';
import Contest from '../../pages/analysisPages/Contest/contestGroupDetails/Contest.jsx';
import Result from '../../pages/analysisPages/Contest/result/Result.jsx';
import Engagement from '../../pages/analysisPages/Analytics/Engagement/Engagement';
import Activity from '../../pages/analysisPages/Analytics/UserActivity/Activity.jsx';
import RecentUsage from '../../pages/analysisPages/RecentUsage/RecentUsage.jsx';
import ScratchCard from '../../pages/analysisPages/ScratchCard/ScratchCard.jsx';
import PinPost from '../../pages/analysisPages/PinPost/PinPost.jsx';







function MarketingRoutes() {
  return (
    <>
      <div className="d-flex">
        <SideNavBar />
        <Routes>
          <Route path="home" element={<AnalysisDashBoard />} />

          <Route path="pushnotifications" element={<PushNotifications />} />
          <Route path="ContestGroup" element={<ContestGroup />} />
          <Route path="createinfo" element={<CreatePosts />} />
          <Route path="getresult" element={<Result />} />
          <Route path="engagement" element={<Engagement />} />
          <Route path="activity" element={<Activity />} />
          <Route path="recent-usage" element={<RecentUsage />} />
          <Route path="scratchcard" element={<ScratchCard />} />

          

          <Route path="pinPost" element={<PinPost />} />
        





          <Route path="contest" element={<Contest />} />

        </Routes>
      </div>
    </>
  );
}

export default MarketingRoutes;
