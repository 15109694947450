import Container from './widget.js';
import icons from '../../../utils/icons.js';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { useState } from 'react';
import { HEADER_URL } from '../../../api/constants';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { validateDate } from '@mui/x-date-pickers/internals';
import { NearMeDisabledOutlined } from '@mui/icons-material';

var Trigger = '';
var Feeder = [];
var Helper = '';
// var view=false;

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Widget = (props) => {
  //props.endpoint
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // view=false;
  };

  // const [graphData, setGraphData] = useState([])
  const [view, setView] = useState(false);
  const [show, SetShow] = useState('');
  const TOKEN = useSelector((state) => state.auth.token);

  //Calling Route to get Graph Data
  //have to send Name , endpoint
  function Pick() {
    if (Trigger) {
      console.log('Trigger val is : ', Trigger);

      const val = JSON.stringify({
        title: Trigger,
        endpoint: props.endpoint,
        DateSend: props.dateP,
      });

      axios
        .post(`${HEADER_URL}/${localStorage.getItem('role')}/abcd`, val, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const DataNew = res.data.map((i) => {
            const dateX = new Date(i.date).getDate() + 'th';

            return {
              name: dateX,
              count: i[`${Trigger}`],
            };
          });

          Feeder = DataNew;
          setView(true);
          setOpen(true);
        })
        .catch((e) => {
          alert('Error Occured');
          console.log('error', e);
        });
    } else {
      console.log('helper is : ', Helper);

      if (
        Helper == 'TOTAL OPEN TICKETS' ||
        Helper == 'PENDING PARTNERSHIP APPLICATIONS' ||
        Helper == 'ACTIVE PARTNERS'
      ) {
        alert(
          'This Data cannot be represented in Charts/Graphs as it is a Total Counter',
        );
      } else {
        const val = JSON.stringify({
          title: Helper,
          endpoint: props.endpoint,
          DateSend: props.dateP,
        });

        axios
          .post(`${HEADER_URL}/${localStorage.getItem('role')}/abcd`, val, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            if (Helper == 'ACCOUNT SUSPENSION') {
              Helper = 'accountsSuspended';
            } else if (Helper == 'SUPER SPAARKS CREATED') {
              Helper = 'superSpaarks';
            } else if (Helper == 'TICKETS CLOSED') {
              Helper = 'ticketsClosed';
            } else if (Helper == 'TICKETS RAISED') {
              Helper = 'ticketsRaised';
            } else if (Helper == 'REJECTED PARTNERSHIPS') {
              Helper = 'partnershipRejected';
            } else if (Helper == 'ACCEPTED PARTNERSHIPS') {
              Helper = 'partnershipAccepted';
            } else if (Helper == 'PARTNERSHIP APPLICATIONS') {
              Helper = 'partnershipApplied';
            }

            const DataNew = res.data.map((i) => {
              const dateX = new Date(i.date).getDate() + 'th';
              return {
                name: dateX,
                count: i[`${Helper}`],
              };
            });

            Feeder = DataNew;
            setView(true);
            setOpen(true);
          })
          .catch((e) => {
            alert('Error Occured');
            console.log('error', e);
          });
      }
    }
  }

  return (
    <Container>
      {/* <div className="row" style={{justifyContent:'space-between'}}> */}

      {props.data &&
        props.data.map((item, index) => (
          <div
            onClick={() => {
              if(!props.dashboard&&!props.rewards)
              {

                SetShow(item.title);
                Trigger = item.Namer;
                Helper = item.title;
                Pick();
              }
              
             
            }}
            className="w-container mt-5"
          >
            <div className="widget" key={index}>
              <div className="left">
                <span className="title">{item.title}</span>
                <span className="counter">{item.value ? item.value : 0}</span>
              </div>
              <div className="right">{icons[item.icon]}</div>
            </div>
          </div>
        ))}

      {view && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ textAlign: 'center' }}>
              <h3>{show}</h3>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={Feeder}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        </Modal>
      )}
    </Container>
  );
};

export default Widget;
