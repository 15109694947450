import Container from './livesuperspaark.js';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import { HEADER_URL } from '../../../../api/constants.js';
import PostCard from '../../../../components/analysisComponents/PostCard/PostCard';
import axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import { resolveComponentProps } from '@mui/base';


const fetchSuperSpaarks = (TOKEN, cb) => {
  const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/superspaark/get-superspaarks`;

  axios.get(url, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      console.log("res is : ",resolveComponentProps)
      const val1=res.data.superSpaarks;
      const val2=res.data.Data;
      const newone=val1.concat(val2)

      cb(newone)

    })
    .catch((err) => {
      console.log('ERR', err);
      alert('Something went wrong!');
    });
};

function LiveSuperSpaarks() {
  const TOKEN = useSelector((store) => store.auth.token);
  const [posts, setPosts] = React.useState([]);
  const location = useLocation();



  
  const setPostsCallBack = (posts) => {
    setPosts(posts);
  };

  console.log("posts length : ",posts.length)


  const triggerSearch = (key) => {
    fetchSuperSpaarks(TOKEN, setPostsCallBack, key);
  };

  const changePosts = (postId) => {
    const newPosts = posts.filter((post) => {
      return post._id !== postId;
    });
    setPosts(newPosts);
  };

  useEffect(() => {
    fetchSuperSpaarks(TOKEN, setPostsCallBack, '');

  }, []);

  return (
    <Container>
      {/* <SideBar /> */}
      <div className="right">
        <div className='position-sticky top-0' style={{zIndex:'4'}}>
        <SearchAppBar cb={triggerSearch} />
        </div>
        <div className="posts">
          {posts.length !== 0 &&
            posts.map((post,idx) => {
              return (
                <div className="post m-2" key={idx} >
                  <PostCard
                    post={post}
                    from={'live'}
                    token={TOKEN}
                    changePosts={changePosts}
                  />
                </div>
              );
            })}
            {
              posts.length === 0?<h1>No Super Posts are Available</h1>:" "

            }
        </div>
      </div>
    </Container>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchAppBar = React.memo(
  ({ cb }) => {
    const handleInputChange = (e) => {
      cb(e.target.value);
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: '#7451f8' }}>
          <Toolbar>
            {/* <Search>
                <SearchIconWrapper>
                <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleInputChange}
                />
            </Search> */}
          </Toolbar>
        </AppBar>
      </Box>
    );
  },
  () => true,
);

export default LiveSuperSpaarks;
