import styled from 'styled-components';

const LeadSummaryContainer = styled.div`
 
  background-color: #b8b9bd;
  padding:10px;
  font-size: 12px;
  width: 100%;
  h3{
    color: #676060;
  }
  .cardBody{
    background-color: #fff;
    border-radius: 15px;
    padding:15px;
    /* box-shadow: 2px 2px 5px black; */
  }
  .w-35{
    width:35%;
  }
  img{
    border-radius:50%
  }
  .side-heading {
    font-size: 15px;
    font-weight: 500;

  }
 
`;

export default LeadSummaryContainer;
