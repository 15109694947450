import React from 'react';
import posGr from '../../../../assets/upGraph.svg';
import negGr from '../../../../assets/downGraph.svg';

const WizCard = ({ name, totalData, percentData, value, dataGraph }) => {
  return (
    <div style={{ minWidth: '280px', maxWidth: '350px' }}>
      <div className="card rounded ms-3 animate">
        <div className=" p-3 ">
          <h3 className="d-flex justify-content-between">
            <p>{name}</p>
            <p className=" fs-5 mt-2 grText  lightGrBg p-1 ps-2 pe-2 rounded">
              {percentData}%
            </p>
          </h3>
          <div className="d-flex justify-content-between">
            <p className="card-text fs-3 fw-600">{totalData}</p>
            <div className="data-graph ms-2 grText   p-1 ps-2 pe-2 rounded">
              <img src={dataGraph === 'positive' ? posGr : negGr} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WizCard;
