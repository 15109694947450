import Container from './analysisDashboard.js';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import Widget from '../../../components/analysisComponents/Widget/Widget';
import Table from '../../../components/analysisComponents/Table/Table';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/userstats`;

function AnalysisDashBoard() {
  const [widgetData, setWidgetData] = useState([]);
  const TOKEN = useSelector((state) => state.auth.token);
  const sus_user_passkey = localStorage.getItem('sus_user_passkey');
  const role = localStorage.getItem('role');

  const getWigetData = async () => {
    await axios
      .get(
        `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/userstats`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        const response = res.data.finalusers;

        const data = [
          {
            title: 'Total Users',
            value: response.totalusers,
            icon: 'cart',
          },
          {
            title: 'LoggedIn Users',
            value: response.active,
            icon: 'users',
          },
          {
            title: 'LoggedOut Users',
            value: response.inactive,
            icon: 'monetize',
          },
          {
            title: 'Active Rate',
            value: ((response.active / response.totalusers) * 100).toPrecision(
              2,
            ),
            icon: 'active',
          },
        ];
        setWidgetData(data);
      })
      .catch((err) => {
        console.log('err', err);
        // alert('something went wrong!');
      });
  };

  useEffect(() => {
    getWigetData();
  }, []);

  return (
    <>
      <Container>
        <div className="right">
          <Widget
            dashboard="dashboard"
            data={widgetData.length !== 0 && widgetData}
          />
          {role == 'analysisuser' ? <Table /> : <Welcome />}
        </div>
      </Container>
    </>
  );
}

const Welcome = () => {
  return (
    <>
      <h1 style={{ marginTop: '50px', fontWeight: '500' }}>Welcome!!</h1>
    </>
  );
};

export default AnalysisDashBoard;
