import UserPostContainer from "./UserPosts";
import {useEffect,useState} from 'react'
import ScaleLoader from "react-spinners/ScaleLoader";
import { HEADER_URL,header } from '../../../api/constants.js';
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Carousel } from 'react-responsive-carousel';
import { ImLocation2 } from "react-icons/im";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY:'scroll',
    maxHeight:'500px'
  };
function UserPosts({userId,user}){




 //States
 let [loading, setLoading] = useState(true);
 const [data,setData]=useState([])
 const [hasMore, setHasMore] = useState(true);
 const [story,setStory]=useState([])
 const [page,setPage]=useState(1)





    var getUserPosts=async()=>{
        setLoading(true)
        await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/user/posts?uid=${userId}&&page=${page}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }})
        .then((res)=>{
            res.data.posts.length<6?setHasMore(false):setHasMore(true)
            setData([...data,...res.data.posts])
            setPage(page+1)
        })
        .catch((err)=>{
            console.log("Error occured")
            alert(err.message)
        })
        setLoading(false)
    }
    useEffect(()=>{
        getUserPosts()
    },[])


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [currPost,setCurrPost]=useState([])



    return <UserPostContainer>
            <p className="lead text-center mt-2">posts</p>


    <InfiniteScroll
    dataLength={data.length!=0 && data.length}
    style={{ display: 'flex', flexWrap:'wrap',justifyContent:'start',marginTop:'40px',gap:30,paddingLeft:'40px',marginBottom:'30px' }}
    next={()=>{
    getUserPosts()
    }}  hasMore={hasMore} loader={<div>Loading...</div>}>




    {   data.length!=0&&

        
        data.map((ele,idx)=>{


            return <div className="card position-relative" style={{width:'500px'}} key={idx}>


             <div className="card-header d-flex gap-4 ">
                <img src={user.profilePic}  width="80px" height="80px" style={{borderRadius:'50%'}} alt="" />
                <div className="d-flex flex-column gap-1 justify-content-around">
                    <div>{user.name}</div>
                    <div>{moment(ele.createdAt).format('lll')}</div>
            
                </div>

                <div style={{height:'15px',position:'absolute',right:'4px',display:'flex',gap:'10px', alignItems:'center'}}>
                 <div style={{cursor:'pointer',color:'#2a04bb'}}><a href={`https://www.google.com/maps/place/${ele.locationStatic.coordinates[1]}%${ele.locationStatic.coordinates[0    ]}`} target="_black"> <ImLocation2/></a></div> 
               {localStorage.getItem('role')=='admin'&&ele.status&& <IconButton aria-label="delete"  className='text-primary'> <DeleteIcon /> </IconButton> }
               {localStorage.getItem('role')=='admin'&&!ele.status&& <IconButton aria-label="delete" disabled > <DeleteIcon /> </IconButton> }





                </div>

             </div>
             <div className="d-flex justify-content-around">
             {ele.featureName=="market" &&<h5 className="text-center text-success">Market</h5>}
             {ele.featureName=="showtime" &&<h5 className="text-center text-success">Social</h5>}
             {ele.featureName=="greet" &&<h5 className="text-center text-success">Make Friend</h5>}

             </div>
               { ele.featureName=="market" &&<div className="d-flex  justify-content-around">

                    <div className="d-flex gap-2 align-items-center">
                        <p className="">Category : </p>
                        <h6 className="text-primary">{ele.category} </h6>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                        <p className=""> SubCategory :</p>
                        <h6 className="text-primary">{ele.subCategory} </h6>
                    </div>

                </div>}
            <hr />
            <div className="card-body d-flex flex-column justify-content-between gap-2 ">

                <div>{ele.content}</div>
        {ele&&ele.photo&& 
               
               < Carousel showThumbs={false}  autoPlay={true}  infiniteLoop={true}>

                        {ele&&ele.photo&&ele.photo.length!=0&&
                            ele.photo.map((image,imgIdx)=>{
                                return <div key={imgIdx} >
                                <img src={image} width='500px' height='400px'/>
                                {/* <div>{image}</div> */}
                            </div>
                            })
                        }

                </Carousel>
        }


        <div className='text-primary' style={{cursor:'pointer'}} onClick={()=>{
            
            if(ele.subposts.length!=0){
            setCurrPost(ele)
                handleOpen();
        }
        }} >Comments( {ele.subposts.length} )</div>
            </div>

            <div className="card-footer d-flex justify-content-between">
                <div className="w-75 mx-auto">
                    <textarea className="form-control"rows="2"></textarea>
                </div>
                <Button variant="text">Reply</Button>
            </div>
            </div>

        })
        
    }






















    </InfiniteScroll>



    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

            {currPost&&currPost.subposts&&
                
                currPost.subposts.map((subpost,subpostIdx)=>{
                    return <div key={subpostIdx}>
                        {/* subpost */}
                        <div className="d-flex justify-content-around flex-column">

                        <div className="d-flex gap-4">
                            <img src={subpost.userId.profilePic} alt="" width="50px" height="50px" style={{borderRadius:'50%'}} />
                            <div className="d-flex align-items-start justify-content-start flex-column">
                                    <p style={{fontSize:'14px',marginBottom:'3px'}} className='text-primary'>{subpost.userId.name}</p>
                                    <p>{subpost.content}</p>

                            </div>
                        </div>
                        <div className="d-flex justify-content-around">
                            
                            <div>

                                {
                                  subpost.comments.map((comment,commentIdx)=>{
                                        return<li key={commentIdx}>{comment.content}</li>
                                    })
                                }

                            </div>
                        </div>
                        </div>


                    </div>
                })
            
            }

        </Box>
      </Modal>




        <ScaleLoader color="#36d7b7"  loading={loading} style={{position:'absolute',left:'50%',top:'40%'}}  size={150}/>
    </UserPostContainer>
}


export default UserPosts;