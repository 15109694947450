import { useState, useRef } from 'react';
import { HEADER_URL, header } from '../../../api/constants.js';
import axios from 'axios';

/*
featureName=showtime
originalName=Events & Announcements

radius=5000
redirect=admin
mycategory=category#subcategory
title=Title
content="....." (textArea)
uservisibility[name]=Team Spaarks
uservisibility[profilePic]=https://staging-static-content.ososweb.com/image/user/profilePic-610ccbb93832cc1821f090d4-1628229071204.jpeg
isProvider=true

photo= (photo
video
location=""

*/
function CreatePosts() {
  const [data, setData] = useState({
    radius: 5000,
    redirect: 'admin',
    mycategory: 'category#subcategory',
    title: 'Title',
    content: '',
    // 'uservisibility[name]': 'Team Spaarks',
    // 'uservisibility[profilePic]':
    uservisibility: {
      name: 'Team Spaarks',
      profilePic:
        'https://staging-static-content.ososweb.com/image/user/profilePic-610ccbb93832cc1821f090d4-1628229071204.jpeg',
    },
    isProvider: 'true',
    photo: '',
    video: '',
    location: '',
  });
  const Image=useRef();
  const Video=useRef();


  const onChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.name != ('photo' || 'video')) {
      setData((data) => ({ ...data, [e.target.name]: e.target.value }));
    }

    console.log(`${HEADER_URL}/marketing/addpostasdadmin`);
    // console.log(data);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form=new FormData();
    form.append("photo",Image.current.files[0]);
    form.append("video",Video.current.files[0]);
    form.append("radius",data.radius);
    form.append("redirect",data.redirect);
    form.append("mycategory",data.mycategory);
    form.append("title",data.title);
    form.append("content",data.content);
    form.append("uservisibility",data.uservisibility);
    form.append("isProvider",data.isProvider);
    form.append("location",data.location);

    axios
      .post(HEADER_URL + '/marketing/addpostasadmin', form, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        alert('Created Successfully');
        console.log(res.data);
      })
      .catch((err) => {
        alert('Error');
        console.log(err.message);
      });

    console.log(data);
  };

  return (
    <>
      <div className="container-fluid pt-5 text-start">
        <h4>Create Posts</h4>
        <hr />
      <div className="row">
        <div className="col-md-8">
          <label>Title</label>
          <input name="title" className="form-control" value="Title" readOnly />
          <label>Content</label>
          <textarea
            name="content"
            className="form-control"
            rows="10"
            onChange={(e) => onChangeHandler(e)}
          ></textarea>
        </div>

        <div className="col-md-4">
          <div className="row ">
            <label>Name</label>
            <input
              type="text"
              name="uservisibility[name]"
              value="Team Spaarks"
              className="form-control"
              // onChange={(e) => onChangeHandler(e)}
              readOnly
            />
          </div>
          <div className="row">
            <label>ProfilePic</label>
            <input
              type="text"
              name="uservisibility[profilePic]"
              value="https://staging-static-content.ososweb.com/image/user/profilePic-610ccbb93832cc1821f090d4-1628229071204.jpeg"
              className="form-control"
              readOnly
              // onChange={(e) => onChangeHandler(e)}
            />
          </div>
        </div>
        <br />
        <hr />
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-4">
              <label>Photo(s)</label>
              <input
                accept="image/*"
                id="fileupload"
                ref={Image}
                className="image form-control"
                type="file"
                name="photo"
                multiple=""
                onChange={(e) => onChangeHandler(e)}
              />
              <div
                className="validation"
                style={{ display: 'none', color: 'red' }}
              >
                {' '}
                Upload Max 4 Files allowed{' '}
              </div>
            </div>

            <div className="col-md-4">
              <label>Video</label>
              <input
                accept="video/*"
                type="file"
                ref={Video}

                name="video"
                className="form-control"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
          </div>
        </div>

        <input id="location" type="hidden" name="location" value="" />
        <div className="col-md-4">
          <button
            type="submit"
            onClick={(e) => onSubmitHandler(e)}
            className="form-control btn btn-sm btn-success"
          >
            Submit
          </button>
        </div>
      </div>
      </div>
     
    </>
  );
}

export default CreatePosts;
