import RewardDetailsContainer from "./RewardDetails";
import {useState,useEffect} from 'react';
import { HEADER_URL,header } from '../../../api/constants.js';
import axios from "axios";
import moment from 'moment/moment';
// import { useSelector } from "react-redux";
// const TOKEN = useSelector((state) => state.auth.token);
function RewardDetails({userId}){


    const [data,setData]=useState([])

    var getRewardDetails=async()=>{
        await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/rewarddetails?uid=${userId}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }})
        .then((res)=>{
            console.log(res.data)
            setData(res.data.rewards)
        })
        .catch((err)=>{
            console.log(err.message)
        })
    }


    useEffect(()=>{
        getRewardDetails();
    },[])


    return <RewardDetailsContainer>
        
{
        data.map((ele,idx)=>{

            return <div key={idx} className='card p-4' style={{width:'300px'}} >
                <div className="d-flex align-items-center  ">
                   <div className="side-heading" >Status: </div>
                     <div className="content  mx-auto">{ele.status}</div>
                 </div>
                 <div className="d-flex align-items-center  ">
                   <div className="side-heading" >Created At: </div>
                     <div className="content  mx-auto">{moment(ele.createdAt).format('lll')}</div>
                 </div>
                 <div className="d-flex align-items-center  ">
                   <div className="side-heading" >Unlock At: </div>
                     <div className="content  mx-auto">{moment(ele.unlockedAt).format('lll')}</div>
                 </div>
                 <div className="d-flex align-items-center  ">
                   <div className="side-heading" >Expires At: </div>
                     <div className="content  mx-auto">{moment(ele.expiresAt).format('lll')}</div>
                 </div>
                 <div className="d-flex align-items-center  ">
                   <div className="side-heading" >Amount: </div>
                     <div className="content  mx-auto">{ele.amount}</div>
                 </div>
                 <div className="d-flex align-items-center  ">
                   <div className="side-heading" >Type: </div>
                     <div className="content  mx-auto">{ele.scratchCardType}</div>
                 </div>

            </div>
        })

    }
    </RewardDetailsContainer>
}


export default RewardDetails;