import styled from 'styled-components';

const WizContainer = styled.div`
  * {
    /* border: 1px solid green; */
    /* -webkit-box-shadow: 10px 14px 26px -24px rgba(0,0,0,1);
-moz-box-shadow: 10px 14px 26px -24px rgba(0,0,0,1);
box-shadow: 10px 14px 26px -24px rgba(0,0,0,1); */
  }

  .container {
    background-color: white;
    height: 100vh;
    width: 100vw;
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  table {
    color: black;
  }
  .th-one {
    padding: 10px 113px 10px 20px;
  }
  th {
    /* background: #000; */
    /* color: #fff; */
    leading-trim: both;
    text-edge: cap;
    font-size: 20px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    height: 34px;
  }

  .th-two {
    padding: 10px 167px 10px 20px;
  }
  .th-three {
    padding: 10px 80px 10px 20px;
  }
  .th-four {
    width: 148px;
    padding: 10px 42px 10px 20px;
    text-align: left;
  }
  .th-five {
    width: 141px;
    padding: 10px 42px 10px 20px;
    text-align: left;
  }

  tbody tr {
    height: 88px;
  }

  td {
    height: 14px;
    /* border: 1px solid red; */
  }
  .profile {
    width: 48px;
    height: 48px;
    border: 3px solid white;
    /* background-color: red; */
    border-radius: 48px;
    text-align: center;
    object-fit: cover;
  }

  tbody tr .flex-box {
    display: flex;
    flex-direction: row;
    gap: 14px;
  }

  .profile-name {
    color: #000;
    font-size: 16px;
    font-family: Inter;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .content {
    color: #6e6e6e;
    text-align: justify;
    font-size: 14px;
    font-family: Inter;
    font-weight: 300;
    width: 237px;
    font-weight: 600;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .flex-box-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .category {
    display: flex;
    border: 0.6px solid #04c000;
    background: #e7ffe9;
    text-align: center;
    margin-bottom: 5px;
    color: #000;
    font-size: 14px;
    font-family: Inter;

    align-items: center;
    width: 150px;
    text-overflow: ellipsis;
  }

  .loc {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 21px;
    background: #f2f2f2;
    width: 150px;
    text-overflow: ellipsis;
  }

  .uploadcsv-btn {
    border: 1px solid #5597ff;
    background-color: white;
    color: #5597ff;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
  }

  .flex-box3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .search {
    width: 93px;
    padding: 10px 20px;
    display: flex;
    border-radius: 28px;
    border: 1px solid #4c95f4;
    background: var(
      --story-ring,
      linear-gradient(318deg, #4a94f2 0%, #5597ff 89.16%)
    );
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-family: Inter;
    line-height: 24px;
    max-width: 93px;
    min-width: 93px;
    min-height: 32px;
    max-height: 32px;
    align-items: center;
  }

  .reset {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    gap: 10px;
    border: 1px solid #4c95f4;
    border-radius: 28px;
    background-color: white;
    color: #4c95f4;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-family: Inter;
    line-height: 24px;
    max-width: 83px;
    min-width: 83px;
    min-height: 32px;
    max-height: 32px;
    align-items: center;
    /* margin-top: 10px; */
  }

  .next {
    margin-top: 10px;
    display: flex;
    padding: 14px;
    gap: 10px;
    border-radius: 20px;
    background: #ababab;
    color: #000;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    min-width: 64px;
    max-width: 64px;
    min-height: 40px;
    max-height: 40px;
    align-items: center;
    border: none;
    font-weight: 700;
    right: 0;
    align-self: flex-end;
  }

  /* Second Screen */
  .flex-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin-top:10px 
    margin:auto
  }
  .flex-container::-webkit-scrollbar{
    display:none
  }

  .left {
    border: 1px solid red;
    background-color: rgb(255, 255, 255);
    height: 100vh;
    width: 40vw;
    overflow: scroll;
  }
  .right {
    border: 1px solid green;
    background-color: rgb(255, 255, 255);
    height: 100vh;
    width: 40vw;
    overflow: scroll;
  }

  .business-info {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 116px;
    max-width: 116px;
    min-height: 32px;
    max-height: 32px;
    flex-shrink: 0;
    border-radius: 21px;
    background: #5597ff;
    color: #fafafa;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    margin-left: 2px;
    margin-top:10px
  }

  .accepted-spaarks-info {
    margin-top: 10px;
    font-size: 13px;
    font-family: Inter;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 21px;
    background: #04c000;
    color: #fafafa;
  }

  .send-msg-btn {
    border-radius: 30px;
    background: #4a94f2;
    color: #fafafa;
    text-align: center;
    font-size: 14px;
    font-family: Inter;
  }
`;

export default WizContainer;
