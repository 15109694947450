import styled from 'styled-components';

const UserDetailsContainer = styled.div`
  width: 100%;
  text-align: start;    
  background-color: #f8f9fe;
  .MuiFormControl-root {
    width:100%;
  }
  .cardd{
      background-color:#fff;
      padding:20px;
      border-radius:15px;
      box-shadow: 2px 2px 5px 0.5px black ;
      margin-top: 30px;
      text-align:center;
    }
    .text-count{
      font-size:30px;
      font-style:italic;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .side-heading {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
  }
  .content {
    font-size: 12px;
  }
`;

export default UserDetailsContainer;  
