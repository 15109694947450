import styled from "styled-components";


const SendFcmContainer=styled.div`

    display:flex;
    justify-content:center;
    align-items: center;
    .MuiInputBase-input{
        background-color: white;
    }
    .bs{
       box-shadow: 1px 3px 10px 3px black;
       border-radius: 5px;
    }
`;



export default SendFcmContainer;