// New Page

import GroupPostContainer from './GroupPost';
import { HEADER_URL, header } from '../../../../api/constants.js';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as React from 'react';
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ImageModel from '../../ImageModel/ImageModel';
// carsouel
import ReactDOM from 'react-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import RewardModal from '../../RewardModal/RewardModal.jsx';
import ScaleLoader from 'react-spinners/ScaleLoader';
const rewardUrl = `${HEADER_URL}/${localStorage.getItem(
  'role',
)}/dashboard/superspaark/give-scratchcard`;

function GroupPost() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState('#36d7b7');
  // Search variables
  const [search, setSearch] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [source, setSource] = React.useState('All');
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [maxLength, setMaxLength] = useState(0);
  const [page, setPage] = useState(1);
  const [currIdx, setCurrIdx] = useState(-1);
  const [currEle, setCurrentEle] = useState({});

  //Modal image
  const [modelImage, setModelImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);

  const [rewardModal, setRewardModal] = useState(false);
  //Function to get group posts
  var getGroupPosts = async () => {
    if (page == 1) {
      setLoading(true);
    }
    var url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/groupPosts`;
    url += '?page=' + page + '&source=' + source;
    if (search.trim() != '') {
      url += '&search=' + search.trim();
    }
    if (from != '') {
      url += '&from=' + from;
    }
    if (to != '') {
      url += '&to=' + to;
    }
    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        res.data.length < 20 ? setHasMore(false) : setHasMore(true);
        setData([...data, ...res.data]);
        if (page == 1) {
          setLoading(false);
        }
        setPage(page + 1);
      })
      .catch((err) => {
        console.log('erroro occured', err.message);
      });
  };
  function Deleter() {
    axios
      .get(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/DeleteGroupPosts?postId=${currEle._id}`,

        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        alert('Deleted');
        var temp = data;
        temp.splice(currIdx, 1);
        setData([...temp]);
      })
      .catch((e) => {
        console.log('err', e);
      });
  }

  const sendReward = async (amount) => {
    console.log('amount', amount);

    await axios
      .post(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/superspaark/give-scratchcard`,
        {
          type: 'groupReward',
          name: currEle.uservisibility.name,
          amount: amount,
          title: 'Super Spaark reward',
          body: `you are rewarded with amount ${amount}`,
          postId: currEle._id,
          userId: currEle.userId,
        },
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        if (res.data.success == 'Scratch card added successfuly') {
          currEle.didRewardGet = true;
          var temp = data;
          temp.splice(currIdx, 1, currEle);
          setData([...temp]);
        }
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

  // console.log('data',data)
  const [callUseEffect, setCallUseEffect] = useState(false);
  useEffect(() => {
    getGroupPosts();
  }, [callUseEffect]);

  return (
    <GroupPostContainer>
      <ScaleLoader
        color={color}
        loading={loading}
        style={{ position: 'absolute', left: '50%', top: '40%' }}
        size={150}
      />

      <h3>Posts </h3>
      {/* filter */}
      <div className="d-flex justify-content-around align-items-end mt-3">
        <div style={{ width: '400px' }}>
          <input
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            className="form-control"
            placeholder="Find Cars, Mobile Phones and more..."
          />
        </div>

        <div className="">
          <label>From :</label>
          <input
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            max={moment().format().slice(0, 16)}
            type="date"
            name="from"
            id="from"
            className="form-control"
          />
        </div>
        <div className="">
          <label>To :</label>
          <input
            value={to}
            onChange={(e) => {
              setTo(e.target.value);
            }}
            max={moment().format().slice(0, 16)}
            type="date"
            name="to"
            id="to"
            className="form-control"
          />
        </div>

        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Source
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={source}
              onChange={(e) => {
                setSource(e.target.value);
              }}
              label="Source"
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'User'}>User</MenuItem>
              <MenuItem value={'TeleCaller'}>TeleCaller</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Button
          variant="contained"
          onClick={() => {
            setPage(1);
            setMaxLength(maxLength + 1);
            setData([]);
            setHasMore(true);
            setCallUseEffect(!callUseEffect);
          }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setSearch('');
            setFrom('');
            setTo('');
            setSource('All');
            setPage(1);
            setData([]);
            setHasMore(true);
            setCallUseEffect(!callUseEffect);
          }}
        >
          Reset
        </Button>
      </div>
      {/* Infinite scroll start */}

      {data && data.length != 0 && (
        <InfiniteScroll
          dataLength={data && data.length != 0 && data.length}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'start',
            marginTop: '40px',
            gap: 30,
            paddingLeft: '40px',
          }}
          next={() => {
            getGroupPosts();
          }}
          hasMore={hasMore}
          loader={
            <div className="d-flex justify-content-center"> LOADING . . . </div>
          }
        >
          {data &&
            data.length != 0 &&
            data.map((ele, idx) => {
              if (ele.pollOptions == 0) {
                return (
                  <div
                    className="card"
                    style={{
                      width: '300px',
                      boxShadow: '2px 2px 5px 0.5px black',
                    }}
                    key={idx}
                  >
                    <div
                      className="d-flex gap-3 align-items-center w-100  "
                      style={{
                        padding: '5px',
                        backgroundColor: '#302928',
                        borderRadius: '0.375rem 0.375rem 0px 0px',
                        opacity: '0.5',
                      }}
                    >
                      {ele &&
                        ele.uservisibility &&
                        ele.uservisibility.profilePic && (
                          <img
                            src={ele.uservisibility.profilePic}
                            width="30px"
                            height="30px"
                            style={{
                              borderRadius: '50%',
                              boxShadow: '2px 2px 5px black',
                            }}
                            alt=""
                          />
                        )}

                      {ele && ele.uservisibility && ele.uservisibility.name && (
                        <div className="d-flex flex-column align-items-start justify-content-start">
                          <div
                            style={{
                              color: 'white',
                              fontWeight: 'bold',
                              textAlign: 'start',
                            }}
                          >
                            {ele.uservisibility.name}
                          </div>

                          <div style={{ color: 'white', fontWeight: '' }}>
                            {moment(ele.createdAt).calendar()}
                          </div>
                        </div>
                      )}
                      <div
                        className="ms-auto"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '16px',
                        }}
                      >
                        {ele.title}
                      </div>
                    </div>

                    <div className="card-img-top">
                      <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        infiniteLoop={true}
                      >
                        {ele.photo &&
                          ele.photo.map((img, imgIdx) => {
                            return (
                              <div
                                key={imgIdx}
                                onClick={() => {
                                  setModelImage(img);
                                  setModelOpen(true);
                                }}
                              >
                                <img src={img} width="300px" height="220px" />
                              </div>
                            );
                          })}
                        {ele.video &&
                          ele.video.map((img, imgIdx) => {
                            return (
                              <div
                                key={imgIdx}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <video
                                  width="300px"
                                  height="220px"
                                  controls
                                  className="mx-auto d-block"
                                >
                                  <source src={img} type="video/mp4" />
                                </video>
                              </div>
                            );
                          })}
                      </Carousel>
                    </div>
                    {ele.content.trim() != '' && (
                      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {ele.content.slice(0, 30)}
                      </div>
                    )}
                    <div className="card-footer d-flex justify-content-around mt-auto">
                      {ele.didRewardGet == true && ele.source == 'user' && (
                        <Button variant="contained" disabled color="success">
                          Reward
                        </Button>
                      )}
                      {ele.didRewardGet == false && ele.source == 'user' && (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setCurrIdx(idx);
                            setCurrentEle(ele);
                            setRewardModal(true);
                            console.log('Clicked on reward');
                          }}
                        >
                          Reward
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setCurrIdx(idx);
                          setCurrentEle(ele);
                          Deleter();
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="card"
                    style={{
                      width: '300px',
                      boxShadow: '2px 2px 5px 0.5px black',
                    }}
                    key={idx}
                  >
                    <div
                      className="d-flex gap-3 align-items-center w-100  "
                      style={{
                        padding: '5px',
                        backgroundColor: '#302928',
                        borderRadius: '0.375rem 0.375rem 0px 0px',
                        opacity: '0.5',
                      }}
                    >
                      {ele &&
                        ele.uservisibility &&
                        ele.uservisibility.profilePic && (
                          <img
                            src={ele.uservisibility.profilePic}
                            width="30px"
                            height="30px"
                            style={{
                              borderRadius: '50%',
                              boxShadow: '2px 2px 5px black',
                            }}
                            alt=""
                          />
                        )}

                      {ele && ele.uservisibility && ele.uservisibility.name && (
                        <div className="d-flex flex-column align-items-start justify-content-start">
                          <div
                            style={{
                              color: 'white',
                              fontWeight: 'bold',
                              textAlign: 'start',
                            }}
                          >
                            {ele.uservisibility.name}
                          </div>
                          <div style={{ color: 'white', fontWeight: '' }}>
                            {moment(ele.createdAt).calendar()}
                          </div>
                        </div>
                      )}
                      <div
                        className="ms-auto"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '16px',
                        }}
                      >
                        {ele.title}
                      </div>
                    </div>
                    {ele.content.trim() != '' && (
                      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {ele.content.slice(0, 30)}
                      </div>
                    )}

                    {/* Options */}

                    <table className="table my-auto table-striped">
                      <thead className="bg-success text-light">
                        <tr>
                          <th>Option</th>
                          <th>Votes</th>
                          <th>Vote Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ele.pollOptions.map((opt, optIdx) => {
                          return (
                            <tr key={idx}>
                              <td>{opt.option}</td>
                              <td>{opt.votes.length}</td>
                              <td>{opt.votePercentage}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="card-footer d-flex justify-content-around mt-auto">
                      {ele.didRewardGet == true && ele.source == 'user' && (
                        <Button variant="contained" disabled color="success">
                          Reward
                        </Button>
                      )}
                      {ele.didRewardGet == false && ele.source == 'user' && (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setCurrIdx(idx);
                            setCurrentEle(ele);
                            setRewardModal(true);
                            console.log('Clicked on reward');
                          }}
                        >
                          Reward
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setCurrIdx(idx);
                          setCurrentEle(ele);
                          Deleter();
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                );
              }
            })}
        </InfiniteScroll>
      )}

      <RewardModal
        open={rewardModal}
        setOpen={setRewardModal}
        handleReward={sendReward}
      />
      <ImageModel
        image={modelImage}
        width={300}
        height={500}
        open={modelOpen}
        setOpen={setModelOpen}
      />
    </GroupPostContainer>
  );
}

export default GroupPost;
