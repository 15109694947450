import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Container from './Result.js';
import axios from 'axios';
import { HEADER_URL } from '../../../../api/constants';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import ActionAreaCard from '../../../../components/analysisComponents/Cards/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',


  p: 4,
};
function Result() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imgUrl,setImgUrl]=useState('')
  const location = useLocation();
  const [participants, SetParticipants] = useState([]);
  const TOKEN = useSelector((state) => state.auth.token);

  const fetchData = async (e) => {
    await axios
      .get(`${HEADER_URL}/contest/details/${location.state.contestid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }, 
      })
      .then((res) => {
        SetParticipants(res.data.responses);
      })
      .catch((err) => {
        console.error('err', err);
        alert('something went wrong!');
      });
  };

  //Here im using 'contestUpdator' as dependent variable to run fetch again when form is submitted
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {/* <SideBar /> */}
      <div className="right">
        <div class="cards">
          <div class="maindiv">
            <ActionAreaCard Hack={location.state.contestid} />
          </div>
        </div>
        <div class="participantHead">
          <h1>Participants</h1>
        </div>
        <div class="contesttable">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">User</th>
                <th scope="col">Answer</th>
                <th scope="col">Wins</th>
                <th scope="col">Today Rewards</th>
                <th scope="col">Total Rewards</th>
                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {participants.map((i, idx, participants) => {
                return (
                  <tr>
                    <td scope="row">{idx + 1}</td>
                    <td>{i.userId.name}</td>
                    {i.answerType === 'image' ? (
                      <td >
                        <div onClick={()=>{handleOpen(); setImgUrl(i.uploadedImage)}}>
                        <img src={i.uploadedImage} alt="img" style={{ width: "auto", height: "180px" }} />
                        </div>
                      </td>
                    ) : (i.answerType==='poll')? <td>{i.vote}</td> :(
                      <td>{i.answer}</td>
                    )}

                    <td>{i.wonToday}</td>
                    <td>{i.rewards}</td>
                    <td>{i.totalRewards}</td>
                    {/* <td>
                      {i.didWin ? (
                        <button class="btn btn-success">
                          send scratch card
                        </button>
                      ) : (
                        ''
                      )}
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
      
          <img src={imgUrl} alt="" height='450px'  width="auto" />
         
        </Box>
      </Modal>

      </div>
    </Container>
  );
}

export default Result;
