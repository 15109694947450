import * as React from 'react';
import moment from 'moment/moment';
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { HEADER_URL } from '../../../api/constants';
export default function ActionAreaCard({ Hack }) {
  const getter = useRef();
  const TOKEN = useSelector((state) => state.auth.token);
  const [imageCard, SetImageCard] = useState({});
  const [xanswers, Setanswers] = useState('');
  const [xdidAnswerAnnounced, SetdidAnswerAnnounced] = useState(false);

  const fetchData = async (e) => {
    await axios
      .get(`${HEADER_URL}/contest/details/${Hack}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const obj = {
          From: res.data.contest.contestStartTime,
          To: res.data.contest.contestEndTime,
          answers: res.data.contest.correctAnswer,
          src: res.data.contest.contestImage,
          scratchcards: res.data.contest.scratchCardWinners.length,
          winners: res.data.contest.winners.length,
          contestName: res.data.contest.contestName,
          correctAnswer: res.data.contest.correctAnswer,
          didAnswerAnnounced: res.data.contest.didAnswerAnnounced,
          question: res.data.contest.question,
          pollOptions: res.data.contest.pollOptions,
          contestType: res.data.contest.contestType,
        };

        Setanswers(obj.answers);
        SetdidAnswerAnnounced(obj.didAnswerAnnounced);

        SetImageCard(obj);
      })
      .catch((err) => {
        console.error('err', err);
        alert('something went wrong!');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Answer Updator
  async function Set(e) {
    e.preventDefault();

    const correctAnswer = getter.current.value;

    await axios
      .get(
        `${HEADER_URL}/contest/${Hack}/answer?correctAnswer=${correctAnswer}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        fetchData(); // Calling to reload
      })
      .catch((e) => {
        alert('error occured');
        console.log('error occured : ', e);
      });
  }
  console.log('poll options are : ', imageCard.pollOptions);

  return (
    <div className="insider">
      <h1 class="centering">{imageCard.contestName}</h1>

      {imageCard.contestType !== 'Poll' ? (
        <img src={imageCard.src} alt=" " />
      ) : (
        <div style={{ maxWidth: '450px' }}>
          <h5>
            <b>Question : {imageCard.question}</b>
          </h5>
          <div>
            {imageCard.pollOptions.map((val, index) => {
              return (
                <li>
                  Option {index + 1}: {val.option}
                </li>
              );
            })}
          </div>
        </div>
      )}

      <div class="wraps">
        <div class="textincx">
          <h5>
            <b>
              From: {moment(imageCard.From).format('L')} ,{' '}
              {moment(imageCard.From).format('LT')}{' '}
            </b>
          </h5>
        </div>
        <div class="textincx">
          <h5>
            <b>
              To: {moment(imageCard.To).format('L')} ,{' '}
              {moment(imageCard.To).format('LT')}
            </b>
          </h5>
        </div>
        <div class="textincx">
          <h5>
            <b>Winners: {imageCard.winners}</b>
          </h5>
        </div>

        <div class="textincx">
          <h5>
            <b>Scratch Cards: {imageCard.scratchcards}</b>
          </h5>
        </div>
        <div class="textincx">
          <h5>
            <b>Answer: {xanswers}</b>
          </h5>
        </div>

        {new Date().getTime() > new Date(imageCard.To).getTime() &&
        !xdidAnswerAnnounced ? (
          <div>
            <form onSubmit={Set}>
              <input type="text" ref={getter} />
              <button class="btnn btn-success" type="submit">
                Update
              </button>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
