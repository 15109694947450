import React, { useRef, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WizContainer from './WizardFirstPage';
import { HEADER_URL } from '../../../api/constants.js';
import Papa from 'papaparse';

// import copyImage from '../../../../public/assets/images/copyIcon.svg';

import axios from 'axios';

// import copyImage from '../../../../public/assets/images/copyIcon.svg';
import copyImg from '../../../assets/copyIcon.svg';

function FirstScreenRows({
  updateStatus,
  updateCSV,
  index,
  profilePic,
  name,
  content,
  category,
  loc,
  toggleModal,
  setContent,
  ele,
}) {
  const uploadRef = useRef(null);
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [copied, setCopied] = useState('');
  const showError = status == 'accepted' ? '' : 'error';
  // const copyImg = '/assets/images/copyIcon.svg';
  //2d array to JSON function

  function copyText(cat, location) {
    let search = `${cat} in ${location}`;

    // Copy the text inside the text field
    navigator.clipboard.writeText(search);
    setCopied(search);
    setTimeout(() => {
      setCopied('');
    }, 1000);

    // Alert the copied text
  }

  function TwoDimArrayToJSON(arr) {
    let arrOfObj = [];
    let keyArray = [...arr[0]];
    for (let i = 1; i < arr.length; i++) {
      let obj = {};

      for (let j = 0; j < keyArray.length; j++) {
        obj[keyArray[j]] = arr[i][j];
      }
      arrOfObj.push(obj);
      obj = {};
    }
    return arrOfObj;
  }
  const handleButtonClick = () => {
    uploadRef.current.click();
  };
  function fileupload() {
    var files = uploadRef.current.files;
    console.log('inside');
    console.log(files[0].name);

    if (files.length == 0) {
      alert('Please choose any file...');
      return;
    }
    var filename = files[0].name;
    var extension = filename.substring(filename.lastIndexOf('.')).toUpperCase();
    if (extension == '.CSV') {
      Papa.parse(files[0], {
        complete: async function (results) {
          console.log(results.data);
          results.data.pop();
          let uploadedJSON = TwoDimArrayToJSON(results.data);
          console.log(`-------------JSON of ${index} -----------------`);
          uploadedJSON = uploadedJSON.map((ele) => {
            return {
              ...ele,
              sendMessage: false,
              sendWhatsapp: false,
              sendSpaarks: false,
              callStatus: false,
              isSpaarksUser: false,
            };
          });
          console.log('uploadedJSON', uploadedJSON);

          let finalJSON = await isSpaarksUserFetch(uploadedJSON);
          console.log('fianlJSON', finalJSON);

          //   console.log('---------finalJSON----------');
          //   console.log(finalJSON);
          updateCSV(index, finalJSON);

          //   setUploadedData(finalJSON);
          //   let additionalServiceProviders = finalJSON.map((e, i) => {
          // 	return {
          // 	  ...e,
          // 	  profilePic: '',
          // 	  username: '',
          // 	  _id: i,
          // 	  callStatus:'notContacted'
          // 	};
          //   });
          //   console.log('************');
          //   console.log(finalJSON);
          //   console.log('************');

          //   setServiceProviders([
          // 	...serviceProviders,
          // 	...additionalServiceProviders,
          //   ]);
        },
      });
    } else {
      alert('Please select a valid csv file.');
    }
  }

  //checking csv data for spaarks user (take an array of csvUsers)
  async function isSpaarksUserFetch(csvUsers) {
    try {
      setLoading(true);
      let url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/sparkswizard/checkSpaarksUser`;
      console.log(url);
      let res = await axios.post(
        url,
        { csvUsers: csvUsers },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      console.log('_____issparksAPI_______');
      console.log(res.data.data);
      console.log('____________');

      setLoading(false);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (event) => {
    console.log(`-------------status of ${index} -----------------`);
    console.log(event.target.value);
    updateStatus(index, event.target.value);
  };

  return (
    <WizContainer>
      {copied && <p>Copied</p>}
      <div className="container-fluid mt-3 ">
        <tr className="animate shadow-sm ">
          <div className="d-flex mt-2 justify-content-between ps-2 pe-2">
            <td>
              <div className="d-flex p-2" style={{ width: '160px' }}>
                <div>
                  <img className="profile" src={profilePic} />
                </div>
                <p className="profile-name ms-3 mt-2 ">{name}</p>
              </div>
            </td>
            <td onClick={() => setContent(content)} className="h-50">
              <p className="content">{content}</p>
            </td>
            <a
              href={`https://www.spaarksweb.com/spaark/${
                ele.shareLink.split('/')[5]
              }`}
              target="_blank"
            >
              Link
            </a>
            <td>
              <div
                className="flex-box-2 ms-3 me-3 justify-content-center"
                style={{ width: '150px' }}
              >
                <div className="category  pt-2 pb-2 rounded ps-2 pe-3 justify-content-center">
                  {category ? category : 'Not Available'}
                </div>
                <div className="loc ps-2  pt-1 pb-1 justify-content-center">
                  <svg
                    width="9"
                    height="13"
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.26697 12.4469C3.43146 10.7315 2.53562 9.67164 1.78892 8.78806C0.759876 7.5707 0 6.67149 0 4.90738C0 3.66491 0.503879 2.53972 1.31811 1.72533C2.13234 0.911105 3.25753 0.407227 4.5 0.407227C5.74247 0.407227 6.86766 0.911105 7.68189 1.72533C8.49612 2.53972 9 3.66491 9 4.90738C9 6.67149 8.24013 7.5707 7.21109 8.78806C6.46438 9.67164 5.56854 10.7315 4.73303 12.4469C4.67041 12.5755 4.51516 12.6291 4.38655 12.5665C4.33235 12.5401 4.29147 12.4973 4.26697 12.4469ZM2.18393 8.45367C2.88195 9.27969 3.70705 10.2558 4.5 11.7602C5.29295 10.2558 6.11805 9.27969 6.81607 8.45367C7.77376 7.32052 8.48127 6.48363 8.48127 4.90738C8.48127 3.80776 8.03557 2.81225 7.31535 2.09188C6.59513 1.37165 5.59962 0.925957 4.5 0.925957C3.40038 0.925957 2.40487 1.37165 1.68465 2.09188C0.964428 2.81225 0.51873 3.80776 0.51873 4.90738C0.51873 6.48363 1.22624 7.32052 2.18393 8.45367ZM4.5 3.09626C4.99975 3.09626 5.45279 3.29913 5.78044 3.62678C6.10809 3.95443 6.31096 4.40763 6.31096 4.90738C6.31096 5.40712 6.10809 5.86002 5.78044 6.18767C5.45279 6.51532 4.99975 6.71819 4.5 6.71819C4.00026 6.71819 3.54721 6.51532 3.21956 6.18767C2.89191 5.86002 2.68904 5.40712 2.68904 4.90738C2.68904 4.40763 2.89191 3.95443 3.21956 3.62678C3.54721 3.29913 4.00026 3.09626 4.5 3.09626ZM5.4139 3.99332C5.18026 3.75968 4.85705 3.61499 4.5 3.61499C4.14295 3.61499 3.81974 3.75968 3.5861 3.99332C3.35245 4.22697 3.20777 4.55033 3.20777 4.90738C3.20777 5.26427 3.35245 5.58749 3.5861 5.82113C3.81974 6.05477 4.14311 6.19946 4.5 6.19946C4.8569 6.19946 5.18026 6.05477 5.4139 5.82113C5.64755 5.58749 5.79223 5.26427 5.79223 4.90738C5.79223 4.55033 5.64755 4.22697 5.4139 3.99332Z"
                      fill="black"
                    />
                  </svg>
                  {loc}
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex">
                <select
                  className={`form-select me-3  ${
                    ele.status === 'accepted'
                      ? 'text-success border border-success'
                      : ele.status === 'rejected'
                      ? 'text-danger border border-danger'
                      : ''
                  }`}
                  aria-label="Default select example"
                  onChange={handleChange}
                >
                  <option selected>Status </option>
                  <option value="accepted">accepted</option>
                  <option value="rejected">rejected</option>
                </select>
              </div>
            </td>
            <td>
              <div className="d-flex">
                {/* <img
                  src={copyImg}
                  alt="copyImg"
                  className="ms-2 me-1 w-25 h-25"
                /> */}
                <img
                  src={copyImg}
                  style={{ height: '30px', width: '30px' }}
                  onClick={() => copyText(ele.category, ele.placeName)}
                  className="mt-1 me-1 mb-1"
                  alt="copyImg"
                />
                <div
                  className="d-flex  ms-2"
                  onClick={handleButtonClick}
                  // onChange={() => fileupload()}
                >
                  <span
                    className="uploadcsv-btn pt-2 pb-2 p-1 rounded"
                    style={{
                      width: '100px',
                    }}
                  >
                    Upload
                  </span>
                  <input
                    type="file"
                    // className=" d-none"
                    onChange={() => fileupload()}
                    ref={uploadRef}
                    className="d-none"
                  />
                </div>
              </div>
            </td>
          </div>
        </tr>
      </div>
    </WizContainer>
  );
}

export default FirstScreenRows;
