import { createSlice } from '@reduxjs/toolkit';

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");

export const authSlice = createSlice({
    name: "Auth",
    initialState: {
        token: token ? token : "",
        user: user ? user : "",
    },
    reducers: {
        signin: (state, action) => {
            state.token = action.payload?.token;
            state.user = action.payload?.user;
        },
        logout: (state) => {
            state.token = "";
            state.user = "";
        }
    }
});

export const { signin, logout } = authSlice.actions;

export default authSlice.reducer;
