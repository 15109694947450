import AddEmpContainer from "./AddEmp";
import { useState } from "react";
import TextField from '@mui/material/TextField';
import {useForm} from 'react-hook-form'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from "axios";
import { HEADER_URL } from '../../../../api/constants.js';
function AddEmp(){

    const {register,handleSubmit,formState:{errors}}=useForm()

    var submitted=async(obj)=>{

        var url=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/add_employee`
        await axios.post(url ,obj, {  method: 'POST',  headers: {  'Content-Type': 'application/json',  Authorization: `Bearer ${localStorage.getItem('token')}`,  },},)
        .then((res)=>{
            alert(res.data.message)
        })
        .catch((err)=>{
            alert(err.message)
        })
   
    }
    

    return <AddEmpContainer>
    

            <form onSubmit={handleSubmit(submitted)} >
            <div className="d-flex gap-5">
                <div>
                    <TextField id="outlined-basic" label="User Name" variant="outlined"   {...register('username',{required:true})} />
                    {errors.username?.type==='required' &&  <div className="text-danger">* Username is a Required Field</div> }
                </div>
                <div>
                    <TextField id="outlined-basic" label="Email" type='email' variant="outlined"   {...register('email',{required:true})} />
                    {errors.email?.type==='required' &&  <div className="text-danger">* Email is a Required Field</div> }
                </div>
            </div>
            {/* 2nd rrow */}
            <div className="d-flex gap-5">
                <div>
                    <TextField id="outlined-basic" label="Name" variant="outlined"   {...register('name',{required:true})} />
                    {errors.name?.type==='required' &&  <div className="text-danger">* Name is a Required Field</div> }
                </div>
                <div>
                    <TextField id="outlined-basic" label="Mobile Number" type='number' variant="outlined"   {...register('phone',{required:true,minLength:10,maxLength:10})} />
                    {errors.phone?.type==='required' &&  <div className="text-danger">* Number is a Required Field</div> }
                    {errors.phone?.type==='minLength' &&  <div className="text-danger">* Enter a valid Number</div> }
                    {errors.phone?.type==='maxLength' &&  <div className="text-danger">* Enter a valid Number</div> }
                </div>
            </div>
            {/* 3rd row */}
            <div className="d-flex gap-5">
            <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                   
                    label="Gender"
                    {...register('gender',{required:true})}
                    >
                    <MenuItem value={'Male'}>Male</MenuItem>
                    <MenuItem value={'Female'}>Female</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                   
                    label="Role"
                    {...register('role',{required:true})}
                    >
                    <MenuItem value={'admin'}>Admin</MenuItem>
                    <MenuItem value={'analysisuser'}>Analysis</MenuItem>
                    <MenuItem value={'emp'}>Employee</MenuItem>
                    <MenuItem value={'superuser'}>Super</MenuItem>
                    <MenuItem value={'wizard'}>Wizard</MenuItem>
                   
                    </Select>
                </FormControl>
            </div>
            <div className="d-flex gap-5">
                <div>
                    <TextField id="outlined-basic" label="Secret Code" type='password' variant="outlined"   {...register('code',{required:true})} />
                    {errors.secretcode?.type==='required' &&  <div className="text-danger">* Email is a Required Field</div> }
                </div>
            </div>
         



            <Button variant="contained" type='submit'>Create Employee</Button>

            </form>
    </AddEmpContainer>
}

export default AddEmp