import styled from "styled-components";


const UserCardContainer=styled.div`




.side-heading {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
  }
  .content {
    font-size: 12px;
  }
  
  `;


export default UserCardContainer;