import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../../pages/login/Login.jsx";
import AnalysisDashBoard from '../../pages/analysisPages/DashBoard/AnalysisDashBoard.jsx';
import SideNavBar from '../../components/analysisComponents/SideNavBar/SideNavBar.jsx';
import Story from '../../pages/analysisPages/StoryComponent/Story.jsx'
import ShowListing from "../../pages/analysisPages/ShowListing/ShowListing.jsx";
import SpecificListing from "../../pages/analysisPages/SpecificListing/SpecificListing.jsx";
import ShowProducts from "../../pages/analysisPages/ShowProducts/ShowProducts.jsx";
import SpecificProduct from "../../pages/analysisPages/SpecificProduct/SpecificProduct.jsx";
import PushNotifications from '../../pages/analysisPages/PushNotifications/PushNotifications.jsx';
import InAppNotification from '../../pages/analysisPages/In-App-Notifications/InAppNotification.jsx';
import Engagement from '../../pages/analysisPages/Analytics/Engagement/Engagement';
import Contest from '../../pages/analysisPages/Contest/contestGroupDetails/Contest.jsx';
import Activity from '../../pages/analysisPages/Analytics/UserActivity/Activity.jsx';
import Result from '../../pages/analysisPages/Contest/result/Result.jsx';
import ContestGroup from '../../pages/analysisPages/Contest/contestGroup/ContestGroup.jsx';
import Posters from '../../pages/analysisPages/Posters/Posters.jsx';
import PageNotFound from '../../pages/PageNotFound/PageNotFound.jsx';
import RecentUsage from '../../pages/analysisPages/RecentUsage/RecentUsage.jsx';
import LatestPost from '../../pages/analysisPages/SuperSpaark/Latest-Posts/LatestPost';
import LiveSuperSpaarks from '../../pages/analysisPages/SuperSpaark/Live-SuperSpaarks/LiveSuperSpaark.jsx';
import Rewards from '../../pages/analysisPages/Rewards/Rewards.jsx';
import GroupPosts from '../../pages/analysisPages/SuperSpaark/Group-Posts/GroupPosts.jsx';
import PinPost from '../../pages/analysisPages/PinPost/PinPost.jsx';
import StartupDailog from '../../pages/analysisPages/StartupDailog/StartuDailog.jsx';
import TelecallerLeads from '../../pages/analysisPages/TelecallerLeads/TelecallerLeads.jsx';
import LeadsDetails from '../../pages/analysisPages/LeadsDetails/LeadsDetails.jsx';
import CreateListing from "../../pages/analysisPages/CreateListing/CreateListing.jsx";
import Jobs from '../../pages/analysisPages/JobsPage/Jobs.jsx';
import OpenTicket from "../../pages/analysisPages/Tickets/OpenTicket.jsx";
import TicketDetails from "../../pages/analysisPages/TicketDetails/TicketDetails.jsx";
import UserDetails from "../../pages/analysisPages/UserDetails/UserDetails.jsx";
import EmpManagaDashboard from "../../pages/analysisPages/ManageEmploye/EmpManageDashboard/EmpManageDashboard.jsx";
import CreatePosts from '../../pages/analysisPages/CreatePosts/CreatePosts.jsx';
import LastWeekReward from "../../pages/analysisPages/LastWeekReward/LastWeekReward.jsx";
import Wizard from "../../pages/analysisPages/SpaarksWizard/Wizard.jsx"
import WizardSendMessage from "../../pages/analysisPages/SpaarksWizard/WizardSendMessage.jsx"
import WizardAnalytics from "../../pages/analysisPages/SpaarksWizard/WizardAnalytics.jsx"
import WizardDashboard from "../../pages/analysisPages/SpaarksWizard/WizardDashboard.jsx"


import ReadCSV from "../../pages/analysisPages/SpaarksWizard/ReadCSV.jsx"


function AdminRoutes() {

  return (
    <>
    <div className='d-flex'>
        <SideNavBar/>
        <Routes>
        <Route index element={<AnalysisDashBoard />} />
        <Route path="home" element={<AnalysisDashBoard />} />
          <Route path="login" element={<Login />} />
          <Route path="createinfo" element={<CreatePosts />} />

          <Route path="story" element={<Story/>}/>  
          <Route path="rewards/last-week-reward" element={<LastWeekReward/>}/>

          <Route path="pushnotifications" element={<PushNotifications />} />
          <Route path="app-notifiations" element={<InAppNotification />} />
          <Route path="listings" element={<ShowListing/>}/>
          <Route path="listing" element={<SpecificListing/>}/>
          <Route path="products" element={<ShowProducts/>}/>
          <Route path="product" element={<SpecificProduct/>}/>
          <Route path="posters" element={<Posters />} />
          <Route path="engagement" element={<Engagement />} />
          <Route path="ContestGroup" element={<ContestGroup />} />
          <Route path="contest" element={<Contest />} />
          <Route path="activity" element={<Activity />} />
          <Route path="ticket-details" element={<TicketDetails />} />
          <Route path="create-listing" element={<CreateListing/>} />
          <Route path="recent-usage" element={<RecentUsage />} />
          <Route path="open-tickets" element={<OpenTicket />} />
          <Route path="user-detail" element={<UserDetails />} />
          <Route path='employee' element={<EmpManagaDashboard/>}/>
          <Route path="open-ticket/emp" element={<OpenTicket />} />
          <Route path="open-tickets" element={<OpenTicket />} />
          {/* <Route path="sparks-wizard" element={<Wizards />} /> */}

          <Route path="super-spaark/*">
                  <Route path="latest" element={<LatestPost />} />
                  <Route path="live" element={<LiveSuperSpaarks />} />
                  <Route path="groups" element={<GroupPosts />} />
                  <Route path="pinPost" element={<PinPost />} />
        </Route>
        <Route path="rewards" element={<Rewards />} />
        <Route path="wizard" element={<Wizard />} />
        <Route path="wizardAnalytics" element={<WizardAnalytics />} />
        <Route path="WizardDashboard" element={<WizardDashboard />} />

        <Route path="wizard/sendMessage" element={<WizardSendMessage />} />
        <Route path="readcsv" element={<ReadCSV />} />

        

        </Routes>      
        </div>

    </>
  );
}

export default AdminRoutes;


  
