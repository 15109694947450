import { useEffect, useState } from "react";
import axios from 'axios';

import TelecallerLeadsContainer from "./telecallerLeads";
import { HEADER_URL } from '../../../api/constants.js';
const url = `${HEADER_URL}/superuser/dashboard/emp/leads`;
const updateUrl=`${HEADER_URL}/superuser/dashboard/emp/lead/update`
import { useSelector } from "react-redux";
import * as React from 'react';
// import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import { AiFillDelete } from "react-icons/ai";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import moment from 'moment/moment';
import InfiniteScroll from "react-infinite-scroll-component";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,


  p: 4,
};
function TelecallerLeads() {




//Infinite scroll Related
const [hasMore, setHasMore] = useState(true);
const [page,setPage]=useState(1)

  const [imgUrl, setImgUrl] = useState('')
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [leadsData, setLeadsData] = useState({
    leads: [
      {
          empId: "",
          empname: "",
          savedAt:"",
          status: "",
          remark: "",
          _id: "",
          userId: {
              profilePic: "",
              _id: "",
              name: "",
              phone: "",
              location: ""
          },
          postId: {
              photo: [
              ],
              placeName: "",
              _id: "",
              content: ""
          }
      },
     
  ],
  total: 0,
  pages: 0,
  page: 0,
  skip: 0,
  limit: 0
});
  const TOKEN = useSelector((state) => state.auth.token);
  const sus_user_passkey = localStorage.getItem('sus_user_passkey');

  const getLeadsData = async () => {
    await axios
      .get(url+'?page='+page, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },


      })
      .then((res) => {
        // setLeadsData(res.data)
        res.data.leads.length<5?setHasMore(false):setHasMore(true)
          
        if(page==1){
          setLeadsData(res.data)
        }
        else{
            var temp=leadsData
            temp.leads=[...temp.leads,...res.data.leads]
            setLeadsData({...temp})

        }
        setPage(page+1)



      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };
console.log(hasMore)
  const handleSaveClicked=async(idx)=>{

     let updObj={}
     updObj.leadId=leadsData.leads[idx]._id;
     updObj.status=leadsData.leads[idx].status;
     updObj.postId=leadsData.leads[idx].postId._id;
     updObj.content=leadsData.leads[idx].postId.content;
    updObj.remark=leadsData.leads[idx].remark;
    updObj.photo=leadsData.leads[idx].postId.photo;
    await axios
    .post(updateUrl,updObj, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
       
      },
      // data:leadsData.leads[idx]
    })
    .then((res) => {
      console.log(res.data)
      getLeadsData();
    })
    .catch((err) => {
      console.log('err', err);
      getLeadsData();
      alert('something went wrong!');
    });

    
  }


  useEffect(() => {
    getLeadsData();
  }, []);
  // console.log(leadsData.leads[0].empname)
  useEffect(()=>{

  },[leadsData])
  console.log('148',leadsData)

  return (
    <TelecallerLeadsContainer>
      <div className="heading">  Leads</div>



      <div className="mx-auto w-75 mt-3 ">
      <InfiniteScroll
            dataLength={ leadsData.leads.length!=0 &&  leadsData.leads.length}
            // style={{ display: 'flex', flexWrap:'wrap',gap:'30px' }}
            next={()=>{
              getLeadsData();
               }}
            hasMore={hasMore}
            loader={<div>Loading ... </div>}
            >
        {
          leadsData &&
          leadsData.leads.map((ele, idx) => {
            var contentValue = ele.postId.content
            return (<div className="cardd mb-5" key={idx}>
              <Card >
                <CardContent>
                  <div className="d-flex  justify-content-between ">

                    <div>
                      <div className="d-flex">
                        <div className="post-head d-flex gap-4  align-items-center details-border " >
                          <div className="d-flex flex-column align-items-center">
                            <h4>User Details</h4>
                            <div className="d-flex gap-4">
                              <div className="profile-pic"
                                 >
                                <img src={ele.userId.profilePic} className='rounded-circle' width='70px' height='70px' alt="" onClick={() => {
                                  console.log(ele.userId.profilePic)
                                  setImgUrl(ele.userId.profilePic)
                                  handleOpen()
                                }} /></div>
                              <div className="user-details d-flex justify-content-around flex-column">
                                <div className="name" >{ele.userId.name}</div>
                                <div className="phone-num">{ele.userId.phone}</div>
                                <div className="user-place">{ele.postId.placeName}</div>
                              </div>
                            </div>
                            <div className="user-details d-flex justify-content-around  w-100 flex-column">
                                <div className="d-flex align-items-center gap-2 ">
                                  <div className="side-heading" >Category :  </div>
                                  <div className="content  ms-1 mt-1">{ele.postId.category}</div>
                                </div>
                                <div className='d-flex align-items-center gap-2' >
                                  <div className="side-heading" >Sub Category :  </div>
                                  <div className="content  ms-1 mt-1">{ele.postId.subCategory}</div>
                                </div>

                              </div>

                          </div>
                        </div>
                        <div className="post-head d-flex gap-4  align-items-center details-border " >
                          <div className="d-flex flex-column align-items-center">
                            <h4>Employee Details</h4>
                            <div className="d-flex gap-4">

                              <div className="user-details d-flex justify-content-around flex-column">
                                <div className="emp-details d-flex align-items-center ">
                                  <div className="side-heading" >Employee Name :  </div>
                                  <div className="content  ms-1">{ele.empname}</div>
                                </div>
                                <div className='d-flex' >
                                  <div className="side-heading" >Saved At :  </div>
                                  <div className="content  ms-1"> {moment(ele.savedAt).format('lll')}</div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="form-floating mt-3">
                        <textarea className="form-control" onChange={(e) => {
                          ele.postId.content=e.target.value;
                          if(ele.status=='Saved'){
                            ele.status='changed'
                          }
                          setLeadsData({...leadsData,ele})
                          
                        }} cols='30' rows='30' id="floatingTextarea2"  style={{ height: 100 }} defaultValue={contentValue}></textarea>
                        <label >Content</label>
                      </div>
                      <div className="form-floating mt-3">
                        <textarea className="form-control" onChange={(e) => {
                          ele.remark=e.target.value;
                          if(ele.status=='Saved'){
                            ele.status='changed'
                          }
                          setLeadsData({...leadsData,ele})

                        }} cols='30' style={{ height: 100 }} defaultValue={ele.remark} ></textarea>
                        <label >Remarks (write your remarks here)</label>
                      </div>
                      <div className="d-flex flex-wrap justify-content-center mt-5">

                        {
                          ele.postId.photo.map((image, index) => {
                            return <div className="position-relative m-1" key={index}>
                              <img src={image} width='150px' hieght='200px' alt=""  onClick={() => {
                              setImgUrl(image)
                              handleOpen()
                            }}   />

                              <IconButton aria-label="delete" size="small" onClick={()=>{
                               if(ele.status=='Saved'){
                                ele.status='changed'
                              }
                                ele.postId.photo.splice(index,1)
                               setLeadsData({...leadsData,ele})
                                
                              }} >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>

                            </div>
                          })
                        }



                      </div>
                    </div>
                    {/* 2nd col */}

                    <div className="d-flex flex-column mt-5">

                      <div className="d-flex gap-1 align-items-center">
                        <div style={{ fontSize: '20px', marginRight: '10px' }} >Status : </div>
                        {ele.status == 'Saved' && <span style={{ backgroundColor: '#007BFF', borderRadius: '50%' }}>
                          <button type="button" className="btn " data-toggle="tooltip" data-placement="right" title="Saved">
                          </button>
                        </span>}
                        {ele.status == 'changed' && <span style={{ backgroundColor: '#FFC107', borderRadius: '50%' }}>
                          <button type="button" className="btn" data-toggle="tooltip" data-placement="right" title="Changed and Not saved">
                          </button>
                        </span>}
                        {ele.status == 'error' && <span style={{ backgroundColor: '#DC3545', borderRadius: '50%' }}>
                          <button type="button" className="btn" data-toggle="tooltip" data-placement="right" title="Error occured">
                          </button>
                        </span>}
                      </div>


                      <div className='d-flex flex-column justify-content-around align-items-center me-4 mt-auto' style={{ height: 200 }}>
                      <Button variant="contained" onClick={()=>{
                        ele.status='Saved'
                        setLeadsData({...leadsData,ele})
                        handleSaveClicked(idx);
                        }} >
                          Save
                        </Button>
                        
                        <Button variant="contained" color="success" onClick={()=>{
                              ele.status='Accepted'
                              setLeadsData({...leadsData,ele})
                              handleSaveClicked(idx);
                                                      
                              }} >
                          Accept
                        </Button>
                        <Button variant="outlined" color="error" onClick={()=>{
                              ele.status='Rejected'
                              setLeadsData({...leadsData,ele})
                              handleSaveClicked(idx);
                                                      
                              }}>
                          Reject
                        </Button>
                      </div>
                    </div>

                  </div>
                </CardContent>

              </Card>
            </div>)
          })

        }
  </InfiniteScroll> 
      </div>
    
      {/* Image Modal */}
      <div>
       
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img src={imgUrl} width='500px' alt="" />
          </Box>
        </Modal>
      </div>


    </TelecallerLeadsContainer>
  )

}

export default TelecallerLeads




{/* <div className="emp-details d-flex align-items-center ">
                   <div className="side-heading" >Employee Id : </div>
                  <div className="content mx-auto">{ele.empId}</div>
                </div>
                <div className="emp-details d-flex align-items-center ">
                   <div className="side-heading" >Employee Name : </div>
                     <div className="content  mx-auto">{ele.empname}</div>
                 </div>
                <div className="emp-details d-flex align-items-center ">
                     <div className="side-heading" >Post Id : </div>
                     <div className="content  mx-auto">{ele.postId._id}</div>
           </div>
                 <div className="emp-details d-flex align-items-center ">
                     <div className="side-heading" >Status : </div>
                     <div className="content  mx-auto">{ele.status}</div>
                </div> */}




{/* <div style={{width:'300px',height:'300px'}}>
                      <div id="carouselExampleControls" className="mx-auto  carousel slide" data-ride="carousel" style={{width:'100%',height:'100%'}}>
                         
                          <div className="carousel-inner"  style={{width:'100%',height:'100%'}}>
                          {
                            ele.postId && ele.postId.photo.map((img,imgIdx)=>
                              <div className={imgIdx==0? "carousel-item active":"carousel-item"} style={{width:'100%',height:'100%'}} key={imgIdx}>
                                <img className="d-block " style={{width:'100%',height:'100%'}} src={img} alt="First slide"/>
                                </div>
                              
                              )
                          }
                          </div>
                         
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                      </div> */}