import Container from './pushnotifications.js';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import { useEffect,useMemo, useState, useRef } from 'react';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import TabPanel from '../../../components/analysisComponents/NotificationCard/Tabs/Tabs';
import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { createNotification } from '../../../store/notificationSlice.js';
import NotificationDetailsCard from '../../../components/analysisComponents/NotificationCard/NotificationDetailsCard';
const types = ['sheduled', 'pending', 'sent'];
import places from  '../../../utils/cities'

function PushNotifications() {
  const dispatch = useDispatch();
  const [type, setType] = useState('sheduled');
  const [open, setOpen] = useState('');
  const [formPhone, setFormPhone] = useState('none');
  const [formPostId, setFormPostId] = useState('none');
  const [formUrl, setFormUrl] = useState('none');
  const [formState, setFormState] = useState('none');
  const [formSearch, setFormSearch] = useState('none');
  const formRef = useRef(null);

  const [category, setCategory] = useState('All_Users');
  const [redirect, setRedirect] = useState('Feed');
  const [language, setLanguage] = useState('en');
  const [title, setTitle] = useState("");
  const [body, setBody] = useState(null);
  const imageRef = useRef(null);
  const imageURLRef = useRef(null);
  const [sendAt, setSendAt] = useState(null);
  const [phone, setPhone] = useState(null);
  const [postId, setPostId] = useState(null);
  const [location, setLocation] = useState(null);
  const [url, setUrl] = useState(null);
  const [search, setSearch] = useState(null);
  const TOKEN = useSelector((state) => state.auth.token);

  // useEffect(() => {}, [type]);

  const getIndex = (index) => {
    setType(types[index]);
  };

  //Form Submit here
  const submitForm = async (e, data) => {
    e.preventDefault();

    setOpen('');

    const formData = new FormData();

    if (!data) {
      formData.append('category', category);
      formData.append('clickAction', redirect);
      formData.append('language', language);
      formData.append('title', title);
      formData.append('body', body);
      formData.append('imageURL', imageURLRef.current.value);
      if (imageRef.current.files.length) {
        formData.append(imageRef.current.name, imageRef.current.files[0]);
      } else {
        formData.append(imageRef.current.name, '');
      }
      formData.append('sendAt', sendAt);
      if (category === 'Test') formData.append('phone', phone);
      if (category === 'Location') formData.append('location', location);
      if (redirect === 'PostSpecificScreen') formData.append('postId', postId);
      if (redirect === 'Web') formData.append('url', url);
      if (redirect === 'search') formData.append('search', search);
      const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/notification/send`;

      axios
        .post(url, formData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          dispatch(createNotification(res.data.notification));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/notification/reschedule-notification`;

      axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          dispatch(createNotification(res.data.notification));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return;
  };

  const categoryChanged = (e) => {
    if (e.target.value === 'Test') {
      setFormPhone('block');
      setFormState('none');
    } else if (e.target.value === 'Location') {
      setFormState('block');
      setFormPhone('none');
    } else {
      setFormPhone('none');
      setFormState('none');
    }
    setCategory(e.target.value);
  };

  const redirectChange = (e) => {
    if (e.target.value === 'PostSpecificScreen') {
      setFormPostId('block');
      setFormSearch('none');
      setFormUrl('none');
    } else if (e.target.value === 'Web') {
      setFormUrl('block');
      setFormSearch('none');
      setFormPostId('none');
    } else if (e.target.value === 'search') {
      setFormSearch('block');
      setFormPostId('none');
      setUrl('none');
    } else {
      setFormPostId('none');
      setFormSearch('none');
      setFormUrl('none');
    }
    setRedirect(e.target.value);
  };

  //Notification Main Code

  function LayoutModalDialog() {
    return (
      <>
        <Modal open={!!open} onClose={() => setOpen('')}>
          <ModalDialog
            aria-labelledby="layout-modal-title"
            aria-describedby="layout-modal-description"
            layout={open || undefined}
            style={{ overflow: 'scroll' }}
          >
            <ModalClose />
            <h1
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: '300',
                textAlign: 'center',
              }}
            >
              {' '}
              Push Notification Scheduler
            </h1>
            <form
              enctype="multipart/form-data"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '50%',
              }}
              ref={formRef}
              onSubmit={submitForm}
            >
              <div className="row mt-3">
                <div className="col">
                  <label for="inputState">Select Category</label>
                  <select
                    name="category"
                    value={category}
                    className="form-control"
                    onChange={categoryChanged}
                  >
                    <option value="All_Users" selected>
                      All Users
                    </option>
                    <option value="Android_Users">Android Users</option>
                    <option value="IOS_Users">IOS Users</option>
                    <option value="Group_Users">Group Users</option>
                    <option value="Guest_Users">Guest Users</option>
                    <option value="Location">Location</option>
                    <option value="Partners">Partners</option>
                    <option value="BDE">BDE"S</option>
                    <option value="Test">Test</option>
                  </select>
                </div>
                <div className="form-group col">
                  <label for="inputState">Redirect To</label>
                  <select
                    id="inputState"
                    name="clickAction"
                    className="form-control"
                    value={redirect}
                    onChange={redirectChange}
                  >
                    <option value="Feed" selected>
                      Feed
                    </option>
                    <option value="PostSpecificScreen">
                      Post Specific Screen
                    </option>
                    <option value="Web">web</option>
                    <option value="Search">search</option>
                    <option value="contest">contest</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label for="language">Language</label>
                  <select
                    id="language"
                    name="language"
                    className="form-control"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="en" selected>
                      English
                    </option>
                    <option value="hi">Hindi</option>
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <input
                    key="title"
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="form-group col">
                  <textarea
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    name="body"
                    id="body"
                    className="form-control"
                    cols="10"
                    rows="6"
                    placeholder="Body"
                  ></textarea>
                </div>
              </div>

              <div className="form-group mt-2" style={{ display: formPhone }}>
                <label for="phone" className="title">
                  Phone
                </label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control ml-4"
                />
              </div>
              <div className="form-group mt-2" style={{ display: formState }}>
                <Autocomplete
                  disablePortal
                  onChange={(e, value) => setLocation(value)}
                  id="combo-box-demo"
                  options={places}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
              </div>
              <div className="form-group mt-2" style={{ display: formPostId }}>
                <label for="postId" className="title">
                  Post Id
                </label>
                <input
                  value={postId}
                  onChange={(e) => setPostId(e.target.value)}
                  type="text"
                  name="postId"
                  id="postId"
                  className="form-control ml-4"
                />
              </div>
              <div className="form-group mt-2" style={{ display: formSearch }}>
                <label for="searchString" className="title">
                  Search
                </label>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  name="searchString"
                  id="searchString"
                  className="form-control ml-4"
                />
              </div>
              <div className="form-group mt-2" style={{ display: formUrl }}>
                <label for="url" className="title">
                  URL
                </label>
                <input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  name="url"
                  id="url"
                  className="form-control ml-4"
                />
              </div>

              {/* image part */}
              <div className="row mb-3">
                <div className="form-group col-6 mt-1">
                  <label for="photo">Image</label>
                  <input
                    ref={imageRef}
                    type="file"
                    name="photo"
                    id="photo"
                    className="form-control"
                  />
                  <br />
                  <label for="photoURL">Image URL</label>
                  <input
                    ref={imageURLRef}
                    type="text"
                    name="photoURL"
                    placeholder="https://static-content.spaarksweb.com/contest/contest.jpg"
                    id="photoURL"
                    className="form-control"
                  />
                </div>
                <div className="form-group col-6 mt-1">
                  <label for="sendAt" className="title">
                    Send At
                  </label>
                  <input
                    value={sendAt}
                    onChange={(e) => setSendAt(e.target.value)}
                    min={moment().format().slice(0, 16)}
                    type="datetime-local"
                    name="sendAt"
                    id="sendAt"
                    className="form-control"
                  />
                </div>
              </div>
              <Button type="submit" variant="contained">
                Schedule
              </Button>
            </form>
          </ModalDialog>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Container>
        {/* <SideBar /> */}
        <TabPanel getIndex={getIndex} />
        <div className="right">
          <NotificationDetailsCard querytype={type} reschedule={submitForm} />
          <Fab
            onClick={() => setOpen('fullscreen')}
            color="primary"
            aria-label="add"
            style={{ position: 'fixed', bottom: '30px', right: '50px' }}
          >
            <AddIcon />
          </Fab>
        </div>
        {LayoutModalDialog()}
      </Container>
    </>
  );
}

export default PushNotifications;
