
import styled from "styled-components";


const Container = styled.div`
   display: flex;
   justify-content: center;
   width:100%;

   .right{
      
      display: flex;
      justify-content: center;
      margin-top: 8%;
   }

`

export default Container;  