import { BrowserRouter, Routes, Route } from "react-router-dom";
import SideNavBar from '../../components/analysisComponents/SideNavBar/SideNavBar.jsx';
import AnalysisDashBoard from '../../pages/analysisPages/DashBoard/AnalysisDashBoard.jsx';
import OpenTicket from "../../pages/analysisPages/Tickets/OpenTicket.jsx";
import ClosedTicket from "../../pages/analysisPages/ClosedTickets/ClosedTicket.jsx";
import AddLead from "../../pages/analysisPages/AddLeads/AddLead.jsx";
import MyLeads from "../../pages/analysisPages/MyLeads/MyLeads.jsx";
import Posts from "../../pages/analysisPages/Posts/Posts.jsx";
import TicketDetails from "../../pages/analysisPages/TicketDetails/TicketDetails.jsx";
import UserDetails from "../../pages/analysisPages/UserDetails/UserDetails.jsx";

function EmpRoutes() {

  return (
    <>
    <div className='d-flex'>
        <SideNavBar/>
        <Routes>
        <Route index element={<AnalysisDashBoard />} />
        <Route path="home" element={<AnalysisDashBoard />} />
        <Route path="open-tickets" element={<OpenTicket />} />
        <Route path='closed-tickets' element={<ClosedTicket/>}/>
        <Route path='addlead' element={<AddLead/>} />
        <Route path='myleads' element={<MyLeads/>}/>
        <Route path="posts" element={<Posts/>}/>
        <Route path='ticket-details' element={<TicketDetails/>}/>
        <Route path='user-detail' element={<UserDetails/>}/>
        </Routes>      
        </div>

    </>
  );
}

export default EmpRoutes;


  
