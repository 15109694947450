import SpecificProductContainer from "./SpecificProduct";
import * as React from 'react';
import { useLocation } from "react-router-dom";
import { HEADER_URL } from '../../../api/constants.js';
import { useSelector } from "react-redux";
import { useEffect, useState,useRef } from "react";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
var productUrl=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/productSpecific`
var productDeleteUrl=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/product/delete`
import { useNavigate } from "react-router-dom";
import { ImLocation2 } from "react-icons/im";
import { SlCalender } from "react-icons/sl";
import moment from 'moment/moment';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };
  

function SpecificProduct(){
    const reason=useRef();
    console.log("hello")
const navigate=useNavigate();
const id=useLocation().state.productId;
const TOKEN = useSelector((state) => state.auth.token);
const [data,setData]=useState({})
const [currImg,setCurrImg]=useState('')
const [mouseOver,setMouseOver]=useState(false)
const [mouseOverImg,setMouseOverImg]=useState('')
//get product
const getProduct= async () => {
    console.log("hello")
    var url=productUrl
    url+='?productId='+id
    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },    
      })
      .then((res) => {
        console.log(res.data)
        setData(res.data.product)
        setCurrImg(res.data.product.photos[0])
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };
  const deleteProduct = async () => {
    var url=productDeleteUrl
    url+='?productId='+id
    await axios
      .post(url,{reason:reason.current.value.trim()} ,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },    
      })
      .then((res) => {
        if(res.data.message=='success')
        {
          navigate(-1)

        }
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

console.log('data',data)
useEffect(() => {
    getProduct();
  },[])

// console.log("mouse Over",mouseOver)
const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    return <SpecificProductContainer>
        <div className="cont">
            <div className="d-flex flex-column gap-2 " style={{width:'70px'}}>
                {
                    data && data.photos &&

                    data.photos.map((ele,idx)=>{
                        return <img key={idx} src={ele} width="50px" height="80px" alt="" 
                        onMouseOver={()=>{ 
                            setMouseOverImg(ele)
                            setMouseOver(true)}
                        }
                        onClick={()=>{setCurrImg(ele)}} 
                        onMouseOut={()=>{
                              setMouseOverImg('')        
                                setMouseOver(false)}
                        }

                        className={((mouseOverImg==ele&&mouseOver==true)||(currImg==ele)) ? 'orangeBg':'blackBg'}

                        />
                    })

                }
            </div>
            <div  >
              {mouseOver==false&&  <img src={currImg} width="500px" height="500px" alt="" /> }
              {mouseOver==true&&  <img src={mouseOverImg} width="500px" height="500px" alt="" /> }
            </div>
        </div>
        <div  className="card p-3 w-75 overflow-scroll  me-5" style={{height:'90vh'}}>
                {/* profile details */}
                <h3>Sellers Description</h3>
                <div className="d-flex align-items-center gap-3 ">

                    <div className="profilepic">
                      {data && data.userDetails&&data.userDetails.profilePic &&  <img src={data.userDetails.profilePic} alt="" width="80px" height="80px" style={{borderRadius:'50%'}} />}
                    </div>
                    <div className="profileDetails mt-3 ">
                {
                    data &&data.userDetails&& <div>
                        <h6>{data.userDetails.name}</h6>
                        <p style={{fontSize:'15px'}} >{data.userDetails.phone}</p >
                    </div>
                }

                    </div>
                </div>

                {/* details */}
                <div className=' mt-4 ms-2'>
                <div className='d-flex w-100 justify-content-between'>
                    {/* Name and price */}
                 <div>
                {data&&data.productName&& <h4 className="text-start mt-2">{data.productName}</h4> }

                {data&&data.price && <h3 className="text- mt-2"><sup>₹</sup>{data.price} </h3>}

                </div>
                {/* del button */}
                <IconButton aria-label="delete" onClick={handleOpen}>
                    <DeleteIcon />
                </IconButton>
                </div>
                </div>
             
                <div className="d-flex gap-2 align-items-center mt-3"><ImLocation2/> <div>{data.locationName}</div></div>
                <div  className="d-flex gap-2 align-items-center mt-3"><SlCalender/> <div>{moment(data.publishedAt).format('lll')}</div></div>
                
                <div className='mt-4 '>

                <div className="display-6 text-start text-primary">Description</div>
                <div style={{width:'100%',overflowWrap:'anywhere'}}>
                        <p className="text-start lead">{data.description}</p>
                </div>
                </div>
                  
        </div>
  
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5 className="text-primary">Delete?</h5>

            <p className="text-danger"> This following action will permanently delete this product!</p>
            <div className="form-group">
            <label>Enter Reason</label>
            <textarea className="form-control" ref={reason} rows="3"></textarea>
            </div>
                <div className="w-100 d-flex justify-content-end gap-2">
                <Button variant="outlined" onClick={handleClose}>Close</Button>
                <Button variant="contained" onClick={deleteProduct}>Delete</Button>
                </div>
        </Box>
      </Modal>
    </SpecificProductContainer>
}

export default SpecificProduct;
