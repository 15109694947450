import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';



export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.getIndex(newValue)
    };

  return (
    <div style={{
      position: 'fixed',
      top: '0px',
      left:"17%",
      paddingTop: '50px',
      marginBottom:'10px',
      width: '70%',
      zIndex: '10',
      background: 'white'
    }}>
      <h1 style={{marginLeft:'15px',fontFamily:"'Poppins', sans-serif",textAlign:'left'}}>Notifications</h1>
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',marginBottom:'20px' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Scheduled"  />
          <Tab label="Pending"  />
            <Tab label="Sent" />
          </Tabs>
        </Box>
    </Box>
    </div>
  );
}
