
import styled from "styled-components";


const Container = styled.div`
   width:100%;
   .right{
      display: flex;
      justify-content: center;
      margin-top:180px;
   }
`

export default Container;  