import LeadSummaryContainer from "./LeadSummary";
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import { useSelector } from "react-redux";
const leadsUrl=`${HEADER_URL}/superuser/dashboard/emp/lead/summary`
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import InfiniteScroll from "react-infinite-scroll-component";

function LeadSummary(){
    const [leadsSummary,setLeadsSummary]=useState({leads:[]})
    const TOKEN = useSelector((state) => state.auth.token);
    const sus_user_passkey = localStorage.getItem('sus_user_passkey');
    const navigate=useNavigate()
    const getLeadsSummary = async () => {
        await axios
          .get(leadsUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },    
          })
          .then((res) => {
           
            res.data.leads.length<20?setHasMore(false):setHasMore(true)
          
            if(page==1){
              setLeadsSummary(res.data)
            }
            else{
                var temp=leadsSummary
                temp.leads=[...temp.leads,...res.data.leads]
                setLeadsSummary({...temp})

            }
            setPage(page+1)
          })
          .catch((err) => {
            console.log('err', err);
            alert('something went wrong!');
          });
      };


//Infinite scroll Related
const [hasMore, setHasMore] = useState(true);
const [page,setPage]=useState(1)



  useEffect(() => {
    getLeadsSummary();
  }, []);
    return(
    <>
    <LeadSummaryContainer>
   <h3>Employees</h3>
       <div className="d-flex flex-wrap ">

       <InfiniteScroll
            dataLength={ leadsSummary.leads.length!=0 &&  leadsSummary.leads.length}
            style={{ display: 'flex', flexWrap:'wrap',gap:'30px' }}
            next={()=>{
              getLeadsSummary();
               }}
            hasMore={hasMore}
            loader={<div>Loading ... </div>}
            >
        {
            leadsSummary && leadsSummary &&
            leadsSummary.leads.map((ele,idx)=>{
                return <div key={idx} className="cardBody " style={{width:'500px'}}>
                    <div className="d-flex flex-column justify-content-around gap-1">
                        <div className="profile d-flex ">
                            <div className="image">
                                <img src={ele.employee.profilePic} alt="" width='100px' height='100px' />
                            </div>
                            <div className="profile-details p-1 d-flex flex-column justify-content-center align-items-start gap-1">
                                <div className="d-flex"><div className="content mt-1 ms-3 text-primary font-weight-bold"> {ele.employee.name}</div></div>
                                <div className="d-flex"><div className="content  mt-1 ms-3 text-primary font-weight-bold"> {ele.employee.email}</div></div>
                                <div className="d-flex"><div className="content  mt-1 ms-3 text-primary font-weight-bold"> {ele.employee.phone}</div></div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-around">
                        <div className="d-flex flex-column ms-4 gap-2">
                        <div className="d-flex"><div className="side-heading">PENDING : </div><div className="content  mt-1 ms-2 text-primary  font-weight-bold "> {ele.pending}</div></div>
                        <div className="d-flex"><div className="side-heading">SAVED : </div><div className="content  mt-1 ms-2 text-primary   font-weight-bold"> {ele.saved}</div></div>
                        </div>
                        <div className="d-flex flex-column ms-4 gap-2">
                        <div className="d-flex"><div className="side-heading">ACCEPTED : </div><div className="content  mt-1 ms-2 text-primary  font-weight-bold  "> {ele.accepted}</div></div>
                        <div className="d-flex"><div className="side-heading">REJECTED : </div><div className="content  mt-1 ms-2 text-primary  font-weight-bold  "> {ele.rejected}</div></div>
                        </div>
                       
                        </div>
                        <div className="d-flex justify-content-center ms-4 gap-2">
                        <div className="d-flex"><div className="side-heading">Total : </div><div className="content  mt-1 ms-2 text-primary  font-weight-bold  "> {ele.total}</div></div>
                        </div>
                        <Button variant="outlined" size="medium" onClick={()=>{
                            navigate('../lead-details',{state:{
                               'employeeId':ele._id,
                            }})
                        }}>
                            Details
                        </Button>
                    </div>
                     
                 </div>
            })
        }
        </InfiniteScroll> 
            </div>
    </LeadSummaryContainer>
    </>
    );
}

export default LeadSummary;