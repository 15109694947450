import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import moment from 'moment/moment';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { HEADER_URL } from '../../../api/constants.js';
import WizardContainer from './Wizard.js';

const Wizard = () => {
  const navigate = useNavigate();
  let uploadRef = useRef(null);

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [serviceProviders, setServiceProviders] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedPost, setSelectedPost] = useState({});
  const [reset, setReset] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [isActive, setIsActive] = useState(0);

  //Infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [maxLength, setMaxLength] = useState(0);
  const [page, setPage] = useState(1);

  //2d array to JSON function
  function TwoDimArrayToJSON(arr) {
    let arrOfObj = [];
    let keyArray = [...arr[0]];
    for (let i = 1; i < arr.length; i++) {
      let obj = {};

      for (let j = 0; j < keyArray.length; j++) {
        obj[keyArray[j]] = arr[i][j];
      }
      arrOfObj.push(obj);
      obj = {};
    }
    return arrOfObj;
  }

  //CSV Upload
  function upload() {
    var files = uploadRef.current.files;
    console.log('inside');
    console.log(files[0].name);

    if (files.length == 0) {
      alert('Please choose any file...');
      return;
    }
    var filename = files[0].name;
    var extension = filename.substring(filename.lastIndexOf('.')).toUpperCase();
    if (extension == '.CSV') {
      Papa.parse(files[0], {
        complete: async function (results) {
          console.log(results.data);
          results.data.pop();
          const uploadedJSON = TwoDimArrayToJSON(results.data);
          console.log(uploadedJSON);

          let finalJSON = await isSpaarksUserFetch(uploadedJSON);

          console.log('---------finalJSON----------');
          console.log(finalJSON);

          setUploadedData(finalJSON);
          let additionalServiceProviders = finalJSON.map((e, i) => {
            return {
              ...e,
              profilePic: '',
              username: '',
              _id: i,
              callStatus:'notContacted'
            };
          });
          console.log('************');
          console.log(finalJSON);
          console.log('************');

          setServiceProviders([
            ...serviceProviders,
            ...additionalServiceProviders,
          ]);
        },
      });
    } else {
      alert('Please select a valid csv file.');
    }
  }

  //checking csv data for spaarks user
  async function isSpaarksUserFetch(csvUsers) {
    try {
      setLoading(true);
      let url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/sparkswizard/checkSpaarksUser`;
      console.log(url);
      let res = await axios.post(
        url,
        { csvUsers: csvUsers },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      console.log('_____issparksAPI_______');
      console.log(res.data.data);
      console.log('____________');

      setLoading(false);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  //fetch need service post based on dates
  function fetchPosts() {
    let url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/need-service-posts?`;
    if (from != '') {
      url += `from=${from}`;
    }
    if (to != '') {
      url += `&to=${to}`;
    }

    console.log(url);
    axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log('____________');
        console.log(res.data);
        console.log('____________');
        setPostData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  useEffect(() => {
    //fetching need service posts
    function fetchonLoad() {
      let fromCurr = moment().subtract(1, 'year').format().slice(0, 10);
      let toCurr = moment().format().slice(0, 10);

      let url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/sparkswizard/need-service-posts?`;

      if (fromCurr != '') {
        url += `from=${fromCurr}`;
      }
      if (toCurr != '') {
        url += `&to=${toCurr}`;
      }

      console.log("180: ", url);
      
      axios(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          console.log('____________');
          console.log(res.data);
          console.log('____________');
          setPostData(res.data.data);
          updateServicePost(res.data.data[0], 0);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }

    fetchonLoad();
  }, [reset]);

  //updating serviceProviders post on right
  async function updateServicePost(obj, i) {
    setIsActive(i);
    setSelectedPost(obj);
    if (uploadRef.current?.value) uploadRef.current.value = '';
    const id = obj._id;
    setServiceProviders([]);
    console.log(id);
    setLoading(true);
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/service-providers`;
    console.log(url);
    axios(url, {
      method: 'POST',
      data: { postId: id },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log('*********');
        console.log(res.data);
        console.log('*********');
        let response = res.data.data.map((e) => {
          return { ...e, isSpaarksUser: true, callStatus:'notContacted' };
        });
        setServiceProviders(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  //add/remove to list when checkbox is tick/untick
  function addtoList(e, newValue) {
    let updatedArray = [...selectedServiceProviders];
    if (updatedArray.map((el) => el._id).includes(newValue._id)) {
      updatedArray = updatedArray.filter((item) => item._id !== newValue._id);
      console.log('remove');
    } else {
      updatedArray = [...selectedServiceProviders, { ...newValue }];
      console.log('add');
      setCount(count + 1);
    }

    setSelectedServiceProviders(updatedArray);
  }

  //next page navigate
  function nextPage() {
    if (selectedServiceProviders.length == 0) {
      console.log(selectedServiceProviders);
      alert('Please select any Service Providers');
      return;
    }

    console.log("******Next Page Values Sending******");
    console.log(selectedServiceProviders);
    console.log("************");

    navigate('sendMessage', {
      state: { selectedServiceProviders, postDetails: selectedPost },
    });
  }

  function copyText(phone) {
    phone = phone.slice(3);

    // Copy the text inside the text field
    navigator.clipboard.writeText(phone);

    // Alert the copied text
  }


  function updateCallStatus(e, id){
    let updatedArray = [...selectedServiceProviders];
    let filteredArray = updatedArray.filter((ele) => ele._id == id)
    console.log("filteredArray", filteredArray)
    if(filteredArray.length == 0){
      alert("Please select Service Provider");
      e.target.value="notContacted"
      return
    }
    updatedArray.map(ele => {
      if(ele._id == id){
        ele.callStatus = e.target.value
      }
      return{
        ...ele,
      }
    })
    console.log(updatedArray);
    setSelectedServiceProviders([...updatedArray]);
  }

  return (
    <WizardContainer>
      <div className="container-fluid d-flex justify-content-between">
        <div className="left border-danger border-3">
          <div className=" d-flex mb-2 ">
            <div className="d-flex flex-column">
              <label>From </label>
              <input
                value={from}
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                max={moment().format().slice(0, 16)}
                type="date"
                name="from"
                id="from"
                className="form-control"
              />
            </div>
            <div className="d-flex flex-column ms-2">
              <label>To </label>
              <input
                value={to}
                onChange={(e) => {
                  setTo(e.target.value);
                }}
                max={moment().format().slice(0, 16)}
                type="date"
                name="to"
                id="to"
                className="form-control"
              />
            </div>

            <Button
              variant="contained"
              onClick={() => {
                fetchPosts();
              }}
              className="mb-2 ms-2 mt-2"
            >
              Search
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                setFrom('');
                setTo('');
                setReset(!reset);
              }}
              className="mb-2 ms-2 mt-2"
            >
              Reset
            </Button>
          </div>

          <table className="table  table-hover shadow">
            <thead className="table-dark">
              <tr>
                <th scope="col">Details </th>
                <th scope="col">Category</th>
                <th scope="col">Post Link</th>
              </tr>
            </thead>

            <tbody>
              {postData.length > 0 &&
                postData.map((e, i) => {
                  return (
                    <tr key={e._id} className={isActive == i ? 'active' : ''}>
                      <td onClick={() => updateServicePost(e, i)}>
                        <div
                          className="d-flex border-2 border-primary text-start"
                          style={{ width: '15vw' }}
                        >
                          <img
                            src={e.profilePic}
                            className="profile-img"
                            alt=""
                          />
                          <div>
                            <h5>{e.name}</h5>
                            <h6>{e.phone}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="subcategory">
                          {e.subCategory ? e.subCategory : 'Not Available'}
                        </h5>
                        <h5 className="location">
                          {e.placeName ? e.placeName : 'Not Available'}
                        </h5>
                      </td>
                      <td>
                        <a
                          href={`https://www.spaarksweb.com/spaark/${
                            e.shareLink.split('/')[5]
                          }`}
                          target="_blank"
                        >
                          Post Link
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="right border-danger border-3 d-flex flex-column ">
          <div
            className="d-flex flex-column overflow-scroll"
            style={{ height: '98%', width: '90%' }}
          >
            <table className="table table-hover">
              <thead className="table-dark">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Profile Link</th>
                  <th colSpan={2}>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading && 'Loading...'}
                {serviceProviders.length > 0 &&
                  serviceProviders.map((ele, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <div className="d-flex border-2 border-primary text-start ">
                            <img
                              src={ele.profilePic}
                              className="profile-img"
                            />
                            <div>
                              <h5
                                style={{
                                  maxWidth: '140px',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {ele.name}
                              </h5>
                              <span className='d-flex flex-row' onClick={()=>copyText(ele.phone)}>
                              <p className="me-1">{ele.phone}</p>
                              <ContentCopyIcon />
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href={`https://www.spaarksweb.com/user/${ele.username}#market`}
                            target="_blank"
                            className="text-center"
                          >
                            {ele.username ? 'Profile' : ''}
                          </a>
                          <br />
                          <div
                            className={
                              ele.isSpaarksUser ? 'spaarkUser' : 'nonSpaarkUser'
                            }
                          >
                            {ele.isSpaarksUser ? 'spaarks' : 'non-spaarks'}
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) => addtoList(e, ele)}
                              id="flexCheckDefault"
                            />
                          </div>
                        </td>
                        {/* <td><button }>copy phone Number</button></td> */}
                        <td>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            onChange={(event) => updateCallStatus(event, ele._id)}
                          >
                            <option value="connected">connected</option>
                            <option value="notConnected">not-connected</option>
                            <option value="busy">busy</option>
                            <option value="switchOff">switch off</option>
                            <option selected value="notContacted">not contacted</option>

                          </select>
                        </td>
                      </tr>
                    );
                  })}

                {/* {uploadedData && JSON.stringify(uploadedData)}   */}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end me-5 mt-2 ">
            {serviceProviders.length < 10 && (
              <>
                <div className="d-flex flex-column">
                  <label for="myfile">Upload csv:</label>
                  <input
                    type="file"
                    ref={uploadRef}
                    onChange={upload}
                    id="myfile"
                    name="myfile"
                  ></input>
                </div>
              </>
            )}
            <Button
              id="send"
              variant="contained"
              className="nextPage"
              onClick={nextPage}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </WizardContainer>
  );
};

export default Wizard;
