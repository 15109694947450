import styled from 'styled-components';

const ShowListingContainer = styled.div`

background-color: #f8f9fe;
  padding:10px;
  font-size: 12px;
  width: 100%;
  .carousel img {
    height: 220px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.carousel-item {
  transition: transform 0.5s ease-in-out;
}
.d-text{
  font-size:30px
}
img{
  max-width:100%
}
`;

export default ShowListingContainer;
