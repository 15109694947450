import styled from 'styled-components';

const SideNavBarContainer = styled.div`
  position: sticky;
  top: 0px;
  height: 100vh;

  .sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    background-color: white;
    width: 15vw;

    .top {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        font-size: 20px;
        font-weight: bold;
        color: #6439ff;

        .img-logo {
          height: 50px;
          width: 100%;
        }
      }
    }

    hr {
      height: 0;
      border: 0.5px solid rgb(230, 227, 227);
    }

    .center {
      padding-left: 10px;

      .scroll {
        height: 80vh;
        overflow-y: scroll;
      }

      .scroll::-webkit-scrollbar {
        display: none;
      }

      .scroll {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .title {
          font-size: 10px;
          font-weight: bold;
          color: #999;
          margin-top: 15px;
          margin-bottom: 5px;
          text-align: -webkit-left;
        }

        li {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background-color: #ece8ff;
          }

          .icon {
            font-size: 18px;
            color: #7451f8;
          }

          span {
            font-size: 13px;
            font-weight: 600;
            color: #888;
            margin-left: 10px;
          }
        }
      }
    }

    .active {
      background-color: #ece8ff;
    }

    .bottom {
      display: flex;
      align-items: center;
      margin: 10px;

      .colorOption {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #7451f8;
        cursor: pointer;
        margin: 5px;

        &:nth-child(1) {
          background-color: whitesmoke;
        }
        &:nth-child(2) {
          background-color: #333;
        }
        &:nth-child(3) {
          background-color: darkblue;
        }
      }
    }
  }
`;

export default SideNavBarContainer;
