import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginContainer from './Login.js';
import axios from 'axios';
import { HEADER_URL } from '../../api/constants.js';
import { useNavigate } from 'react-router-dom';
import { signin } from '../../store/authSlice.js';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loginState, setLogin] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const login = async (e) => {
    e.preventDefault();

    const dataX = {
      username: email,
      password,
    };

    
    await axios({
      method: 'post',
      url: `${HEADER_URL}/auth/signin`,
      data: JSON.stringify(dataX),
      credentials: "include",
      withCredentials:true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          alert('Please check your credentials !!');
        }
        const data = {
          token: res.data?.token,
          user: res.data?.user,
        };
        localStorage.setItem('role',res.data.role);
        localStorage.setItem('token', data.token);



        dispatch(signin(data));
        setLogin(true);
      })
      .catch((err) => {
        alert('Please check your credentials !!');
        console.log('err', err);
      });
  };

  useEffect(() => {
    if(localStorage.getItem('role')=='admin'){
      navigate('/admin')
    }
    else if(localStorage.getItem('role')=='analysisuser'){
      console.log('true')
      navigate('/analysis')
    }
    else if(localStorage.getItem('role')=='superuser'){
      navigate('/superuser')
    }
    else if(localStorage.getItem('role')=='emp'){
      navigate('/employee')
    }
    else if(localStorage.getItem('role')=='marketing'){
      navigate('/marketing')
    }
    else if(localStorage.getItem('role')=='wizard'){
      navigate('/wizard')
    }
    
  }, [loginState]);



  if (!loginState) {
    return (
      <>
        <LoginContainer>
          <section
            className="background-radial-gradient overflow-hidden"
            style={{ paddingTop: '80px', height: '100vh' }}
          >
            <div>
              <img src="OSOS logo SVG.svg" className="logo-img" alt="" />
            </div>
            <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
              <div className="row gx-lg-5 align-items-center mb-5">
                <div
                  className="col-lg-6 mb-5 mb-lg-0"
                  style={{
                    zIndex: '10',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <h1
                    className="my-5 display-5 fw-bold ls-tight"
                    style={{
                      color: 'hsl(218, 81%, 85%)',
                      position: 'relative',
                      top: '-40px',
                    }}
                  >
                    Welcome! <br />
                    <span style={{ color: 'hsl(218, 81%, 85%)' }}></span>
                  </h1>

                  <p
                    className="mb-4 opacity-70"
                    style={{ color: 'hsl(218, 81%, 85%)' }}
                  ></p>
                </div>

                <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                  <div
                    id="radius-shape-1"
                    className="position-absolute rounded-circle shadow-5-strong"
                  ></div>
                  <div
                    id="radius-shape-2"
                    className="position-absolute shadow-5-strong"
                  ></div>

                  <div className="card bg-glass">
                    <div className="card-body px-4 py-5 px-md-5">
                      <form onSubmit={login}>
                        <div className="form-outline mb-4 mt-5">
                          <input
                            type="email"
                            id="form3Example3"
                            className="form-control"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="form-outline mb-4 mt-5">
                          <input
                            type="password"
                            id="form3Example4"
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                        <div className="btn-container">
                          <button
                            type="submit"
                            className="btn btn-blue btn-block mb-4 login-btn"
                          >
                            Sign up
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LoginContainer>
      </>
    );
  }
  //  else {
  //   if(localStorage.getItem('role')=='admin'){
  //     navigate('/admin/home')
  //   }
  //   else if(localStorage.getItem('role')=='analysisuser'){
  //   navigate('/analysis/home');
  //   }
  // }
}

export default Login;