import UserCardContainer from "./UserCard";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Switch from '@mui/material/Switch';
function UserCard({data }){

  const navigate=useNavigate()
  const [val,setVal]=useState('on')
  console.log(val)
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  console.log("val",val)
    return <UserCardContainer>

        <div className="card position-relative"  >
        {/* <Switch {...label} value={val} onChange={(e)=>{setVal(e.target.value)}} /> */}

            <div className="card-header d-flex justify-content-center">
                <img style={{borderRadius:'50%'}} src={data.gender=='Male'?'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvFUdgtpdkz1qJxkCKkYjNiTeb5qqdioX7ZA&usqp=CAU':'https://icons.iconarchive.com/icons/thesquid.ink/free-flat-sample/512/support-icon.png'} width="80px" height='80px' alt="" />
            </div>
            <div className="card-body d-flex flex-column gap-3">
            <div className="emp-details d-flex align-items-center ">
                   <div className="side-heading" >Employee Name : </div>
                     <div className="content  ms-2">{data.name}</div>
                 </div>
                 <div className="emp-details d-flex align-items-center ">
                   <div className="side-heading" >Username : </div>
                     <div className="content  ms-2">{data.username}</div>
                 </div>
                 <div className="emp-details d-flex align-items-center ">
                   <div className="side-heading" >Phone number: </div>
                     <div className="content  ms-2">{data.phone}</div>
                 </div>
                 <div className="emp-details d-flex align-items-center ">
                   <div className="side-heading" >Email : </div>
                     <div className="content  ms-2">{data.email}</div>
                 </div>
                

            <Button onClick={()=>{
            navigate('/admin/open-ticket/emp',{state:{eid:data._id,unauthorized:data.unauthorized}})
        }} variant="contained">View More</Button>
            </div>
        </div>


    </UserCardContainer>
}



export default UserCard;