import JobsContainer from "./Jobs";

//Imports related to api calls
import { HEADER_URL } from '../../../api/constants.js';
import { useSelector } from "react-redux";
import { useEffect, useState,useRef } from "react";
import axios from 'axios';
// var jobsUrl=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`
// var `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`
// const rewardUrl=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/superspaark/give-scratchcard`


//Modals
    //Modal for published jobs
    import PublishedModal from "./PublishedModal/PublishedModal";

    //modal for unpublished jobs
    import UnPublishedModal from "./UnPublishedModal/UnPublishedModal";

    //Modal for Rejected and expired jobs
    import ReAcceptedModal from "./ReAcceptModal/ReAcceptModal";

//Time related
import moment from 'moment/moment';

//Mui Imports
import * as React from 'react';
import Button from '@mui/material/Button';
import RewardModal from '../RewardModal/RewardModal'

//Infinite scroll import
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@mui/material/CircularProgress';


//Input Related imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//react Icons
import { AiOutlineUser,AiFillPhone } from "react-icons/ai";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Jobs(){

//Infinite Scroll and Data values
        const[hasMore,setHasMore]=useState(true)
        const [data,setData]=useState([])
        const [maxLength,setMaxLength]=useState(0)
        const [page,setPage]=useState(1)
        const [source,setSource]=useState('All')
//Reward Related
const [rewardModal,setRewardModal]=useState(false)


//Auth related
        const TOKEN = useSelector((state) => state.auth.token);

//Fetching Data
        const [currStatus,setCurrStatus]=useState('published')
        var getJobsData=async()=>{

            await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`+currStatus+'?page='+page+'&source='+source,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },  
            })
            .then((res)=>{
                res.data.jobs.length<20?setHasMore(false):setHasMore(true)
                setData([...data,...res.data.jobs])
                setPage(page+1)
            //     if(data.length==0)
            //         setMaxLength(res.data.jobs.length)
            //    if(res.data.jobs.length<maxLength || res.data.jobs.length==0){
            //     setHasMore(false)
            //     console.log('false')
            //    }
            //    if(page==1){
            //     setData(res.data.jobs)
            //    }
            //    else
            //       setData([...data,...res.data.jobs])
            })
            .catch((err)=>{
                alert(`error occured ${err.message}`)
                console.log(err)
            })
        }
        
//Modal variables
        const [currEle,setCurrentEle]=useState( {
            user: {
              name: "",
              profilePic: "",
              phone: "",
              _id: ""
            },
            location_map: {
              type: "Point",
              coordinates: [
                78.4410784,
                17.4137358
              ]
            },
            requirements: {
              resume: false,
              email: false
            },
            source: "telecaller",
            skills: [
              "ng"
            ],
            views: [],
            isPublished: true,
            isRejected: false,
            reason: "",
            _id: "643fdc6b6256e97afa981d96",
            category: " Crane operator",
            expired: false,
            title: " Crane operator",

            description: "",
            lastDate: "2023-05-19T12:40:53.848Z",
           
            createdAt: "2023-04-19T12:19:56.002Z",
            updatedAt: "2023-04-19T12:40:53.850Z",
            __v: 0,
            publishedAt: "2023-04-19T12:40:53.841Z"
          })
          const [currIdx,setCurrIdx]=useState(-1)
        const [modalOpen,setModalOpen]=useState(false)
        const [childOpen,setChildOpen]=useState(false)
        
const [reason,setReason]=useState('')
//Reject 

        var handleReject = async()=> {
            console.log('reject')
            console.log(reason)
            await axios.post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`+'reject',{jobId:data[currIdx]._id,reason:reason},{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }, 
            })
            .then((res)=>{
                if(res.data.message=='success'){
                    var temp=data
                    temp.splice(currIdx,1)
                    setData([...temp])
                    console.log(temp)
                }
            })
            .catch((e)=>{
                alert(`error occured ${e.message}`)
            })

            setReason('')
        }


//Approve
        var handleApprove=async()=>{
            console.log("approve")
            await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`+'accept/'+data[currIdx]._id,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }, 
            })
            .then((res)=>{
                if(res.data.message=='success'){
                    var temp=data
                    temp.splice(currIdx,1)
                    setData([...temp])
                    console.log(temp)
                }
            })
            .catch((e)=>{
                alert(`error occured ${e.message}`)
            })

            setReason('')
        }


//republish

        var handleReAccept=async()=>{
            await axios.post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`+'reaccept',{jobId:data[currIdx]._id,reason:reason},{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }, 
            })
            .then((res)=>{
                if(res.data.message=='success'){
                    var temp=data
                    temp.splice(currIdx,1)
                    setData([...temp])
                    console.log(temp)
                }
            })
            .catch((e)=>{
                alert(`error occured ${e.message}`)
            })

            setReason('')
        }
    

//expire

        var handleExpire=async()=>{
            console.log("InExpire")
            await axios.post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/jobs/`+'expire/'+data[currIdx]._id,{reason:reason},{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },   
            })
            .then((res)=>{
                console.log(res.data)
                if(res.data.message=="This users Job's been expired."){
                    var temp=data
                    temp.splice(currIdx,1)
                    setData([...temp])
                    console.log(temp)
                }
            })
            .catch((e)=>{
                alert(`error occured ${e.message}`)
            })

            setReason('')
        }


//Scratch Card

const sendReward = async(amount)=>{
     
    console.log("amount",amount)

    await axios
    .post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/superspaark/give-scratchcard`,{
      type:'jobReward',
      name:currEle.user.name,
      amount:amount,
      title:'Jon reward',
      body:`you are rewarded with amount ${amount}`,
      jobId:currEle._id,
      userId:currEle.user._id
    }, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },   
    })
    .then((res) => {
      if(res.data.success=='Scratch card added successfuly'){
        currEle.didRewardGet=true
        var temp=data;
        temp.splice(currIdx,1,currEle)
        setData([...temp])
      }
    })
    .catch((err) => {
      console.log('err', err);
      alert('something went wrong!');
    });
  };

//UseEffect        
        useEffect(()=>{
            getJobsData()
        },[currStatus,maxLength])







    return <JobsContainer>
<div className="d-flex justify-content-around">
    <div className="div">  <Button variant={currStatus=='published'?"contained":"outlined"} onClick={()=>
        {
        if(currStatus!='published'){
        setData([]);
        setPage(1);
        }
        setCurrStatus('published')}} >Published</Button></div>
    <div className="div">  <Button variant={currStatus=='unpublished'?"contained":"outlined"} onClick={()=>{
           if(currStatus!='unpublished'){
            setData([]);
            setPage(1);
        }
            setCurrStatus('unpublished')
        }} >Un-Published</Button></div>
    <div className="div">  <Button variant={currStatus=='rejected'?"contained":"outlined"} onClick={()=>{
           if(currStatus!='rejected'){
            setData([]);
            setPage(1);
            }
            setCurrStatus('rejected')
    }} >Rejected</Button></div>
    <div className="div">  <Button variant={currStatus=='expired'?"contained":"outlined"} onClick={()=>{
           if(currStatus!='expired'){
            setData([]);
            setPage(1);}
            setCurrStatus('expired')
    }} >Expired</Button></div>
</div>

<div className="d-flex justify-content-center gap-5 mt-5 align-items-end">
    <div>
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Source</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={source}
          onChange={(e)=>{setSource(e.target.value);}}
          label="Source"
        > 
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"User"}>User</MenuItem>
          <MenuItem value={"TeleCaller"}>TeleCaller</MenuItem>
        </Select>
      </FormControl>
    </div>
    <Button variant="contained" onClick={()=>{setData([]);setPage(1);setMaxLength(1+maxLength)}}>Search</Button>
</div>



{/* Infinite Scroll Component */}

<InfiniteScroll
dataLength={data.length!=0 && data.length}
style={{ display: 'flex', flexWrap:'wrap',justifyContent:'start',marginTop:'40px',gap:30,paddingLeft:'40px' }}
next={()=>{ getJobsData() }}
hasMore={hasMore}
loader={<div className="d-flex justify-content-center"><div className="d-flex flex-column gap-3"><CircularProgress />LOADING . . .</div></div>}
>

{
    data.map((ele,idx)=>{

        if(currStatus=='published'){
        return <div className="card" style={{width:'300px'}} key={idx}>

            <div className="card-body">
                {/* title */}
                <h4 className='text-primary' >{ele.category}</h4>

                {/* Name anf phone number */}

                <div className="d-flex justify-content-around align-items-center">
                    <div ><AiOutlineUser/><span className='text-secondary'>{"  "}{ele.user.name}</span> </div>
                    <div><AiFillPhone/><span className='text-secondary'>{"  "}{ele.user.phone}</span></div>
                </div>

                {/* published At */}
                <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                    <div className='text-dark font-weight-bold' >Published On : </div>
                    <div className=''>{moment(ele.publishedAt).format('lll')}</div>
                </div>

                {/* Last date */}

                <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                    <div className='text-dark font-weight-bold' >Last Date : </div>
                    <div className=''>{moment(ele.lastDate).format('lll')}</div>
                </div>

                {/* Salary */}
                <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                    <div className='text-dark font-weight-bold' >Salary : </div>
                    <div className='font-weight-bold text-success' style={{fontSize:'16px'}}><span>₹ </span>{ele.salary}</div>
                </div>

                {/* Skills */}
                <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                    <div className='text-dark font-weight-bold' >Skills  </div>
                    <div className='d-flex flex-wrap gap-1 align-items-end'>
                            {
                                ele.skills.map((skill,skillIdx)=>{
                                    if(skillIdx<5){
                                    return <div className="skill" key={skillIdx}>
                                           { skill}
                                    </div>
                                    }
                                })

                            }
                            {ele.skills.length>5&& <div className="ms-2" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}  style={{cursor:'pointer',textDecoration:'underline',color:'blue'}}>View More</div> }
                    </div>
                </div>

                {/* Description */}

                <div className="d-flex justify-content-start gap-2 align-items-start ms-3 mt-2">
                    <div className='text-dark font-weight-bold' >Description: </div>
                    <div style={{overflowWrap:'anywhere',textAlign:'start'}}>
                        {ele.description.slice(0,90)}
                        {ele.description.length>90 && <span className="ms-2" style={{cursor:'pointer',color:'blue'}} onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>......</span> }
                    </div>
                    
                </div>
            </div>
            <div className="card-footer d-flex justify-content-around">
              
           {ele.didRewardGet==true&&ele.source=='user' && <Button variant="contained" disabled color="success">Reward</Button>}
            {ele.didRewardGet==false&&ele.source=='user' && <Button variant="contained" color="success" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setRewardModal(true)}}>Reward</Button>}
              <Button variant="outlined" color="error" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>Expire</Button>
            </div>
        </div>
        }   
        else if(currStatus=='unpublished'){
            return <div className="card" style={{width:'300px'}} key={idx}>
    
                <div className="card-body">
                    {/* title */}
                    <h4 className='text-primary' >{ele.category}</h4>
    
                    {/* Name anf phone number */}
    
                    <div className="d-flex justify-content-around align-items-center">
                        <div ><AiOutlineUser/><span className='text-secondary'>{"  "}{ele.user.name}</span> </div>
                        <div><AiFillPhone/><span className='text-secondary'>{"  "}{ele.user.phone}</span></div>
                    </div>    
                    {/* Salary */}
                    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Salary : </div>
                        <div className='font-weight-bold text-success' style={{fontSize:'16px'}}><span>₹ </span>{ele.salary}</div>
                    </div>
    
                    {/* Skills */}
                    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Skills  </div>
                        <div className='d-flex flex-wrap gap-1 align-items-end'>
                                {
                                    ele.skills.map((skill,skillIdx)=>{
                                        if(skillIdx<5){
                                        return <div className="skill" key={skillIdx}>
                                               { skill}
                                        </div>
                                        }
                                    })
    
                                }
                                {ele.skills.length>5&& <div className="ms-2" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}  style={{cursor:'pointer',textDecoration:'underline',color:'blue'}}>View More</div> }
                        </div>
                    </div>
    
                    {/* Description */}
    
                    <div className="d-flex justify-content-start gap-2 align-items-start ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Description: </div>
                        <div style={{overflowWrap:'anywhere',textAlign:'start'}}>
                            {ele.description.slice(0,90)}
                            {ele.description.length>90 && <span className="ms-2" style={{cursor:'pointer',color:'blue'}} onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>......</span> }
                        </div>
                        
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-around">
                <Button variant="contained" color="success" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);handleApprove()}}>Approve</Button>
                  <Button variant="outlined" color="error" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>Reject</Button>
                </div>
            </div>
            }   
        else if(currStatus=='expired'){
            return <div className="card" style={{width:'300px'}} key={idx}>
    
                <div className="card-body">
                    {/* title */}
                    <h4 className='text-primary' >{ele.category}</h4>
    
                    {/* Name anf phone number */}
    
                    <div className="d-flex justify-content-around align-items-center">
                        <div ><AiOutlineUser/><span className='text-secondary'>{"  "}{ele.user.name}</span> </div>
                        <div><AiFillPhone/><span className='text-secondary'>{"  "}{ele.user.phone}</span></div>
                    </div>    
                    {/* Salary */}
                    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Salary : </div>
                        <div className='font-weight-bold text-success' style={{fontSize:'16px'}}><span>₹ </span>{ele.salary}</div>
                    </div>
    
                    {/* Skills */}
                    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Skills  </div>
                        <div className='d-flex flex-wrap gap-1 align-items-end'>
                                {
                                    ele.skills.map((skill,skillIdx)=>{
                                        if(skillIdx<5){
                                        return <div className="skill" key={skillIdx}>
                                               { skill}
                                        </div>
                                        }
                                    })
    
                                }
                                {ele.skills.length>5&& <div className="ms-2" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}  style={{cursor:'pointer',textDecoration:'underline',color:'blue'}}>View More</div> }
                        </div>
                    </div>
    
                    {/* Description */}
    
                    <div className="d-flex justify-content-start gap-2 align-items-start ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Description: </div>
                        <div style={{overflowWrap:'anywhere',textAlign:'start'}}>
                            {ele.description.slice(0,90)}
                            {ele.description.length>90 && <span className="ms-2" style={{cursor:'pointer',color:'blue'}} onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>......</span> }
                        </div>
                        
                    </div>
                </div>
               
            </div>
            }
        else if(currStatus=='rejected'){
            return <div className="card" style={{width:'300px'}} key={idx}>
    
                <div className="card-body">
                    {/* title */}
                    <h4 className='text-primary' >{ele.category}</h4>
    
                    {/* Name anf phone number */}
    
                    <div className="d-flex justify-content-around align-items-center">
                        <div ><AiOutlineUser/><span className='text-secondary'>{"  "}{ele.user.name}</span> </div>
                        <div><AiFillPhone/><span className='text-secondary'>{"  "}{ele.user.phone}</span></div>
                    </div>    
                    {/* Salary */}
                    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Salary : </div>
                        <div className='font-weight-bold text-success' style={{fontSize:'16px'}}><span>₹ </span>{ele.salary}</div>
                    </div>
    
                    {/* Skills */}
                    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Skills  </div>
                        <div className='d-flex flex-wrap gap-1 align-items-end'>
                                {
                                    ele.skills.map((skill,skillIdx)=>{
                                        if(skillIdx<5){
                                        return <div className="skill" key={skillIdx}>
                                               { skill}
                                        </div>
                                        }
                                    })
    
                                }
                                {ele.skills.length>5&& <div className="ms-2" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}  style={{cursor:'pointer',textDecoration:'underline',color:'blue'}}>View More</div> }
                        </div>
                    </div>
    
                    {/* Description */}
    
                    <div className="d-flex justify-content-start gap-2 align-items-start ms-3 mt-2">
                        <div className='text-dark font-weight-bold' >Description: </div>
                        <div style={{overflowWrap:'anywhere',textAlign:'start'}}>
                            {ele.description.slice(0,90)}
                            {ele.description.length>90 && <span className="ms-2" style={{cursor:'pointer',color:'blue'}} onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>......</span> }
                        </div>
                        
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-around">
                <Button variant="contained" color="success" onClick={()=>{setCurrIdx(idx);setCurrentEle(ele);setModalOpen(true)}}>Re-Publish</Button>
         
                </div>
            </div>
            }







    })
}
</InfiniteScroll>


{/* Modals */}
{(currStatus=='expired'||currStatus=='rejected') &&  <ReAcceptedModal ele={currEle} setReason={setReason} open={modalOpen} setOpen={setModalOpen} childOpen={childOpen} setChildOpen={setChildOpen} handleReAccept={handleReAccept} status={currStatus} />}
   {currStatus=='published' &&  <PublishedModal ele={currEle} setReason={setReason} open={modalOpen} setOpen={setModalOpen} childOpen={childOpen} setChildOpen={setChildOpen} handleExpire={handleExpire}  />}
   {currStatus=='unpublished' &&  <UnPublishedModal ele={currEle} setReason={setReason} open={modalOpen} setOpen={setModalOpen} childOpen={childOpen} setChildOpen={setChildOpen} handleReject={handleReject} handleApprove={handleApprove}  />}
   
   <RewardModal open={rewardModal} setOpen={setRewardModal} handleReward={sendReward} />
    </JobsContainer>

}   



export default Jobs