
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import { useState } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function RewardModal({open,setOpen,handleReward}){
    const [amount,setAmount]=useState(25)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
  
    return  <div>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>


        <h6 className="text-primary">Enter Amount</h6>

        <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">₹</span>
            <input type="number" value={amount} onChange={(e)=>{setAmount(parseInt(e.target.value))}} className="form-control" placeholder="Enter Amount" min="1"/>
        </div>
        {amount<=0&& <p className="text-danger">* Enter Valid Amount</p>}
        <div className="d-flex w-100 justify-content-end">
        
           {amount>0
            &&<Button variant="contained"  color="success"  onClick={()=>{
            handleReward(amount)
            handleClose()
            }
            } >Send Reward</Button>}
            {amount<=0
            &&<Button variant="contained"  color="success"  disabled >Send Reward</Button>}
        </div>

      </Box>
    </Modal>
  </div>
}

export default RewardModal;