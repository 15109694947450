import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    maxHeight:'90vh',
    overflowY:'scroll',
    boxShadow: 24,
    p: 4,
  };
  







function ReplyModal({open,setOpen,setMsg,setPics,msg,pics,sendMsg})
{
    const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

          
  

  var handleImagesChange=(e)=>{
    var temp=pics
    for(var i=0;i<e.target.files.length;i++){
        if(temp.length<4){
            temp.push(e.target.files[i])
        }
        else{
            break;
        }
    }
    setPics([...temp])
    
}
var handleDelete=(idx)=>{
  var temp=pics
  temp.splice(idx,1)
  setPics([...temp])
}

    return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
     <p className="lead text-primary">Reply to Thread</p>

     <div >
            <div  className="form-label">Content </div>
            <textarea className="form-control"rows="7"  value={msg} onChange={(e)=>{setMsg(e.target.value)}}></textarea>
        </div>

        <div >
        <label htmlFor="formFileMultiple" className="form-label">Photos</label>
        <input className="form-control" onChange={(e)=>{handleImagesChange(e)}} type="file" id="formFileMultiple" multiple />
        </div>



        <div className="d-flex justify-content-around mt-2 flex-wrap">
          {
            pics.map((image,imageIdx)=>{
              return  <div className='d-flex flex-column  ' >
              <img src={URL.createObjectURL(image)} key={imageIdx}  height={100} width="auto" alt="" />
              <IconButton aria-label="delete" onClick={()=>{handleDelete(imageIdx)}}>
                <DeleteIcon />
              </IconButton>
              </div>
            })
          }
        </div>
       
        <div className='d-flex mt-3 justify-content-end gap-2 align-items-center '>
         <div>⚠️This will send a notification to the user⚠️</div> <Button variant="contained" onClick={()=>{handleClose();sendMsg()}}>Send</Button>
        </div>
    </Box>
  </Modal>
}


export default ReplyModal