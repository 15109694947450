import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';
import * as L from 'leaflet';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import moment from 'moment/moment';
import Container from './map';
import { HEADER_URL } from '../../../api/constants';
import { formatDate, lastSeenAt } from '../../../utils/formatDate.js';
import Chooser from '../Charts/Chooser/Chooser';
import CircularProgress from '@mui/material/CircularProgress';

const options = ['Jobs', 'Users', 'Listings', 'Posts', 'Products', 'Stories'];

const DynamicPopup = ({ item, type }) => {
  if (type === 'Users') {
    return (
      <>
        <div className="flex">
          <div className="img">
            <img src={item.user.profilePic} alt="profile" />
          </div>
          <div>
            <p>{item.user.name}</p>
          </div>
        </div>
        <div>
          <p className="last-seen-pill">
            Last seen {formatDate(item.updatedAt)}
          </p>
        </div>
      </>
    );
  }

  if (type === 'Jobs') {
    return (
      <>
        <div>
          <p className="last-seen-pill">Created {lastSeenAt(item.createdAt)}</p>
        </div>
      </>
    );
  }

  if (type === 'Listings') {
    return (
      <>
        <div>
          <p className="last-seen-pill">Created {lastSeenAt(item.createdAt)}</p>
        </div>
      </>
    );
  }

  if (type === 'Products') {
    return (
      <>
        <div>
          <p className="last-seen-pill">Created {lastSeenAt(item.createdAt)}</p>
        </div>
      </>
    );
  }

  if (type === 'Posts') {
    return (
      <>
        <div>
          <p className="last-seen-pill">Created {lastSeenAt(item.createdAt)}</p>
        </div>
      </>
    );
  }
  if (type === 'Stories') {
    return (
      <>
        <div>
          <p className="last-seen-pill">Created {lastSeenAt(item.createdAt)}</p>
        </div>
      </>
    );
  }
};

const Title = ({ type, count }) => {
  let title = '';

  if (type === 'Users') {
    title = 'User Activity Report Today';
  }

  if (type === 'Jobs') {
    title = 'Todays Created Jobs';
  }

  if (type === 'Listings') {
    title = 'Todays Created Listings';
  }

  if (type === 'Products') {
    title = 'Todays Created Products';
  }

  if (type === 'Posts') {
    title = 'Today Created Posts';
  }
  if (type === 'Stories') {
    title = 'Today Created Stories';
  }

  return (
    <>
      <h1>{`${title} (${count})`}</h1>
    </>
  );
};

//TODO center correctly

function Map() {
  const TOKEN = useSelector((store) => store.auth.token);
  const [page, setPage] = useState(1);
  const [date, setDate] = useState(moment(Date.now()));
  const [mapData, setMapData] = useState([]);
  const [initialLatLong, setInitialLatLong] = useState([17.385, 78.4867]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState('Users');
  const LeafIcon = L.Icon.extend({
    options: {
    iconSize: [20, 30]
    },
  });
  
  const greenIcon = new LeafIcon({
    iconUrl: `${process.env.PUBLIC_URL}/leafletMarkers/marker-icon-green.png`,
  });
  const blueIcon = new LeafIcon({
    iconUrl: `${process.env.PUBLIC_URL}/leafletMarkers/marker-icon-blue.png`,
  });
  const getData = async (newDate) => {
    if (newDate) {
      setDate(newDate);
      setMapData([]);
      setPage(1);
    }
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/recentusage?page=${page}&date=${
      newDate ? moment(newDate).format('L') : moment(date).format('L')
    }&type=${option}`;
    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        // console.log("DATATATATA", res.data.count);
        // alert(page);

        if (page === 1) {
          if (res.data.ta.length > 1) {
            setInitialLatLong([
              res.data.ta[0].location.coordinates[0],
              res.data.ta[0].location.coordinates[1],
            ]);
          }

          setCount(res.data.count);
        }

        setMapData(mapData.concat(res.data.ta));

        if (res.data.ta.length !== 0) {
          setPage(page + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('ERR', err);
      });
  };

  console.log('MAPDATA', mapData);

  const getOptionFromChild = (option) => {
    setMapData([]);
    setPage(1);
    setOption(option.value);
  };

  useEffect(() => {
    getData();
  }, [page, option]);

  return (
    <Container>
      <div className="container">
        <Title type={option} count={count} />
      </div>
      <div className="date">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Date Desktop"
            inputFormat="DD/MM/YYYY"
            value={date}
            onChange={getData}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Chooser options={options} getOptionFromChild={getOptionFromChild} />
      </div>

      <MapContainer
        center={initialLatLong && [initialLatLong[0], initialLatLong[1]]}
        zoom={13}
        scrollWheelZoom={false}
        className="map-container"
      >
        {loading && <CircularProgress sx={{ margin: 'auto atuo' }} />}
        {!loading && (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        )}
        {mapData &&
          mapData.map((item) => {
            let extraProps = {};

            if (item.fromCRM) {
              extraProps = {
                icon: greenIcon,
              };
            } else {
              extraProps = {
                icon:blueIcon,
              }
            }
            return (
              <Marker
                {...extraProps}
                position={[
                  item.location.coordinates[1],
                  item.location.coordinates[0],
                ]}
                style={{
                  fontSize: '5px',
                }}
              >
                <Popup>
                  <DynamicPopup item={item} type={option} key={item} />
                </Popup>
              </Marker>
            );
          })}
      </MapContainer>
    </Container>
  );
}

export default Map;
