// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const icons = {
    cart: <ShoppingCartOutlinedIcon
    className="icon"
    style={{
      backgroundColor: "rgba(218, 165, 32, 0.2)",
      color: "goldenrod",
      }}
  />,
  
    users:<AccountBalanceWalletOutlinedIcon
    className="icon"
    style={{
      backgroundColor: "rgba(128, 0, 128, 0.2)",
      color: "purple",
    }}
  />,
    
    monetize: <MonetizationOnOutlinedIcon
    className="icon"
    style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
  />,
    
    active: <PersonOutlinedIcon
    className="icon"
    style={{
      color: "crimson",
      backgroundColor: "rgba(255, 0, 0, 0.2)",
    }}
  />,
    
    group:<PeopleAltOutlinedIcon
    className="icon"
    style={{
      color: "blue",
      backgroundColor: "rgb(221, 230, 255)",
    }} />,

    thumb: <ThumbUpOffAltIcon
    className="icon"
    style={{
      color: "blue",
      backgroundColor: "rgb(221, 230, 255)",
      fontSize:"30px"
    }} />,
    pending: <HourglassEmptyIcon
    className="icon"
    style={{
      backgroundColor: "rgba(218, 165, 32, 0.2)",
      color: "goldenrod",
      fontSize:"30px"
    }} />,
    processed: <DoneIcon
    className="icon"
    style={{
      color: "green",
      backgroundColor: "rgba(0, 128, 0, 0.2)",
      fontSize:"30px"
    }} />,
    initiated: <RestartAltIcon
    className="icon"
    style={{
      color: "crimson",
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      fontSize:"30px"
    }} />,
    
}
  
export default icons;