import SideNavBarSideNavBarContainer from './SideNavBar';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ImageIcon from '@mui/icons-material/Image';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import CableIcon from '@mui/icons-material/Cable';
// import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/authSlice.js';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import * as React from 'react';
import { MobileFriendlyOutlined } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PushPinIcon from '@mui/icons-material/PushPin';
import SideNavBarContainer from './SideNavBar';
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import { useSelector } from 'react-redux';

// import { useNavigate } from "react-router-dom";
const KEY = 'current_active_index';

function SideNavBar() {
  const TOKEN = useSelector((state) => state.auth.token);
  const [logoutState, setLogout] = useState(false);
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const location = useLocation();
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const logoutMe = async () => {
    setLogout(true);
    await axios
      .get(`${HEADER_URL}/auth/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if ((res.data.message = 'logged Out')) {
          dispatch(logout());
          localStorage.clear();
          console.log('loggedOut', res.data);
          navigate('/login');
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // useEffect(() => {
  //   const currentActiveIndexId = localStorage.getItem(KEY);
  //   if (currentActiveIndexId) {
  //     const activeItem = document.getElementById(currentActiveIndexId);
  //     activeItem.classList.add('active');
  //   }
  // }, [localStorage.getItem(KEY)]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.classList.add('scroll');
      const listElements = listRef.current.querySelectorAll('li');

      listElements.forEach((li) => {
        li.addEventListener('click', (e) => {
          if (li) {
            localStorage.setItem(KEY, li.id);
          }
        });
      });
    }
  }, [listRef]);

  if (localStorage.getItem('role') == 'analysisuser') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top">
            <NavLink
              className="nav-link"
              to="/analysis/home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <hr />
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="/analysis/home"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="/analysis/wizardDash"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <InsertChartIcon className="icon" />
                  <span>Wizard Dashboard</span>
                </li>
              </NavLink>

              <p className="title">LISTS</p>
              {
                <NavLink
                  className="nav-link"
                  to="/analysis/pushnotifications"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="push-notifications">
                    <NotificationsNoneIcon className="icon" />
                    <span>Push Notifications</span>
                  </li>
                </NavLink>
              }

              <NavLink
                className="nav-link"
                to="/analysis/app-notifiations"
                style={{ textDecoration: 'none' }}
              >
                <li id="in-app-notification">
                  <MobileFriendlyOutlined className="icon" />
                  <span>In App Notifications</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="/analysis/posters"
                style={{ textDecoration: 'none' }}
              >
                <li id="Posters">
                  <ImageIcon className="icon" />
                  <span>Posters</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/engagement"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <PsychologyOutlinedIcon className="icon" />
                  <span>Engagement</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/ContestGroup"
                style={{ textDecoration: 'none' }}
              >
                <li id="ContestGroup">
                  <EmojiEventsIcon className="icon" />
                  <span>ContestGroup</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/activity"
                style={{ textDecoration: 'none' }}
              >
                <li id="Activity">
                  <InsertChartIcon className="icon" />
                  <span>Activity</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/recent-usage"
                style={{ textDecoration: 'none' }}
              >
                <li id="recent-usage">
                  <LocalShippingIcon className="icon" />
                  <span>Recent Usage</span>
                </li>
              </NavLink>
              <p className="title">SUPER SPAARKS</p>
              {
                <NavLink
                  className="nav-link"
                  to="/analysis/super-spaark/latest"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="latest-posts">
                    <AllInboxIcon className="icon" />
                    <span>Latest Posts</span>
                  </li>
                </NavLink>
              }
              <NavLink
                className="nav-link"
                to="/analysis/super-spaark/live"
                style={{ textDecoration: 'none' }}
              >
                <li id="live-super-spaarks">
                  <LiveTvIcon className="icon" />
                  <span>Live Super Spaarks</span>
                </li>
              </NavLink>
              {/* <NavLink className='nav-link'
                      to="/analysis/super-spaark/groups"
                      style={{ textDecoration: 'none' }}
                    >
                      <li id="group-posts">
                        <GroupsIcon className="icon" />
                        <span>Group Posts</span>
                      </li>
                    </NavLink> */}
              <NavLink
                className="nav-link"
                to="/analysis/super-spaark/group"
                style={{ textDecoration: 'none' }}
              >
                <li id="group-posts">
                  <GroupsIcon className="icon" />
                  <span> Group Posts</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/super-spaark/pinPost"
                style={{ textDecoration: 'none' }}
              >
                <li id="pin-posts">
                  <PushPinIcon className="icon" />
                  <span>Pin Post</span>
                </li>
              </NavLink>

              <p className="title">Story</p>
              <NavLink
                className="nav-link"
                to="story"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Story</span>
                </li>
              </NavLink>
              <p className="title">Buy & sell</p>
              <NavLink
                className="nav-link"
                to="listings"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Show Listing</span>
                </li>
              </NavLink>
              <p className="title">Store</p>
              <NavLink
                className="nav-link"
                to="products"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Show products</span>
                </li>
              </NavLink>
              <p className="title">Jobs Related</p>
              <NavLink
                className="nav-link"
                to="/analysis/jobs"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Jobs</span>
                </li>
              </NavLink>
              <p className="title">REWARDS</p>
              <NavLink
                className="nav-link"
                to="/analysis/rewards"
                style={{ textDecoration: 'none' }}
              >
                <li id="Rewards">
                  <AccountBalanceWalletOutlinedIcon className="icon" />
                  <span>Rewards</span>
                </li>
              </NavLink>
              <p className="title">USER</p>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>
          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else if (localStorage.getItem('role') == 'emp') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top">
            <NavLink
              className="nav-link"
              to="home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <hr />
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="home"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="open-tickets"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Open Tickets</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="closed-tickets"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Closed Tickets</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="addlead"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Add Lead</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="myleads"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>My Lead</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="posts"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>posts</span>
                </li>
              </NavLink>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>
          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else if (localStorage.getItem('role') == 'admin') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top ">
            <NavLink
              className="nav-link"
              to="/analysis/home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <hr />
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="home"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </NavLink>

              <p className="title">LISTS</p>
              {
                <NavLink
                  className="nav-link"
                  to="pushnotifications"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="push-notifications">
                    <NotificationsNoneIcon className="icon" />
                    <span>Push Notifications</span>
                  </li>
                </NavLink>
              }
              <NavLink
                className="nav-link"
                to="app-notifiations"
                style={{ textDecoration: 'none' }}
              >
                <li id="in-app-notification">
                  <MobileFriendlyOutlined className="icon" />
                  <span>In App Notifications</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="posters"
                style={{ textDecoration: 'none' }}
              >
                <li id="Posters">
                  <ImageIcon className="icon" />
                  <span>Posters</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="engagement"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <PsychologyOutlinedIcon className="icon" />
                  <span>Engagement</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="ContestGroup"
                style={{ textDecoration: 'none' }}
              >
                <li id="ContestGroup">
                  <EmojiEventsIcon className="icon" />
                  <span>ContestGroup</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="activity"
                style={{ textDecoration: 'none' }}
              >
                <li id="Activity">
                  <InsertChartIcon className="icon" />
                  <span>Activity</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="recent-usage"
                style={{ textDecoration: 'none' }}
              >
                <li id="recent-usage">
                  <LocalShippingIcon className="icon" />
                  <span>Recent Usage</span>
                </li>
              </NavLink>
              <p className="title">TICKETS</p>
              <NavLink
                className="nav-link"
                to="open-tickets"
                style={{ textDecoration: 'none' }}
              >
                <li id="recent-usage">
                  <LocalShippingIcon className="icon" />
                  <span>Open Tickets</span>
                </li>
              </NavLink>
              <p className="title">Employee</p>
              <NavLink
                className="nav-link"
                to="employee"
                style={{ textDecoration: 'none' }}
              >
                <li id="recent-usage">
                  <LocalShippingIcon className="icon" />
                  <span>Employee</span>
                </li>
              </NavLink>
              <p className="title">SUPER SPAARKS</p>
              {
                <NavLink
                  className="nav-link"
                  to="super-spaark/latest"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="latest-posts">
                    <AllInboxIcon className="icon" />
                    <span>Latest Posts</span>
                  </li>
                </NavLink>
              }
              <NavLink
                className="nav-link"
                to="super-spaark/live"
                style={{ textDecoration: 'none' }}
              >
                <li id="live-super-spaarks">
                  <LiveTvIcon className="icon" />
                  <span>Live Super Spaarks</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="story"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Story</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="create-listing"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Create Listing</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="listings"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Show Listing</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="products"
                style={{ textDecoration: 'none' }}
              >
                <li>
                  <span>Show products</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="super-spaark/groups"
                style={{ textDecoration: 'none' }}
              >
                <li id="group-posts">
                  <GroupsIcon className="icon" />
                  <span>Group Posts</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="super-spaark/pinPost"
                style={{ textDecoration: 'none' }}
              >
                <li id="pin-posts">
                  <PushPinIcon className="icon" />
                  <span>Pin Post</span>
                </li>
              </NavLink>

              <p className="title">REWARDS</p>
              <NavLink
                className="nav-link"
                to="rewards"
                style={{ textDecoration: 'none' }}
              >
                <li id="Rewards">
                  <AccountBalanceWalletOutlinedIcon className="icon" />
                  <span>Rewards</span>
                </li>
              </NavLink>
              <p className="title">USER</p>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>
          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else if (localStorage.getItem('role') == 'ososweb') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top">
            <NavLink
              className="nav-link"
              to="/analysis/home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <hr />
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="/analysis/home"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </NavLink>

              <p className="title">LISTS</p>
              {
                <NavLink
                  className="nav-link"
                  to="/analysis/pushnotifications"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="push-notifications">
                    <NotificationsNoneIcon className="icon" />
                    <span>Push Notifications</span>
                  </li>
                </NavLink>
              }
              <NavLink
                className="nav-link"
                to="/analysis/app-notifiations"
                style={{ textDecoration: 'none' }}
              >
                <li id="in-app-notification">
                  <MobileFriendlyOutlined className="icon" />
                  <span>In App Notifications</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="/analysis/posters"
                style={{ textDecoration: 'none' }}
              >
                <li id="Posters">
                  <ImageIcon className="icon" />
                  <span>Posters</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/engagement"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <PsychologyOutlinedIcon className="icon" />
                  <span>Engagement</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/ContestGroup"
                style={{ textDecoration: 'none' }}
              >
                <li id="ContestGroup">
                  <EmojiEventsIcon className="icon" />
                  <span>ContestGroup</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/activity"
                style={{ textDecoration: 'none' }}
              >
                <li id="Activity">
                  <InsertChartIcon className="icon" />
                  <span>Activity</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/recent-usage"
                style={{ textDecoration: 'none' }}
              >
                <li id="recent-usage">
                  <LocalShippingIcon className="icon" />
                  <span>Recent Usage</span>
                </li>
              </NavLink>
              <p className="title">SUPER SPAARKS</p>
              {
                <NavLink
                  className="nav-link"
                  to="/analysis/super-spaark/latest"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="latest-posts">
                    <AllInboxIcon className="icon" />
                    <span>Latest Posts</span>
                  </li>
                </NavLink>
              }
              <NavLink
                className="nav-link"
                to="/analysis/super-spaark/live"
                style={{ textDecoration: 'none' }}
              >
                <li id="live-super-spaarks">
                  <LiveTvIcon className="icon" />
                  <span>Live Super Spaarks</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/super-spaark/groups"
                style={{ textDecoration: 'none' }}
              >
                <li id="group-posts">
                  <GroupsIcon className="icon" />
                  <span>Group Posts</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/analysis/super-spaark/pinPost"
                style={{ textDecoration: 'none' }}
              >
                <li id="pin-posts">
                  <PushPinIcon className="icon" />
                  <span>Pin Post</span>
                </li>
              </NavLink>

              <p className="title">REWARDS</p>
              <NavLink
                className="nav-link"
                to="/analysis/rewards"
                style={{ textDecoration: 'none' }}
              >
                <li id="Rewards">
                  <AccountBalanceWalletOutlinedIcon className="icon" />
                  <span>Rewards</span>
                </li>
              </NavLink>
              <p className="title">USER</p>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>
          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else if (localStorage.getItem('role') == 'superuser') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top">
            <NavLink
              className="nav-link"
              to="home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="home"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>

              </NavLink>
              <NavLink
                className="nav-link"
                to="jobsManage"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">

                  <DashboardIcon className="icon" />
                  <span>Jobs Manage </span>
                </li>
              </NavLink>
              <p className="title">TELECALLERS RELATED</p>

              <NavLink
                className="nav-link"
                to="lead-summary"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Lead Summary</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="telecaller-leads"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Telecaller Leads</span>
                </li>
              </NavLink>
              {/* startupdailog */}
              <p className="title">Startup Dailog</p>
              <NavLink
                className="nav-link"
                to="startupdailog"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Startu Dailog</span>
                </li>
              </NavLink>
              <p className="title">USER</p>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>

          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else if (localStorage.getItem('role') == 'marketing') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top">
            <NavLink
              className="nav-link"
              to="home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="home"
                style={{ textDecoration: 'none' }}
              >
                <li id="dashboard">
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </NavLink>
              <NavLink
                className="nav-link"
                to="createinfo"
                style={{ textDecoration: 'none' }}
              >
                <li id="in-app-notification">
                  <MobileFriendlyOutlined className="icon" />
                  <span>Add Post</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="scratchcard"
                style={{ textDecoration: 'none' }}
              >
                <li id="in-app-notification">
                  <AddBoxIcon className="icon" />
                  <span>Create Scratch Card</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/marketing/pinPost"
                style={{ textDecoration: 'none' }}
              >
                <li id="pin-posts">
                  <PushPinIcon className="icon" />
                  <span>Pin Post</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/marketing/ContestGroup"
                style={{ textDecoration: 'none' }}
              >
                <li id="ContestGroup">
                  <EmojiEventsIcon className="icon" />
                  <span>ContestGroup</span>
                </li>
              </NavLink>

              <p className="title">LISTS</p>
              {
                <NavLink
                  className="nav-link"
                  to="/marketing/pushnotifications"
                  style={{ textDecoration: 'none' }}
                >
                  <li id="push-notifications">
                    <NotificationsNoneIcon className="icon" />
                    <span>Push Notifications</span>
                  </li>
                </NavLink>
              }

              <NavLink
                className="nav-link"
                to="engagement"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <PsychologyOutlinedIcon className="icon" />
                  <span>Engagement</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/marketing/activity"
                style={{ textDecoration: 'none' }}
              >
                <li id="Activity">
                  <InsertChartIcon className="icon" />
                  <span>Activity</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/marketing/recent-usage"
                style={{ textDecoration: 'none' }}
              >
                <li id="recent-usage">
                  <LocalShippingIcon className="icon" />
                  <span>Recent Usage</span>
                </li>
              </NavLink>

              <p className="title">USER</p>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>

          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else if (localStorage.getItem('role') == 'wizard') {
    return (
      <SideNavBarContainer>
        <div className="sidebar">
          <div className="top">
            <NavLink
              className="nav-link"
              to="home"
              style={{ textDecoration: 'none' }}
            >
              <span className="logo">
                <img
                  src="https://www.ososweb.com/images/5fc9fd50f4dd4b8958f3c01f_ososicon.svg"
                  alt=""
                  className="img-logo"
                />
              </span>
            </NavLink>
          </div>
          <div className="center">
            <ul ref={listRef}>
              <p className="title">MAIN</p>

              <NavLink
                className="nav-link"
                to="wizardfirst"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <CableIcon className="icon" />
                  <span>Wizard</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                to="wizardAnalytics"
                style={{ textDecoration: 'none' }}
              >
                <li id="Engagement">
                  <InsertChartIcon className="icon" />
                  <span>Wizard Analytics</span>
                </li>
              </NavLink>

              <p className="title">USER</p>
              <li id="Logout" onClick={logoutMe}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </ul>
          </div>

          <div className="bottom"></div>
        </div>
      </SideNavBarContainer>
    );
  } else {
    return <h1>Konsa User hai bhai tu ? Ask Suresh Sir</h1>;
  }
}

export default SideNavBar;
