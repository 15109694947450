import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WizContainer from './WizardFirstPage';
import FirstScreenRows from './FirstScreenRows';
import moment from 'moment/moment';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import ContentModal from './ContentModal';
import Button from '@mui/material/Button';

function FirstScreen() {
  const navigate = useNavigate();
  //   {
  //     name: 'edward',
  //     content:
  //       'We are looking to welcome a UX/UI Designer  will ensure that you absolutely love each moment spent at Gharpayy. See more',
  //     category: 'Salon',
  //     location: 'delhi',
  //     serviceProviders: [],
  //     status: '',
  //   },
  //   {
  //     name: 'cg',
  //     content:
  //       'We are looking to welcome a UX/UI Designer  will ensure that you absolutely love each moment spent at Gharpayy. See more',
  //     category: 'Salon',
  //     location: 'h',
  //     serviceProviders: [],
  //     status: '',
  //   },
  //   {
  //     name: 'tg',
  //     content:
  //       'We are looking to welcome a UX/UI Designer  will ensure that you absolutely love each moment spent at Gharpayy. See more',
  //     category: 'Salon',
  //     location: 'Hyderabad',
  //     serviceProviders: [],
  //     status: '',
  //   },
  //   {
  //     name: 'og',
  //     content:
  //       'We are looking to welcome a UX/UI Designer  will ensure that you absolutely love each moment spent at Gharpayy. See more',
  //     category: 'Salon',
  //     location: 'Hyderabad',
  //     serviceProviders: [],
  //     status: '',
  //   },
  //   {
  //     name: 'eg',
  //     content:
  //       'We are looking to welcome a UX/UI Designer  will ensure that you absolutely love each moment spent at Gharpayy. See more',
  //     category: 'Salon',
  //     location: 'Hyderabad',
  //     serviceProviders: [],
  //     status: '',
  //   },
  //   {
  //     name: 'lg',
  //     content:
  //       'We are looking to welcome a UX/UI Designer  will ensure that you absolutely love each moment spent at Gharpayy. See more',
  //     category: 'Salon',
  //     location: 'Hyderabad',
  //     serviceProviders: [],
  //     status: '',
  //   },
  // ];

  const [data, setData] = useState();
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [reset, setReset] = useState(false);

  console.log('-----XXX-----------');
  console.log(data);

  const [isOpen, setIsOpen] = useState(false);
  const [currContent, setCurrContent] = useState('');

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //fetching need service posts
    async function fetchonLoad() {
      let fromCurr = moment().subtract(1, 'year').format().slice(0, 10);
      let toCurr = moment().format().slice(0, 10);

      let url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/sparkswizard/need-service-posts?`;

      if (fromCurr != '') {
        url += `from=${fromCurr}`;
      }
      if (toCurr != '') {
        url += `&to=${toCurr}`;
      }

      console.log('180: ', url);
      try {
        let res = await axios(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        console.log('_____FETCH FROM POST MODEL_______');
        console.log(res.data);
        console.log('____________');
        let settingData = res.data.data.map((ele) => {
          return {
            ...ele,
            serviceProviders: [],
          };
        });
        setData(settingData);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    }

    fetchonLoad();
  }, [reset]);

  function updateCSV(i, jsonData) {
    console.log('ooo');
    console.log('jsonData ', jsonData);
    let updatedData = [...data];
    jsonData.forEach((ele) => {
      updatedData[i].serviceProviders.push(ele);
    });
    console.log('-------updated JSON-----------');
    console.log(updatedData);
    setData(updatedData);
  }

  function updateStatus(i, bool) {
    let updatedData = [...data];
    updatedData[i].status = bool;
    console.log('-------updated status-----------');
    console.log(updatedData);
    setData(updatedData);
  }

  //fetch need service post based on dates
  function fetchPosts() {
    let url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/need-service-posts?`;
    if (from != '') {
      url += `from=${from}`;
    }
    if (to != '') {
      url += `&to=${to}`;
    }

    console.log(url);
    axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log('____________');
        console.log(res.data);
        console.log('____________');
        let settingData = res.data.data.map((ele) => {
          return {
            ...ele,
            serviceProviders: [],
          };
        });
        setData(settingData);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function nextPage() {
    const rejectedList = data.filter((ele) => ele.status == 'rejected');
    //network call to reject
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/rejectPost`;
    axios(url, {
      method: 'POST',
      data: { rejectedList },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
      });

    const nextData = data.filter((ele) => ele.status == 'accepted');
    if (nextData.length == 0) {
      alert('Please select some service Providers');
      return;
    }
    console.log(data);
    console.log('nextData', nextData);

    navigate('/wizard/wizardsecond', {
      state: { nextData },
    });
  }

  function setContent(content) {
    setCurrContent(content);
    toggleModal();
  }

  return (
    <WizContainer>
      {isOpen && (
        <ContentModal
          toggleModal={toggleModal}
          isOpen={isOpen}
          content={currContent}
        />
      )}

      <div className="container-fluid">
        <div className="row justify-content-end ">
          <div className="wiz-container ">
            <div className="d-flex mt-2 ">
              <div className="d-flex flex-column">
                {/* <label>From </label> */}
                <input
                  value={from}
                  onChange={(e) => {
                    setFrom(e.target.value);
                  }}
                  max={moment().format().slice(0, 16)}
                  type="date"
                  name="from"
                  id="from"
                  className="form-control"
                />
              </div>
              <div className="d-flex flex-column ms-2">
                {/* <label>To </label> */}
                <input
                  value={to}
                  onChange={(e) => {
                    setTo(e.target.value);
                  }}
                  max={moment().format().slice(0, 16)}
                  type="date"
                  name="to"
                  id="to"
                  className="form-control"
                />
              </div>

              <button
                className="search"
                onClick={() => {
                  fetchPosts();
                }}
              >
                Search
              </button>
              <button
                className="reset ms-3"
                onClick={() => {
                  setFrom('');
                  setTo('');
                  setReset(!reset);
                }}
              >
                Reset
              </button>
            </div>

            <div className="container-fluid ">
              <table className="mt-2 shadow rounded p-2 ">
                <thead>
                  <tr className="d-flex justify-content-between bg-dark text-light p-1 ps-3 pe-3 ">
                    <th>Details</th>
                    <th>Content</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>Upload CSV</th>
                  </tr>
                </thead>
                <tbody>
                  <div className="row">
                    <div className="d-flex flex-column overflow-controller  ">
                      {data &&
                        data.slice(0,6).map((ele, i) => (
                          <>
                            <FirstScreenRows
                              updateStatus={updateStatus}
                              updateCSV={updateCSV}
                              index={i}
                              ele={ele}
                              name={ele.name}
                              content={ele.content}
                              category={ele.category}
                              loc={ele.placeName}
                              setContent={setContent}
                              profilePic={ele.profilePic}
                            />
                          </>
                        ))}
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
          <button className="next  mb-4 animate ANIMATE" onClick={nextPage}>
            Next
          </button>
        </div>
      </div>
    </WizContainer>
  );
}

export default FirstScreen;
