import TicketDetailsContainer from "./TicketDetails";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { HEADER_URL,header } from '../../../api/constants.js';
import { useEffect,useState } from "react";
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import ReplyModal from "./ReplyModal";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';







// import { useSelector } from "react-redux";
// const TOKEN = useSelector((state) => state.auth.token);
function TicketDetails(){
    const navigate=useNavigate()
    const ticketId=useLocation().state.ticketId
    // console.log(ticketId)
    const [data,setData]=useState({})
    const [replyModal,setReplyModal] =useState(false)
    const [msg,setMsg]=useState("")
    const [pics,setPics]=useState([])
    const [emp,setEmp]=useState([])    
    const [p1,setP1]=useState('')
    const [p2,setP2]=useState("")


// FUnction to get ticket data

    var getEmpDetails=async()=>{
        await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/getemp`,{method: 'GET', headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${localStorage.getItem('token')}`,}})
        .then((res)=>{setEmp([{'_id':'0','name':''},...res.data.emp])})
        .catch((err)=>{console.log(err)})
    }

    var updateSupport=async()=>{
     
        var obj={}
        if(p1!=''){
            obj.p1={}
            obj.p1.name=emp[p1].name
            obj.p1.empId=emp[p1]._id
        }   
        if(p2!=''){
            obj.p2={}
            obj.p2.name=emp[p2].name    
            obj.p2.empId=emp[p2]._id
        }
        console.log(obj)
        await axios.put(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/ticket/updatesupport/`+ticketId,obj,{method: 'PUT', headers: { 'Content-Type': 'application/json',Authorization: `Bearer ${localStorage.getItem('token')}`,}})
        .then((res)=>{alert(res.data.message)})
        .catch((err)=>{console.log(err)})
    
    
    }


    var sendMsg=async()=>{
       if(msg.trim().length==0){
        alert("enter some text")
        return
       }
       var formData=new FormData()
       formData.append("content",msg)
       pics.forEach(ele=>formData.append("photo",ele))
       formData.append("name",data.name)
       await axios.post(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/tickets/`+ticketId,formData ,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }})
       .then((res)=>{
        var temp=data
        var userDetails=data.details.userId
        temp.details=res.data.ticket
        temp.details.userId=userDetails
        temp.details.thread=  temp.details.thread.reverse()
console.log("temp",temp)
        setData({...temp})   
     
       })
       .catch((err)=>{
        console.log(err.message)
       })

    }

    var getTicketData=async()=>{
        await axios.get(`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/tickets/`+ticketId, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then((res)=>{
            setData(res.data)
            console.log("get ticket data",res.data)
         
        })
        .catch((err)=>{
            console.log('err',err)
            alert(err.message)
        })
    }

useEffect(()=>{
    if(localStorage.getItem('role')=='admin')
    {
    getEmpDetails()
    }
    getTicketData()
},[])

if(p1=='' && data&&data.details&&data.details.p1 ){
    for(var i=0;i<emp.length;i++)
    {
        if(emp[i]._id==data.details.p1.empId){
            setP1(i)
            break;
        }
    }
}
if(p2=='' && data&&data.details&&data.details.p2 ){
    for(var i=0;i<emp.length;i++)
    {
        if(emp[i]._id==data.details.p2.empId){
            setP2(i)
            break;
        }
    }
}

return <TicketDetailsContainer>
   {data &&data.details&& <div className="">

            {/* Heading */}
            <div className="text-center mt-3 heading text-primary">User Details</div>
            {/* User Details */}
            <div className="cardd d-flex justify-content-between mx-auto">
            <div className="d-flex flex-column justify-content-around gap-2">
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >Name : </div>
                            <div className="content ms-1 mt-1">{data.details.userId.name}</div>
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >Phone Number : </div>
                            <div className="content ms-1 mt-1">{data.details.userId.phone}</div>
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >UPI ID : </div>
                            <div className="content ms-1 mt-1">{data.details.userId.bankDetails.upiId}</div>
                        </div>


            </div>
            <div className="d-flex flex-column justify-content-around gap-2">
                        <div className="d-flex align-items-center  gap-2 ">
                            <div className="side-heading" >UserName : </div>
                            <div className="content ms-1 mt-1">{data.details.userId.username}</div>
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >TicketId : </div>
                            <div className="content ms-1 mt-1">{data.details.ticketId}</div>
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >Subject : </div>
                            <div className="content ms-1 mt-1">{data.details.subject}</div>
                        </div>


            </div>
            <div className="d-flex flex-column justify-content-around gap-2">
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >Language : </div>
                            <div className="content ms-1 mt-1">{data.details.userId.webLanguage }</div>
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >Created At : </div>
                            <div className="content ms-1 mt-1">{moment(data.details.createdAt).format('ll')}</div>
                        </div>
            </div>
            
            {localStorage.getItem('role')=='admin'&& <div className="d-flex flex-column gap-2">
                        <div className="d-flex  align-items-center gap-2 ">
                            <div className="side-heading" >P1: </div>
                            <Box sx={{ minWidth: 120 }}>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={p1}
                                label=""
                                onChange={(e)=>{setP1(e.target.value)}}
                                >
                               
                                {emp&&

                                        emp.map((employe,empIdx)=>{
                                            return   <MenuItem key={empIdx} value={empIdx}>{employe.name}</MenuItem> })

                                }
                                </Select>
                            </FormControl>
                            </Box>               
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                            <div className="side-heading" >P2 : </div>
                            <Box sx={{ minWidth: 120 }}>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={p2}
                                label=""
                                onChange={(e)=>{
                                    
                                    console.log("Idx",e.target.value)
                                    setP2(e.target.value)
                                }}
                                >
                           
                                 {emp&&

                                        emp.map((employe,empIdx)=>{
                                            return  <MenuItem key={empIdx} value={empIdx}>{employe.name}</MenuItem>
                                        })
                                }
                                </Select>
                            </FormControl>
                            </Box> 
                        </div>

                        <Button variant="text" onClick={updateSupport}>Update</Button>
            </div>}




            </div>
            {/* Buttons below user details */}
            <div className="d-flex justify-content-around mt-4">
            <Button variant="contained" color="success">Go to Issue</Button>
            <Button variant="contained" color="success"  onClick={()=>{navigate('../user-detail',{state:{'userId':data.details.userId._id}})}} >Show User Data</Button>
            <Button variant="contained" >Activate</Button>
            </div>

    <hr style={{width:'70%'}} className='mx-auto mt-5' />
            
            
            
            
            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    




{/* Add to Chat */}

                    <div className='d-flex me-5 justify-content-end'>
                    <Button variant="contained" onClick={()=>{setReplyModal(true)}} >Add To Thread</Button>
                    </div>

    {/* Chat */}


    <div className="p-5">
        {
            data.details.thread.map((ele,idx)=>{


                if(ele.isUser==true){
                    
                    return <div key={idx} className='d-flex flex-column p-2' style={{maxWidth:'80%'}} >

                        <div className="chat-text " >

                            

                             <span className="chat-content d-inline-flex flex-column">
                                <span className="d-flex justify-content-between "style={{gap:'10px'}}>
                            <span className="number text-light">#{data.details.thread.length-idx}</span>
                            <span className="number text-light" style={{fontSize:'10px'}}>{moment(ele.createdAt).calendar()}</span>
                            </span>
                                <span className="chat text-light ">    {ele.content} </span>
                                <div className="chat-images d-flex justify-content-around gap-2"> 
                                {
                                   ele.photo.map((image,imageIdx)=>{
                                        return <img src={image} key={imageIdx} width="auto" height="100px"  alt="" />
                                    })
                                }
                                </div>
                            </span>
                         

                        </div>

                       

                    </div>
                }
                else{
                  
                    return <div key={idx} className='d-flex flex-column p-2 ms-auto' style={{maxWidth:'80%'}} >

                    <div className="chat-text ms-auto" >

                        

                         <span className="chat-contentt d-inline-flex flex-column" >
                         <span className="d-flex justify-content-between " style={{gap:'10px'}}>
                            <span className="number text-secondary" style={{fontSize:'12px'}}>{data.details.thread.length-idx}{' '} {ele.name}</span>
                            <span className="number" style={{fontSize:'10px'}}>{moment(ele.createdAt).calendar()}</span>
                            </span>
                            <span className="chat  " > {ele.content} </span>
                            <div className="chat-images d-flex justify-content-around gap-2"> 
                                {
                                   ele.photo.map((image,imageIdx)=>{
                                        return <img src={image} key={imageIdx} width="auto" height="100px"  alt="" />
                                    })
                                }
                                </div>
                        </span>
                    </div>
                    </div>
                }






            })
        }

</div>
        </div>
}

<ReplyModal open={replyModal} setOpen={setReplyModal} setMsg={setMsg} setPics={setPics} msg={msg} pics={pics} sendMsg={sendMsg}/>
</TicketDetailsContainer>

}

export default TicketDetails