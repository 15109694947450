import styled from "styled-components";

const RewardDetailsContainer=styled.div`

display:flex;
justify-content:space-around;
flex-wrap:wrap;
gap:10px;
margin-left: auto;
margin-right: auto;
margin-top:30px;
margin-bottom:30px;
.side-heading {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
  }
  .content {
    font-size: 12px;
  }
`;

export default RewardDetailsContainer;