import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import RejectModal from './RejectModal';
    //Time related
import moment from 'moment/moment';
import { AiOutlineUser,AiFillPhone } from "react-icons/ai";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow:'scroll',
    maxHeight:'90vh',
    overflowX:'hidden',
  };


function UnPublishedModal({ele,setReason,open,setOpen,childOpen,setChildOpen,handleReject,handleApprove}){
    const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    
    return  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...style, width: 700 }}>
    
    <h4 className='text-primary text-center' >{ele.category}</h4>
    <div className="d-flex justify-content-around align-items-center">
        <div ><AiOutlineUser/><span className='text-secondary'>{"  "}{ele.user.name}</span> </div>
        <div><AiFillPhone/><span className='text-secondary'>{"  "}{ele.user.phone}</span></div>
    </div>
    {/* Salary */}
    <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
        <div className='text-dark font-weight-bold' >Salary : </div>
        <div className='font-weight-bold text-success' style={{fontSize:'16px'}}><span>₹ </span>{ele.salary}</div>
    </div>
        {/* Skills */}
        <div className="d-flex justify-content-start gap-2 align-items-center ms-3 mt-2">
        <div className='text-dark font-weight-bold' >Skills  </div>
        <div className='d-flex flex-wrap gap-1 align-items-end'>
                {
                    ele.skills.map((skill,skillIdx)=>{
                      
                        return <div className="skill" style={{backgroundColor:'#e94545',padding:'2px 8px',borderRadius:'15px',color:'whitesmoke'}} key={skillIdx}>
                                { skill}
                        </div>
                   
                    })

                }
             
        </div>
    </div>

        {/* Description */}

        <div className="d-flex justify-content-start gap-2 align-items-start ms-3 mt-2">
            <div className='text-dark font-weight-bold' >Description: </div>
            <div style={{overflowWrap:'anywhere',textAlign:'start'}}>
                {ele.description}
                
            </div>
            
        </div>
        <div className=" d-flex justify-content-around mt-2">
        <Button variant="contained" color="success" onClick={()=>{setOpen(false);handleApprove()}}>Approve</Button>
             <Button variant="outlined" color="error" onClick={()=>{setChildOpen(true)}}>Reject</Button>
        </div>            
      <RejectModal setReason={setReason} open={childOpen} setOpen={setChildOpen} handleReject={handleReject} setParentOpen={setOpen} />
    </Box>
  </Modal>
}


export default UnPublishedModal;