import styled from 'styled-components';

const TicketDetailsContainer = styled.div`
  width: 100%;
  text-align: start;    
  background-color: #f8f9fe;
  .side-heading {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
  }
  .content {
    font-size: 12px;
  }
  .cardd{
      background-color:#fff;
      padding:20px;
      border-radius:15px;
      box-shadow: 2px 2px 5px 0.5px black ;
      
      text-align:center;
      width:90%;
    }
    .heading{
      font-size:30px;
      font-style:italic
    }
    .chat-content{
    
      padding:10px;
      background-color:rgb(25,118,210);
      border-radius: 2px 10px 10px 10px;
      white-space: pre-wrap;
    }
    .chat-contentt{
    
    padding:10px;
    background-color:#d5d1d1;
    color:black;
    border-radius: 10px 2px 10px 10px;
    margin-left: auto;
    white-space: pre-wrap;
  }
  .MuiFormControl-root{
    width:100%;
    
  }
  .MuiInputBase-root {
    height:30px
  }

`;

export default TicketDetailsContainer;  
