import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';
import { HEADER_URL } from '../../../../api/constants';
import Container from './engagement';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import Widget from '../../../../components/analysisComponents/Widget/Widget';
import Chooser from '../../../../components/analysisComponents/Charts/Chooser/Chooser';


const queryOptions = ['User To User', 'User To App', 'App To User'];



function Engagement() {
  const [data, setData] = useState([]);
  const [queryEndPoint, setQuery] = useState('USER TO APP');
  const [date, setDate] = useState(moment(Date.now()));
  const [heading, setHeading] = useState('USER TO APPLICATION ENGAGEMENT');
  const TOKEN = useSelector((state) => state.auth.token);
  const url = useLocation();

  const fetchData = async (newDate) => {
    if (newDate) {
      setDate(newDate);
    }
    const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/dailyengagement?date=${
      newDate ? moment(newDate).format('L') : moment(date).format('L')
    }&endpoint=${queryEndPoint}`;

    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }, 
      })
      .then((res) => {
        const { data } = res;

        console.log("response ?? : ",res.data)
        setData(data.response);

        if (queryEndPoint === 'User To User') {
          setHeading('USER TO USER ENGAGEMENT');
        } else if (queryEndPoint === 'App To User') {
          setHeading('APPLICATION TO USER ENGAGEMENT');
        } else if (queryEndPoint === 'User To App') {
          setHeading('USER TO APPLICATION ENGAGEMENT');
        }
      })
      .catch((err) => {
        console.error('err', err);
        alert('something went wrong!');
      });
  };

  useEffect(() => {
    fetchData();
  }, [queryEndPoint]);

  const getOptionFromChild = (option) => {
    setQuery(option.value);
  };

  console.log("asal dat enti : ",data)

  return (
    <Container>
      {/* <SideBar /> */}
      <div className="left">
        <div className="date">
          {/* <Navbar/> */}
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Date Desktop"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={fetchData}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="chooser">
            <Chooser
              options={queryOptions}
              getOptionFromChild={getOptionFromChild}
              flag="engagement"
            />
          </div>
        </div>
        <div>
          <Typography variant="h6" gutterBottom className="title-inner">
            {heading}
          </Typography>
          <Widget dateP={date} endpoint={queryEndPoint} data={data&& data} />
          <hr />
        </div>
      </div>
    </Container>
  );
}

export default Engagement;
