import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { Carousel } from 'react-responsive-carousel';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PushPinIcon from '@mui/icons-material/PushPin';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import { useState } from 'react';
import { toggleButtonGroupClasses } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { TextField, Grid, Button } from '@mui/material';
import { display } from '@mui/system';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const no_image =
  'https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg';

const propsType = {
  title: 'string',
  subHeader: 'string',
  image: 'string',
  content: 'string',
  postId: 'string',
  userId: 'string',
};

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  overflowY: 'hide',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  boxSizing: 'border-box',
  p: 4,
};

function BadgeAvatars({ image, overlay, type }) {
  if (type === 'latest') {
    return <Avatar alt="Travis Howard" src={image} />;
  }

  return (
    <Stack direction="row" spacing={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src={image} />}
      >
        <Avatar alt="Travis Howard" src={overlay} />
      </Badge>
    </Stack>
  );
}

function getMediaType(photo, video) {
  if (photo[0]) {
    return { component: 'img', image: photo[0] };
  } else if (video[0]) {
    return { component: 'video', image: video[0] };
  } else {
    return { component: 'img', image: no_image };
  }
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// var toggle=true;
//Setter will call this after Deleting the Post
export default function PostCard({
  Setter,
  post,
  from,
  changePosts,
  Refetch,
  FirstHelper,
  onDelete,
}) {
  const date = new Date(post.createdAt);
  console.log(post);
  const [isRewarded, setIsRewarded] = useState(post.didRewardGet);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toggle, setToggle] = useState(true);
  const format =
    months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
  const navigate = useNavigate();
  const TOKEN = useSelector((store) => store.auth.token);
  const [formTitle, setFormTitle] = useState('Congratulations');
  const [formBody, setFormBody] = useState('You got a new Reward!');

  const redirectToSteeper = () => {
    navigate(`/analysis/super-spaark/latest?steeper`, {
      state: {
        postId: post._id,
        userId: post.userId,
      },
    });
  };

  const removeSuperSpaark = () => {
    let url = '';

    if (post.isGroupPost) {
      //"From Group-Post model"

      url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/expireSuperSpaark?postId=${post._id}`;
      // alert(url)
    } else {
      // alert("Not a Group Post")
      url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/superspaark/expire-superspaark?postId=${post._id}`;
      // alert(url)
      //"From Post model"
    }

    // alert(url)
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          'Content-Type': 'application/json',
        },
      })
      .then((_) => {
        changePosts(post._id);
      })
      .catch((err) => {
        console.log('Err', err);
        alert('Something went wrong !');
      });
  };

  function Deleter(e) {
    // fromUserPage
    // postIdmodal

    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/checkcontent/remove`;
    const val = JSON.stringify({
      fromUserPage: true,
      postIdmodal: post._id,
      contenttitle: 'xyz', //Not necessary
    });

    axios
      .post(url, val, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        alert('Deleted Successfully');
        // Setter();
        onDelete(post._id);
      })
      .catch((err) => {
        console.log('Err', err);
        alert('Something went wrong !');
      });
  }

  function ToggleShift() {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }

    // alert("toggle val is ",toggle)
    console.log('toggle val is', toggle);
  }

  function Rewarder() {
    handleOpen();
  }

  function SendReward(e) {
    e.preventDefault();

    console.log(e);
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/superspaark/give-scratchcard`;

    let amount = 1;

    if (Number(e.target[4].value) && Number(e.target[4].value) > 0) {
      amount = Number(e.target[4].value);
    }

    if (Number(e.target[4].value) < 0) {
      amount = Math.abs(Number(e.target[4].value));
    }

    const data = {
      title: 'Congratulations',
      body: 'You got a new Reward!',
      name: 'Reward',
      type: 'postReward',
      amount: amount,
      postId: post._id,
      userId: post.userId,
      DirectReward: 'yes',
    };

    if (e.target[0].value) {
      data.title = e.target[0].value;
    }

    if (e.target[2].value) {
      data.body = e.target[2].value;
    }

    console.log('new body will be: ', data);

    axios
      .post(url, data, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        alert('Reward Given Successfully!');
        setOpen(false);
        setIsRewarded(true);
        // onReward(setIsRewarded);
        console.log('response is : ', res);
      })
      .catch((err) => {
        alert('something went wrong!!');
        console.log('ERR', err);
      });
  }

  function PinPostToggle() {
    let call = 'makePinPost';
    if (post.pinned) {
      call = 'removePinPost';
    }

    console.log('status : ');
    axios
      .get(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/${call}?postId=${post._id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        console.log(res);
        // Setter();

        if (call === 'removePinPost' && FirstHelper) {
          Refetch();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 style={{ display: 'flex', justifyContent: 'Center' }}>
            Enter Reward Details
          </h3>
          <div
            style={{
              height: '300px',
              display: 'flex',
              justifyContent: 'Center',
              alignItems: 'flex-start',
              margin: '20px 0px',
            }}
          >
            <form onSubmit={SendReward}>
              <div class="form-group">
                <TextField
                  type="text"
                  className="mt-3"
                  // label="FCM Title"
                  variant="outlined"
                  name="FCMTitle"
                  value={formTitle}
                  autoComplete="off"
                  onChange={(e) => {
                    setFormTitle(e.target.value);
                  }}
                />
              </div>

              <div class="form-group">
                <TextField
                  type="text"
                  className="mt-3"
                  value={formBody}
                  onChange={(e) => {
                    setFormBody(e.target.value);
                  }}
                  // label="FCM Body"
                  variant="outlined"
                  name="FCMbody"
                  autoComplete="off"
                />
              </div>

              <div class="form-group">
                <TextField
                  type="number"
                  className="mt-3"
                  placeholder="Amount"
                  label="FCM Reward"
                  variant="outlined"
                  name="FCMamount"
                  autoComplete="off"
                />
              </div>

              <div class="form-group">
                <Button variant="contained" type="submit">
                  Send
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      <Card
        sx={{
          width: 345,
          height: '100%',
          borderRadius: '10px',
          margin: '20px 0px',
        }}
      >
        <CardHeader
          avatar={
            <BadgeAvatars
              image={
                post.uservisibility?.profilePic
                  ? post.uservisibility?.profilePic
                  : ''
              }
              overlay={post?.ImageIcon}
              type={from}
            />
          }
          title={post.uservisibility.name.toUpperCase()}
          subheader={format}
        />
        {/* {post.pollOptions.length==0 &&<CardMedia {...getMediaType(post.photo, post.video)} height="194" />} */}
        {from !== 'live' &&
          post &&
          post.pollOptions &&
          post.pollOptions.length == 0 && (
          <Carousel showThumbs={false} infiniteLoop={true}>
              {post.photo &&
                post.photo.map((img, imgIdx) => {
                  return (
                    <div
                      key={imgIdx}
                      onClick={() => {
                        setModelImage(img);
                        setModelOpen(true);
                      }}
                    >
                      <img src={img} width="300px" height="220px" />
                    </div>
                  );
                })}
              {post.video &&
                post.video.map((img, imgIdx) => {
                  return (
                    <div
                      key={imgIdx}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <video
                        width="300px"
                        height="220px"
                        controls
                        className="mx-auto d-block"
                      >
                        <source src={img} type="video/mp4" />
                      </video>
                    </div>
                  );
                })}
            </Carousel>
          )}
        <CardContent>
          {/* <Typography variant="body2" color="text.secondary"> */}

          {post.content ? (
            <details>
              <summary style={{ listStyle: 'none' }} onClick={ToggleShift}>
                {post.content?.substr(0, 35)}
                {toggle && post && post.content && post.content.length > 35
                  ? '...'
                  : ''}
              </summary>
              <p>{post.content?.substr(35, post.content.length - 1)}</p>
            </details>
          ) : (
            ''
          )}

          {from !== 'live' &&
            post &&
            post.pollOptions &&
            post.pollOptions.length != 0 && (
              <table className="table my-auto table-striped">
                <thead className="bg-success text-light">
                  <tr>
                    <th>Option</th>
                    <th>Votes</th>
                    <th>Vote Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {post.pollOptions.map((opt, optIdx) => {
                    return (
                      <tr key={optIdx}>
                        <td>{opt.option}</td>
                        <td>{opt.votes.length}</td>
                        <td>{Math.round(opt.votePercentage)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

          {/* </Typography> */}
        </CardContent>

        <div className="mt-auto">
          <IconButton aria-label="add to favorites">
            <SuperButton
              type={from}
              clickAction={
                from === 'latest' ? redirectToSteeper : removeSuperSpaark
              }
            />
          </IconButton>
          {from === 'latest' &&
            !post.didRewardGet &&
            localStorage.getItem('role') == 'analysisuser' && (
              // <IconButton aria-label="delete" size="large">
              <Button
                color="success"
                size="small"
                disabled={isRewarded}
                variant="contained"
                sx={{ width: 80 }}
                onClick={Rewarder}
              >
                Reward
              </Button>
            )}
            
        
        <span className=' bg-info p-2 w-25 rounded mb-2 me-2 text-dark'>
          {post.featureName}
        </span>
        <span className='border border-black w-25 p-2 rounded me-2'>
        <ThumbUpIcon color="primary" />
        {" "}{post?.likes?.length ? post?.likes?.length: " 0"}
        </span>
        

          {from !== 'live' && from != 'pinPost' ? (
            <IconButton onClick={Deleter} aria-label="delete" size="large">
              <DeleteIcon color="error" fontSize="inherit" />
            </IconButton>
          ) : (
            ' '
          )}

          {from === 'pinPost' && !post.pinned ? (
            <div
              style={{
                width: '345px',
                display: 'flex',
                justifyContent: 'center',
                marginTop:'15px'
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={PinPostToggle}
              >
                Pin Post
                <PushPinIcon />
              </Button>{' '}
            </div>
          ) : (
            from === 'pinPost' && (
              <div
                style={{
                  width: '345px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop:'15px'

                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={PinPostToggle}
                >
                  Remove Pin
                  <PushPinIcon />
                </Button>{' '}
              </div>
            )
          )}
        </div>
      </Card>
    </>
  );
}

const SuperButton = ({ type, clickAction }) => {
  if (type === 'latest') {
    return (
      <div>
        {localStorage.getItem('role') == 'analysisuser' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={clickAction}
          >
            {' '}
            Super Spaark it!{' '}
            <FavoriteIcon style={{ color: '#fa4f4f', height: '17px' }} />{' '}
          </Button>
        )}
      </div>
    );
  }

  if (type === 'live') {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={clickAction}
      >
        Remove <DeleteIcon style={{ color: '#fa4f4f', height: '17px' }} />
      </Button>
    );
  }
};
