import styled from "styled-components";



const AddEmpContainer=styled.div`


    display:flex;
    justify-content:center;
    align-items: center;
    height:100%;

    form{
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        gap:30px;
        background-color:white;
        padding:30px;
        border-radius: 10px;
        box-shadow:3px 3px 10px 3px black;
    }





`;


export default AddEmpContainer;