import { useRef, useState } from 'react';
import Papa from 'papaparse';

function ReadCSV() {
  let uploadRef = useRef(null);
  const [uploadedData, setUploadedData] = useState([]);

  function TwoDimArrayToJSON(arr) {
    let arrOfObj = [];
    let keyArray = [...arr[0]];
    for (let i = 1; i < arr.length; i++) {
      let obj = {};

      for(let j=0; j<keyArray.length; j++){
        obj[keyArray[j]] = arr[i][j];
      }
      arrOfObj.push(obj);
      obj = {};
    }
    return arrOfObj;
  }

  function upload() {
    var files = uploadRef.current.files;
    console.log('inside');
    console.log(files[0].name);

    if (files.length == 0) {
      alert('Please choose any file...');
      return;
    }
    var filename = files[0].name;
    var extension = filename.substring(filename.lastIndexOf('.')).toUpperCase();
    if (extension == '.CSV') {
      Papa.parse(files[0], {
        complete: function (results) {
          console.log("*******2d array***********");
          console.log(results.data);
          results.data.pop()
          const uploadedJSON = TwoDimArrayToJSON(results.data)
          setUploadedData(uploadedJSON);
        },
      });
    } else {
      alert('Please select a valid csv file.');
    }
  }

  return (
    <>
      {uploadedData && JSON.stringify(uploadedData)}
      <h1>CSV to JSON | Javacodepoint</h1>
      <h2>Upload a CSV file to convert in human readable JSON</h2>

      <input ref={uploadRef} type="file" id="file_upload" />
      <button onClick={upload}>Upload</button>
    </>
  );
}

export default ReadCSV;

