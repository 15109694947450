import styled from "styled-components";

const Container = styled.div`

    
    width:100%;
        .right{
           

            .widgets{
                display: flex;
            }
        }
`

export default Container;