import { Routes, Route } from 'react-router-dom';
import AnalysisDashBoard from '../../pages/analysisPages/DashBoard/AnalysisDashBoard.jsx';
import Engagement from '../../pages/analysisPages/Analytics/Engagement/Engagement';
import Contest from '../../pages/analysisPages/Contest/contestGroupDetails/Contest.jsx';
import Activity from '../../pages/analysisPages/Analytics/UserActivity/Activity.jsx';
import PushNotifications from '../../pages/analysisPages/PushNotifications/PushNotifications.jsx';
import Result from '../../pages/analysisPages/Contest/result/Result.jsx';
import ContestGroup from '../../pages/analysisPages/Contest/contestGroup/ContestGroup.jsx';
import Posters from '../../pages/analysisPages/Posters/Posters.jsx';
import PageNotFound from '../../pages/PageNotFound/PageNotFound.jsx';
import RecentUsage from '../../pages/analysisPages/RecentUsage/RecentUsage.jsx';
import LatestPost from '../../pages/analysisPages/SuperSpaark/Latest-Posts/LatestPost';
import LiveSuperSpaarks from '../../pages/analysisPages/SuperSpaark/Live-SuperSpaarks/LiveSuperSpaark.jsx';
import Rewards from '../../pages/analysisPages/Rewards/Rewards.jsx';
import GroupPosts from '../../pages/analysisPages/SuperSpaark/Group-Posts/GroupPosts.jsx';
import InAppNotification from '../../pages/analysisPages/In-App-Notifications/InAppNotification.jsx';
// import Retention from '../../pages/analysisPages/Analytics/retention/Retention.jsx';
import PinPost from '../../pages/analysisPages/PinPost/PinPost.jsx';
import LeadSummary from '../../pages/analysisPages/LeadSummary/LeadSummary.jsx';
import StartupDailog from '../../pages/analysisPages/StartupDailog/StartuDailog.jsx';
import SideNavBar from '../../components/analysisComponents/SideNavBar/SideNavBar.jsx';
import TelecallerLeads from '../../pages/analysisPages/TelecallerLeads/TelecallerLeads.jsx';
import LeadsDetails from '../../pages/analysisPages/LeadsDetails/LeadsDetails.jsx';
import ShowListing from '../../pages/analysisPages/ShowListing/ShowListing.jsx';
import SpecificListing from '../../pages/analysisPages/SpecificListing/SpecificListing.jsx';
import ShowProducts from '../../pages/analysisPages/ShowProducts/ShowProducts.jsx';
import SpecificProduct from '../../pages/analysisPages/SpecificProduct/SpecificProduct.jsx';
import Jobs from '../../pages/analysisPages/JobsPage/Jobs.jsx';
import GroupPost from '../../pages/analysisPages/SuperSpaark/Group-Posts/GroupPost.jsx';

// from admin routes
import Story from '../../pages/analysisPages/StoryComponent/Story.jsx';
import WizardDashBoard from '../../pages/analysisPages/DashBoard/WizardDashBoard.jsx';

function AnalysisRoutes() {
  return (
    <>
      <div className="d-flex">
        <div>
          <SideNavBar />
        </div>
        <div className="mx-auto"></div>
        <Routes>
          <Route index element={<AnalysisDashBoard />} />
          <Route path="lead-summary" element={<LeadSummary />} />
          <Route path="lead-details" element={<LeadsDetails />} />
          <Route path="startupdailog" element={<StartupDailog />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="/listings" element={<ShowListing />} />
          <Route path="/listing" element={<SpecificListing />} />
          <Route path="/products" element={<ShowProducts />} />
          <Route path="/product" element={<SpecificProduct />} />
          <Route path="telecaller-leads" element={<TelecallerLeads />} />
          <Route path="home" element={<AnalysisDashBoard />} />
          <Route path="wizardDash" element={<WizardDashBoard />} />
          <Route path="pushnotifications" element={<PushNotifications />} />
          <Route path="app-notifiations" element={<InAppNotification />} />
          <Route path="engagement" element={<Engagement />} />
          <Route path="getresult" element={<Result />} />
          <Route path="ContestGroup" element={<ContestGroup />} />
          <Route path="contest" element={<Contest />} />
          <Route path="activity" element={<Activity />} />
          <Route path="posters" element={<Posters />} />
          <Route path="story" element={<Story />} />
          <Route path="listings" element={<ShowListing />} />
          <Route path="listing" element={<SpecificListing />} />
          <Route path="products" element={<ShowProducts />} />
          <Route path="product" element={<SpecificProduct />} />
          {/* <Route path="retention" element={<Retention />} /> */}
          <Route path="recent-usage" element={<RecentUsage />} />
          <Route path="super-spaark/*">
            <Route path="latest" element={<LatestPost />} />
            <Route path="live" element={<LiveSuperSpaarks />} />
            <Route path="groups" element={<GroupPosts />} />
            <Route path="group" element={<GroupPost />} />
            <Route path="pinPost" element={<PinPost />} />
          </Route>
          <Route path="rewards" element={<Rewards />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <div className="mx-auto"></div>
      </div>
    </>
  );
}

export default AnalysisRoutes;
