import styled from "styled-components";

const Container = styled.div`
    .box{
        padding: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 39px;
        padding-top:28px;
    }
`

export default Container;