import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 20px;
  margin: 15px;
  justify-content: center;

  .right {
    display: flex;
    flex-direction: column;
    
  }
  .contesttable {
    width: 1000px;
    margin-top: 50px;
  }
`;

export default Container;
