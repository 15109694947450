import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoutes(props) {
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  // const user = useSelector((state) => state.auth.user);

  // for Role based authentication use allowedRoles array ( the prop passed from App.js ) to check permissions to that user
  // and based on that show a relevent page or Navigate to Unauthorized Page.
for (var i=0;i<props.allowedRoles.length;i++){

if(props.allowedRoles[i]==localStorage.getItem('role'))
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
return (<div>UnAuthorized Access</div>);
}
export default ProtectedRoutes;
