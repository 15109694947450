import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'; // Import Bootstrap components

const ContentModal = ({isOpen,toggleModal, content}) => {
  return (
    <div>
      {/* <Button onClick={toggleModal}>Toggle Modal</Button> */}

      <Modal show={isOpen} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{content}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContentModal;
