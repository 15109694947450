
import styled from "styled-components";


const Container = styled.div`
   display: flex;
   justify-content: center;
   background-color: #f7e9e9;

   .right{

      display: flex;
      flex-direction: column;
     width:100%;
      margin: 10px 5px;

      .posts{
         display: flex;
         flex-wrap: wrap;
         gap:30px;
         margin: 20px;
         justify-content: space-around;
         .post{
            max-width:400px;
         }
      }
      .MuiPaper-root {
         display:flex;
         flex-direction:column;
         justify-content:space-between
      }
   }



`

export default Container;  