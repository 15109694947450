import styled from 'styled-components';

const JobsContainer = styled.div`
 
  background-color: #f8f9fe;
  padding:10px;
  font-size: 12px;
  width: 100%;

  .skill{
    background-color:#e94545;
    padding:2px 8px;
    border-radius: 15px;
    color:whitesmoke
  }

`;

export default JobsContainer;
