import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import WizContainer from './WizardFirstPage';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckboxComponent from './SecondPageRows';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import spaarksLogo from '../../../assets/spaarksLogo.svg';
import copyImg from '../../../assets/copyIcon.svg';

import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';

function ThirdScreen() {
  const { state } = useLocation();
  console.log(typeof state);

  console.log(state);
  console.log(typeof state.nextData);
  console.log(state.nextData);

  const navigate = useNavigate();

  const [data, setData] = useState(state.nextData);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [currIdx, setCurrIdx] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [copied, setCopied] = useState('');

  function onSubmit(e) {


    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/updateCallStatus`;
    axios(url, {
      method: 'POST',
      data: { arrOfReq: data },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });


    alert("Contact Status Updated Successfull");

    // setTimeout(()=>{
    //   navigate('/wizard/wizardFirst')
    // },1000)    
  }

  function copyText(phone) {
    phone = phone.slice(3);

    // Copy the text inside the text field
    navigator.clipboard.writeText(phone);
    setCopied(phone);
    setTimeout(() => {
      setCopied('');
    }, 1000);

    // Alert the copied text
  }

  const updateCallStatus = (event, serviceProviderIndex, field) => {
    const updatedServiceProviders = [...selectedUser.serviceProviders];
    updatedServiceProviders[serviceProviderIndex][field] = event.target.value;
    setSelectedUser({
      ...selectedUser,
      serviceProviders: updatedServiceProviders,
    });
  };

  return (
    <WizContainer>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-5  ">
            <div className="accepted-spaarks-info mb-2">Accepted Spaarks</div>
            <table>
              <thead className=" bg-black text-light pt-1 pb-1  ">
                <tr className="accSpp ">
                  <th className="th-one ">Details</th>
                  <th className="th-two ">Category</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((ele, idx) => {
                    return (
                      <>
                        <tr
                          onClick={() => {
                            setServiceProvider(ele.serviceProviders);
                            setCurrIdx(idx);
                            setSelectedUser(ele);
                            // setData((prevData) => ({
                            //   ...prevData,
                            //   ...selectedUser,
                            // }));
                          }}
                        >
                          <td>
                            <div className="flex-box">
                              <div className="profile">
                                <img className="profile" src={ele.profilePic} />
                              </div>
                              <p className="profile-name text-break w-50">
                                {ele.name}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="flex-box-2">
                              <div className="category rounded  p-2 justify-content-center ">
                                {ele.category}
                              </div>
                              <div className="loc p-1 justify-content-center ">
                                <svg
                                  width="9"
                                  height="13"
                                  viewBox="0 0 9 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.26697 12.4469C3.43146 10.7315 2.53562 9.67164 1.78892 8.78806C0.759876 7.5707 0 6.67149 0 4.90738C0 3.66491 0.503879 2.53972 1.31811 1.72533C2.13234 0.911105 3.25753 0.407227 4.5 0.407227C5.74247 0.407227 6.86766 0.911105 7.68189 1.72533C8.49612 2.53972 9 3.66491 9 4.90738C9 6.67149 8.24013 7.5707 7.21109 8.78806C6.46438 9.67164 5.56854 10.7315 4.73303 12.4469C4.67041 12.5755 4.51516 12.6291 4.38655 12.5665C4.33235 12.5401 4.29147 12.4973 4.26697 12.4469ZM2.18393 8.45367C2.88195 9.27969 3.70705 10.2558 4.5 11.7602C5.29295 10.2558 6.11805 9.27969 6.81607 8.45367C7.77376 7.32052 8.48127 6.48363 8.48127 4.90738C8.48127 3.80776 8.03557 2.81225 7.31535 2.09188C6.59513 1.37165 5.59962 0.925957 4.5 0.925957C3.40038 0.925957 2.40487 1.37165 1.68465 2.09188C0.964428 2.81225 0.51873 3.80776 0.51873 4.90738C0.51873 6.48363 1.22624 7.32052 2.18393 8.45367ZM4.5 3.09626C4.99975 3.09626 5.45279 3.29913 5.78044 3.62678C6.10809 3.95443 6.31096 4.40763 6.31096 4.90738C6.31096 5.40712 6.10809 5.86002 5.78044 6.18767C5.45279 6.51532 4.99975 6.71819 4.5 6.71819C4.00026 6.71819 3.54721 6.51532 3.21956 6.18767C2.89191 5.86002 2.68904 5.40712 2.68904 4.90738C2.68904 4.40763 2.89191 3.95443 3.21956 3.62678C3.54721 3.29913 4.00026 3.09626 4.5 3.09626ZM5.4139 3.99332C5.18026 3.75968 4.85705 3.61499 4.5 3.61499C4.14295 3.61499 3.81974 3.75968 3.5861 3.99332C3.35245 4.22697 3.20777 4.55033 3.20777 4.90738C3.20777 5.26427 3.35245 5.58749 3.5861 5.82113C3.81974 6.05477 4.14311 6.19946 4.5 6.19946C4.8569 6.19946 5.18026 6.05477 5.4139 5.82113C5.64755 5.58749 5.79223 5.26427 5.79223 4.90738C5.79223 4.55033 5.64755 4.22697 5.4139 3.99332Z"
                                    fill="black"
                                  />
                                </svg>
                                {ele.placeName}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="col-5 ms-auto height-Con" style={{ width: '600px' }}>
            <div className="business-info mb-2 mt-2">Businesses</div>
            <table className="row">
              {copied && <p>Copied</p>}
              <thead className=" bg-black text-light pt-1 pb-1  ">
                <tr className="accSpp row">
                  <th className="col">Details</th>
                  <th className="col">Contact</th>
                </tr>
              </thead>
              <tbody
                className="shadow-sm overflow-auto overflow-controller mb-2 animate "
                style={{ maxHeight: '70vh' }}
              >
                {data[currIdx]?.serviceProviders &&
                  data[currIdx].serviceProviders.map((ele, index) => {
                    return (
                      <>
                        <tr
                          className="shadow-sm  d-flex justify-content-between pt-2 pb-2 animate"
                          style={{ height: '65px' }}
                        >
                          <td>
                            <div className="flex-box">
                              <div className="profile">
                                <img className="profile" src={ele.profilePic} />
                              </div>
                              <p className="profile-name text-break w-50">
                                {ele.name}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-around align-items-baseline">
                              <p className=" p-2 w-25">
                                {ele.sendMessage ? <SmsIcon /> : null}
                              </p>
                              <p className=" p-2 w-25">
                                {ele.sendWhatsapp ? <WhatsAppIcon /> : null}
                              </p>
                              <p className=" p-2 w-25">
                                {ele.sendSpaarks ? (
                                  <img src={spaarksLogo} />
                                ) : null}
                              </p>
                              {/* <h3 className='ms-5'>{ele.callStatus ? 'SendSpaarks' : 'NA'}</h3> */}
                            </div>
                          </td>
                          {/* <CheckboxComponent currIdx={currIdx} setData={setData} data={data} ele={ele} handleCheckBoxes={handleCheckBoxes} idx={idx} /> */}
                          <td>
                            <div className="d-flex">
                              <img
                                src={copyImg}
                                style={{ height: '30px', width: '30px' }}
                                onClick={() => copyText(ele.phone)}
                                className="mt-1 me-1 mb-1"
                                alt="copyImg"
                              />
                              <select
                                className={`form-select h-2 ${
                                  ele.callStatus === 'connected'
                                    ? 'text-success border border-success'
                                    : ele.callStatus === 'disConnected'
                                    ? 'text-danger border border-danger'
                                    : ''
                                } `}
                                aria-label="Default select example"
                                value={ele.callStatus}
                                onChange={(e) =>
                                  updateCallStatus(e, index, 'callStatus')
                                }
                              >
                                <option value="connected">Connected</option>
                                <option value="disConnected">
                                  DisConnected
                                </option>
                                <option value="busy">Busy</option>
                                <option value="switchOff">Switch Off</option>
                                <option selected value="notContacted">
                                  Not Contacted
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-5">
          <button
            // className="send-msg-btn border-0 submitBtn-p3 mb-3 ps-3 pe-3 shadow animate selection"
            className="rounded-5 border-0 right-0  send-msg-btn ps-3 pe-3 pt-2 pb-2 "
            onClick={onSubmit}
            // style={{
            //   marginTop: '-30px',
            // }}
          >
            Submit
          </button>
        </div>
      </div>
    </WizContainer>
  );
}

export default ThirdScreen;
