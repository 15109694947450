
import {Link} from "react-router-dom"
function PageNotFound(){
    return (
        <>
            <div className="container" style={{
                display: "flex",
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '20%'
            }}>
            <h1 style={{
                textAlign: "center",
            }}>404</h1>
            <Link to="/" style={{
                textAlign: "center",
            }}>Get Back to Home</Link>
          </div>
        </>
    )
}


export default PageNotFound;