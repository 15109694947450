import axios from 'axios';
import { useState, useEffect } from 'react';
import { HEADER_URL } from '../../../api/constants';

const ScratchCard = () => {
  const [data, setData] = useState({
    type: 'Referal',
    phone: '',
    name: '',
    amount: '',
    unlockTime: '0',
    title: 'Congratulations🎉,you earned a scratch card',
    body: '',
  });

  const onChangeHandler = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));

    console.log(data);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      ///dashboard/scratchcard
      const url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/scratchcard`;
      console.log(url);
      const res = await axios.post(url, data, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      alert(res.data.success);
      window.location.reload(false)

    } catch (error) {
      if(error.data){
        alert(error.data.error);
      }
      else{
        alert("Something Went Wrong!");
      }
    }
  };
  return (
    <>
      <div className="container-fluid text-left">
        <h1 style={{ textAlign: 'center' }}>Create Scratch Card</h1>
        <div className="row">
          <div className="col-2"></div>
          <div className="col">
            <form onSubmit={onSubmitHandler}>
              <div className="form-row">
                <div className="col">
                  <label htmlFor="type">Type:</label>
                  <select
                    name="type"
                    id="type"
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                  >
                    <option value="Reward">Reward</option>
                    <option value="Puzzle">puzzle</option>
                    <option value="Referal">Referal</option>
                    <option value="SuperSpaark">SuperSpaark</option>
                    <option value="FifaLeague">Fifa League</option>
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="name">Phone:</label>
                  <input
                    type="text"
                    minLength="10"
                    maxLength="10"
                    name="phone"
                    id="phone"
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <label htmlFor="name">Scratch Card Name:</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    required
                  />
                </div>
                <div className="col">
                  <label htmlFor="amount">Amount:</label>
                  <input
                    type="text"
                    name="amount"
                    id="amount"
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    required
                  />
                </div>
              </div>
              <label htmlFor="amount">Unlock Time(Hrs):</label>
              <input
                type="text"
                name="unlockTime"
                id="unlockTime"
                className="form-control"
                onChange={(e) => onChangeHandler(e)}
                value={data.unlockTime}
              />
              
              <div className="form-group mt-3">
                <span className='font-weight-bold'>Push Notification Details</span>
                <br />
                <label htmlFor="title">Title:</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control"
                  onChange={(e) => onChangeHandler(e)}
                  value={data.title}
                />
                <label htmlFor="body">Body:</label>
                <input
                  type="text"
                  name="body"
                  id="body"
                  className="form-control"
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>

              <input
                type="submit"
                className="btn btn-success"
                style={{ position: 'absolute', right: '5%' }}
                onSubmit={(e) => onSubmitHandler(e)}
                value="create"
              />
            </form>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </>
  );
};

export default ScratchCard;
