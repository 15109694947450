import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../src/pages/login/Login.jsx';

import PageNotFound from './pages/PageNotFound/PageNotFound.jsx';
import AnalysisRoutes from './router/analysisRoutes/AnalysisRoutes.jsx';
import AdminRoutes from './router/adminRoutes/AdminRoutes';
import ProtectedRoutes from './components/ProtectedRoutes';
import Layout from './components/Layout';
// import SideBar from './components/analysisComponents/SideBar/SideBar.jsx';
import SuperRoutes from './router/SuperUserRoutes/SuperRoutes';
import EmpRoutes from './router/EmpRoutes/EmpRoutes';
import MarketingRoutes from './router/marketingRoutes/MarketingRoutes';
import WizardRoutes from './router/WizardRoutes/WizardRoutes';

const ROLES = [
  'analysisuser',
  'admin',
  'superuser',
  'marketing',
  'emp',
  'wizard',
];

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public Routes */}
            <Route index element={<Navigate to="login" replace />} />
            <Route path="login" element={<Login />} />

            {/* Analysis user Routes */}

            <Route element={<ProtectedRoutes allowedRoles={[ROLES[0]]} />}>
              <Route path="analysis/*" element={<AnalysisRoutes />} />
            </Route>

            {/* Admin routes */}
            <Route element={<ProtectedRoutes allowedRoles={[ROLES[1]]} />}>
              <Route path="admin/*" element={<AdminRoutes />} />
            </Route>
            <Route element={<ProtectedRoutes allowedRoles={[ROLES[2]]} />}>
              <Route path="superuser/*" element={<SuperRoutes />} />
            </Route>
            <Route element={<ProtectedRoutes allowedRoles={[ROLES[3]]} />}>
              <Route path="marketing/*" element={<MarketingRoutes />} />
            </Route>
            <Route element={<ProtectedRoutes allowedRoles={[ROLES[4]]} />}>
              <Route path="employee/*" element={<EmpRoutes />} />
            </Route>
            <Route element={<ProtectedRoutes allowedRoles={[ROLES[5]]} />}>
              <Route path="wizard/*" element={<WizardRoutes />} />
            </Route>

            {/* 404 or Unknown Routes */}
            <Route path="*" element={<PageNotFound />} />
            {/* Lead Summary */}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
