import Container from './Grouppost.js';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import Steeper from '../../../../components/analysisComponents/Steeper/Steeper.jsx';
import { HEADER_URL } from '../../../../api/constants.js';
import PostCard from '../../../../components/analysisComponents/PostCard/PostCard';
import axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import SteepGroupPost from '../../../../components/analysisComponents/SteeperGroupPost/SteeperGroupPost.jsx';

import RewardModal from '../../RewardModal/RewardModal.jsx';
const rewardUrl=`${HEADER_URL}/${localStorage.getItem('role')}/dashboard/superspaark/give-scratchcard`


//Scratch Card



const fetchPosts = async (TOKEN, cb, key) => {
  const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/groupPosts?search=${key}`;

  await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      cb(res.data);
    })
    .catch((e) => {
      console.log('err', e);
    });
};

const no_image =
  'https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg';

function getMediaType(photo, video) {
  if (photo[0]) {
    return { component: 'img', image: photo[0] };
  } else if (video[0]) {
    return { component: 'video', image: video[0] };
  } else {
    return { component: 'img', image: no_image };
  }
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function BadgeAvatars({ image, overlay, type }) {
  return (
    <Stack direction="row" spacing={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src={image} />}
      >
        <Avatar alt="Travis Howard" src={overlay} />
      </Badge>
    </Stack>
  );
}

function GroupPosts() {
//Reward Related
const [rewardModal,setRewardModal]=useState(false)
  const [currIdx,setCurrIdx]=useState(-1)
  const [currEle,setCurrentEle]=useState({})
  const TOKEN = useSelector((store) => store.auth.token);
  const [posts, setPosts] = React.useState([]);
  const location = useLocation();
  const [refresher, SetRefresher] = useState('');
  const navigate = useNavigate();
  const [Ypost, setYPost] = useState({});
  const [toggle, setToggle] = useState(true);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];


  const sendReward = async(amount)=>{
     
    console.log("amount",amount)
  
    await axios
    .post(rewardUrl,{
      type:'groupReward',
      name:currEle.uservisibility.name,
      amount:amount,
      title:'Super Spaark reward',
      body:`you are rewarded with amount ${amount}`,
      postId:currEle._id,
      userId:currEle.userId
    }, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },    
    })
    .then((res) => {
      if(res.data.success=='Scratch card added successfuly'){
        currEle.didRewardGet=true
        var temp=posts;
        temp.splice(currIdx,1,currEle)
        setPosts([...temp])
      }
    })
    .catch((err) => {
      console.log('err', err);
      alert('something went wrong!');
    });
  };

  function Dater(props) {
    const date = new Date(props);
    //   console.log("date : ",props)
    const format =
      months[date.getMonth()] +
      ' ' +
      date.getDate() +
      ', ' +
      date.getFullYear();

    return format;
  }

  const setPostsCallBack = (posts) => {
    setPosts(posts);
  };

  const triggerSearch = (key) => {
    SetRefresher(key);
    fetchPosts(TOKEN, setPostsCallBack, key);
  };

  useEffect(() => {
    fetchPosts(TOKEN, setPostsCallBack, ' ');
  }, []);

  if (location.search !== '') {
    return (
      <Container>
        {/* <SideBar /> */}
        <SteepGroupPost />
      </Container>
    );
  }

  //Get Vals from Call Back
  function Setter(vals) {
    fetchPosts(TOKEN, setPostsCallBack, refresher);
  }

  //Deleter
  function Deleter(e) {
    axios
      .get(
        `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/DeleteGroupPosts?postId=${e}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        alert('Deleted');
        fetchPosts(TOKEN, setPostsCallBack, ' ');
      })
      .catch((e) => {
        console.log('err', e);
      });
  }

  const redirectToSteeper = (post) => {
    // console.log("post details got via Props are : ",post)
    navigate(`/analysis/super-spaark/groups?steeper`, {
      state: {
        postId: post._id,
        userId: post.userId,
      },
    });
  };

  function ToggleShift() {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }

    // alert("toggle val is ",toggle)
    console.log('toggle val is', toggle);
  }

  return (
    <>
      <Container>
        {/* <SideBar /> */}

        <div className="right">
          <div className='position-sticky top-0' style={{zIndex:'5'}}>
                      <SearchAppBar cb={triggerSearch} />
          </div>
         
          <div className="posts">
            {posts.length !== 0 &&
              posts.map((post,idx) => {
                return (
                  <div className="post">
                    <Card
                      sx={{
                        maxWidth: 345,
                        borderRadius: '10px',
                        margin: '20px 0px',
                      }}
                    >
                      <CardHeader
                        avatar={
                          <BadgeAvatars
                            image={
                              post.uservisibility?.profilePic
                                ? post.uservisibility?.profilePic
                                : ''
                            }
                            overlay={post?.ImageIcon}
                          />
                        }
                        title={post.uservisibility.name.toUpperCase()}
                        subheader={
                          Dater(post.createdAt) + `-${post.xyz[0].title}`
                        }
                      />

                      <CardMedia
                        {...getMediaType(post.photo, post.video)}
                        height="194"
                        controls
                      />

                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {post.content ? (
                            <details>
                              <summary
                                style={{ listStyle: 'none' }}
                                onClick={ToggleShift}
                              >
                                {post.content?.substr(0, 35)}
                                {toggle && post.content.length > 35
                                  ? '...'
                                  : ''}
                              </summary>
                              <p>
                                {post.content?.substr(
                                  35,
                                  post.content.length - 1,
                                )}
                              </p>
                            </details>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </CardContent>

                      <CardActions disableSpacing>
                        {/* <IconButton
                          aria-label="add to favorites"
                          onClick={() => {
                           
                          }}
                        >
                           <Button variant="outlined" color="success" onClick={()=>{setCurrIdx(idx);setCurrentEle(post);setRewardModal(true)}}>Reward</Button>
                        </IconButton> */}
                        <div className="d-flex justify-content-around w-100">
                        {post.didRewardGet==true &&localStorage.getItem('role')=='analysisuser'&& <Button variant="contained" color="success">Reward</Button>}
            {post.didRewardGet==false && localStorage.getItem('role')=='analysisuser'&&<Button variant="outlined" color="success" onClick={()=>{setCurrIdx(idx);setCurrentEle(post);setRewardModal(true)}}>Reward</Button>}
                       
                        {
                          <IconButton aria-label="delete" size="large">
                            <DeleteIcon
                              onClick={(z) => {
                                Deleter(post._id);
                              }}
                              color="error"
                              fontSize="inherit"
                            />
                          </IconButton>
                        }
                        </div>
                      </CardActions>
                    </Card>
                  </div>
                );
              })}
            {posts.length === 0 ? <h1>No Posts are Available</h1> : ' '}
          </div>
        </div>
        <RewardModal open={rewardModal} setOpen={setRewardModal} handleReward={sendReward} />
      </Container>
    </>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchAppBar = React.memo(
  ({ cb }) => {
    const handleInputChange = (e) => {
      cb(e.target.value);
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: '#7451f8' }}>
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleInputChange}
              />
            </Search>
          </Toolbar>
        </AppBar>
      </Box>
    );
  },
  () => true,
);

const SuperButton = ({ type, clickAction }) => {
  return (
    <Button variant="contained" color="primary" size="small">
      Super Spaark it!{' '}
      <FavoriteIcon style={{ color: '#fa4f4f', height: '17px' }} />
    </Button>
  );
};

export default GroupPosts;
