import styled from 'styled-components';

const Container = styled.div`
  display: flex;

  margin: 15px;
  justify-content: center;


  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;

    
  }
  .maindiv {
    padding: 1vh 2vw;
    margin-bottom: 5vh;
    text-align:left;
   
  }

  img{
    width:auto;
    height:100px;
  }
`;

export default Container;
