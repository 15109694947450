import { BrowserRouter, Routes, Route } from "react-router-dom";
import SideNavBar from '../../components/analysisComponents/SideNavBar/SideNavBar.jsx';
import AnalysisDashBoard from '../../pages/analysisPages/DashBoard/AnalysisDashBoard.jsx';
import StartupDailog from '../../pages/analysisPages/StartupDailog/StartuDailog.jsx';
import TelecallerLeads from '../../pages/analysisPages/TelecallerLeads/TelecallerLeads.jsx';
import LeadSummary from "../../pages/analysisPages/LeadSummary/LeadSummary.jsx";
import LeadsDetails from '../../pages/analysisPages/LeadsDetails/LeadsDetails.jsx';
import JobDashboard from "../../pages/OsosJobManagement/JobsManage.jsx";

function SuperRoutes() {

  return (
    <>
      <div className='d-flex'>
        <SideNavBar />
        <Routes>
          <Route index element={<AnalysisDashBoard />} />
          <Route path="home" element={<AnalysisDashBoard />} />
          <Route path="telecaller-leads" element={<TelecallerLeads />} />
          <Route path="lead-summary" element={<LeadSummary />} />
          <Route path="lead-details" element={<LeadsDetails />} />
          <Route path="jobsManage" element={<JobDashboard />} />
          <Route path="startupdailog" element={<StartupDailog />} />
        </Routes>
      </div>

    </>
  );
}

export default SuperRoutes;



