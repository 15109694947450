import react, { useEffect } from 'react';
import Container from './contestGroup.js';
import { useState } from 'react';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import ContestGroupTable from '../../../../components/analysisComponents/Table/ContestGroupTable/ContestGroupTable';
import ContestGroupForm from '../../../../components/analysisComponents/ContestForms/ContestGroupForm';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { HEADER_URL } from '../../../../api/constants.js';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height:500,
  margin:10,
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  boxSizing:"border-box",
  p: 4,
};

const ContestGroup = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [ContestGroups, SetContestGroups] = useState([]);
  const TOKEN = useSelector((state) => state.auth.token);

  const fetchData = async () => {
    await axios
      .get(`${HEADER_URL}/contestgroups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }, 
      })
      .then((res) => {
        console.log('array : ', res.data.data);
        SetContestGroups(res.data.data);
      })
      .catch((e) => {
        console.log('error occured in catch');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container>
        {/* <SideBar /> */}
        <div className="right">
          <div class="adder">
            <Fab onClick={handleOpen} color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
          <ContestGroupTable array={ContestGroups} />

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ContestGroupForm />
            </Box>
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default ContestGroup;
