import styled from 'styled-components';

const ClosedTicketContainer = styled.div`
  width: 100%;
  text-align: start;    
  background-color: #f8f9fe;

`;
    
export default ClosedTicketContainer;
