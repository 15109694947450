import { ExpandCircleDownTwoTone } from '@mui/icons-material';

export const userStatus = (active, canLogin) => {
  if (!canLogin) {
    return {
      class: 'Suspended',
      show: 'Suspended',
    };
  }

  if (active) {
    return {
      class: 'Approved',
      show: 'Active',
    };
  }

  return {
    class: 'Pending',
    show: 'Inactive',
  };
};

export const checkActivity = (lastseen, canLogin) => {
  if (!canLogin) {
    return {
      class: 'Suspended',
      show: 'Suspended',
    };
  }

  if (lastseen.includes('hour') || lastseen.includes('less')) {
    return {
      class: 'Approved',
      show: 'Active',
    };
  }

  if (lastseen.includes('day')) {
    return {
      class: 'Approved',
      show: 'Active',
    };
  }

  return {
    class: 'Pending',
    show: 'Inactive',
  };
};

export const rewardClimed = (scrached) => {
  if (scrached) {
    return {
      class: 'Approved',
      show: 'Yes',
    };
  }
  return {
    class: 'Pending',
    show: 'No',
  };
};

export const rewardExpired = (expired) => {
  if (expired) {
    return {
      class: 'Suspended',
      show: 'Yes',
    };
  }
  return {
    class: 'Approved',
    show: 'No',
  };
};
