import styled from "styled-components";


const Container = styled.div`
    .table {
  .cellWrapper {
    display: flex;
    align-items: center;
    color: black;
    flex-direction: column;
    justify-content: space-between;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }



  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
    &.Suspended {
      color: #BE421B !important;
      background-color: #FDE8E8 !important;
    }
  }

  .last-seen{
    margin: 0px;
    min-width: 77px;
    text-align: center;
    margin-top: 3px;
    font-size: 10px;
    font-family: 'Poppins',sans-serif;
    font-weight: 300;
    color: green;
    background-color: rgba(0, 128, 0, 0.151);
    padding: 2px;
    border-radius: 5px;
  }

  .btn-font-loc{
    font-size: 13px;
    color: #1C64F2 !important;
    background-color: #C3DDFC !important;
  }

  .btn-font-loc:hover{
    font-size: 13px;
    color: #C3DDFC !important;
    background-color: #1C64F2 !important;
  }

  .btn-font-del{
    font-size: 13px;
    color: #BE421B !important;
    background-color: #FDE8E8 !important;
  }

  .btn-font-del:hover{
    font-size: 13px;
    color: #FDE8E8 !important;
    background-color: #BE421B !important;
  }
}


`

export const SearchContainer = styled.div`
    /* padding: 20px; */
      .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

  .btn-font-ser{
    font-size: 13px;
    margin-left: 7px;
    color: #0C9780 !important;
    background-color: #D8ECD8 !important;
  }

  .btn-font-ser:hover{
    font-size: 13px;
    margin-left: 7px;
    color:  #D8ECD8 !important;
    background-color: #0C9780 !important;
    border-radius: 0px ;
  }
`

export default Container;