import ImageModelContainer from "./ImageModelContainer";
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

 
 
    p: 4,
  };
function ImageModel({image,height,width,open,setOpen}){
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    return <ImageModelContainer>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <img src={image} alt="image" height={height} width='auto' />
        </Box>
      </Modal>
    </ImageModelContainer>
}

export default ImageModel;