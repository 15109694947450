
const getButtonTextAndColor = (contestStartTime,contestEndTime)=>{

    // var s = new Date('2020-01-14T17:43:37.000Z').toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
    const cdate = new Date()
    const cutil=new Date(contestStartTime)
    const eutil=new Date(contestEndTime)
    const current=cdate.getTime();
    const start=cutil.getTime();
    const end=eutil.getTime();

    if((current<start)&&(current<end)){

        return {
            bgColor:"btn btn-primary",
            text:"Upcoming"
          }
    }
    else if((current>start)&&(current<end))
    {

        return {
            bgColor:"btn btn-success",
            text:"Onging"
          }

    }
    else{
        return{
            bgColor:"btn btn-light",
            text:"Over"
        }
    }
  
  }

  export default getButtonTextAndColor;

//   <button class="btn btn-success">Ongoing</button>:<button class="btn btn-light" disabled>Over</button>
  
//   `${getButtonTextAndColor().bgColor}`