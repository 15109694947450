import Container from './notificationDetailsCard.js';
import { useEffect, useState, useRef } from 'react';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Card from '@mui/material/Card';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNotifications,
  updateNotification,
  deleteNotification,
  approveNotification,
} from '../../../store/notificationSlice.js';

moment.locale('en-gb');
function NotificationDetailsCard(props) {
  const dispatch = useDispatch();

  const notification = useSelector(
    (state) => state.notification[props.querytype],
  );

  const TOKEN = useSelector((state) => state.auth.token);
  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openReshedule, setOpenReshedule] = useState(false);
  const [resheduleData, setResheduleData] = useState(undefined);
  const [currentId, setCurrentId] = useState('');
  const titleRef = useRef(null);
  const bodyRef = useRef(null);
  const editImageRef = useRef(null);
  const editDateRef = useRef(null);
  const [openSnack, setOpenSnack] = useState(false);
  const dateRef = useRef(null);
  const [transition, setTransition] = useState(undefined);
  const [date, setDate] = useState(moment(Date.now()));
  const imageURlRef = useRef(null);

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/notification/getnotifications`;

    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const data = res.data;
        dispatch(setNotifications(data));
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [props.querytype]);

  //Approver
  const ApprovePopup = () => {
    return (
      <>
        <Modal
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
          open={openApprove}
          onClose={() => setOpenApprove(false)}
        >
          <ModalDialog variant="outlined" role="alertdialog">
            <Typography
              id="alert-dialog-modal-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb="0.25em"
              startDecorator={<WarningRoundedIcon />}
            >
              Confirmation
            </Typography>
            <Typography
              id="alert-dialog-modal-description"
              textColor="text.tertiary"
              mb={3}
            >
              Are you sure you want to Approve?
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button
                variant="plain"
                color="neutral"
                onClick={() => setOpenApprove(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ background: ' #0C9780' }}
                variant="solid"
                onClick={() => handleApprove()}
              >
                Approve
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      </>
    );
  };

  const handleApprove = async () => {
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/notification/approve?noteId=${currentId !== '' && currentId}`;
    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch(approveNotification({ noteId: currentId }));
        setOpenApprove(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openApproveModal = (id) => {
    setCurrentId(id);
    setOpenApprove(true);
  };

  //Deleter
  const DeletePopup = () => {
    return (
      <>
        <Modal
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalDialog variant="outlined" role="alertdialog">
            <Typography
              id="alert-dialog-modal-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb="0.25em"
              startDecorator={<WarningRoundedIcon />}
            >
              Confirmation
            </Typography>
            <Typography
              id="alert-dialog-modal-description"
              textColor="text.tertiary"
              mb={3}
            >
              Are you sure you want to Delete?
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button
                variant="plain"
                color="neutral"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ background: '#BE421B' }}
                variant="solid"
                color="danger"
                onClick={() => handleDelete()}
              >
                Delete
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      </>
    );
  };

  const openDeleteModal = (id) => {
    setCurrentId(id);
    setOpen(true);
  };

  const handleDelete = async () => {
    setOpen(false);
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/notification/delete?noteId=${currentId !== '' && currentId}`;
    await axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch(
          deleteNotification({ type: props.querytype, noteId: currentId }),
        );
      })
      .catch((err) => {
        alert('Something went wrong!');
      });
  };

  const EditPopup = (props) => {
    const note = notification.find((item) => String(item._id) === currentId);

    return (
      <>
        <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setOpenEdit(false);
              }}
            >
              <Stack spacing={2}>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Title</label>
                  <input
                    type="text"
                    defaultValue={note.title}
                    class="form-control"
                    id="exampleFormControlInput1"
                    ref={titleRef}
                  />
                </div>

                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Body</label>
                  <textarea
                    class="form-control"
                    defaultValue={note.body}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    ref={bodyRef}
                  ></textarea>
                </div>
                <div className="form-group mt-3">
                  <label for="sendAt" className="title">
                    Send At
                  </label>
                  <input
                    ref={editDateRef}
                    defaultValue={moment(note.sendAt).format().split('+')[0]}
                    type="datetime-local"
                    name="sendAt"
                    id="sendAt"
                    className="form-control ml-4"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="editImage">image</label>
                  <input
                    class="form-control"
                    type="file"
                    id="editImage"
                    name="photo"
                    ref={editImageRef}
                  />
                </div>

                <div class="form-group">
                  <label for="editImage2">imageURL</label>
                  <input
                    class="form-control"
                    type="text"
                    id="editImage2"
                    name="photo"
                    ref={imageURlRef}
                  />
                </div>

                {note.image === '' ? (
                  'no image'
                ) : (
                  <img
                    src={note.image}
                    style={{ maxHeight: '50px', maxWidth: '100px' }}
                  />
                )}

                <Button
                  style={{ background: 'green', color: 'white' }}
                  onClick={handleEdit}
                  type="submit"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </ModalDialog>
        </Modal>
      </>
    );
  };

  //handler
  const handleEdit = async (e) => {
    e.preventDefault();
    setOpenEdit(false);
    const formData = new FormData();
    formData.append('title', titleRef.current.value);
    formData.append('body', bodyRef.current.value);
    formData.append('sendAt', editDateRef.current.value);
    formData.append('noteId', currentId);

    if (editImageRef.current.files.length) {
      formData.append('photo', editImageRef.current.files[0]);
    } else if (imageURlRef.current.value.length > 0) {
      formData.append('imageURL', imageURlRef.current.value);
    } else {
      formData.append(editImageRef.current.name, '');
    }

    const data = {
      title: titleRef.current.value,
      body: bodyRef.current.value,
      noteId: currentId,
    };
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/notification/update`;
    await axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        console.log('data:', res);
        data.image = res.data.note.image;
        data.sendAt = res.data.note.sendAt;
        dispatch(updateNotification({ type: props.querytype, data }));
      })
      .catch((err) => {
        console.log('ERR', err);
        alert('something went wrong!');
      });
  };

  const openEditModal = (id) => {
    setCurrentId(id);
    setOpenEdit(true);
  };

  const openResheduleModel = (item) => {
    setResheduleData(item);
    setOpenReshedule(true);
  };

  const resheudleNotification = () => {
    const e = new Event('build');
    const sendAt = new Date(dateRef.current.value);
    setResheduleData({ ...resheduleData, sendAt });
    props.reschedule(e, { ...resheduleData, sendAt: sendAt });
    setOpenReshedule(false);
    setOpenSnack(true);
    setTimeout(() => {
      setOpenSnack(false);
    }, 3000);
  };

  const ResheduleNotification = () => {
    return (
      <>
        <Modal open={openReshedule} onClose={() => setOpenReshedule(false)}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setOpenReshedule(false);
              }}
            >
              <Stack spacing={2}>
                <div className="date">
                  <div className="form-group mt-3">
                    <label for="sendAt" className="title">
                      Send At
                    </label>
                    <input
                      ref={dateRef}
                      type="datetime-local"
                      min={moment().format().split('+')[0]}
                      name="sendAt"
                      id="sendAt"
                      className="form-control ml-4"
                    />
                  </div>
                </div>
                <Button
                  style={{ background: 'green', color: 'white' }}
                  onClick={() => resheudleNotification()}
                  type="submit"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </ModalDialog>
        </Modal>
      </>
    );
  };

  if (notification && notification.length === 0) {
    return (
      <>
        <Container>
          <h1
            style={{
              textAlign: 'center',
              fontFamily: "'poppins',san-serfs",
              fontWeight: '300',
              fontSize: '30px',
            }}
          >
            Items not found!
          </h1>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        {notification &&
          notification.length !== 0 &&
          [...notification].map((item) => (
            <Card
              sx={{
                // height: '60px',
                // width: '800px',
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                padding: '5px',
                margin: '10px',
              }}
            >
              <div className="icon">
                <NotificationsNoneIcon />
              </div>
              <div className="content">
                <p className="text">{item.title}</p>
                <p className="text">{item.body}</p>
                {item.image ? (
                  <img
                    src={item.image}
                    style={{ maxHeight: '50px' }}
                    alt="image broken"
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="dt">
                <p className="time">{moment(item.sendAt).format('LT')}</p>
                <p className="date">
                  {moment(item.sendAt).format('DD/MM/YYYY')}
                </p>
              </div>
              <div className="icon-del">
                <DeleteIcon onClick={() => openDeleteModal(item._id)} />
              </div>

              {props.querytype !== 'sent' && (
                <div className="icon-edit">
                  <EditIcon onClick={() => openEditModal(item._id)} />
                </div>
              )}

              {props.querytype === 'pending' && (
                <div className="icon-approve">
                  <DoneIcon onClick={() => openApproveModal(item._id)} />
                </div>
              )}
              {props.querytype === 'sent' && (
                <div className="icon-reschedule">
                  <EventRepeatRoundedIcon
                    onClick={() => openResheduleModel(item)}
                  />
                </div>
              )}
            </Card>
          ))}
        {openEdit && <EditPopup />}
        {open && <DeletePopup />}
        {openApprove && <ApprovePopup />}
        {openReshedule && <ResheduleNotification />}
        <Snackbar
          open={openSnack}
          onClose={handleClose}
          TransitionComponent={transition}
          message="FCM Resheduled"
          key={transition ? transition.name : ''}
          sx={{
            backgroundColor: 'green',
          }}
        />
      </Container>
    </>
  );
}

export default NotificationDetailsCard;
