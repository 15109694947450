import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../../pages/login/Login.jsx';
import SideNavBar from '../../components/analysisComponents/SideNavBar/SideNavBar.jsx';
import AnalysisDashBoard from '../../pages/analysisPages/DashBoard/AnalysisDashBoard.jsx';



import Wizard from '../../pages/analysisPages/SpaarksWizard/Wizard.jsx';
import WizardSendMessage from '../../pages/analysisPages/SpaarksWizard/WizardSendMessage.jsx';
import WizardAnalytics from '../../pages/analysisPages/SpaarksWizard/WizardAnalytics.jsx';
import WizardDashboard from '../../pages/analysisPages/SpaarksWizard/WizardDashboard.jsx';
import FirstScreen from '../../pages/analysisPages/SpaarksWizard/WizardFirstPage.jsx';
import SecondScreen from '../../pages/analysisPages/SpaarksWizard/WizardSecondPage.jsx';
import ThirdScreen from '../../pages/analysisPages/SpaarksWizard/WizardThirdPage.jsx';




import ReadCSV from '../../pages/analysisPages/SpaarksWizard/ReadCSV.jsx';

function WizardRoutes() {
  return (
    <>
      <div className="d-flex">
        <SideNavBar />
        <Routes>
        <Route  element={<AnalysisDashBoard />} />
        <Route path="h" element={<h1>HELOOOOO</h1>} />

          {/* <Route path="wizard" element={<Wizard />} />
          <Route path="wizardAnalytics" element={<WizardAnalytics />} />
          <Route path="WizardDashboard" element={<WizardDashboard />} /> */}

          <Route path="wizard/sendMessage" element={<WizardSendMessage />} />
          <Route path="readcsv" element={<ReadCSV />} />
          <Route path="wizardfirst" element={<FirstScreen />} />
          <Route path="wizardsecond" element={<SecondScreen />} />          
          <Route path="wizardthird" element={<ThirdScreen />} />          

        </Routes>
      </div>
    </>
  );
}

export default WizardRoutes;
