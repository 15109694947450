import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import Button from '@mui/material/Button';
import moment from 'moment/moment';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const Wizard = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [crmWizardUsers, setCrmWizardUsers] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({});
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const crmWizardRandomUsers = [
    { name: 'Yes', value: 2 },
    { name: 'No', value: 9 },
  ];

  const COLORS = ['#50a31f','#c83232'];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  function fetchCustomAnalytics() {
    setLoading(true);

    let url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/need-service-posts?`;
    if (from != '') {
      url += `from=${from}`;
    }
    if (to != '') {
      url += `&to=${to}`;
    }

    console.log(url);
    axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        console.log('_______after searching_____');
        console.log(res.data);
        console.log('____________');
        setAnalyticsData(res.data.data);
        setCrmWizardUsers([
          { name: 'Yes', value: res.data.data.respondedYes },
          { name: 'No', value: res.data.data.respondedNo },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  useEffect(() => {
    function fetchonLoad() {
      setLoading(true);

      let fromCurr = '2022-06-15';
      let toCurr = moment().format().slice(0, 10);

      let url = `${HEADER_URL}/${localStorage.getItem(
        'role',
      )}/dashboard/sparkswizard/getWizardAnalytics?`;

      if (fromCurr != '') {
        url += `from=${fromCurr}`;
      }
      if (toCurr != '') {
        url += `&to=${toCurr}`;
      }

      console.log(url);
      axios(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          console.log('____________');
          console.log(res.data.data);
          console.log('____________');
          setAnalyticsData(res.data.data);
          setCrmWizardUsers([
            { name: 'Yes', value: res.data.data.respondedYes },
            { name: 'No', value: res.data.data.respondedNo },
          ]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
    fetchonLoad();
  }, [reset]);


  return (
    <>
      <div className="container-fluid d-flex flex-column container w-100 align-items-baseline">
        <div className="up d-flex flex-row justify-content-center w-100 align-items-end text-left">
          <div className="d-flex flex-column">
            <label>From </label>
            <input
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
              }}
              max={moment().format().slice(0, 16)}
              type="date"
              name="from"
              id="from"
              className="form-control"
            />
          </div>
          <div className="d-flex flex-column ms-2">
            <label>To </label>
            <input
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
              }}
              max={moment().format().slice(0, 16)}
              type="date"
              name="to"
              id="to"
              className="form-control"
            />
          </div>

          <Button
            variant="contained"
            onClick={() => {
              fetchCustomAnalytics();
            }}
            className="ms-2"
          >
            Search
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              setFrom('');
              setTo('');
              setReset(!reset);
            }}
            className="ms-2"

          >
            Reset
          </Button>
        </div>
        {crmWizardUsers[0]?.value ? (
          <div
            className="down d-flex flex-row mt-4"
            style={{ width: '100%', height: 500 }}
          >
            <div className=" shadow w-50 shadow-sm">
              <h4>CRM Wizards Users</h4>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                  <Pie
                    data={crmWizardUsers}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                      label={renderCustomizedLabel}
                    // label
                    labelType="square"
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {crmWizardUsers.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    verticalAlign="bottom"
                    align="middle"
                    height={36}
                    content="CRM Wizards Users"
                    iconSize={29}
                  />
                </PieChart>
              </ResponsiveContainer>
              <h4>Total Users: {analyticsData.totalUsers}</h4>

            </div>

            <div className=' shadow w-50 shadow-sm'>
              <h4>Random Users</h4>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={300} height={400}>
                  <Pie
                    data={crmWizardRandomUsers}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                      label={renderCustomizedLabel}
                    // label
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    labelType="square"
                  >
                    {crmWizardRandomUsers.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    verticalAlign="bottom"
                    align="middle"
                    height={36}
                    content="Random Users"
                    iconSize={29}
                  />
                </PieChart>
              </ResponsiveContainer>
              <h4>Total Users: 12</h4>
              {/* <h4>Total Users: {analyticsData.totalUsers}</h4> */}

            </div>
          </div>
        ) : (
          <h3 className="mt-2">Nothing..</h3>
        )}
      </div>
    </>
  );
};

export default Wizard;

/*
userName
Phone
ProfilePic
*/
