import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import axios from 'axios';
import { HEADER_URL } from '../../../../api/constants.js';
import InfiniteScroll from 'react-infinite-scroll-component';

import Container from './rewardTable.js';
import { rewardClimed, rewardExpired } from '../../../../utils/userStatus';
import moment from 'moment';

const RewardsTable = ({ date, fetch }) => {
  console.log("PROPS is ", date, fetch);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [prevFetch, setPrevFetch] = useState('created');
  const [prevDate, setPrevDate] = useState(date);
  const TOKEN = useSelector((state) => state.auth.token);

  const updateData = (id) => {
    const updatedData = tableData.map((item) => {
      if (String(item._id) === String(id)) {
        item.expired = true;
        return item;
      }
      return item;
    });

    setTableData(updatedData);
  };

 

  const fetchTableData = async () => {
    let p = page;
    if (prevFetch !== fetch || prevDate !== date) p = 1;
    await axios
      .get(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/reward/table-data?date=${moment(date[0].startDate).format(
          'L',
        )}&to=${moment(date[0].endDate).format('L')}&page=${p}&type=${fetch}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          alert('Something went wrong!!');
        }
        const { current_data } = res.data;
        current_data.length < 20 ? setMore(false) : setMore(true);
        if (prevFetch !== fetch || prevDate !== date) {
          console.log('inside if');
          setTableData([...current_data]);
          setPage(2);
          setPrevFetch(fetch);
          setPrevDate(date);
          console.log('page2:', page);
        } else {
          console.log('inside else');
          setTableData([...tableData, ...current_data]);
          setPage(page + 1);
          console.log('page3:', page);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };


 


  useEffect(() => {
    fetchTableData(fetch);
  }, [fetch, date]);

  return (
    <Container>
      <TableContainer component={Paper} className="table">
        {/* <h2>{pageNo} - { fetch} -        {tableData.length}</h2> */}
        <InfiniteScroll
          dataLength={tableData.length !== 0 && tableData.length}
          next={fetchTableData}
          hasMore={more}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">#</TableCell>
                <TableCell className="tableCell">Name</TableCell>
                <TableCell className="tableCell">Refferd By</TableCell>
                <TableCell className="tableCell">Time</TableCell>
                <TableCell className="tableCell">Claimed</TableCell>
                <TableCell className="tableCell">Expired</TableCell>
                <TableCell className="tableCell">Amount</TableCell>
                {fetch === 'created' ? (
                  <TableCell className="tableCell">Action</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            {console.log(tableData)}
            <TableBody>
              {tableData.length &&
                tableData.map((item, index) => (
                  <TableRow key={index + 1} id={'i' + index}>
                    <TableCell className="tableCell">{index + 1}</TableCell>
                    <TableCell className="tableCell">
                      {item.user_got_reffered_name ? (
                        item.user_got_reffered_name
                      ) : (
                        <span className={`status btn-font-loc`}>
                          {item.scratchCardType}
                        </span>
                      )}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.reffered_user_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {moment(item.date).format('MMM Do YY hh:mma')}
                    </TableCell>
                    <TableCell className="tableCell">
                      <span
                        className={`status ${
                          rewardClimed(item.scrached).class
                        }`}
                      >
                        {rewardClimed(item.scrached).show}
                      </span>
                    </TableCell>
                    <TableCell className="tableCell">
                      <span
                        className={`status ${
                          rewardExpired(item.expired).class
                        }`}
                      >
                        {rewardExpired(item.expired).show}
                      </span>
                    </TableCell>
                    <TableCell className="tableCell">
                      <span className="status Approved">
                        {'₹' + item.amount}
                      </span>
                    </TableCell>
                    {fetch === 'created' ? (
                      <ExpireButton
                        expired={item.expired}
                        id={item._id}
                        token={TOKEN}
                        update={updateData}
                      />
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Container>
  );
};

const ExpireButton = ({ expired, id, token, update }) => {
  const handleExpire = (id) => {
    axios
      .post(
        `${HEADER_URL}/${localStorage.getItem(
          'role',
        )}/dashboard/reward/expire-reward`,
        { rewardId: id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          alert('Something went wrong!!');
        }

        update(id);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  if (expired) {
    return (
      <TableCell className="tableCell">
        <span className="status Suspended">Expired</span>
      </TableCell>
    );
  }
  return (
    <TableCell className="tableCell">
      <button
        className="btn btn-font-del"
        onClick={() => {
          try {
            handleExpire(id);
          } catch (err) {
            console.log(err);
          }
        }}
      >
        Expire
      </button>
    </TableCell>
  );
};

export default RewardsTable;
