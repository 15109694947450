import { useSelector } from 'react-redux';
import Container from './LastWeekReward';
import SideBar from '../../../components/analysisComponents/SideBar/SideBar.jsx';
import Widget from '../../../components/analysisComponents/Widget/Widget';
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import { useState, useEffect, memo } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import icons from '../../../utils/icons';

import RewardsOneWeekTable from '../../../components/analysisComponents/RewardsOneWeekTable/RewardsOneWeekTable.jsx';

import RewardsTable from '../../../components/analysisComponents/Table/RewardTable/RewardTable';
import moment from 'moment';
import Chooser from '../../../components/analysisComponents/Charts/Chooser/Chooser';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const rewards_options = ['initiated', 'created', 'processed'];

function LastWeekReward() {
  const TOKEN = useSelector((state) => state.auth.token);
  const [cards, setCards] = useState([]);
  const [date, setDate] = useState(moment(Date.now()));
  const [refetch, setRefetch] = useState('created');
  const d = new Date();
  const [dateRanges, setDateRange] = useState([
    {
      startDate: d.setDate(d.getDate() - 7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [showDateRange, setShowDateRange] = useState(false);

  const getData = () => {
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/reward/get-counts?date=${moment(
      dateRanges[0].startDate,
    ).format('L')}&to=${moment(dateRanges[0].endDate).format('L')}`;
    console.log(":::::GET DATA Ln 42", url);

    axios
      .get(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        console.log('DATA', res.data);
        setCards(res.data.rewardCounts);
        // setRefetch(!refetch)
      })
      .catch((err) => {
        console.log('err', err);
        alert('something went wrong!');
      });
  };

  const getOptionFromChild = (data) => {
    setRefetch(data.value);
  };

  useEffect(() => {
    getData();
    setShowDateRange(false);
  }, []);

  const handleClick = () => {
    setShowDateRange(true);
  };

  const showDate = () => {
    if (!showDateRange) {
      return (
        <>
          <div>
            <Chip
              label={`${moment(dateRanges[0].startDate).format(
                'DD-MM-YY',
              )} to ${moment(dateRanges[0].endDate).format('DD-MM-YY')}`}
              variant="outlined"
            //   onClick={handleClick}
              disabled={true}
            />
            {/* -
                            <Chip label={moment(dateRanges[0].endDate).format("DD/MM/YY")} variant="outlined" onClick={handleClick} /> */}
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Container>
        {/* <SideBar /> */}
        <div className="right">
          <h1 className="title-re">Last 7 Days Rewards</h1>
          <div className="date-picker-container">
            {showDate()}
            {/* <div
              style={{
                position: 'absoulte',
                display: showDateRange ? 'block' : 'none',
              }}
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRanges}
                disabled={true}
              />
            </div> */}
            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                        label={moment(date).format('LL')}
                        inputFormat="DD/MM/YYYY"
                        value={date}
                        onChange={setDate}
                        renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider> */}
          </div>
          <Chooser
            options={rewards_options}
            getOptionFromChild={getOptionFromChild}
            disabled={true}
          />

          {/* Total Spends */}
          <SpendingCard amount={Math.ceil(cards[2]?.amount ?? 0)} />

          <Widget rewards="rewards" data={cards} />

          <RewardsTable date={dateRanges} fetch={refetch} />
          {/* <RewardsOneWeekTable date={dateRanges} fetch={refetch} /> */}
        </div>
      </Container>
    </>
  );
}

const SpendingCard = memo(({ amount }) => {
  return (
    <>
      <Card className="card-spendings">
        <div className="top-card">
          <span>Total Spent</span>
          <div className="icon">{icons['monetize']}</div>
        </div>
        <div className="card-body">
          <span className="money">
            ₹{amount ?? '0'}
            <span className="inc-text">inclusive* </span>
          </span>
        </div>
      </Card>
    </>
  );
});

export default LastWeekReward;
