import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  function RejectedModal({setReason,open,setOpen,handleReject,setParentOpen}){

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  

     return  <Modal
     open={open}
     onClose={handleClose}
     aria-labelledby="child-modal-title"
     aria-describedby="child-modal-description"
   >
     <Box sx={{ ...style, width: 400}}>
     <div className="form-group">
            <label>Enter Reason</label>
            <textarea className="form-control" onChange={(e)=>{setReason(e.target.value)}} rows="3"></textarea>
        </div>
    <div className="d-flex justify-content-end">
    <Button variant="outlined" color="error" onClick={()=>{handleReject();setOpen(false);setParentOpen(false)}}>Reject</Button>
    </div>
     </Box>
   </Modal>

}

export default RejectedModal;