import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { HEADER_URL } from '../../../api/constants.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { SearchContainer } from './table.js';
import Snackbar from '@mui/material/Snackbar';
import * as moment from 'moment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from './table.js';
import { checkActivity } from '../../../utils/userStatus';
import { lastSeenAt } from '../../../utils/formatDate';

//user.canLogin = false;
const List = () => {
  const [openSnackBar, setSnackBar] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [searchKey, setSearchKey] = useState('');
  const searchRef = useRef(null);
  const reasonRef = useRef(null);
  // const [showLocation,setShowLocation] = useState(0)
  const TOKEN = useSelector((state) => state.auth.token);

  const fetchTableData = async () => {
    await axios
      .get(
        `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/users?page=${page}&search=${searchKey}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }, 
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          alert('Something went wrong!!');
        }

        const newUsers = res.data.allusers;
        newUsers.length < 10 ? setMore(false) : setMore(true);

        setTableData(tableData.concat(newUsers));
        setPage(page + 1);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const search = async () => {
    const key = searchRef.current.value;

    if (key.length === 0) {
      setMore(true);
    }

    await axios
      .get(
        `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/users?search=${key}&page=${1}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }, 
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          alert('Something went wrong!!');
        }

        setTableData([]);

        const newUsers = res.data.allusers;
        newUsers.length < 10 ? setMore(false) : setMore(true);
        setPage(2);
        setTableData(newUsers);
        setSearchKey(key);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const SearchBar = () => {
    return (
      <div className="navbar" style={{ margin: '15px' }}>
        <div className="wrapper" style={{ marginLeft: 'auto' }}>
          <div className="search">
            <input
              type="text"
              name="search"
              placeholder="Search..."
              ref={searchRef}
            />
            <SearchOutlinedIcon />
          </div>
        </div>
        <button className="btn btn-font-ser" onClick={search}>
          search
        </button>
      </div>
    );
  };

  const EditPopup = (props) => {
    return (
      <>
        <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setOpenEdit(false);
              }}
            >
              <Stack spacing={2}>
                <Typography
                  id="alert-dialog-modal-title"
                  component="h2"
                  level="inherit"
                  fontSize="1.25em"
                  mb="0.25em"
                  startDecorator={<WarningRoundedIcon />}
                >
                  Confirmation
                </Typography>
                <Typography
                  id="alert-dialog-modal-description"
                  textColor="text.tertiary"
                  mb={3}
                >
                  Are you sure you want to Suspend?
                </Typography>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Reason</label>
                  <textarea
                    class="form-control"
                    defaultValue={
                      'Your account has been suspended for spaming.'
                    }
                    id="exampleFormControlTextarea1"
                    rows="7"
                    cols="7"
                    ref={reasonRef}
                  ></textarea>
                </div>
                <Button
                  style={{ background: 'red', color: 'white' }}
                  onClick={handleEdit}
                  type="submit"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </ModalDialog>
        </Modal>
      </>
    );
  };

  const handleEdit = async (item) => {
    setOpenEdit(false);
    handleClick();
    let element = document.querySelector(`#i${currentId}`);
    element.innerHTML = '';

    const data = {
      reason: reasonRef.current.value,
      userId: currentId,
    };

    const url = `${HEADER_URL}/${localStorage.getItem('role')}/dashboard/suspend`;

    await axios
      .post(url, data, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }, 
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openEditModal = (id) => {
    setCurrentId(id);
    setOpenEdit(true);
  };

  const handleClick = () => {
    setSnackBar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <Container>
        <TableContainer component={Paper} className="table">
          <InfiniteScroll
            dataLength={tableData.length !== 0 && tableData.length}
            next={fetchTableData}
            hasMore={more}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">S.No</TableCell>
                  <TableCell className="tableCell">Profile Pic</TableCell>
                  <TableCell className="tableCell">User Details</TableCell>
                  <TableCell className="tableCell">App Version</TableCell>
                  <TableCell className="tableCell">Spaarks Created</TableCell>
                  <TableCell className="tableCell">Status</TableCell>
                  <TableCell className="tableCell">Location</TableCell>
                  <TableCell className="tableCell">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length !== 0 &&
                  tableData.map((item, index) => (
                    <TableRow key={index + 1} id={'i' + item._id}>
                      <TableCell className="tableCell">{index + 1}</TableCell>
                      <TableCell className="tableCell">
                        <div className="cellWrapper">
                          <img src={item.profilePic} alt="" className="image" />
                          <p className="last-seen">
                            {moment(item.locations.updatedAt).fromNow()}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell className="tableCell">
                        {(item.name ? item.name : 'N/A') +
                          ' ' +
                          (item.source ? `(${item.source})` : '')}{' '}
                        <br /> {item.phone ? item.phone : 'No phone'}
                      </TableCell>
                      <TableCell className="tableCell">
                        {item.os_flavour +
                          ' | ' +
                          item.currentAppVersion +
                          ' | ' +
                          item.phoneModel +
                          ' | ' +
                          item.phone_resolution +
                          ' | ' +
                          item.spaarks}
                      </TableCell>
                      <TableCell className="tableCell">{item.post}</TableCell>
                      <TableCell className="tableCell">
                        <span
                          className={`status ${
                            checkActivity(
                              lastSeenAt(item.locations.updatedAt),
                              item.canLogin,
                            ).class
                          }`}
                        >
                          {
                            checkActivity(
                              lastSeenAt(item.locations.updatedAt),
                              item.canLogin,
                            ).show
                          }
                        </span>
                      </TableCell>

                      <TableCell className="tableCell">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://www.google.com/maps/place/${item.locations.location?.coordinates[1]} ${item.locations.location?.coordinates[0]}`}
                        >
                          <button className="status Approved btn btn-font-loc">
                            Show Location
                          </button>
                        </a>
                      </TableCell>
                      <TableCell className="tableCell">
                        {item.canLogin && (
                          <button
                            className={`status ${'Pending'} btn btn-font-del`}
                            onClick={() => openEditModal(item._id)}
                          >
                            {'suspend'}
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
        <EditPopup />
      </Container>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="User Suspended Successfully!"
        action={action}
      />
    </>
  );
};

export default List;
