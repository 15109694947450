import AddLeadContainer from "./AddLead";
//Common imports
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { HEADER_URL,header } from '../../../api/constants.js';
import { useSelector } from "react-redux";
//  Mui Imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


function AddLead(){
    const TOKEN = useSelector((state) => state.auth.token);

    //Function to add lead
    var addLead=async()=>{
        
        // await axios.get(``, { method: 'GET',headers: header })
    }



    return <AddLeadContainer>
        <div className="display-6 text-primary text-center">Add Lead</div>
        
        <div className="d-flex flex-column justify-content-around gap-2 w-75 mx-auto mt-2 bg-white p-4 mb-5 rounded-4" style={{height:'90%',boxShadow:'2px 2px 4px 0.5px black'}}>
        {/* Category */}
        <div >

            <div className="d-flex justify-content-around gap-5">
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category :</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        //   value={age}
          label="Category :"
        //   onChange={handleChange}
        >
          <MenuItem value={10}>Agri Mall</MenuItem>
          <MenuItem value={20}>Daily Services</MenuItem>
          <MenuItem value={30}>Fitness</MenuItem>
        </Select>
        </FormControl>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sub Category :</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        //   value={age}
          label="Sub Category"
        //   onChange={handleChange}
        >
          <MenuItem value={10}>Agri Mall</MenuItem>
          <MenuItem value={20}>Daily Services</MenuItem>
          <MenuItem value={30}>Fitness</MenuItem>
        </Select>
        </FormControl>
            </div>





        </div>

        {/* Business Details */}
        <div>
        <h6 >Business Details : </h6>
        <div className="d-flex justify-content-around mt-2 gap-5">
        <TextField id="outlined-basic" label="Business Name :" variant="outlined" />
        <div className="d-flex flex-column w-100">
        <TextField id="outlined-basic" label="Business Phone" type="number"  variant="outlined" />
        <Button variant="contained" className='mt-2 w-25' color="success"> Check </Button>
        </div>
       
        </div>
        </div>
        {/* Location  */}
        <div className="d-flex justify-content-around gap-5">
        <TextField id="outlined-basic" label="Latitude" type="number"  variant="outlined" />
        <TextField id="outlined-basic" label="longitude" type="number"  variant="outlined" />
        </div>
        {/* File upload  profile pic*/}
        <div class=" w-50">
        <label for="formFile" class="form-label">Profile Pic :</label>
        <input class="form-control" type="file" id="formFile"/>
        </div>

        {/* Post Details */}
        <div className="w-50">
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type :</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        //   value={age}
          label="Type"
        //   onChange={handleChange}
        >
          <MenuItem value={10}>Service</MenuItem>
          <MenuItem value={20}>Seller</MenuItem>
         
        </Select>
        </FormControl>
        </div>

        {/* COntent Details */}

        <div className="w-75">
            <div  className="form-label">Content </div>
            <textarea className="form-control"rows="7"></textarea>
        </div>

        {/* Multiple files Select */}

        <div class="w-50">
        <label for="formFileMultiple" class="form-label">Photos</label>
        <input class="form-control" type="file" id="formFileMultiple" multiple />
        </div>


        <Button variant="contained" className='w-25 mx-auto ' color="success">   Submit </Button>


        </div>



    </AddLeadContainer>
}


export default AddLead;