import {useState} from 'react'
import SendFcmContainer from "./SendFcm";
//  Mui Imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


function SendFcm(){
    const [loading, setLoading] = useState(true);

    return <SendFcmContainer>
        
        <div className="w-50 p-5 bg-white bs d-flex flex-column justify-content-around gap-3 mt-5 mb-5">
        <div className="display-6 text-center text-primary">Send FCM</div>
            <TextField id="outlined-basic" label="Title :" variant="outlined" />
            <div >
            <div  className="form-label">Content </div>
            <textarea className="form-control"rows="7"></textarea>
             </div>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Notification Priority :</InputLabel>
                 <Select  labelId="demo-simple-select-label"  id="demo-simple-select" label="Notification Priority :"   >
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="high">High</MenuItem>
 
                </Select>
        </FormControl>
        <TextField id="outlined-basic" label="Notification Click Action :" variant="outlined" />
        <Button variant="contained" color="success"> Send Fcm </Button>
        </div>
    </SendFcmContainer>
}


export default SendFcm