import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./JobsManage.css"
const JobDashboard = () => {
    const [jobs, setJobs] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [isResume, setIsResume] = useState(false)
    const [currentResume, setCurrentResume] = useState("")
    const [Jstatus, setJStatus] = useState(); // Set the initial status here
    const [selectedJob, setSelectedJob] = useState(null)
    const [isCopied, setIsCopied] = useState(false);
    const ResumeHandler = (resume) => {
        setIsResume(true)
        setCurrentResume(resume)
    }

    // api call  
    useEffect(() => {
        // Fetch the list of jobs from the API
        axios.get('https://backend.ososweb.com/api/v1.0/jobs')
            .then(response => {
                setJobs(response.data.job);
            })
            .catch(error => {
                console.error('Error fetching jobs:', error);
            });
    }, []);
    const handleJobClick = (jobId) => {
        // Fetch the list of applicants for the selected job
        setSelectedJob(jobId);
        axios.get(`https://backend.ososweb.com/api/v1.0/applicants/${jobId}`)
            .then(response => {
                console.log("app----------------------", response.data);
                setApplicants(response.data);
            })
            .catch(error => {
                console.error('Error fetching applicants:', error);
            });
    };
    const handleStatus = (applicantId, Jstatus) => {

        axios.put(`https://backend.ososweb.com/api/v1.0/updatestatus/${applicantId}?status=${Jstatus}`)
            .then(response => {
                console.log("sucess");
                setJStatus(Jstatus);
                getColor()
            })
            .catch(error => {
                console.error('Error fetching applicants:', error);
            });
    };
    const getColor = () => {
        switch (status) {
            case "pending":
                return "gold";
            case "approved":
                return "green";
            case "rejected":
                return "red";
            default:
                return "grey";
        }
    };
    const closeModal = () => {
        const modalContainer = document.getElementById('modalContainer');
        modalContainer.style.display = 'none';
        setIsCopied(false)

    };
    useEffect(() => {
        setTimeout(() => {
            closeModal();
            setIsCopied(false)
        }, 2000);
    }, [isCopied])
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setIsCopied(true);

            const modalContainer = document.getElementById('modalContainer');
            modalContainer.style.display = 'flex';

            setTimeout(() => {
                closeModal();
                setIsCopied(false)
            }, 2000);
        } catch (error) {
            console.error('Copy to clipboard failed:', error);
        }
    };

    return (
        < div className="container-fluid" >
            <div className="row">
                <nav className='mt-4 mb-3  gap-2 '>
                    <ul className='d-flex p-2 list-unstyled text-primary border rounded gap-1 job-tabs'
                        style={{ cursor: "pointer", fontWeight: "bold", fontSize: "12px" }}
                    > <li onClick={() => handleJobClick("all")}
                        className={selectedJob === "all" ? "active border rounded cardSelect p-2 " : "border rounded cardSelect p-2"}
                    >All
                        </li>
                        {jobs && jobs.map(job => (
                            <li
                                className={selectedJob === job.title ? "active border rounded cardSelect p-2" : "border rounded cardSelect p-2"}
                                key={job._id} onClick={() => handleJobClick(job.title)}>
                                {job.title}
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="d-flex  f-monts justify-content-around ">
                    <div className='DivCOn '>
                        <h3 className='text-success fw-800 border-bottom f-monts mb-3 '>Applicants List</h3>
                        {
                            applicants.length === 0 ?
                                (<p>No applicants found for the selected job.</p>)
                                : (
                                    <main className=''>
                                        <ul className='border  shadow rounded-4 p-3 overflow-auto bodym ' style={{ maxHeight: "750px" }} >
                                            {applicants.applicant.map(applicant => (
                                                <li className='d-flex border cardSelect gap-2 mt-2 pt-auto rounded p-2'
                                                    key={applicant.id}>
                                                    <div className="col-2 pt-3 text-truncate ">
                                                        <h6 className="text-truncate">{applicant.name}
                                                            <p
                                                                style={{ fontSize: "12px" }}
                                                            >
                                                                <i class="bi bi-phone text-success text-truncate"></i> {applicant.phone}</p>
                                                        </h6>

                                                    </div>
                                                    <p className="mt-4 col-3 d-flex text-truncate" >
                                                        <i className="bi bi-envelope-at-fill me-1  text-success"></i>
                                                        <p className="d-flex  " onClick={() => copyToClipboard(applicant.email)} >

                                                            {applicant.email}
                                                        </p>
                                                    </p>
                                                    {isCopied &&
                                                        <div className="modal-container" id="modalContainer" onClick={closeModal}>
                                                            <div className="modal-bg" onClick={closeModal}></div>
                                                            <div className="modal-content">
                                                                <p id="copiedMsg" onClick={closeModal}>Email ID Copied!</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <p className="mt-4  "><i class="bi bi-calendar-month me-1 text-success"></i>{new Date(applicant.createdAt).toLocaleDateString()}</p>

                                                    <div className="mt-4">
                                                        <select
                                                            className={`rounded ${status && "selected"} text-success`}
                                                            value={applicant.status}
                                                            onChange={(e) => handleStatus(applicant._id, e.target.value)}>
                                                            <option style={{ color: getColor() }}>{applicant.status}</option>
                                                            <option value="pending" >Pending</option>
                                                            <option value="approved" >Approved</option>
                                                            <option value="rejected" >Rejected</option>
                                                        </select>
                                                    </div>
                                                    <p
                                                        onClick={() => ResumeHandler(applicant.resume)}
                                                        className="mt-4 d-flex ">View
                                                        <i class="ms-2 bi bi-file-earmark-person text-success"></i>
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </main>
                                )
                        }
                    </div>
                    <div className='DivCOn'>
                        <h3 className='text-success fw-800 border-bottom f-monts mb-3 '>Resume</h3>
                        <ul className=''  >
                            {!isResume ?
                                <div className='justfy-content-center'>
                                    please Select Any Applicant To preview Resume
                                </div>
                                :
                                <embed src={currentResume} type="application/pdf" width="550" height="700" className='rounded-3' />
                            }
                        </ul>
                    </div>

                </div>
            </div >
        </div >

    );
};

export default JobDashboard;


