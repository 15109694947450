import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import Container from './Contest.js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import moment from 'moment/moment';
import { HEADER_URL } from '../../../../api/constants';
import Modal from '@mui/material/Modal';
import SideBar from '../../../../components/analysisComponents/SideBar/SideBar.jsx';
import getButtonTextandColor from '../../../../utils/getButtonTextAndColor';
import { useLocation } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 600,
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Contest(props) {
  const navigate = useNavigate();
  const [contests, SetContests] = useState([]); //Contest Store
  const [contestUpdator, SetContestUpdator] = useState(1);
  const [count, SetCount] = useState(0);
  const [image, SetImage] = useState(null);
  const imgRef = useRef(null);
  const TOKEN = useSelector((state) => state.auth.token);
  const location = useLocation();

  //MUI
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //MUI ends here

  const [form, SetForm] = useState({
    contestGroup: '',
    contestName: '',
    contestType: 'please select',
    answerType: '',
    question: '',
    contestStartTime: '',
    contestEndTime: '',
  });

  const [polls, Setpolls] = useState({
    pollOption1: '',
    pollOption2: '',
    pollOption3: '',
    pollOption4: '',
  });

  async function FormSetter(e) {
    // const name = e.target.name;
    // const value = e.target.value;
    SetForm({ ...form, [e.target.name]: e.target.value });
  }

  //Poll Setter
  async function PollSetter(e) {
    Setpolls({ ...polls, [e.target.name]: e.target.value });
  }

  //Form Creation
  async function CreateContest(obj) {
    obj.preventDefault();

    const formData = new FormData();

    formData.append('contestGroup', form.contestGroup);
    formData.append('contestName', form.contestName);
    formData.append('answerType', form.answerType);

    formData.append('contestType', form.contestType);
    formData.append('question', form.question);
    formData.append('contestStartTime', form.contestStartTime);
    formData.append('contestEndTime', form.contestEndTime);

    if (form.contestType !== 'Poll') {
      formData.append('photo', image);
    }

    formData.append('contestGroupId', location.state.contestGroup);

    // contestGroupId

    const pollData = [];

    pollData.push(polls.pollOption1);
    pollData.push(polls.pollOption2);
    pollData.push(polls.pollOption3);
    pollData.push(polls.pollOption4);

    for (let i = 0; i <= 3; i++) {
      formData.append('pollOptions', pollData[i]);
    }

    console.log({
      form,
    });

    await axios
      .post(`${HEADER_URL}/contest/create`, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }, 
      })
      .then((res) => {
        SetContestUpdator(function (contestUpdator) {
          return (contestUpdator += 1);
        });
      })
      .catch((err) => {
        console.error('err', err);
        alert('something went wrong!');
      });
  }

  //Note: location.state.contestGroup is contestGroupId here

  //Fetching Contests

  const fetchData = async (prop) => {
    console.log('got data is : ', location.state.contestGroup);
    await axios
      .post(
        `${HEADER_URL}/contest/form`,
        { contestGroup: location.state.contestGroup },
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }, 
        },
      )
      .then((res) => {
        console.log('fetched contests are : ', res);
        SetContests(res.data.contests);
      })
      .catch((err) => {
        console.error('err', err);
        alert('something went wrong!');
      });
  };

  //Here im using 'contestUpdator' as dependent variable to run fetch again when form is submitted
  useEffect(() => {
    fetchData();
  }, [contestUpdator]);

  function CountInc(e) {
    SetCount(function (count) {
      return (count += 1);
    });
  }

  function AddChild(e) {
    const parent = document.getElementById('pollparent');
    const Child = document.createElement('input');
    Child.type = 'text';
    Child.name = 'newoption';
    Child.value = '';

    parent.appendChild(Child);
  }

  function imageSetter(e) {
    if (e.target.files) SetImage(e.target.files[0]);
  }

  return (
    <>
      <Container>
        {/* <SideBar /> */}
        <div className="right">
          <div onClick={handleOpen} class="adder">
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div>
                <form onSubmit={CreateContest}>
                  <div style={{ textAlign: 'center' }}>
                    <h1>Create Contest</h1>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Group:
                    </label>
                    <input
                      required
                      onChange={FormSetter}
                      placeholder="Christmas and New year"
                      value={form.Group}
                      name="contestGroup"
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      Name:
                    </label>
                    <input
                      required
                      onChange={FormSetter}
                      value={form.contestName}
                      name="contestName"
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="optionmenu1" class="form-label">
                      Type:
                    </label>
                    <select
                      required
                      onChange={FormSetter}
                      name="contestType"
                      class="form-select"
                      id="optionmenu1"
                      aria-label="Default select example"
                    >
                      <option selected>please select</option>
                      <option value="Poll">Poll</option>
                      <option value="Hidden">Hidden</option>
                      <option value="Puzzle">Puzzle</option>
                      <option value="Riddle">Riddle</option>
                      <option value="Missing Word">Missing Word</option>
                    </select>
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputPassword3" class="form-label">
                      Question:
                    </label>
                    <input
                      required
                      onChange={FormSetter}
                      value={form.question}
                      name="question"
                      type="text"
                      class="form-control"
                      id="exampleInputPassword3"
                    />
                  </div>

                  {
                    //renering options based on Contest type
                    form.contestType === 'Poll' ? (
                      <div id="pollparent">
                        {' '}
                        <div class="mb-3">
                          <label for="exampleInputPassword5" class="form-label">
                            option :1
                          </label>
                          <input
                            required
                            onChange={PollSetter}
                            value={polls.pollOption1}
                            name="pollOption1"
                            type="text"
                            class="form-control"
                            id="exampleInputPassword5"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword6" class="form-label">
                            option :2
                          </label>
                          <input
                            required
                            onChange={PollSetter}
                            value={polls.pollOption2}
                            name="pollOption2"
                            type="text"
                            class="form-control"
                            id="exampleInputPassword6"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword7" class="form-label">
                            option :3
                          </label>
                          <input
                            required
                            onChange={PollSetter}
                            value={polls.pollOption3}
                            name="pollOption3"
                            type="text"
                            class="form-control"
                            id="exampleInputPassword7"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword8" class="form-label">
                            option :4
                          </label>
                          <input
                            required
                            onChange={PollSetter}
                            value={polls.pollOption4}
                            name="pollOption4"
                            type="text"
                            class="form-control"
                            id="exampleInputPassword8"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <button class="btn btn-primary" onClick={AddChild}>
                            Add Option
                          </button>
                        </div>
                      </div>
                    ) : form.contestType === 'Hidden' ||
                      form.contestType === 'Puzzle' ||
                      form.contestType === 'Missing Word' ||
                      form.contestType === 'Riddle' ? (
                      <div class="mb-3">
                        <label for="exampleInputPassword9" class="form-label">
                          Image :
                        </label>
                        <input
                          required
                          onChange={imageSetter}
                          name="file"
                          type="file"
                          class="form-control"
                          ref={imgRef}
                          id="exampleInputPassword9"
                        />
                      </div>
                    ) : (
                      ''
                    )
                  }

                  <div class="mb-3">
                    <label for="optionmenu4" class="form-label">
                      Answer Type:
                    </label>
                    <select
                      required
                      name="answerType"
                      onChange={FormSetter}
                      class="form-select"
                      id="optionmenu4"
                      aria-label="Default select example"
                    >
                      <option selected>please select</option>
                      <option value="text">Text</option>
                      <option value="image">Image</option>
                      <option value="poll">Poll</option>
                    </select>
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputEmail5" class="form-label">
                      Start Time:
                    </label>
                    <input
                      required
                      onChange={FormSetter}
                      name="contestStartTime"
                      type="datetime-local"
                      class="form-control"
                      id="exampleInputEmail5"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputEmail6" class="form-label">
                      End Time:
                    </label>
                    <input
                      required
                      onChange={FormSetter}
                      name="contestEndTime"
                      type="datetime-local"
                      class="form-control"
                      id="exampleInputEmail6"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div class='GroupButton'>
                  <button type="submit" class="btn btn-primary">
                     create
                  </button>
                  </div>

                </form>
              </div>
            </Box>
          </Modal>
          <div class="contesttable">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Type</th>
                  <th scope="col">Question</th>
                  <th scope="col">From</th>
                  <th scope="col">To</th>
                  <th scope="col">Status</th>
                  <th scope="col">Details</th> 
                </tr>
              </thead>
              <tbody>
                {contests.map((i, idx, contests) => {
                  return (
                    <tr>
                      <td scope="row">{idx + 1}</td>
                      <td>{i.contestType}</td>
                      <td>{i.question}</td>
                      <td>
                        {moment(i.contestStartTime).format('lll')} ,
                        {/* {moment(i.contestStartTime).format('ll')} */}
                      </td>
                      <td>
                        {moment(i.contestEndTime).format('lll')} ,
                        {/* {moment(i.contestEndTime).format('LT')} */}
                      </td>
                      <td>
                        <button
                          class={
                            getButtonTextandColor(
                              i.contestStartTime,
                              i.contestEndTime,
                            ).bgColor
                          }
                        >
                          {
                            getButtonTextandColor(
                              i.contestStartTime,
                              i.contestEndTime,
                            ).text
                          }
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={(e) => {
                            if(localStorage.getItem("role") == 'analysisuser'){
                              navigate('/analysis/getresult', {
                                state: { contestid: i._id },
                              });
                            }
                            if(localStorage.getItem("role") == 'marketing'){
                              navigate('/marketing/getresult', {
                                state: { contestid: i._id },
                              });
                            }
                          }}
                          class="btn btn-primary"
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Contest;
