import React, { useState } from 'react';
import { HEADER_URL } from '../../../api/constants.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import './Wizard.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import WizardContainer from './Wizard.js';


import { Navigate, useLocation } from 'react-router-dom';

const WizardSendMessage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedServiceProviders = location.state.selectedServiceProviders;
  const needPostDetails = location.state.postDetails;
  console.log('*************');
  console.log(needPostDetails);
  console.log('*************');
  const [data, setData] = useState(selectedServiceProviders);
  const [content, setContent] = useState('');
  const [sendSpaark, setSendSpaark] = useState(false);
  const [sendWhatsapp, setSendWhatsapp] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [sendMsgDisabled, setSendMsgDisabled] = useState(false);


  // function onChangeHandler(e) {
  //   setContent(e.target.value);
  // }

  function getCallStatus(status) {
    let obj = {
      connected: 'Connected',
      notConnected: 'Not Connected',
      busy: 'Busy',
      switchOff: 'Switch Off',
      notContacted: 'Not Contacted',
    };
    return obj[status];
  }

  console.log("in component")
  console.log(sendMail,sendWhatsapp,sendSpaark)
 function onSubmitHandler() {
    if (data.length == 0) {
      alert("Can't Proceed");
      return;
    }
    console.log(sendSpaark,sendWhatsapp,sendMail)
    if((sendSpaark==false) && (sendWhatsapp==false) && (sendMail==false)) {
      alert("Please Select any Platform")
      return
    } 
    setSendMsgDisabled(true)
    const jidArray = data.map((e) => {
      return {
        jid: e.jid,
        _id: e._id,
        postId: false,
        name: e.name,
        email: e.email,
        phone: e.phone,
        isSpaarksUser: e.isSpaarksUser,
        callStatus: e.callStatus
      };
    });
    console.log('jidArray', jidArray);
    let requestBody = {};
    requestBody.serviceProvidersList = jidArray;
    // requestBody.content = content;
    requestBody.postDetails = needPostDetails;
    requestBody.sendSpaark = sendSpaark;
    requestBody.sendWhatsapp = sendWhatsapp;
    requestBody.sendMail = sendMail;
    console.log("requestBody",requestBody);
    console.log("HITTTTT")
    const url = `${HEADER_URL}/${localStorage.getItem(
      'role',
    )}/dashboard/sparkswizard/sendMessageByWizard`;
    console.log(url);
    alert('Message have been sent Successfully');
    setTimeout(()=>{
      navigate(-1);
    },1000)
    axios(url, {
      method: 'POST',
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
       console.log(res);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  return (
   
    <WizardContainer>
      <div className="container-fluid d-flex justify-content-between text-start">
        <div className="w-25 p-3">
          <FormGroup>
            <FormControlLabel  control={<Checkbox />} label="Mail" onClick={()=>setSendMail(!sendMail)} />
            <FormControlLabel
              control={<Checkbox />}
              label="Whatsapp"
              onChange={()=>setSendWhatsapp(!sendWhatsapp)}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Spaarks"
              onChange={()=>setSendSpaark(!sendSpaark)}
            />
          </FormGroup>

          <Button
            variant="contained"
            color="success"
            id="send"
            onClick={onSubmitHandler}
            disabled={sendMsgDisabled}
          >
            Send Message
          </Button>
        </div>

        <div className="" style={{ height: '100vh', width: '70vw' }}>
          <table className="table table-hover rounded shadow">
            <thead className="table-dark">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Profile Link</th>
                <th>Call Status</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((ele, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td className=" w-5">
                        <div className="d-flex    text-start ">
                          <img
                            src={ele.profilePic}
                            className="profile-img"
                          />
                          <div>
                            <h5>{ele.name}</h5>
                            <h6>{ele.phone}</h6>
                          </div>
                        </div>
                      </td>
                      <td className=" w-25 ">
                        <a
                          href={`https://www.spaarksweb.com/user/${ele.username}#market`}
                          target="_blank"
                          className="text-center"
                        >
                          {ele.username ? 'Show Profile' : ''}
                        </a>
                        <br />
                        <div
                          className={
                            ele.isSpaarksUser ? 'spaarkUser' : 'nonSpaarkUser'
                          }
                        >
                          {ele.isSpaarksUser ? 'spaarks' : 'non-spaarks'}
                        </div>
                      </td>
                      <td className='text-left'><p> {ele.callStatus ? getCallStatus(ele.callStatus) : 'Not Recorded'}</p></td>

                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </WizardContainer>
  );
};

export default WizardSendMessage;
