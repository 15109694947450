import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width:100%;
  margin: 15px;
  justify-content: center;

  .right {
    display: flex;
    flex-direction: column;
   
  }
`;

export default Container;
